import React, { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { ApiService } from '../../services/apiUtility'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactQuill from 'react-quill'

const ConsentInformation = () => {
    const navigate = useNavigate()
    const { setShowNotification, brandSettings, getBrandSettings, setBrandSettings } = useContext(AppContext)
    const [consentInformation, setConsentInformation] = useState('')

    useEffect(() => {
        console.log(brandSettings?.setting_meta_json?.consent_information);
        setConsentInformation(brandSettings?.setting_meta_json?.consent_information || '')
    }, [brandSettings])

    console.log("brandSettings", consentInformation);


    const handleConsentInformationChange = async () => {
        setBrandSettings((brandSettings) => {
            const settings = { ...brandSettings.setting_meta_json }
            console.log("settings", settings);
            settings['consent_information'] = consentInformation
            const params = {
                brandId: ApiService.brandId,
                setting: {
                    json: settings
                }
            }
            ApiService.updateSettings(params).then(res => {
                if (res && res.status == 200) {
                    setShowNotification({ open: true, message: 'Settings saved successfully', type: 'success', hideAutomatically: true })
                } else if (res && res.status == 403) {
                    localStorage.clear()
                    navigate('/authentication/sign-in')
                    setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error', hideAutomatically: true })
                } else {
                    setShowNotification({ open: true, message: 'There is some error while saving settings', type: 'error', hideAutomatically: true })
                }
            })
            brandSettings.setting_meta_json = settings
            return { ...brandSettings }
        })
    }

    return (
        <div className='flex flex-col gap-[30px] items-center w-full h-[calc(100%_-_40px)] my-[20px] bg-[#fff]'>
            <div className='py-[20px] flex flex-col gap-[20px] w-[90%]'>
                <div className='text-center font-medium text-[16px]'>
                    Consent Information
                </div>
                <div className='border-[1px] rounded flex flex-col p-[20px] w-full'>
                    <div className='mb-[15px]'>
                        <span className='ml-[5px] text-[14px] font-medium'>Consent Information</span>
                        <div>
                            <ReactQuill
                                // defaultValue={consentInformation}
                                value={consentInformation}
                                modules={{
                                    toolbar: [
                                        ['bold', 'italic', 'underline', 'strike', "link"],
                                        // [{ list: "ordered" }, { list: "bullet" }]
                                    ]
                                }}
                                onChange={e => setConsentInformation(e)}
                            />
                        </div>
                    </div>
                    <div className='w-full flex justify-center'>
                        <button className='bg-black text-[14px] text-white px-[15px] py-[3px]'
                            onClick={handleConsentInformationChange}
                        >Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConsentInformation