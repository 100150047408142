import React from 'react'

export const ProductType = ({handleProductStatus=()=>{},type,statusType}) =>{
    const handleProductStatusChange = (e)=>{
        handleProductStatus(e.target.value)
    }
    return(
        <div>
            <select className="text-center p-0 cursor-pointer bg-white min-w-[150px] min-h-[36px] max-h-[36px] border-[2px] "
                value={statusType}
                onChange={handleProductStatusChange}
            >
                    <option className='bg-white text-black'
                            value='all'
                        >
                        All {type}
                    </option>
                    <option className='bg-white text-black'
                        value={true}
                    >
                       Enabled {type}
                    </option>
                    <option className='bg-white text-black'
                        value={false}
                    >
                        Disabled {type}
                    </option>

            </select>
        </div>
    )
}