import React, { useContext } from 'react'
import Menu from './Menu';
import { ApiService } from '../../services/apiUtility';
import { AppContext } from '../../context/AppContextProvider';
import { useLocation } from 'react-router-dom'
import { PiCopyLight } from "react-icons/pi";

const Header = ({handleShowSettings=()=>{}}) => {
  const {setShowNotification} = useContext(AppContext)
  let location = useLocation();
  const handleCopyBrandId = ()=>{
    navigator.clipboard.writeText(ApiService.brandId);
    setShowNotification({open:true,message:'Brand id copied to clipboard',type:'success',hideAutomatically:true})
  }
    return (
      <div className="bg-white h-[10%] flex p-[20px] border-b-[1px] border-[#00000017] justify-end">
        {
          location.pathname.includes('home')?
          <div className='flex justify-center items-center w-[70%]'>
          <div className='flex relative items-center w-[65%] max-h-[40px] rounded-l bg-[#00000012]'>
            <input type="text" className=' w-full border-0 text-[15px] p-[10px] text-[#000000a8] bg-transparent outline-0 cursor-pointer' value={ApiService.brandId} readOnly={true} />
            <div className='absolute right-[20px] cursor-pointer'
              onClick={handleCopyBrandId}
            >
              <PiCopyLight size='22'/>
            </div>
            
          </div>
        </div>:''
        }
          <div className="flex items-center z-50">
              {/* <div className="flex px-2.5">
                <div>
                    <img
                    src={require('../../assets/images/notification.jpg')}
                    className="max-w-[63px] max-h-[35px]"
                    alt=""
                    />
                </div>
              </div> */}
              <Menu handleShowSettings={handleShowSettings}/>
          </div>
  </div>
    )
}

export default Header