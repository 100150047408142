import { Link, useLocation } from 'react-router-dom'
export const NavLink = ({ path, children,icon,openSideBar,isBack,isReactIcon }) => {
    let location = useLocation();
    const isSelected = () => {
        return path==location.pathname || location.pathname.includes(children && children.toLowerCase())
    }
    
    return (
        <Link to={path}>
            <div className={`${children==='Back To Home Screen'?'text-black gap-[10px] text-[14px]':'text-[#130F24] text-[16px] gap-[20px]'}
              flex w-full items-center cursor-pointer 
              ${isSelected() ? 'font-semibold text-[#130F24]': 'font-light'}`}>
                <div className='w-[25px]'>
                    { isReactIcon?icon:<img src={icon} alt='' />}
                </div>
                {children}
            </div>
        </Link>
    )
}