import React,{useState} from 'react'
import UploadProductSheet from './UploadSheet/UploadProductSheet'
import UploadFolder from './UploadFolder/UploadFolder'

const UploadInventory = () => {
  const [tab,setTab] = useState('sheet')

  const handleTabClick = (tab)=>{
    setTab(tab)
  }

  return (
    <div>
      <div className='font-semibold text-[16px] py-[19px]'>UPLOAD INVENTORY</div>
      <div className='flex flex-col gap-[10px] rounded w-full min-h-full h-max'>
          <div className='flex w-full mt-[-15px] justify-center h-[45px] text-[14px] font-medium cursor-pointer'>
              <div className={`${tab==='sheet'?'bg-black text-white':'bg-white font-medium'} 
                    cursor-pointer min-h-[54px] w-[362px] text-[16px] font-medium flex items-center justify-center`}
                    onClick={()=>handleTabClick('sheet')}
              >
                  Upload Sheet
              </div>
              <div className={`${tab==='folder'?'bg-black text-white':'bg-white font-medium'} 
                    cursor-pointer min-h-[54px] w-[362px] text-[16px] font-medium flex items-center justify-center`}
                    onClick={()=>handleTabClick('folder')}
              >
                  Upload Folder
              </div>
          </div>
          {
            tab=='sheet'?
            <UploadProductSheet/>
            :<UploadFolder/>
          }
      </div>
    </div>
  )
}

export default UploadInventory