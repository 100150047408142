import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { RiSettings3Line } from "react-icons/ri";
import { AppContext } from '../../context/AppContextProvider';
const Menu = ({handleShowSettings=()=>{}}) => {
    const navigate = useNavigate(); 
    const {setShowNotification,setOpenSideBar} = useContext(AppContext)
    const brandName= JSON.parse(localStorage.getItem('brandData'))?.brandDetails?.brand_alias_name

    const logout = ()=>{
        localStorage.clear()
        window.location.href='/authentication/sign-in'
        window.location.reload()
        setShowNotification({open:true,message:'Logged out successfully',type:'success',hideAutomatically:true})
        setOpenSideBar(true)
    }

    const showSettings = ()=>{
        const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
        handleShowSettings(true)
    }
    return (
        <div className="dropdown">
                <label tabIndex="0" className="flex cursor-pointer gap-[8px] text-[14px] items-center min-w-[130px] min-h-[36px] max-h-[36px]">
                    {brandName}<BsChevronDown size='14px' color='#0000008c'/>
                    </label>
                <ul tabIndex="0" className="dropdown-content right-0 
                            menu min-w-[190px] cursor-pointer shadow shadow-gray-500"
                >
                    <li>
                        <div className='w-full hover:bg-[#f2f2f2] bg-white flex px-[16px] text-[12px]' onClick={showSettings}>
                            <RiSettings3Line size='18'/> Settings
                        </div>
                    </li>
                    <li>
                        <div className='w-full hover:bg-[#f2f2f2] bg-white flex px-[16px] text-[12px]' onClick={logout}>
                        <img src={require('../../assets/images/logout.jpg')}></img> Logout
                        </div>
                    </li>
                </ul>
        </div>
    )
}

export default Menu