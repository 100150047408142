import React, { useRef, useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { ApiService } from '../../services/apiUtility'
import UploadLoader from '../Loader/UploadLoader'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const BackgroundImage = () => {
    const { setShowNotification, brandSettings, getBrandSettings, setBrandSettings } = useContext(AppContext)
    const [uploadedImage, setUploadedImage] = useState('')
    const inputRef = useRef(null)
    const [showUploadLoader, setShowUploadLoader] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setUploadedImage(brandSettings?.setting_meta_json?.background_image || "")
    }, [brandSettings])

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const handleDrop = async (event) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0];
        setShowUploadLoader(true)
        const imageUrl = await getUploadedImageUrl(file)
        if (imageUrl) {
            uploadImage(imageUrl)
        }
    }

    const handleBrowseFiles = async (event) => {
        const file = event.target.files[0];
        setShowUploadLoader(true)
        const imageUrl = await getUploadedImageUrl(file)
        if (imageUrl) {
            uploadImage(imageUrl)
        }
        event.target.value = null
    }

    const uploadDefaultBackground = () => {
        uploadImage('', true)
    }

    const getUploadedImageUrl = async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const res = await ApiService.getUploadedImageUrl(formData)
        return res
    }

    const uploadImage = async (imageUrl, defaultImage) => {
        setBrandSettings((brandSettings) => {
            const settings = { ...brandSettings.setting_meta_json }
            settings['background_image'] = imageUrl
            const params = {
                brandId: ApiService.brandId,
                setting: {
                    json: settings
                }
            }
            ApiService.updateSettings(params).then((res) => {
                if (res && res.status == 200) {
                    if (defaultImage) {
                        setShowNotification({ open: true, message: 'Default background uploaded successfully', type: 'success',hideAutomatically:true })
                    } else {
                        setShowNotification({ open: true, message: 'Background image uploaded successfully', type: 'success',hideAutomatically:true })
                    }
                } else if (res && res.status == 403) {
                    localStorage.clear()
                    navigate('/authentication/sign-in')
                    setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
                } else {
                    setShowNotification({ open: true, message: 'There is some error while saving settings', type: 'error',hideAutomatically:true })
                }
                setShowUploadLoader(false)
            })
            brandSettings.setting_meta_json = settings
            return {...brandSettings}
        })
    }

    return (
        <div className='flex flex-col gap-[30px] items-center my-[20px] w-full h-[calc(100%_-_40px)] p-[25px] bg-[#fff]'>
            <div className='relative  flex items-center justify-center w-[250px] h-[250px] border-[1px] h-max border-[#0001] p-[30px] hover:shadow-lg'>
                <div className='flex items-center justify-center '>
                    {
                        uploadedImage ?
                            <div className='absolute top-[10px] right-[8px] cursor-pointer' onClick={uploadDefaultBackground}>
                                <MdOutlineDeleteOutline size='20' />
                            </div> : ''
                    }
                    {showUploadLoader ? <UploadLoader /> : <img src={uploadedImage ? uploadedImage : require('../../assets/images/no-image.png')} alt="background" className='object-contain w-[220px] h-[220px]' />}
                </div>
            </div>


            <div className='mt-[20px] flex h-[500px] max-h-[500px] bg-[#fff] rounded'>
                <div className='border-dashed rounded border-[2px] border-[#DCDBDB] 
                    max-w-[400px] w-full h-full max-h-[350px] m-auto flex 
                    flex-col items-center justify-center font-medium gap-[10px]'
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <div className='py-[10px] text-center max-w-[40px]'>
                        <img src={require("../../assets/images/uploadIcon.jpg")} alt="" />
                    </div>
                    <input type="file" hidden
                        onChange={handleBrowseFiles}
                        ref={inputRef}
                    />
                    <div className='text-center text-[14px] px-[45px] whitespace-nowrap'>
                        <span>Drop your image here or </span>
                        <span className='cursor-pointer text-sky-800'
                            onClick={() => {
                                inputRef.current.click()
                            }}
                        >
                            browse
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackgroundImage