import React, { useState, useEffect, useContext } from 'react'
import { ApiService } from '../../../services/apiUtility'
import { SearchBar } from '../ManageInventory/SearchBar'
import VariantCard from './VariantCard'
import Pagination from '../ManageInventory/Pagination'
import LinearLoader from '../../Loader/LinearLoader'
import { CategoryTab } from './CategoryTab'
import TryOnWindow from './TryOnWindow'
import { CardService } from '../../../services/VariantServices/cardServices'
import { VariantCardClickHandler, VariantDelesectionHandler, removeAllMakeupEffect } from "./VariantCardClick";
import { AppContext } from '../../../context/AppContextProvider'
import { MessageService } from '../../../services/MessageService/calibrationMessageService'

const CalibrateInventory = () => {
    MessageService.attachListner();
    const { setOpenSideBar, setShowNotification } = useContext(AppContext)
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState({})

    const [categoryVariants, setCategoryVariants] = useState([])
    const [categoryVariantsCopy, setCategoryVariantsCopy] = useState([])

    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])

    const [showLoader, setShowLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const variantsPerPage = 20;
    const lastIndex = currentPage * variantsPerPage
    const firstIndex = lastIndex - variantsPerPage
    useEffect(() => {
        setOpenSideBar(false)
        fetchCategories()
    }, [])

    useEffect(() => {
        const handleCalibrationSaved = async () => {
            setShowNotification({ open: true, message: 'Calibration saved successfully', type: 'success',hideAutomatically:true })
            const category = await initialiseVariants(selectedCategory)
            handleCategoryOnClick(category,true)
            const selectedVariant = CardService.selectedVariant[category.category]
            CardService.selectSpecificId(CardService.getKey(selectedVariant,category.category));
        };

        CardService.eventTarget.addEventListener('calibration-saved', handleCalibrationSaved);

        return () => {
            CardService.eventTarget.removeEventListener('calibration-saved', handleCalibrationSaved);
        };
    }, [selectedCategory]);


    const initialiseVariants = async (category) => {
        const categoryProducts = await ApiService.getCategoryProductsAndVariants(category.category, undefined, 0)
        let data = {
            categoryLabel: category?.label || category?.categoryLabel,
            category: category.category,
            categoryProductsMetaData: categoryProducts.meta,
            filter: categoryProducts.filter
        }
        let variants = []
        for (let product of categoryProducts.data) {
            for (let variant of product.variants) {
                variants.push({
                    product: {
                        productCode: product.product_code,
                        productId: variant.variant_parent_id,
                        subBrandId: product.sub_brand_id,
                    },
                    variant: variant,
                    category:category
                })
            }
        }
        data['variants'] = variants
        return data
    }

    const fetchCategories = async () => {
        setShowLoader(true)
        let categoriesValues = []
        const categoriesData = await ApiService.getCategories()
        if (categoriesData?.length > 0) {
            for (let category of categoriesData) {
                const data = await initialiseVariants(category)
                categoriesValues.push(data)
            }
            setCategories(categoriesValues)
            setShowLoader(false)
            // setSelectedCategory(categoriesValues[0])
            const data = categoriesValues[0].variants.slice(firstIndex, lastIndex)
            const defaultCategory = { ...categoriesValues[0], variants: data }
            setSelectedCategory(defaultCategory)
            setCategoryVariants(data)
            setCategoryVariantsCopy(data)
        } else {
            setShowLoader(false)
        }

    }

    const handleCategoryOnClick = (category,isCalibrated) => {
        if(isCalibrated){
            setCurrentPage(currentPage)
        }else{
            setCurrentPage(1)
        }
        const data = category.variants.slice(firstIndex, lastIndex)
        const clickedCategoryData = { ...category, variants: data }
        setSelectedCategory(clickedCategoryData)
        setCategoryVariants(data)
        setCategoryVariantsCopy(data)
        if (searchValue !== '') {
            handleSearch(searchValue, clickedCategoryData)
        }
    }

    const handleSearchFilter = (searchText) => {
        setCurrentPage(1)
        setSearchValue(searchText)
        handleSearch(searchText, selectedCategory)
    }

    const handleSearch = async (searchText, selectedCategory) => {
        if (searchText === '') {
            setSearchResults([])
            setCategoryVariants(categoryVariantsCopy)
            setSelectedCategory({ ...selectedCategory, variants: categoryVariantsCopy })
        } else {
            const response = await ApiService.searchVariant(selectedCategory?.category,searchText)
            if(response && response.status ==200){
                let searchResult = []
                if(response?.data){
                    for(let item of response.data){
                        const result = {variant:item,product:item?.product,category:item?.category}
                        searchResult.push(result)
                    }
                }
                setSelectedCategory({ ...selectedCategory, variants: searchResult })
                setCategoryVariants(searchResult)
                setSearchResults({ ...selectedCategory, variants: searchResult })
            }else{
                setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true })
            }
        }
    }

    const updateDataOnPageChange = (data, firstIndex, lastIndex) => {
        if (data && data.variants) {
            const variants = data.variants.slice(firstIndex, lastIndex)
            const categoryProducts = { ...data, variants: variants }
            setSelectedCategory(categoryProducts)
            setCategoryVariants(variants)
        }
    }

    const handleVariantSelection = (variant, category, productCode) => {
        if (CardService.higlightSelectedVariant(variant,category)) {
            // the card is selected, so deselect it
            VariantDelesectionHandler(variant, category, productCode);
        } else {
            // this function ensure that everytime the makeup effect is called
            // only one product is rendered at a time
            removeAllMakeupEffect()
            setTimeout(() => {

                VariantCardClickHandler(variant, category, productCode);
            }, 10);
        }
    }


    return (
        <div className='h-[95%]'>
            <div className='font-semibold py-[19px] text-[16px]'>CALIBRATE INVENTORY</div>
            <div className='flex bg-[#fff] rounded w-full h-[95%]'>
                {
                    categories.length > 0 ? (
                        <div className='w-[30%] flex flex-col gap-[20px] overflow-y-auto container1'>
                            <div className='sticky top-0 z-10 bg-[white]'>
                                <div className=''>
                                    <div className='flex flex-col gap-[20px] p-[20px]'>
                                        <CategoryTab categories={categories} selectedCategory={selectedCategory} handleCategoryOnClick={handleCategoryOnClick} />
                                        <div className='flex w-full justify-center'>
                                            <SearchBar handleSearchFilter={handleSearchFilter} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='flex h-full flex-col gap-[50px] mt-[10px]'>
                                {
                                    categoryVariants && categoryVariants.length > 0 ? categoryVariants.map((variant, index) => {
                                        return <VariantCard key={index} variant={variant} handleVariantSelection={handleVariantSelection} />
                                    }) :
                                        <div className='flex justify-center w-full h-full'>No Product found.</div>
                                }
                                {
                                    categoryVariants.length > 0 ?
                                        (
                                            <Pagination selectedCategory={selectedCategory}
                                                categories={categories}
                                                updateDataOnPageChange={updateDataOnPageChange}
                                                searchText={searchValue}
                                                searchResults={searchResults}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                recordsPerPage={variantsPerPage}
                                                type={'calibrate'}
                                            />
                                        ) : ''
                                }
                            </div>
                        </div>
                    ) : showLoader ? <LinearLoader />
                        : <div className='w-[35%] py-[20px] flex justify-center'>No product category found.</div>
                }
                <div className='w-[70%] flex p-[20px]'>
                    <TryOnWindow />
                </div>


            </div>
        </div>

    )
}

export default CalibrateInventory