import React, { useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../services/apiUtility";
import { AppContext } from "../../../context/AppContextProvider";
import DialogComponent from "../../Dialog/Dialog";
import CircularLoader from "../../Loader/CircularLoader";
import NotificationBar from "../../Dialog/NotificationBar";

const SignUpComponent = () => {
  const navigate = useNavigate()
  const {setLoading,setAuthTokens,showNotification,setShowNotification} = useContext(AppContext)
  const [email, setEmail] = useState("");
  const [brandName, setBrandName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [checkboxTicked, setCheckboxTicked] = useState(false);
  const [showLoader,setShowLoader] = useState(false)
  const [errorDialog,setErrorDialog] = useState({show:false,message:''})

  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    const [emailPrefix, emailDomain] = email.split('@');

    const isValidEmail = pattern.test(email);
    const hasValidDomain = emailDomain && emailDomain.includes('.');

    return isValidEmail && hasValidDomain;
  };


  const handleSignIn = ()=>{
    navigate('/authentication/sign-in')
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(confirmPassword!==password){
      setShowNotification({open:true,message:'Password and confirm password do not match',type:'error',hideAutomatically:true})
      return
    }
    if(!checkboxTicked){
      setShowNotification({open:true,message:'Please accept terms and policy first',type:'error',hideAutomatically:true})
      return
    }
    if (!validateEmail(email)) {
        setEmailError("Invalid email format");
        return;
    }
    setShowLoader(true)
    setEmailError(null);
    // const loginParam={
    //     email,
    //     brandName,
    //     password
    // }
    // const admindata =await ApiService.adminLogin(loginParam)
    // if(admindata){
    //   setLoading(false)
    //   setAuthTokens(admindata.token)

    //   ApiService.brandId=admindata.brandDetails.uuid

    //   const userData = admindata.user
    //   localStorage.setItem('authTokens',JSON.stringify(admindata.token))
    //   localStorage.setItem('userData',JSON.stringify(userData))
    //   localStorage.setItem('brandData',JSON.stringify(admindata))
    //   setShowLoader(false)
    //   navigate(`/home`);
    // }else{
    //   setShowLoader(false)
    //   handleErrorDialog({show:true,message:'Please check your email or password or brand name.'})
    // }
  };

  const handleErrorDialog =(val)=>{
    setErrorDialog(val)
  }

  useEffect(()=>{
    const userData= localStorage.getItem('userData')
    if(userData){
      navigate(`/home`);
    }
  },[])

  return (
    <div className='relative w-[40%] py-[10px] rounded bg-[#fff]'>
        <div className="relative text-center">
            <div className="text-[30px] font-extrabold">Create your account</div>
            <div className="text-[#717474] text-center text-[18px]">
                Sign up to get started
            </div>
        </div>
       
        <div className="mt-[30px]">
            <form onSubmit={handleSubmit} className='h-[45%] w-[75%] flex flex-col max-w-[450px] m-auto'>
            <div className='mb-[15px]'>
                  <span className='ml-[5px] text-[#000000] text-[18px] font-light'>Brand Name*</span>
                  <div>
                    <input 
                      type="text" 
                      className="h-[46px] mt-[5px] border bg-white border-gray-300  text-[14px]  rounded w-full p-4" 
                      required
                      placeholder="Enter your brand name"
                      id="brandName"
                      name="brandName"
                      value={brandName}
                      onChange={(event) => setBrandName(event.target.value)}
                    />
                  </div>
                </div>
                <div className='mb-[15px]'>
                  <span className='ml-[5px] text-[#000000] text-[18px] font-light'>Email*</span>
                  <div>
                    <input 
                      type="text" 
                      placeholder="Enter your email" 
                      className="h-[46px] mt-[5px] bg-white  border border-gray-300  text-[14px]  rounded w-full p-4" 
                      required
                      value={email}
                      onChange={(event) => {
                        setEmailError('')
                        setEmail(event.target.value)}}
                      id="email"
                      name="email"
                    />
                    {
                      emailError?
                      <label className="label">
                        <span className="label-text-alt text-red-500">{emailError}</span>
                      </label>:''
                    } 
                  </div>
                </div>
                
                <div className='mb-[15px]'>
                  <span className='ml-[5px] text-[#000000] text-[18px] font-light'>Password*</span>
                  <div className="relative">
                    <input 
                      type="password"
                      className="h-[46px] mt-[5px] bg-white border border-gray-300  text-[14px]  rounded w-full p-4" 
                      required
                      placeholder="Enter your password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </div>
                <div className='mb-[15px]'>
                  <span className='ml-[5px] text-[#000000] text-[18px] font-light'>Confirm Password*</span>
                  <div className="relative">
                    <input 
                      type="password"
                      className="h-[46px] mt-[5px] bg-white border border-gray-300  text-[14px]  rounded w-full p-4" 
                      required
                      placeholder="Confirm your password"
                      id="confirm-password"
                      name="confirm-password"
                      value={confirmPassword}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                  </div>
                </div>
                <div className="flex gap-[10px] text-[14px] text-[#000000]">
                    <input type="checkbox" name="" id="" checked={checkboxTicked}
                      onClick={()=>setCheckboxTicked(!checkboxTicked)}
                    />
                    I agree to all Terms, Privacy & Policy
                </div>
                <div className="flex w-full justify-center mt-[20px]">
                    <button
                      className="bg-black rounded text-white w-full py-[15px] text-[20px] font-medium"
                    >
                      <span>Sign Up</span>  
                    </button>
                </div>
                <div className="w-full flex text-[12px] justify-center py-[10px]">
                  Already have and account? 
                  <span className="pl-[2px]  cursor-pointer text-[#4525DD]"
                        onClick={handleSignIn}
                    >
                      Sign in
                    </span>
                </div>
            </form>
        </div>
        {errorDialog.show?
          <DialogComponent 
            handleErrorDialog={handleErrorDialog}
            message={errorDialog.message}
          />:''}
        {showLoader?<CircularLoader/>:''}
        {showNotification.open?
                <NotificationBar 
                    message={showNotification.message}
                    type={showNotification.type}
                />:''
            }
    </div>
  )
}

export default SignUpComponent