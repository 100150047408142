import React, { useContext } from 'react'
import { AppContext } from '../../context/AppContextProvider'

const DeleteConfirmation = ({onDeleteConfirm=()=>{},}) => {
    const {setOpenDeleteConfirmation} = useContext(AppContext)
    const closeModal = ()=>{
        setOpenDeleteConfirmation(false)
    }

    const handleDeleteConfirmation = ()=>{
        setOpenDeleteConfirmation(false)
        onDeleteConfirm()
    }

  return (
        <div>
            <input type="checkbox" id="my-modal" className="modal-toggle" defaultChecked/>
              <div className="modal flex items-center">
                <div className="modal-box text-center bg-white">
                  <span>Are you sure you want to delete? Once deleted it can be retrieved again.</span>
                  <div className='flex justify-center gap-[30px] mt-[30px]'>
                    <div className='py-[5px] px-[20px] bg-black text-white' onClick={closeModal}>
                        <button>No</button>
                    </div>
                    <div className='py-[5px] px-[20px] bg-black text-white' onClick={handleDeleteConfirmation}>
                        <button>Yes</button>
                    </div>
                  </div>
                </div>
              </div>
        </div>
  )
}

export default DeleteConfirmation