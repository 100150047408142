import React, { useContext, useState, useEffect } from "react";
import { ApiService } from "../../services/apiUtility";
import { AppContext } from "../../context/AppContextProvider";
import { useNavigate } from "react-router-dom";
import UsersList from "./UsersList";

const InviteUser = () => {
    const {setShowNotification} = useContext(AppContext)
    const [email, setEmail] = useState("")
    const [userName, setUserName] = useState("")
    const [userNameError, setUserNameError] = useState(null)
    const [emailError, setEmailError] = useState(null);
    const [generatedPassword, setGeneratedPassword] = useState('')
    const [users, setUsers] = useState([])
    const navigate = useNavigate()
    const loggedInUserEmail = JSON.parse(localStorage.getItem('userData')).user_email

    useEffect(() => {
        fetchUsers()
    }, [])

    const fetchUsers = async () => {
        const response = await ApiService.getAllUsers()
        if (response.status == 200) {
            const sortedData = response.data.sort((a,b)=>{
                return new Date(b.updatedAt) - new Date(a.updatedAt)
            })
            setUsers(sortedData.filter((user) => user.user.userEmail != loggedInUserEmail))
        } else if (response.status == 403) {
            localStorage.clear()
            navigate('/authentication/sign-in')
            setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
        }
        else {
            setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true })
        }
    }

    const handleRemoveUser = async (user) => {
        const params = {
            "brandId": ApiService.brandId,
            "mappingId": user.mappingId
        }
        const response = await ApiService.removeUser(params)
        if (response.status == 200) {
            setShowNotification({ open: true, message: response.message, type: 'success',hideAutomatically:true })
            fetchUsers()
        } else if (response.status == 403) {
            localStorage.clear()
            navigate('/authentication/sign-in')
            setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
        }
        else {
            setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true })
        }
    }
    const validateEmail = (email) => {
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        const [emailPrefix, emailDomain] = email.split('@');

        const isValidEmail = pattern.test(email);
        const hasValidDomain = emailDomain && emailDomain.includes('.');

        return isValidEmail && hasValidDomain;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setEmailError("Invalid email format");
            return
        }
        const loginParam = {
            "inviteeEmail": email,
            "inviteeName": userName
        }
        const response = await ApiService.inviteUser(loginParam)
        if (response && response.status == 200) {
            fetchUsers()
            if (response?.data) {
                setGeneratedPassword(response.data)
            }
            setShowNotification({ open: true, message: response.message, type: 'success',hideAutomatically:true })
        } else if (response.status == 403) {
            localStorage.clear()
            navigate('/authentication/sign-in')
            setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
        } else {
            setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true })
        }
        setEmail('')
        setUserName('')
    };

    const handleCopyPassword = () => {
        navigator.clipboard.writeText(generatedPassword);
        setShowNotification({ open: true, message: 'Password copied to clipboard', type: 'success',hideAutomatically:true })
    }

    const validateUserName = (value) => {
        const pattern = /^\S*$/;
        return pattern.test(value) && value.length < 25
    }

    return (
        <div className='flex-1 my-[20px] px-[25px] py-[20px] w-full bg-[#fff] min-h-[calc(100%_-_40px)]'>
            <div className="relative text-center">
                <span className="text-[16px] font-medium">Invite User</span>
            </div>
            <div className="mt-[30px]">
                <form onSubmit={handleSubmit} className='h-[45%] w-[75%] flex flex-col max-w-[450px] m-auto'>
                    <div className='mb-[15px]'>
                        <span className='ml-[5px] text-[14px] font-medium'>Email</span>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter email"
                                className="h-[50px] mt-[5px] border bg-white border-gray-300 text-gray-900 text-sm rounded w-full p-4"
                                required
                                value={email}
                                onChange={(event) => {
                                    setEmailError('')
                                    setEmail(event.target.value
                                    )
                                }}
                                id="email"
                                name="email"
                            />
                            {
                                emailError ?
                                    <label className="label">
                                        <span className="label-text-alt text-red-500">{emailError}</span>
                                    </label> : ''
                            }
                        </div>
                    </div>
                    <div className='mb-[15px]'>
                        <span className='ml-[5px] text-[14px] font-medium'>User Name</span>
                        <div>
                            <input
                                type="text"
                                className="h-[50px] mt-[5px] bg-white border border-gray-300 text-gray-900 text-sm rounded w-full p-4"
                                required
                                placeholder="Enter user name"
                                id="userName"
                                name="userName"
                                value={userName}
                                onChange={(event) => {
                                    setUserNameError(null)
                                    if (!validateUserName(event.target.value)) {
                                        setUserNameError('Maximum 25 characters with no space allowed')
                                    } else {
                                        setUserName(event.target.value)
                                    }
                                }}
                            />
                            {
                                userNameError ?
                                    <label className="label">
                                        <span className="label-text-alt text-red-500">{userNameError}</span>
                                    </label> : ''
                            }
                        </div>
                    </div>
                    <div className="flex w-full justify-center mt-[20px]">
                        <button
                            className="bg-black rounded text-white  w-full h-[50px] font-medium"
                        >
                            <span>Invite</span>
                        </button>
                    </div>
                </form>
            </div>
            {
                generatedPassword ? (
                    <div className='flex gap-[20px] justify-start items-center w-[100%] mt-[50px]'>
                        <div className='font-medium'>
                            Newly generated password:
                        </div>
                        <div className='flex w-[250px] max-h-[40px] rounded-l bg-[#00000012]'>
                            <input type="text" className='w-full border-0 text-[15px] p-[10px] text-[#000000a8] bg-transparent outline-0 cursor-pointer' value={generatedPassword} readOnly={true} />
                            <button className='min-w-[100px] border-0 bg-black text-[#fff] rounded-r cursor-pointer' onClick={handleCopyPassword}>Copy</button>
                        </div>
                    </div>
                ) : ''

            }
            <UsersList users={users} handleRemoveUser={handleRemoveUser} />
        </div>
    )
}

export default InviteUser