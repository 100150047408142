import React, { useEffect, useState } from 'react'
import { ApiService } from '../../services/apiUtility'
import { CategoryTab } from '../InventoryManagement/CalibrateInventory/CategoryTab'
import LinearLoader from '../Loader/LinearLoader'

const ColorRecommendation = () => {
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState({})
    const [similarityPercent,setSimilarityPercent] = useState(80)
    const [selectedColor,setSelectedColor] = useState('0,0,0')
    const [recommendedColors,setRecommendedColors] = useState([])
    const [colorError,setColorError] = useState(null)
    const [hoverState,setHoverState] = useState(null)
    const [showLoader,setShowLoader] = useState(false)
    const [colorUpperLimit,setColorUpperLimit] = useState(100)
    const [minDistanceBetween,setMinDistanceBetween] = useState(0)
    useEffect(() => {
        fetchCategories()
    }, [])

    const fetchCategories = async () => {
        const categoriesData = await ApiService.getCategories()
        setCategories(categoriesData)
        setSelectedCategory(categoriesData[0])
    }

    const handleCategoryOnClick = async (category) => {
        setSelectedCategory(category)
    }


    const handleRGBValueValidation = ()=>{
        const rgbRegex = /^(\d{1,3}),(\d{1,3}),(\d{1,3})$/;

        if (rgbRegex.test(selectedColor)) {
            const [r, g, b] = selectedColor.split(',')?.map(Number) || [0,0,0];

            if (r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255) {
                return true
            }
        }
        return false;
    }

    const searchForSimilarColors = async(event)=>{
        setShowLoader(true)
        event.preventDefault();
        if(!handleRGBValueValidation()){
            setColorError('Invalid RGB color format.Use the format 0,0,0 with values from 0 to 255.')
            return
        }
        const category = selectedCategory.category
        const response = await ApiService.getRecommendedProducts(category,selectedColor,similarityPercent,colorUpperLimit,minDistanceBetween)
        const colors = []
        if(response){
            response[category].map((product,index)=>{
                return colors.push(
                    {
                        color:product.variant_color,
                        variant_sku:product.variant_sku
                    })
            })
        }
        setRecommendedColors(colors)
        setShowLoader(false)
    }

    return (
        <div className='flex flex-col gap-[30px] items-center w-full min-h-[calc(100%_-_40px)] my-[20px] py-[30px] bg-[#fff]'>
            {
                categories.length>0?
                (
                    <div className=' flex flex-col gap-[20px] w-[35%]'>
                        <form className='border-[1px] rounded flex flex-col p-[20px] gap-[20px] w-full'
                        onSubmit={searchForSimilarColors}>
                            <div className='flex flex-col gap-[5px]'>
                                <div>Category</div>
                                <CategoryTab categories={categories} selectedCategory={selectedCategory} handleCategoryOnClick={handleCategoryOnClick} />
                            </div>
                            <div className='flex flex-col gap-[5px]'>
                                <div>Color similarity limit </div>
                                <div>
                                    <input type="number" name="" id="" max="100" min="0" value={colorUpperLimit} className='w-full bg-white border-[1px] py-[5px] pl-[10px]' onChange={(e)=>{
                                        setColorUpperLimit(e.target.value)
                                    }} required/>
                                </div>
                            </div>
                            <div className='flex flex-col gap-[5px]'>
                                <div>Distance between colors </div>
                                <div>
                                    <input type="number" step="0.1" name="" id="" max="100" min="0" value={minDistanceBetween} className='w-full bg-white border-[1px] py-[5px] pl-[10px]' onChange={(e)=>{
                                        setMinDistanceBetween(e.target.value)
                                    }} required/>
                                </div>
                            </div>
                            <div className='flex flex-col gap-[5px]'>
                                <div>Similarity Percent</div>
                                <div>
                                    <input type="number" name="" id="" max="100" min="0" value={similarityPercent} className='w-full bg-white border-[1px] py-[5px] pl-[10px]' onChange={(e)=>{
                                        setSimilarityPercent(e.target.value)
                                    }} required/>
                                </div>
                            </div>
                            <div className='flex flex-col gap-[5px]'>
                                <div>RGB Color Code (Use the format 0,0,0 with values from 0 to 255)</div>
                                <div className='flex flex-col gap-[5px]'>
                                        <div>
                                            <input type="text" value={selectedColor} 
                                                onChange={(e)=>{
                                                    setColorError(null)
                                                    setSelectedColor(e.target.value)
                                                }} 
                                                className='w-full bg-white border-[1px] py-[5px] pl-[10px]' required maxLength='11'/> 
                                                {
                                                    colorError?
                                                    <label className="label">
                                                        <span className="label-text-alt text-red-500">{colorError}</span>
                                                    </label>:''
                                                }
                                        </div>
                                        
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                        <button  className='text-white bg-black px-[20px] py-[8px] text-center'>
                                            Search
                                        </button>
                            </div>
                        </form>
                    </div>
                ):<div>No categories found.</div>
            }
            
            {
                
                recommendedColors.length>0?
                (<div className='border-[1px] rounded p-[20px] max-w-[80%]'>
                    <div className='mb-[10px] text-[16px] font-medium'>Recommended Colors</div>
                    <div className='flex flex-wrap gap-[20px]'>
                    {
                        recommendedColors.map((color,index)=>{
                            return <div key={index} className='flex flex-col gap-[10px] text-[14px] h-[170px] p-[10px] border-[1px] rounded justify-center w-[170px]'
                                onMouseOver={() => { setHoverState(index) }}
                                onMouseOut={() => { setHoverState(null) }}
                            >
                                        <div className='w-full flex justify-center'>
                                            <div style={{background:`${color.color.split(',').map(rgb => rgb.trim()).length>3?`linear-gradient(to right,${color.color})`:`${color.color}`}`}} className='w-[60px] h-[60px]'></div>
                                        </div>
                                        
                                        <div className='text-center overflow-hidden text-ellipsis'>{color.color}</div>
                                            <div className={`${hoverState==index?'visible':'invisible'} line-clamp text-center`}>{color.variant_sku}</div>
                                    </div>
                        })
                    }
                    </div>
                    
                </div>
                ):
                showLoader?
                <LinearLoader/>:
                <div>
                    No recommended colors found
                </div>
                
            }
            
        </div>
    )
}

export default ColorRecommendation