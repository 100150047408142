import React, { useContext, useEffect, useRef, useState } from 'react'
import { Saturation, Hue, useColor } from "react-color-palette";
import "react-color-palette/css";
import DropDownIcon from '../../../../assets/images/dropdown.svg'
import EyeDropperIcon from '../../../../assets/images/eye-dropper.svg'
import { AppContext } from '../../../../context/AppContextProvider';
import { ManageLooksContext } from '../../../../context/LooksContext';

const ManageLooksColorPicker = () => {

    const { setColor, color } = useContext(ManageLooksContext)

    const [colorPick, setColorPick] = useColor(color || "rgb(253,2,2)");
    const [openShader, setOpenShader] = useState(false)

    const decimalShorten = (value) => {
        if (isNaN(value)) return 0
        return value.toFixed(0)
    }

    const colorSetterFn = () => {
        if (colorPick.rgb) {
            setColor(`rgb(${decimalShorten(colorPick.rgb.r)},${decimalShorten(colorPick.rgb.g)},${decimalShorten(colorPick.rgb.b)})`)
        }
    }

    const hexToRgb = (val) => {
        if (!val) return 'rgb(255,255,255)'
        let hex = val.replace(/^#/, '');

        // Parse the hex value into separate RGB components
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);

        // Create the RGB string
        let rgb = `rgb(${r},${g},${b})`;
        return rgb
    }

    const openEyedropper = async () => {
        if (!window?.EyeDropper) {
            document.getElementById('non_chromium_browser_input_color')?.click()
            return
        }
        const eyeDropper = new window.EyeDropper()
        eyeDropper.open()
            .then(({ sRGBHex }) => {
                let rgb = hexToRgb(sRGBHex)
                setColor(rgb)
            })
    }
    function isRGB(color) {
        const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
        return rgbRegex.test(color);
    }

    // Function to validate RGBA color code with optional spaces
    function isRGBA(color) {
        const rgbaRegex = /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(0(\.\d+)?|1(\.0)?)\s*\)$/;
        return rgbaRegex.test(color);
    }

    // Function to validate hex color code
    function isHex(color) {
        const hexRegex = /^#([0-9A-Fa-f]{3}){1,2}$/;
        return hexRegex.test(color);
    }

    const enterColorManually = async e => {
        const value = e?.target?.value?.replaceAll(" ", '')
        if (!value) return

        if (isHex(value)) {
            let rgb = hexToRgb(value)
            setColor(rgb)
        } else if (isRGBA(value)) {
            setColor(value)
        } else if (isRGB(value)) {
            setColor(value.replace(')', ',1)'))
        }
    }

    return (
        <div id='color-palette'>
            <div className='flex items-stretch gap-2'>
                <div className='h-full m-auto px-2 w-full'
                    onMouseUp={colorSetterFn}
                    onTouchEnd={colorSetterFn}
                >
                    <Hue color={colorPick} onChange={setColorPick} />
                </div>
                <div id='eyedropper-icon' style={{ backgroundColor: colorPick.hex }} onClick={openEyedropper} className={`px-3 py-2 rounded-[5px]`} >
                    <img src={EyeDropperIcon} alt="eyedropper icon" />
                    <input type='color' id='non_chromium_browser_input_color' onChange={(e) => setColor(hexToRgb(e.target.value))} hidden />
                </div>
            </div>
            <div id='color-search-input-box' className={`${openShader ? 'max-h-[1000px] px-4' : 'max-h-0'} flex gap-2 items-center overflow-hidden outline-none`}>
                <label className='text-[10px] font-semibold'>Search Color Code</label>
                <input
                    type='text'
                    onChange={enterColorManually}
                    placeholder='Enter Hex or RGB values'
                    className='text-[10px] w-1 flex-grow py-1 pl-4 border border-[#333] my-2'
                />
            </div>

            <div
                onMouseUp={colorSetterFn}
                onTouchEnd={colorSetterFn}
                className={`mt-2 transition-all rounded-[10px] ${openShader ? 'max-h-[1000px]' : 'max-h-0'} overflow-hidden`}
            >
                <Saturation height={150} color={colorPick} onChange={setColorPick} />
            </div>
            <span id='shader' onClick={() => setOpenShader(!openShader)} className=' cursor-pointer mt-3 w-full text-[10px] bg-[#D4D4D4] block text-center'>{openShader ? "Close Shader" : "Open Shader"} <img className={`inline-block ml-1 h-[5px] w-[10px] m-auto transition-all ${openShader ? 'rotate-180' : 'rotate-0'}`} src={DropDownIcon} /></span>
        </div>
    )
}

export default ManageLooksColorPicker
