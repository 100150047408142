import React from 'react'
import { CardService } from '../../../services/VariantServices/cardServices'

const VariantCard = ({variant,handleVariantSelection}) => {
    const handleProductClick = ()=>{
        handleVariantSelection(variant.variant,variant.category.category,variant.product.productCode || variant.product.product_code)
    }
    return (
        <div className='w-full flex justify-center'>
            <div className={'flex w-[95%]  border-[2px] cursor-pointer h-max border-[#0001] hover:shadow-lg rounded' +
                CardService.higlightSelectedVariant(variant.variant,variant.category.category)}
                onClick={handleProductClick}
                data-category={variant.category.category}
                key={variant.variant.variant_sku}
                id={variant.variant.variant_id}
                data-identifier={CardService.getKey(variant.variant,variant.category.category)}
            > 
                <div className='w-[25%] flex justify-center items-center relative'>     
                    <img src={variant.variant.variant_image_urls[0]} alt="variant" className='w-[85px] h-[85px]'/>
                </div>
                <div className='w-[75%] text-[12px]'>
                        <div className='p-[10px] flex gap-[20px] items-center justify-between'>
                            <span className='w-full overflow-hidden whitespace-nowrap text-ellipsis'>
                                <span className='font-medium '>Product Handle: </span>
                                {variant?.product?.productCode || variant?.product?.product_code}
                            </span>
                        </div>
                        <div className='p-[10px] flex gap-[20px] items-center justify-between'>
                            <span className='w-full overflow-hidden whitespace-nowrap text-ellipsis'>
                                <span className='font-medium '>Variant SKU: </span>
                                {variant.variant.variant_sku}
                            </span>
                        </div>
                        <div className='p-[10px] flex gap-[20px] items-center justify-between'>
                            <span className='w-full overflow-hidden whitespace-nowrap text-ellipsis'>
                                <span className='font-medium '>Variant Category Key: </span>
                                {variant.category.category}
                            </span>
                        </div>
                        <div className='p-[10px] flex gap-[20px] items-center justify-between'>
                            <span className='w-full overflow-hidden whitespace-nowrap text-ellipsis'>
                                <span className='font-medium '>Variant Color Name: </span>
                                {variant.variant.variant_color_name}
                            </span>
                        </div>
                        <div className='p-[10px] flex gap-[20px] items-center justify-between'>
                            <span className='w-full overflow-hidden whitespace-nowrap text-ellipsis'>
                                <span className='font-medium '>Variant Color: </span>
                                {variant.variant.variant_color}
                            </span>
                        </div>
                        <div className='p-[10px] flex gap-[20px] items-center justify-between'>
                            <span className='w-full overflow-hidden whitespace-nowrap text-ellipsis'>
                                <span className='font-medium '>Variant Feature: </span>
                                {variant.variant.variant_feature}
                            </span>
                        </div>
                        <div className='p-[10px] flex gap-[20px] items-center justify-between'>
                            <span className='w-full overflow-hidden whitespace-nowrap text-ellipsis'>
                                <span className='font-medium '>Variant Finish: </span>
                                {variant.variant.variant_finish}</span>
                        </div>
                </div> 
            </div>
        </div>
    )
}

export default VariantCard