import React, { useRef, useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { ApiService } from '../../services/apiUtility'
import UploadLoader from '../Loader/UploadLoader'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const ModelImage = () => {
    const { setShowNotification, brandSettings, getBrandSettings, setBrandSettings } = useContext(AppContext)
    const [uploadedImage1, setUploadedImage1] = useState('')
    const [uploadedImage2, setUploadedImage2] = useState('')
    const [uploadedImage3, setUploadedImage3] = useState('')
    const [uploadedImage4, setUploadedImage4] = useState('')
    const [showUploadLoader1, setShowUploadLoader1] = useState(false)
    const [showUploadLoader2, setShowUploadLoader2] = useState(false)
    const [showUploadLoader3, setShowUploadLoader3] = useState(false)
    const [showUploadLoader4, setShowUploadLoader4] = useState(false)
    const navigate = useNavigate()

    const inputRefs = useRef([]);
    let numberOfUploads = []
    for (let i = 0; i < 4; i++) {
        numberOfUploads.push(i)
    }

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    useEffect(() => {
        setUploadedImage1(brandSettings && brandSettings.setting_meta_json &&
            brandSettings.setting_meta_json.gallery_mode_image && brandSettings.setting_meta_json.gallery_mode_image[0]
            ? brandSettings.setting_meta_json.gallery_mode_image[0] : '')
        setUploadedImage2(brandSettings && brandSettings.setting_meta_json &&
            brandSettings.setting_meta_json.gallery_mode_image && brandSettings.setting_meta_json.gallery_mode_image[1]
            ? brandSettings.setting_meta_json.gallery_mode_image[1] : '')
        setUploadedImage3(brandSettings && brandSettings.setting_meta_json &&
            brandSettings.setting_meta_json.gallery_mode_image && brandSettings.setting_meta_json.gallery_mode_image[2]
            ? brandSettings.setting_meta_json.gallery_mode_image[2] : '')
        setUploadedImage4(brandSettings && brandSettings.setting_meta_json &&
            brandSettings.setting_meta_json.gallery_mode_image && brandSettings.setting_meta_json.gallery_mode_image[3]
            ? brandSettings.setting_meta_json.gallery_mode_image[3] : '')
    }, [brandSettings])


    const handleDrop = async (event, index) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0];
        if (index == 0) {
            setShowUploadLoader1(true)
        } else if (index == 1) {
            setShowUploadLoader2(true)
        } else if (index == 2) {
            setShowUploadLoader3(true)
        } else if (index == 3) {
            setShowUploadLoader4(true)
        }

        const imageUrl = await getUploadedImageUrl(file)
        if (imageUrl) {
            uploadImage(imageUrl, index)
        }
    }

    const handleBrowseFiles = async (event, index) => {
        const file = event.target.files[0];
        if (index == 0) {
            setShowUploadLoader1(true)
        } else if (index == 1) {
            setShowUploadLoader2(true)
        } else if (index == 2) {
            setShowUploadLoader3(true)
        } else if (index == 3) {
            setShowUploadLoader4(true)
        }
        const imageUrl = await getUploadedImageUrl(file)
        if (imageUrl) {
            uploadImage(imageUrl, index)
        }

        event.target.value = null
    }

    const setDefaultModelImage = async (index) => {
        let imageUrl
        if (index == 0) {
            // setShowUploadLoader1(true)
            imageUrl = await getDefaultModelImageUrl(require('../../assets/images/defaultModelImage1.jpg'), 'defaultModelImage1.jpg')
        } else if (index == 1) {
            // setShowUploadLoader2(true)
            imageUrl = await getDefaultModelImageUrl(require('../../assets/images/defaultModelImage2.jpg'), 'defaultModelImage2.jpg')
        } else if (index == 2) {
            // setShowUploadLoader3(true)
            imageUrl = await getDefaultModelImageUrl(require('../../assets/images/defaultModelImage3.jpg'), 'defaultModelImage3.jpg')
        } else if (index == 3) {
            // setShowUploadLoader4(true)
            imageUrl = await getDefaultModelImageUrl(require('../../assets/images/defaultModelImage4.jpg'), 'defaultModelImage4.jpg')
        }
        if (imageUrl) {
            uploadImage(imageUrl, index, true)
        }
    }

    const getDefaultModelImageUrl = async (filePath, modelName) => {
        try {
            const response = await fetch(filePath);
            if (!response.ok) {
                throw new Error('File not found');
            }
            const fileBlob = await response.blob();
            const file = new File([fileBlob], modelName, { type: fileBlob.type });
            const res = await getUploadedImageUrl(file)
            return res
        } catch (error) {
            setShowNotification({ open: true, message: 'There is some error', type: 'error',hideAutomatically:true })
        }
    }

    const getUploadedImageUrl = async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const res = await ApiService.getUploadedImageUrl(formData)
        return res
    }

    const uploadImage = async (imageUrl, index, defaultModels) => {
        setBrandSettings((brandSettings) => {

            const settings = { ...brandSettings.setting_meta_json }
            let galleryModeImage = []
            if (settings.gallery_mode_image) {
                galleryModeImage = [...settings.gallery_mode_image]
                if (galleryModeImage[index]) {
                    galleryModeImage[index] = imageUrl
                } else {
                    galleryModeImage.push(imageUrl)
                }
            } else {
                galleryModeImage.push(imageUrl)
            }
            settings['gallery_mode_image'] = galleryModeImage
            const params = {
                brandId: ApiService.brandId,
                setting: {
                    json: settings
                }
            }
            ApiService.updateSettings(params).then(res => {
                if (res && res.status == 200) {
                    if (defaultModels) {
                        setShowNotification({ open: true, message: 'Default model image set successfully', type: 'success',hideAutomatically:true })
                    } else {
                        setShowNotification({ open: true, message: 'Model image uploaded successfully', type: 'success',hideAutomatically:true })
                    }
                } else if (res && res.status == 403) {
                    localStorage.clear()
                    navigate('/authentication/sign-in')
                    setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
                } else {
                    setShowNotification({ open: true, message: 'There is some error while saving settings', type: 'error',hideAutomatically:true })
                }
                if (index == 0) {
                    setShowUploadLoader1(false)
                } else if (index == 1) {
                    setShowUploadLoader2(false)
                } else if (index == 2) {
                    setShowUploadLoader3(false)
                } else if (index == 3) {
                    setShowUploadLoader4(false)
                }
            })
            brandSettings.setting_meta_json = settings
            return {...brandSettings}
        })
    }

    return (
        <div className='flex flex-col gap-[10px] items-center my-[20px] w-full h-[calc(100%_-_40px)] p-[20px] bg-[#fff]'>
            <div className='relative flex justify-between w-full h-max  '>
                <div className='relative flex items-center justify-center w-[200px] h-[200px] border-[1px] hover:shadow-lg'>
                    {
                        uploadedImage1 && !uploadedImage1.includes('defaultModelImage1') ?
                            <div className='absolute top-[10px] right-[8px] cursor-pointer' onClick={() => setDefaultModelImage(0)}>
                                <MdOutlineDeleteOutline size='20' />
                            </div> : ''
                    }

                    {showUploadLoader1 ? <UploadLoader /> : <img src={uploadedImage1 ? uploadedImage1 : require('../../assets/images/no-image.png')} alt="" className='object-contain w-[180px] h-[180px]' />}
                </div>
                <div className='relative flex items-center justify-center w-[200px] h-[200px] border-[1px] hover:shadow-lg'>
                    {
                        uploadedImage2 && !uploadedImage2.includes('defaultModelImage2') ?
                            <div className='absolute top-[10px] right-[8px] cursor-pointer' onClick={() => setDefaultModelImage(1)}>
                                <MdOutlineDeleteOutline size='20' />
                            </div> : ''
                    }

                    {showUploadLoader2 ? <UploadLoader /> : <img src={uploadedImage2 ? uploadedImage2 : require('../../assets/images/no-image.png')} alt="" className='object-contain w-[180px] h-[180px]' />}
                </div>
                <div className='relative flex items-center justify-center w-[200px] h-[200px] border-[1px] hover:shadow-lg'>
                    {
                        uploadedImage3 && !uploadedImage3.includes('defaultModelImage3') ?
                            <div className='absolute top-[10px] right-[8px] cursor-pointer' onClick={() => setDefaultModelImage(2)}>
                                <MdOutlineDeleteOutline size='20' />
                            </div> : ''
                    }

                    {showUploadLoader3 ? <UploadLoader /> : <img src={uploadedImage3 ? uploadedImage3 : require('../../assets/images/no-image.png')} alt="" className='object-contain w-[180px] h-[180px]' />}
                </div>
                <div className='relative flex items-center justify-center w-[200px] h-[200px] border-[1px] hover:shadow-lg'>
                    {
                        uploadedImage4 && !uploadedImage4.includes('defaultModelImage4') ?
                            <div className='absolute top-[10px] right-[8px] cursor-pointer' onClick={() => setDefaultModelImage(3)}>
                                <MdOutlineDeleteOutline size='20' />
                            </div> : ''
                    }

                    {showUploadLoader4 ? <UploadLoader /> : <img src={uploadedImage4 ? uploadedImage4 : require('../../assets/images/no-image.png')} alt="" className='object-contain w-[180px] h-[180px]' />}
                </div>
            </div>

            <div className='flex w-full justify-between'>
                {
                    numberOfUploads.map((number, index) => {
                        return <div key={index}>
                            <div  className='mt-[20px] flex h-[200px] max-h-[200px] bg-[#fff] rounded'>
                                <div className='border-dashed rounded border-[2px] border-[#DCDBDB]
                                            max-w-[200px] w-[200px] h-[200px]  max-h-[200px] m-auto flex 
                                            flex-col items-center justify-center font-medium gap-[10px]'
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, index)}
                                >
                                    <div className='py-[10px] text-center max-w-[40px]'>
                                        <img src={require("../../assets/images/uploadIcon.jpg")} alt="" />
                                    </div>
                                    <input type="file" hidden
                                        onChange={(e) => handleBrowseFiles(e, number)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                    />
                                    <div className='text-center text-[14px] px-[45px] whitespace-nowrap'>Drag & Drop your file here
                                        <div className='mt-[14px]'>
                                            Or
                                        </div>
                                        <button className='mt-[13px] cursor-pointer rounded border-[1px] border-[#130F24] w-[155px] h-[38px]'
                                            onClick={() => {
                                                inputRefs.current[index].click();
                                            }}
                                        >
                                            Browse Files
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className='mt-[8px] text-center w-full text-[10px] text-[#717474]'>Supports: JPG,JPEG,PNG</div>
                        </div>

                    })
                }
            </div>

        </div>
    )
}

export default ModelImage