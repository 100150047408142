import { CardService } from "../VariantServices/cardServices";

class CalibrationMessageService {
  attachListner() {
    window.onmessage = (eventFromWindow) => {
      const event = eventFromWindow.data

        switch (event) {
          case "calibration-saved":
            CardService.handleCalibrationSaved()
            break;
          default:
            break;
        }
    };
  }
}

let messageService = new CalibrationMessageService();

export { messageService as MessageService };
