import React from 'react'

const LinearLoader2 = () => {
  return (
    <div className="w-full flex flex-col items-center">
        <div className="w-full relative  overflow-hidden h-[4px] bg-black">
            <div className="progress-bar-value w-full h-full bg-[#f2f2f2]"></div>
        </div>
    </div>
  )
}

export default LinearLoader2