import { CardService } from "../../../services/VariantServices/cardServices";

const VariantCardClickHandler = (variant, category, productCode) => {
  CardService.deselectSpecificCategory(category);
  CardService.selectSpecificId(CardService.getKey(variant,category));
  CardService.setVariantSelected(category, variant);

  if (window && window.mirrarBeautyAR && window.mirrarBeautyAR.applyMakeupEffect) {
    try {
      window.mirrarBeautyAR.applyMakeupEffect(category, variant.variant_sku, productCode, variant)
    } catch (error) {
      console.log(error)
    }
  }
};

const VariantDelesectionHandler = (variant, category, productCode) => {
  CardService.deSelectSpecificId(CardService.getKey(variant,category));
  CardService.removeVariantSelected(category);
  if (window && window.mirrarBeautyAR && window.mirrarBeautyAR.removeMakeupEffect) {
    try {
      window.mirrarBeautyAR.removeMakeupEffect(category, variant.variant_sku, productCode)
    } catch (error) {
      console.log(error)
    }
  }
};
const removeAllMakeupEffect = () => {
  if (window && window.mirrarBeautyAR && window.mirrarBeautyAR.removeAllMakeupEffect) {
    try {
      window.mirrarBeautyAR.removeAllMakeupEffect()
    } catch (error) {
      console.log(error)
    }
  }
}

export { VariantCardClickHandler, VariantDelesectionHandler, removeAllMakeupEffect };
