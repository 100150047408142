import React from 'react'
import CloseCatalogIcon from '../../../../assets/images/close-catalog.svg'

const ManageLooksDisablePopup = ({
    setDisableLookPopup
}) => {
    return (
        <div className='fixed h-full w-full left-0 top-0 z-40'>
            <div className='fixed h-full w-full left-0 top-0 bg-[rgba(231,231,231,0.4)] backdrop-blur-sm'>&nbsp;</div>
            <div className='absolute flex flex-col pb-6 rounded-[6px] px-6 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[455px] h-[275px] bg-[#fff] border border-[#D6D6D6]'>
                <img src={CloseCatalogIcon} alt='close button' className='cursor-pointer absolute top-4 right-4 w-3 h-3' onClick={() => setDisableLookPopup(false)} />
                <p className='text-[24px] mt-auto mb-10 text-center'>Disable look?</p>
                <p className='text-[14px] mb-3 text-center'>The changes you have made to the look recently will not be saved and the look will be saved under Disabled Section</p>
                <p className='text-[12px] mb-10 text-center'>Enabling and editing can be done through Manage Looks section.</p>
                <div className='flex items-center gap-4'>
                    <button type='button' className='w-1/2 py-3 border-[2px] border-[#000] rounded-[4px]' onClick={() => setDisableLookPopup(false)} >Cancel</button>
                    <button name='Discard' className='w-1/2 py-3 border-[2px] border-transparent bg-[#E23D3D] text-[#fff] rounded-[4px]' type='submit'>Disable</button>
                </div>
            </div>
        </div>
    )
}

export default ManageLooksDisablePopup
