import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ApiService } from '../../services/apiUtility';
import { AppContext } from '../../context/AppContextProvider';
import { useNavigate } from 'react-router-dom';

const SkinConcernMessaging = () => {
    const navigate = useNavigate();
    const [selectedLabel, setSelectedLabel] = useState(null)
    const [selectedValues, setSelectedValues] = useState(null)

    const { setShowNotification } = useContext(AppContext)

    const [initialJson, setInitialJson] = useState(null)

    const [scoreJson, setScoreJson] = useState(null)
    const [editedJson, setEditedJson] = useState(null)

    const [perConcernScore, setPerConcernScore] = useState({
        bad: null,
        average: null,
        good: null
    })

    const scoreEnumValues = {
        "0": "good",
        "1": "average",
        "2": "bad"
    }

    const logout = () => {
        localStorage.clear()
        navigate('/authentication/sign-in')
        setShowNotification({ open: true, message: 'Logged out successfully', type: 'success', hideAutomatically: true })
    }

    useEffect(() => {
        (async () => {
            const result = await ApiService.getSkinConcernSettings()
            if (result.error) {
                logout()
            }
            if (result?.skinSettingsDetails && result?.skinSettingsGradeDetails) {
                setInitialJson(result?.skinSettingsDetails)
                setEditedJson(result?.skinSettingsDetails)
                setSelectedLabel(Object.keys(result?.skinSettingsDetails)?.[0])
                setScoreJson(result?.skinSettingsGradeDetails)
            }
        })()
    }, [])

    useEffect(() => {
        if (initialJson?.[selectedLabel]) {
            setSelectedValues(initialJson[selectedLabel])
        }
        if (scoreJson?.[selectedLabel]) {
            setPerConcernScore({
                good: [scoreJson?.[selectedLabel]?.range?.[0], scoreJson?.[selectedLabel]?.range?.[1]],
                average: [scoreJson?.[selectedLabel]?.range?.[2], scoreJson?.[selectedLabel]?.range?.[3]],
                bad: [scoreJson?.[selectedLabel]?.range?.[4], scoreJson?.[selectedLabel]?.range?.[5]],
            })
        }
    }, [selectedLabel, initialJson])

    const changeLabel = e => {
        if (!e.target.value) return
        setEditedJson({
            ...editedJson,
            [selectedLabel]: {
                ...editedJson?.[selectedLabel],
                label: e.target.value
            }
        })
    }

    const changedOrAddKey = (e, key) => {
        if (!e || !key) return
        setEditedJson({
            ...editedJson,
            [selectedLabel]: {
                ...editedJson?.[selectedLabel],
                [key]: e
            }
        })
    }

    const changedColor = (e, index) => {
        const color = e?.target?.value
        if (!color) return
        setEditedJson({
            ...editedJson,
            [selectedLabel]: {
                ...editedJson?.[selectedLabel],
                details: {
                    ...editedJson?.[selectedLabel]?.details,
                    [index]: {
                        ...editedJson?.[selectedLabel]?.details?.[index],
                        color: color
                    }
                }
            }
        })
    }

    let timeoutMessage
    const changedMessage = (e, index, item, valueKey) => {
        if (!e || item !== selectedValues?.type || !valueKey) return
        clearTimeout(timeoutMessage)
        setEditedJson({
            ...editedJson,
            [selectedLabel]: {
                ...editedJson?.[selectedLabel],
                details: {
                    ...editedJson?.[selectedLabel]?.details,
                    [index]: {
                        ...editedJson?.[selectedLabel]?.details?.[index],
                        [valueKey]: e
                    }
                }
            }
        })
    }

    const discardEditing = () => {
        // setEditedJson(initialJson)
        window.location.reload()
    }

    const saveEditing = async () => {
        await ApiService.setSkinConcernSettings({
            skinAnalysisSettings: editedJson,
            skinAnalysisGradeDetails: scoreJson
        }).then((result) => {
            if (result.status == 200) {
                setShowNotification({
                    open: true,
                    message: result.message,
                    type: 'success',
                    hideAutomatically: true
                })
                document.getElementById('skin-concern-form').reset()
                setInitialJson(editedJson)
            } else if (result.status == 403) {
                logout()
            } else {
                setShowNotification({
                    open: true,
                    message: result.message,
                    type: 'error',
                    hideAutomatically: true
                })
            }
        })
    }

    function isColorValue(value) {
        // Regular expression to match RGB color values
        const rgbRegex = /^(rgb\((\d+),\s*(\d+),\s*(\d+)\)|#[0-9A-Fa-f]{3,6})$/;
        // Check if the value matches the RGB regex
        if (rgbRegex.test(value)) {
            return true; // It's a valid RGB color value
        } else {
            return false; // It's not a valid RGB color value
        }
    }

    function rgbToHex(rgb) {
        // Split the RGB string into its components
        const [r, g, b] = rgb.match(/\d+/g);

        // Convert each component to hexadecimal
        const rHex = parseInt(r).toString(16).padStart(2, '0');
        const gHex = parseInt(g).toString(16).padStart(2, '0');
        const bHex = parseInt(b).toString(16).padStart(2, '0');

        // Concatenate the hexadecimal components
        const hexValue = `#${rHex}${gHex}${bHex}`;
        return hexValue.toUpperCase(); // Return the hexadecimal value in uppercase
    }

    function convertToHexOrKeep(value) {
        // Check if the value is a color value
        if (isColorValue(value)) {
            // If it's a color value, return it as it is
            return value;
        } else {
            // If it's not a color value, convert it from RGB to hexadecimal
            return rgbToHex(value);
        }
    }


    function isRGB(color) {
        return /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/.test(color);
    }

    function hexToRgb(hex) {
        // Remove # if present
        hex = hex.replace(/^#/, '');

        // Convert hex to RGB
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;

        return `rgb(${r}, ${g}, ${b})`;
    }

    function checkAndConvertColor(color) {
        if (isRGB(color)) {
            return color;
        } else {
            return hexToRgb(color);
        }
    }

    return initialJson ? (
        <form id='skin-concern-form' className='pt-5 h-full px-4 pb-6 flex flex-col gap-3'>
            <div className='flex gap-4 justify-between'>
                {
                    initialJson && Object.values(initialJson)?.map(({ type, label }, index) => {
                        const key = Object.keys(initialJson)?.[index]
                        return <button
                            key={index + type}
                            type='button'
                            className={`py-2 px-3 flex-1 rounded-md border transition-all ${selectedLabel === key ? 'bg-black text-white' : 'border-black'}`}
                            onClick={() => setSelectedLabel(key)}
                        >{label}</button>
                    })
                }
            </div>
            {
                editedJson && Object.values(editedJson)?.map((value, index) => {
                    return value ? (
                        <div className={`h-1 flex-grow bg-white py-3 px-4 rounded-md overflow-auto ${value?.type == selectedValues?.type ? '' : 'hidden'}`} key={index}>
                            <p className='text-[1.25rem] font-semibold uppercase'>Concern Details</p>
                            <div className=' mt-4'>
                                <label className=' font-semibold text-[0.875rem]'>Concern Name</label>
                                <input
                                    type='text'
                                    id={value?.label}
                                    defaultValue={value?.label}
                                    onChange={changeLabel}
                                    className='px-2 mt-1 block py-2 border border-black'
                                />
                            </div>
                            <div className='mt-8'>
                                <p className='text-[1rem] font-semibold'>Concern Message as Per Score</p>
                                {value?.details && Object.values(value?.details)?.map((item, index) => {
                                    const key = scoreEnumValues[Object.keys(value?.details)?.[index]]
                                    return (
                                        <div className=' mt-2' key={index}>
                                            <label className='font-semibold text-[0.875rem] capitalize'>{perConcernScore?.[key]?.[0]}-{perConcernScore?.[key]?.[1]} ({key} Score)</label>
                                            {/* <input type='text' placeholder={selectedValues?.label} className='px-2 w-full mt-1 block py-2 border border-black' /> */}
                                            <ReactQuill
                                                defaultValue={item?.concernMessage}
                                                // value={item?.concernMessage}
                                                modules={{
                                                    toolbar: [
                                                        ['bold', 'italic', 'underline', 'strike', "link"],
                                                        // [{ list: "ordered" }, { list: "bullet" }]
                                                    ]
                                                }}
                                                onChange={e => changedMessage(e, index, value.type, "concernMessage")}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='mt-8'>
                                <p className='text-[1rem] mb-2 font-semibold'>"Know More" Popup Settings</p>
                                <p className='text-[0.875rem] font-semibold'>Description for {value?.label}</p>
                                <ReactQuill
                                    defaultValue={value?.description}
                                    className='mt-2'
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike', "link"],
                                            [{ list: "ordered" }, { list: "bullet" }]
                                        ]
                                    }}
                                    onChange={e => changedOrAddKey(e, "description")}
                                />
                            </div>
                            <div className='mt-8'>
                                <p className='text-[0.875rem] font-semibold'>Tips for {value?.label} <span className=' italic text-[0.75rem]'>(Please enter title text as well)</span><span className=' italic text-[0.75rem]'>(Please enter upto 3 points only)</span></p>
                                <ReactQuill
                                    defaultValue={value?.tips}
                                    className='mt-2'
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike', "link"],
                                            [{ list: "ordered" }, { list: "bullet" }]
                                        ]
                                    }}
                                    onChange={e => changedOrAddKey(e, "tips")}
                                />
                            </div>
                            <div className='mt-8'>
                                <p className='text-[0.875rem] font-semibold'>Ingredients Recommended for {value?.label} <span className=' italic text-[0.75rem]'>(Please enter title text as well)</span><span className=' italic text-[0.75rem]'>(Please enter upto 3 points only)</span></p>
                                <ReactQuill
                                    defaultValue={value?.ingredient}
                                    className='mt-2'
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike', "link"],
                                            [{ list: "ordered" }, { list: "bullet" }]
                                        ]
                                    }}
                                    onChange={e => changedOrAddKey(e, "ingredient")}
                                />
                            </div>
                            <div className='mt-8'>
                                <p className='text-[1rem] font-semibold'>Color Per Score Grade</p>
                                <div className='mt-4'>
                                    {
                                        value?.details && Object.values(value?.details)?.map((item, index) => (
                                            <div className='flex flex-col gap-2 mt-8' key={index}>
                                                <div className=' flex gap-8 items-center'>
                                                    <p className=' capitalize font-semibold text-[0.875rem]'>{scoreEnumValues[Object.keys(value?.details)[index]]}</p>
                                                    <div className='flex gap-2 items-center'>
                                                        <p className=' text-[0.8rem]'>Select the color</p>
                                                        <input className='w-[2rem] border-none' type='color' value={item?.color}
                                                            onChange={e => changedColor(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className=' flex gap-5 items-center'>
                                                    <div className='flex items-center gap-2'>
                                                        <p className=' text-[0.8rem] font-semibold'>Finalized color</p>
                                                        <div className='w-[1.5rem] aspect-square'
                                                            style={{
                                                                backgroundColor: item?.color
                                                            }}
                                                        >&nbsp;</div>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <p className=' font-semibold text-[0.8rem]'>Hex Code</p>
                                                        <div className='text-[0.8rem]'>{convertToHexOrKeep(item.color)}</div>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <p className='text-[0.8rem] font-semibold'>RGB Color Code</p>
                                                        <div className='text-[0.8rem]'>{checkAndConvertColor(item.color)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    )
                        : null
                })
            }
            <div className='mt-2 flex gap-4 w-1/2 ml-auto'>
                <button type='button' className=' flex-1 px-4 py-2 bg-[#686464] text-white text-center'
                    onClick={discardEditing}
                >Discard</button>
                <button
                    type='button'
                    className=' flex-1 px-4 py-2 bg-[#686464] text-white text-center'
                    onClick={saveEditing}
                >Save</button>
            </div>
        </form>
    ) : null
}
export default SkinConcernMessaging
