import React, { useContext, useRef, useState, useEffect } from 'react'
import { Cropper } from 'react-cropper'
import "cropperjs/dist/cropper.css";

import ZoomoutIcon from '../../../../assets/images/zoom_in_icon.svg'
import ZoomInIcon from '../../../../assets/images/zoom_out_icon.svg'
import DeleteIcon from '../../../../assets/images/delete_image.svg'
import { AppContext } from '../../../../context/AppContextProvider';
import { CreateLooksContext } from '../../../../context/LooksContext';

const ReferenceImage = () => {

    const { setReferenceImage, referenceImage } = useContext(CreateLooksContext)

    const fileFormats = 'image/png, image/jpeg, image/jpg'

    const { setShowNotification } = useContext(AppContext)

    const inputRef = useRef(null)
    const cropperRef = useRef(null)
    const [error, setError] = useState(false)

    const [images, setImages] = useState()
    const [zoom, setZoom] = useState(0)
    const [originalZoomRatio, setOriginalZoomRatio] = useState()    // Used to store original zoom ratio value
    const [ready, setReady] = useState(false)                       // Used to check if the cropper component is ready or not

    const handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (!files) return
        if (files && files.length > 1) {
            setShowNotification({ open: true, type: 'failure', message: 'Attaching only 1 file at a time is possible',hideAutomatically:true })
            return
        }
        onChange(event)
    };

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (!fileFormats.includes(files[0].type)) {
            setShowNotification({ open: true, type: 'failure', message: "Please image in PNG, JPEG or JPG format",hideAutomatically:true })
            return
        }
        if (files[0].size / 1000 >= 2048) {
            setError(true)
            return
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImages(reader.result);
        };
        setReferenceImage({
            ...referenceImage,
            type: files[0].type,
            name: files[0].name
        })
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setReferenceImage({ ...referenceImage, imageUrl: cropperRef.current?.cropper.getCroppedCanvas().toDataURL() });
        }
    };

    const handleZoomIn = () => {
        if(!originalZoomRatio) setOriginalZoomRatio(zoom)
        setZoom((prevZoom) => prevZoom + 0.1);
    };

    const handleZoomOut = () => {
        if(!originalZoomRatio) setOriginalZoomRatio(zoom)
        setZoom((prevZoom) => Math.max(originalZoomRatio, prevZoom - 0.1));
    };

    const deleteImage = () => {
        setReferenceImage({
            type: '',
            imageUrl: null,
            name: ''
        })
        setImages()
    }

    const zoomReady = () => {
        setReady(true)
        setZoom(cropperRef?.current?.cropper?.canvasData?.width / cropperRef?.current?.cropper?.canvasData?.naturalWidth)
    }

    const zoomOutSet = (event) => {
        if (!originalZoomRatio) {
            setOriginalZoomRatio(event.detail.oldRatio)
            setZoom(event.detail.oldRatio)
        }
        if (event.detail.ratio < (originalZoomRatio || event.detail.oldRatio)) {
            event.preventDefault()
        }
    }

    useEffect(() => {
        const container = cropperRef?.current
        container?.addEventListener("ready", zoomReady)
        if (ready) {
            container?.addEventListener("zoom", zoomOutSet)
        }
        return () => {
            container?.removeEventListener("ready", zoomReady)
            container?.removeEventListener("zoom", zoomOutSet)
        }
    }, [images, ready, originalZoomRatio])

    return !referenceImage.imageUrl ? (
        <>
            <input onChange={onChange} type='file' accept={fileFormats} hidden ref={inputRef} id="fileUpload" />
            <div
                className='w-full h-full flex flex-col items-center gap-2.5 px-[16px] py-[40px] border border-solid border-[#D9D9D9] rounded justify-end relative'
                onClick={() => inputRef.current.click()}
                onDrop={handleFileDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >
                <img className='mb-[20px]' src={require('../../../../assets/images/create_look_placeholder.png')} alt='look reference' />
                <p className='text-[18px] text-center'><span className='text-[#306CDF] cursor-pointer hover:underline'>Click to upload</span> or drag and drop*</p>
                <p className={`text-[14px] text-center text-[${error ? '#FF0000' : '#737373'}]`}>Supports PNG, JPEG, JPG</p>
                <p className={`text-[14px] text-center text-[${error ? '#FF0000' : '#737373'}]`}>Maximum image size 2MB</p>
                <p className='text-[12px] text-center text-[#737373]'>This image will be used as a reference look/ thumbnail for the look</p>
            </div>
            {
                images && !referenceImage.imageUrl &&
                <div className='flex flex-col absolute min-w-[100%] shadow-[0_4px_10px_0_rgba(0,0,0,0.25)] h-100 top-[30px] left-[30px] bg-[#fff] p-[16px] border border-solid border-[#D9D9D9]'>
                    <p className='text-[14px] pb-[14px]'>Drag  the image to adjust</p>
                    <Cropper
                        ref={cropperRef}
                        style={{ height: "100%", width: "auto" }}
                        aspectRatio={1}
                        preview=".img-preview"
                        src={images}
                        minCanvasHeight={100}
                        viewMode={1}
                        guides={true}
                        zoomTo={zoom}
                        cropBoxMovable={true}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                    >

                    </Cropper>
                    <div className="absolute right-[20px] top-[65px] flex  bg-[#000000] items-center text-[#FFFFFF] px-[12px] py-[4px] rounded gap-[10px]">
                        <img className='h-[15px] cursor-pointer' src={ZoomoutIcon} onClick={handleZoomIn} alt='zoom in'></img>
                        <span>|</span>
                        <img className='h-[15px] cursor-pointer' src={ZoomInIcon} onClick={handleZoomOut} alt='zoom out'></img>
                    </div>
                    <button type='button' className='mt-[16px] rounded ml-[auto] px-[32px] py-[8px] bg-[#413E4F] text-[#FFFFFF]'
                        onClick={getCropData}>Done</button>
                </div>
            }
        </>
    ) : (
        <div
            className='w-full h-full relative flex flex-col items-center justify-end relative'
        >
            <img onClick={deleteImage} src={DeleteIcon} className="cursor-pointer absolute right-[15px] top-[15px]" alt='delete icon' />
            <img src={referenceImage.imageUrl} className='w-full rounded-[12px]' alt='reference url' />
        </div>
    )
}

export default ReferenceImage
