export default function DialogComponent({message,handleErrorDialog}) {
  const closeModal = ()=>{
    handleErrorDialog({show:false,message:''})
  }
  return (
        <div>
            <input type="checkbox" id="my-modal" className="modal-toggle" defaultChecked/>
              <div className="modal flex items-end" onClick={closeModal}>
                <div className="modal-box text-center bg-white">
                  {message}
                </div>
              </div>
        </div>
  )
}