import React, { useEffect } from "react";

import { ApiService } from "../../../services/apiUtility";

const TryOnWindow = ({
  additionalClasses = "items-center",
  skinAnalyze = false,
}) => {
  useEffect(() => {
    let onARLoad = () => {
      // do something when beautyAR successfully loads
      // showing
      const showParam = {
        querySelectorToAttach: "#tryonappenddiv",
        callback: () => {
          // on success full show
        },
        customStyleObj: {
          class: ["w-full", "h-full"],
          css: {
            // top : '1px'
          },
        },
      };
      // below function also works with await as well, incase callback hell is to be avoided
      if (
        window &&
        window.mirrarBeautyAR &&
        window.mirrarBeautyAR.showBeautyAR
      ) {
        window.mirrarBeautyAR.showBeautyAR(showParam);
      }
    };

    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    let brandId = ApiService.brandId;
    let config = {
      callback: onARLoad,
      brandId: brandId,
      urlParams: {
        calibrate: "feature,finish,color",
        sdkVersion: "mirrar",
      },
    };

    script.onload = () => {
      console.log("script loaded");
      window.mirrarBeautyAR.loadBeautyAR(config);
    };
    script.src = process.env.REACT_APP_MIRRAR_SRC;

    document.body.append(script);
  }, []);

  return (
    <>
      <div id="tryOn" className={`flex w-full ${additionalClasses}`}>
        <div
          id="tryonappenddiv"
          className="text-white flex justify-center items-center"
          style={{
            width: "100%",
            aspectRatio: "1",
            maxHeight: "100%",
          }}
        ></div>
      </div>
    </>
  );
};

export default TryOnWindow;
