import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReferenceImage from './ReferenceImage'
import ReferenceVideo from './ReferenceVideo'

import DropDownIcon from '../../../../assets/images/dropdown.svg'
import AddIcon from "../../../../assets/images/white-plus-icon.svg"
import SearchIcon from "../../../../assets/images/search-icon.svg"
import { ApiService } from '../../../../services/apiUtility'
import { AppContext } from '../../../../context/AppContextProvider'
import { CreateLooksContext } from '../../../../context/LooksContext'

const CreateLooksRevampContainer = () => {

    const navigate = useNavigate()

    const { referenceImage } = useContext(CreateLooksContext)
    const { referenceVideo } = useContext(CreateLooksContext)
    const [selectedCategory, setSelectedCategory] = useState({
        label: '',
        value: '',
        error: null
    })

    const [lookName, setLookName] = useState({
        text: '',
        error: null
    })

    // const [lookVideo, setLookVideo] = useState({
    //     file: null,
    //     error: null
    // })

    const [looksCategories, setLooksCategories] = useState([])
    const [looksFilteredCategories, setLooksFilteredCategories] = useState([])
    const [selectedCategoryDropdown, setSelectedCategoryDropdown] = useState(false)

    const [createNewCategory, setCreateNewCategory] = useState({
        open: false,
        text: '',
        error: null
    })

    const { setShowNotification } = useContext(AppContext)

    const convertDataURLToBlob = (url) => {
        const arr = url.split(',');
        const bstr = atob(arr[1]);
        let n = bstr.length;

        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return u8arr
    }

    const convertToKeyFormat = (text) => {
        if (!text) return ''
        return text.replaceAll(' ', '_').toLowerCase()
    }

    const submitHandler = async e => {
        e.preventDefault()
        const submitButtonName = e.nativeEvent.submitter.name
        if (submitButtonName === "reset") {
            window.location.reload()
            return
        }
        if (!referenceImage?.imageUrl) {
            setShowNotification({ open: true, message: 'Please upload image', type: 'error',hideAutomatically:true })
            return
        }
        // if (!lookVideo.file) {
        //     setLookVideo({
        //         ...lookVideo,
        //         error: 'Please upload a video'
        //     })
        //     return
        // }
        if (!lookName.text) {
            setLookName({
                ...lookName,
                error: 'Please enter a Look Name'
            })
            return
        } else if (lookName.text.length > 150) {
            setLookName({
                ...lookName,
                error: 'Look Name should be less than 150 characters'
            })
            return
        } else setLookName({ ...lookName, error: null })

        if (!selectedCategory.label) {
            setSelectedCategory({
                ...selectedCategory,
                error: 'Please Select a Collection'
            })
            return
        } else setSelectedCategory({ ...selectedCategory, error: null })

        const imageBlob = convertDataURLToBlob(referenceImage.imageUrl)
        const file = new File([imageBlob], referenceImage.name, { type: referenceImage.type })

        const formData = new FormData();
        formData.append("file", file);
        const imageUrl = await ApiService.getUploadedImageUrl(formData)

        let videoUrl = null
        if (referenceVideo?.file) {
            const videoFormData = new FormData();
            videoFormData.append("file", referenceVideo.file);
            videoUrl = await ApiService.getUploadedImageUrl(videoFormData)
            console.log("_videoUrl_ : ", videoUrl);
        }
        // Create the Look
        const paramForCreateLook = {
            brandId: ApiService.brandId,
            looks: [{
                key: convertToKeyFormat(lookName.text),
                label: capitalizeFirstLetter(lookName.text),
                image: [imageUrl],
                video: videoUrl ? [videoUrl] : [],
                status: true
            }]
        }
        const responseForCreateCategory = await ApiService.createLook(paramForCreateLook)

        // Map the look to the Collection
        const paramsForCollectionLookMapping = {
            lookCollectionLookMapping: [
                {
                    mappingStatus: true,
                    lookCollection: {
                        collectionKey: selectedCategory.value
                    },
                    look: {
                        key: convertToKeyFormat(lookName.text)
                    }
                }
            ]
        }

        const responseForCollectionLookMapping = await ApiService.createCollectionLookMapping(paramsForCollectionLookMapping)

        // Switch the look to disabled format
        const paramForDisabledLook = {
            brandId: paramForCreateLook.brandId,
            looks: [{
                key: convertToKeyFormat(lookName.text),
                label: capitalizeFirstLetter(lookName.text),
                image: [imageUrl],
                video: videoUrl ? [videoUrl] : [],
                status: false
            }]
        }
        const responseForDisabledLook = await ApiService.createLook(paramForDisabledLook)
        if (responseForCreateCategory && responseForCollectionLookMapping && responseForDisabledLook)
            navigate('/look-success')
    }

    const fetchLooksCategories = async () => {
        const res = await ApiService.fetchLooksCategories()
        if (res) {
            let categories = []
            for (let category of res.lookCollection) {
                const categoryObj = {
                    value: category.collectionKey,
                    label: category.collectionLabel
                }
                categories.push(categoryObj)
            }
            setLooksCategories(categories)
        }
    }

    useEffect(() => {
        fetchLooksCategories()
    }, [])

    useEffect(() => {
        setSelectedCategoryDropdown(false)
    }, [selectedCategory])

    useEffect(() => {
        setLooksFilteredCategories(looksCategories)
    }, [looksCategories])

    const searchCategories = e => {
        const searchedValue = e.target.value
        if (searchedValue) {
            const filteredValue = looksCategories.filter(({ value, label }) => value.toLowerCase().includes(searchedValue.toLowerCase()) || label.toLowerCase().includes(searchedValue.toLowerCase()))
            setLooksFilteredCategories(filteredValue)
        } else
            setLooksFilteredCategories(looksCategories)
    }

    const createCollection = async () => {
        if (!createNewCategory.text) {
            setCreateNewCategory({
                ...createNewCategory,
                error: 'Please enter Collection Name'
            })
        }
        const params = {
            "lookCollection": [
                {
                    "collectionKey": convertToKeyFormat(createNewCategory.text),
                    "collectionLabel": createNewCategory.text,
                }
            ]
        }
        const res = await ApiService.createLooksCategory(params)
        if (res) {
            setSelectedCategory({
                label: createNewCategory.text,
                value: convertToKeyFormat(createNewCategory.text),
                error: null
            })
            await fetchLooksCategories()
            setShowNotification({ open: true, message: 'New Looks Category created', type: 'success',hideAutomatically:true })
        } else {
            setShowNotification({ open: true, message: 'There is some issue while creating new looks category', type: 'error',hideAutomatically:true })
        }

        setCreateNewCategory({
            open: false,
            text: '',
            error: null
        })

    }

    const capitalizeFirstLetter = (category) => {
        if (!category) return ''
        const labelArray = category.split("_")

        const label = labelArray.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");

        return label
    }

    return (
        <div>
            <p className='font-semibold py-[19px] text-[16px]'>
                CREATE LOOKS
            </p>
            <div className='border border-solid border-[#D9D9D9] text-[16px] bg-[#fff] px-[20px] pb-[20px] rounded-[12px] w-full min-h-[95%] h-max'>
                <p className='py-[19px] text-[16px]'>Reference Look</p>
                <form onSubmit={submitHandler} className='flex gap-5'>
                    <div className='w-2/5 h-auto relative'>
                        <ReferenceImage />
                    </div>
                    <div className='w-3/5 flex flex-col'>
                        <div className='mb-[40px]'>
                            <p className='text-[16px] mb-[4px]'>Look Name <span className='text-[#FF0303]'>*</span></p>
                            <p className='text-[12px] text-[#737373] mb-[12px]'>The look will be saved by this name</p>
                            <input type='text' className=' bg-inherit w-full px-[12px] py-[18px] rounded-[4px] border border-[#D9D9D9] placeholder:text-[#989898] outline-none' placeholder='eg. Kendall at Met 2023 ' onChange={e => setLookName({ ...lookName, text: e.target.value })} />
                            {
                                lookName.error && <span className='text-[14px] text-[#FF0000] pt-[2px] pb-[5px]'>{lookName.error}</span>
                            }
                        </div>
                        <div className='mb-[40px]'>
                            <p className='text-[16px] mb-[4px]'>Look Video</p>
                            <p className='text-[12px] text-[#737373] mb-[12px]'>Upload a video for this look</p>
                            <div className='h-[150px]'>
                                <ReferenceVideo />
                        </div>
                        </div>
                        <div className='mb-[40px]'>
                            <p className='text-[16px] mb-[4px]'>Look Collection<span className='text-[#FF0303]'>*</span></p>
                            <p className='text-[12px] text-[#737373] mb-[12px]'>The look will be saved under this collection</p>
                            <div className='relative'>
                                <span onClick={() => setSelectedCategoryDropdown(!selectedCategoryDropdown)} className={`block cursor-pointer transition-all  w-full ${selectedCategoryDropdown ? 'rounded-t-[4px]' : 'rounded-[4px]'}  px-[12px] py-[18px] border border-[#D9D9D9] outline-none ${selectedCategory.label ? '' : 'text-[#989898]'}`}>{selectedCategory.value && selectedCategory.label ? selectedCategory.label : 'Select Collection'}</span>
                                <span><img src={DropDownIcon} alt='dropdown-icon' className={`absolute right-[20px] top-[26px] transition-all ${selectedCategoryDropdown ? 'rotate-180' : 'rotate-0'}`} /></span>
                                {
                                    selectedCategoryDropdown
                                        ? <div className='absolute w-full top-full rounded-b-[4px] px-[12px] mb-10 py-[18px] border border-[#D9D9D9] border-t-0 z-10 bg-[#fff]'>
                                            {
                                                createNewCategory.open
                                                    ? <>
                                                        <div className='mb-3'>
                                                            <p className='text-[16px] mb-[4px]'>Collection Name <span className='text-[#FF0303]'>*</span></p>
                                                            <p className='text-[12px] text-[#737373] mb-[12px]'>The new collection will be created</p>
                                                            <input type='text' className='bg-inherit w-full px-[12px] py-[18px] rounded-[4px] border border-[#D9D9D9] placeholder:text-[#989898] outline-none' placeholder='eg. Kendall at Met 2023 ' value={createNewCategory.text} onChange={e => setCreateNewCategory({ ...createNewCategory, text: capitalizeFirstLetter(e.target.value) })} />
                                                            {
                                                                createNewCategory.error && <span className='text-[14px] text-[#FF0000] pt-[2px] pb-[5px]'>{createNewCategory.error}</span>
                                                            }
                                                            <div className='flex gap-3 mt-3'>
                                                                <button type='button' className=' w-1/4 ml-auto py-2 rounded-[4px] border border-[#2C2C2C] text-[#2C2C2C]' onClick={() => setCreateNewCategory({ ...createNewCategory, open: false })}>Discard</button>
                                                                <button type='button' className=' w-1/4 py-2 rounded-[4px] bg-[#2C2C2C] text-[#fff]' onClick={createCollection}>Create</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <>
                                                        <div className='flex gap-5 items-center'>
                                                            <div className='relative w-4/6'>
                                                                <input placeholder='Search Look Collection' className='bg-inherit w-full text-[12px] px-[12px] py-[8px] rounded-[4px] border border-[#D9D9D9] placeholder:text-[#989898] outline-none' onChange={searchCategories} />
                                                                <img src={SearchIcon} className='absolute top-[50%] w-4 h-4 right-3 translate-y-[-50%]' alt='search-icon' />
                                                            </div>
                                                            <button onClick={() => setCreateNewCategory({ ...createNewCategory, open: true })} className='relative w-2/6 bg-[#373636] text-[#fff] text-[12px] px-[12px] py-[8px] rounded-[4px] flex items-center justify-evenly'>
                                                                <img src={AddIcon} className='w-3 h-3' alt='add-icon' />
                                                                <span>New Collection</span>
                                                            </button>
                                                        </div>
                                                        <ul className='overflow-y-auto mt-4 max-h-32 scrollbar-container3 '>
                                                            {
                                                                looksFilteredCategories.length
                                                                    ? looksFilteredCategories.map((category, index) =>
                                                                        <li className='cursor-pointer text-[14px] px-3 rounded-lg mr-3 py-3 hover:bg-[#F9F8FE]' key={index} onClick={() => setSelectedCategory({ error: null, ...category })}>{category.label}</li>
                                                                    )
                                                                    : null
                                                            }
                                                        </ul>
                                                    </>
                                            }
                                        </div>
                                        : null
                                }
                            </div>
                            {
                                selectedCategory.error && <span className='text-[14px] text-[#FF0000] pt-[2px] pb-[5px]'>{selectedCategory.error}</span>
                            }
                        </div>
                        <div className='mt-auto flex gap-[20px]'>
                            <button type='submit' name='reset' className='w-2/5 text-center rounded-[4px] border-2 border-[#837F9C] py-2'>Reset</button>
                            <button type="submit" name='submit' className='w-3/5 text-center rounded-[4px] border-2 border-[transparent] bg-[#413E4F] text-[#fff] py-2'>Start Creating Look</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateLooksRevampContainer
