import React,{useContext, useEffect, useState} from 'react'
import dayjs from 'dayjs';
import { ApiService } from '../../../services/apiUtility';
import LinearLoader from '../../Loader/LinearLoader';
import { AppContext } from '../../../context/AppContextProvider';

const WishlistedMakeup = () => {
  const {setShowNotification} = useContext(AppContext)
  const [showLoader,setShowLoader] = useState(false)
  const [dateStart,setDateStart] = useState(dayjs(dayjs().subtract(1, 'month').format('YYYY-MM-DD')).format('YYYY-MM-DD'))
  const [dateEnd,setDateEnd] = useState(dayjs().format('YYYY-MM-DD'))
  const [wishlistedProducts,setWishlistedProducts] = useState([])

  useEffect(()=>{
    getWishlistedProducts()
  },[dateEnd,dateStart])

  const handleDateStart = (e)=>{
    const startDate =dayjs(e.target.value).format('YYYYMMDD')
    const endDate = dayjs(dateEnd).format('YYYYMMDD') 
    if((startDate>endDate)){
        setShowNotification({open:true,message:'Invalid date range',type:'error',hideAutomatically:true})
    }else{
        setDateStart(e.target.value)
    }
}

const handleDateEnd = (e)=>{
    const endDate = dayjs(e.target.value).format('YYYYMMDD') 
    const currentDate=dayjs().format('YYYYMMDD') 
    if((endDate>currentDate)){
        setShowNotification({open:true,message:'Invalid date range',type:'error',hideAutomatically:true})
    }else{
        setDateEnd(e.target.value)
    }
}

  const getWishlistedProducts = async()=>{
    setShowLoader(true)
    const res=await ApiService.getWishlistedProducts(dateStart,dateEnd)
    const sortedData=res.sort((a,b)=>{
      return new Date(a.wishlistCreatedAt) > new Date(b.wishlistCreatedAt) ? 1 : -1
    })
    setWishlistedProducts(sortedData)
    setShowLoader(false)
  }

  const formatDate=(dateString)=> {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
  
    // Function to add the ordinal suffix to the day
    const getOrdinalSuffix=(day)=> {
      if (day >= 11 && day <= 13) {
        return 'th';
      }
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }
  
    const ordinalSuffix = getOrdinalSuffix(day);
    return `${day}${ordinalSuffix} ${month} ${year}`;
  }
  
  
  return (
    <div className='w-[60%] h-[630px] bg-white shadow-[0_5px_4px_0px_rgba(0,0,0,0.05)] rounded'>
          <div className='px-[20px] flex flex-col gap-[10px] justify-center font h-[15%] border-b-[1px]'>
              <div className='w-[50%]  text-[16px]'>
                <span className='font-bold'>Wishlisted makeup </span>
              </div>
              <div className='w-full flex justify-around gap-[10px] event-selection-container2 flex-wrap'>
                  <div className='flex items-center gap-[10px]'>
                        <span className='text-[14px]'>Start</span>
                        <input type="date" className='text-center text-[12px] rounded bg-white cursor-pointer border-[2px] border-black'
                            value={dateStart}
                            onChange={handleDateStart}
                        />
                  </div>
                  <div className='flex items-center gap-[10px]'>
                          <span className='text-[14px]'>End</span>
                          <input type="date" className='text-center text-[12px] rounded bg-white cursor-pointer border-[2px] border-black'
                              value={dateEnd}
                              onChange={handleDateEnd}
                          />
                  </div>
              </div>
          </div>
          <div className='h-[85%] overflow-y-auto container1'>
                    <div className='w-full'>
                        {
                          showLoader?<LinearLoader/>:
                            wishlistedProducts.length>0 ?
                            <table className='w-full h-full'>
                                <thead className='h-[50px]'>
                                    <tr>
                                        <td className='text-center text-[14px] font-normal text-[#626262]'>Products</td>
                                        <td className='text-center text-[14px] font-normal text-[#626262]'>Product Code </td>
                                        <td className='text-center text-[14px] font-normal text-[#626262]'>Date</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    wishlistedProducts.map((data,tableRowIndex) => {
                                        return(<tr key={tableRowIndex} className="py-[10px]">
                                                <td className='text-[14px] flex justify-center w-full h-[100px] items-center'>
                                                  {
                                                    data?.variant?.variantImageUrls[0]?
                                                    <img src={data.variant.variantImageUrls[0]} alt="" className='rounded-full p-[10px] shadow w-[81px] h-[81px]'/>
                                                  :<div className='w-[81px] h-[81px] text-[12px] text-center flex justify-center items-center text-white bg-gray-400 rounded-full'>No image available</div>
                                                  }
                                                  
                                                </td>
                                                <td className='text-left text-[14px] font-normal text-[#626262] w-[55%]'>{data.variant.variantSku}</td>
                                                <td className='text-center text-[14px] font-normal text-[#626262] w-[20%]'>{formatDate(data.wishlistCreatedAt)}</td>
                                            </tr>)
                                    })
                                }
                                </tbody>
                            </table>
                            :<div className='p-[20px] text-center'>No data available</div>
                        }
                        
                    </div>
                    
                </div>
    </div>
    
  )
}

export default WishlistedMakeup