import React, { useState,useEffect, useContext } from 'react'
import { BiArrowBack } from "react-icons/bi";
import LooksTableData from './LooksTableData';

import { SearchBar } from '../../../ManageInventory/SearchBar';
import { AppContext } from '../../../../../context/AppContextProvider';
import { ApiService } from '../../../../../services/apiUtility';
import Pagination from '../../../ManageInventory/Pagination';
import { ProductType } from '../../../ManageInventory/ProductType';
import { EntriesSelection } from '../../../ManageInventory/EntriesSelection';

const LooksProductTable = ({look,handleShowOrHideProductsTable}) => {
    const [lookProductsTableData,setLookProductsTableData] = useState([])
    const [lookProductsTableDataCopy,setLookProductsTableDataCopy] = useState([])

    const [searchValue,setSearchValue] = useState('')
    const [searchResults,setSearchResults] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [statusType,setStatusType] = useState('all')
    const [entries,setEntries] = useState([])
    const productsPerPage = 10;
    
    const hideProductsTable = ()=>{
        handleShowOrHideProductsTable(false)
    }

    useEffect(()=>{
        fetchLookProducts()
    },[])

    const fetchLookProducts = async ()=>{
        const products =  await ApiService.getLookProducts(look.key)
        createEntriesOptions(products.data)
        createTableData(products.data)
    } 

    const createTableData = (products)=>{
        let data=[]
        for(let product of products){
            let obj={
                'product_code':product.product_code,
                'product_title':product.product_title,
                'product_image_url':product.product_images,
                'variant_id':product.variant.variant_id,
                'variant_sku':product.variant.variant_sku,
                'variant_image_urls':product.variant.variant_image_urls[0],
                'variant_color':product.variant.variant_color,
                'variant_color_name':product.variant.variant_color_name,
                'variant_feature':product.variant.variant_feature,
                'variant_finish':product.variant.variant_finish,
                'variant_status':product.variant.variant_status,
                'variant_total_price':product.variant.total_price,
                'variant_compare_at_price':product.variant.variant_compare_at_price,
                'variant_description':product.variant.variant_description,
            }
            data.push(obj)
        }
        setLookProductsTableDataCopy(data)
        const lastIndex = currentPage * productsPerPage
        const firstIndex = lastIndex - productsPerPage
        const lookProducts = data.slice(firstIndex,lastIndex)
        setLookProductsTableData(lookProducts)
    }

    const handleSearchFilter =(searchText)=>{
        setCurrentPage(1)
        setSearchValue(searchText)
        handleSearch(searchText)
    }

    const handleSearch = (searchText)=>{
        const results=lookProductsTableDataCopy.filter((product)=>{
            if(statusType=='all'){
                return product.variant_sku.toLowerCase().includes(searchText.toLowerCase())
                || product.product_code.toLowerCase().includes(searchText.toLowerCase())
            }else{
                return (product.variant_sku.toLowerCase().includes(searchText.toLowerCase())
                || product.product_code.toLowerCase().includes(searchText.toLowerCase())) && String(product.variant_status)==statusType
            }
        })
        setSearchResults(results)
        setLookProductsTableData(results)
    }

    const handleProductStatus = (val)=>{
        setCurrentPage(1)
        setStatusType(val)
        const results=lookProductsTableDataCopy.filter((product)=>{
            if(val=='all'){
                return product.variant_sku.toLowerCase().includes(searchValue.toLowerCase())
                || product.product_code.toLowerCase().includes(searchValue.toLowerCase())
            }else{
                return (product.variant_sku.toLowerCase().includes(searchValue.toLowerCase())
                || product.product_code.toLowerCase().includes(searchValue.toLowerCase())) && String(product.variant_status)==val
            }
        })
        setSearchResults(results)
        setLookProductsTableData(results)
    }


    const capitalizeFirstLetter = (value)=>{
        const labelArray = value.split("_")
    
        const label = labelArray.map((word,index) => {
            if(index>0){
                return word[0].toUpperCase() + word.substring(1);
            }
        }).join("");
    
        return labelArray[0]+label
    }

    const updateDataOnPageChange = (data,firstIndex,lastIndex)=>{
        const products = data.slice(firstIndex,lastIndex)
        setLookProductsTableData(products)
    }

    const createEntriesOptions=(productData)=>{
        const entriesArray = []
        let entry
        for(let i=1;i<Math.ceil(productData.length/productsPerPage)+1;i++){
            if(i==Math.ceil(productData.length/productsPerPage)){
                entry = (i-1)*productsPerPage+1 + ' - ' + productData.length
            }else{
                entry = (i-1)*productsPerPage+1 + ' - ' + i*productsPerPage
            }
            
            entriesArray.push(entry)
        }
        setEntries(entriesArray)
    }

    const handleEntrySelection = (value)=>{
        setCurrentPage(value)
    }

    return (
        <div className='flex flex-col gap-[10px]'>
            <div className='flex flex-col gap-[20px]'>
                <div className='flex items-center gap-[10px]' >
                    <BiArrowBack onClick={hideProductsTable} className='cursor-pointer'/>Back
                </div>
            </div>
            <div>
                <div className='flex justify-center text-[14px] font-normal gap-[60px] mb-[15px]'>
                    {
                        entries.length>0?
                        <div className='flex gap-[10px] items-center'>
                            <span className=''>Show</span>  
                            <EntriesSelection entries={entries} handleEntrySelection={handleEntrySelection}/>
                            <span className=''>entries</span>  
                        </div>:''
                    }
                    <div className='flex gap-[10px] items-center'>
                        <span className=''>Status:</span>  
                        <ProductType handleProductStatus={handleProductStatus} type='Products'/>
                    </div>
                    <SearchBar handleSearchFilter={handleSearchFilter}/>
                </div>
                <LooksTableData tableData={lookProductsTableData} />
                <Pagination
                    tableData = {lookProductsTableDataCopy}
                    updateDataOnPageChange={updateDataOnPageChange} 
                    searchText={searchValue}
                    searchResults={searchResults}
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage}
                    recordsPerPage = {productsPerPage}
                    type={'table data'}
                    statusType={statusType}
                />
            </div>
        </div>
    )
}

export default LooksProductTable