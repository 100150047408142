import React from 'react';

const UploadLoader = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-[50px] h-[50px] animate-spin border-[5px] border-[#f3f3f3] rounded-full border-t-[5px] border-t-[#3498db]"></div>
      <div className="mt-[10px] text-[16px]">
        Uploading...
      </div>
    </div>
  );
};

export default UploadLoader;