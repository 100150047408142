import React from 'react'
import Select from 'react-select';
export const CategoryTab = ({categories,selectedCategory,handleCategoryOnClick=()=>{},calibrate})=>{
    const selected = {
      value:selectedCategory.category,
      label:selectedCategory.categoryLabel?selectedCategory.categoryLabel:selectedCategory.label
    }
    let options=[]
    categories.map((category,index)=>{
        const obj={
            value:category.category,
            label:category.categoryLabel?category.categoryLabel:category.label
        }
        options.push(obj)
    })
    const customStyles = {
        option: (defaultStyles, state) => ({
          ...defaultStyles,
          textAlign: state.isSelected ? "center" : "center",
        }),
    
        control: (defaultStyles) => ({
          ...defaultStyles,
          textAlign:'center',
        }),
        singleValue: (defaultStyles) => ({ 
            ...defaultStyles,
             textAlign:'center', 
             fontSize:'16px',
             
        }),
      };
    
    const handleCategoryClick = (option)=>{
        const category = categories.filter((category)=>{
            return category.category == option.value
        })
        handleCategoryOnClick(category[0])
    }
    return (
      <div className=''>
        {
          categories.length>0 && selectedCategory?
          <Select
              defaultValue={selected}
              value={selected}
              onChange={handleCategoryClick}
              options={options}
              styles={customStyles}
              isSearchable={false} 
              theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f2f2f2',
                    primary: 'black',
                  },
              })}
        />:''
        }
      </div>
      
    )
}