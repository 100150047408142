import React, { useEffect, useState } from 'react'

const ToolTipContainer = ({
    setOpenTooltip,
    openTooltip,
    steps = []
}) => {

    const [stepIndex, setStepIndex] = useState(0)

    const convertToPx = (number) => {
        if (!number) return `0px`
        return `${number}px`
    }

    useEffect(() => {
        setStepIndex(0)
    }, [openTooltip])

    const createTransparentBlock = (item) => {
        const transparentBlock = document.createElement('div')
        transparentBlock.id = "transparentBlock"
        transparentBlock.className = "absolute rounded-[4px] bg-[gray] opacity-100 pointer-events-auto transition-all top-[296px] left-[676px]"
        const { height, width, left, top } = item.getBoundingClientRect()
        transparentBlock.style.width = convertToPx(width + 20)
        transparentBlock.style.height = convertToPx(height + 20)
        transparentBlock.style.top = convertToPx(top - 10)
        transparentBlock.style.left = convertToPx(left - 10)
        document.getElementById("overlay-block")?.appendChild(transparentBlock)
    }

    const createContentDiv = () => {
        const contentDiv = document.createElement('div')
        contentDiv.id = "contentDiv"
        contentDiv.className = `w-[200px] absolute -translate-y-1/2 text-white font-semibold`
        const transparentDiv = document.querySelector("#transparentBlock")
        if (!transparentDiv) return
        contentDiv.innerText = steps[stepIndex].content
        const contentDivTopPos = transparentDiv.offsetTop + (transparentDiv.offsetHeight / 2)
        if (steps[stepIndex].positionRight) {
            contentDiv.style.left = `${transparentDiv.offsetLeft + 200 + 30}px`
            contentDiv.style.top = `${contentDivTopPos}px`
            contentDiv.style.textAlign = "left"
        } else {
            contentDiv.style.left = `${transparentDiv.offsetLeft - 200 - 30}px`
            contentDiv.style.top = `${contentDivTopPos}px`
            contentDiv.style.textAlign = "right"
        }
        document.querySelector("#content-overlay-div")?.appendChild(contentDiv)
    }

    useEffect(() => {
        if (stepIndex < steps.length) {
            const elements = steps[stepIndex].target.split(',')
            if (openTooltip)
                if (elements.length > 1) {
                    if (steps[stepIndex].events) steps[stepIndex].events()
                    elements.forEach((element) => {
                        document.querySelector('#dashboard_layout_container')?.scrollTo(0, 0)
                        const item = document.querySelector(element)
                        if (steps[stepIndex].events) steps[stepIndex].events()
                        if (item) {
                            createTransparentBlock(item)
                        }
                    })
                    createContentDiv()
                }
                else {
                    const item = document.querySelector(steps[stepIndex].target)
                    if (item) {
                        if (steps[stepIndex].events) steps[stepIndex].events()
                        createTransparentBlock(item)
                        createContentDiv()
                    }
                }
        }
        return () => {
            document.querySelectorAll('#transparentBlock')?.forEach((element) => {
                element?.remove()
            })
            document.querySelector('#contentDiv')?.remove()
        }
    }, [openTooltip, stepIndex])


    const closeTooltip = () => {
        localStorage.setItem('look-tip-closed', 'true')
        setOpenTooltip(false)
    }


    useEffect(() => {
        const eventListenerClose = (e) => {
            if (e.key == "Escape") {
                closeTooltip()
            }
        }
        if (openTooltip) {
            // To make sure that the tooltip closes when someone presses "Esc" button
            document.body.addEventListener('keydown', eventListenerClose)
        }
        return () => {
            document.body.removeEventListener('keydown', eventListenerClose)
        }
    }, [openTooltip])

    return openTooltip
        ? (
            <div className='bg-[#fff]'>
                <div
                    id='content-overlay-div'
                    className='fixed top-0 left-0 h-full w-full z-[51] bg-transparent pointer-events-none'
                >
                </div>
                <div
                    id="overlay-block"
                    onClick={() =>
                        stepIndex < steps.length
                            ? setStepIndex(stepIndex + 1)
                            : closeTooltip()
                    }
                    className={`fixed top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.55)] ${stepIndex === steps.length ? 'backdrop-blur-sm' : ''} z-50 inset-0 mix-blend-hard-light`}
                >
                    {/* <div id='transparent-block' className='absolute rounded-[4px] bg-[gray] opacity-100 pointer-events-auto transition-all top-[296px] left-[676px]'>
                        <div className=' w-[200%] absolute left-[calc(100%+20px)] top-1/2 -translate-y-1/2 text-white'>asdadasdadasd</div>
                    </div> */}
                </div>
                {
                    stepIndex === steps.length
                    && <div className='text-center w-[250px] bg-transparent h-auto fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-[15px]'>
                        <p className=' uppercase text-[#fff] text-[16px]'>You are ready to go!</p>
                        <button type='button' className='bg-white text-[16px] text-[#282828] w-full flex-grow rounded px-2 py-3' onClick={() => setStepIndex(0)}>Take me through the tutorial again</button>
                        <button type='button' className='bg-transparent border border-[#FFFFFF] w-full flex-grow rounded px-2 py-3 text-[#fff]' onClick={closeTooltip}>Exit</button>
                    </div>
                }
                <button onClick={closeTooltip} type='button' className='rounded-[4px] z-[51] bg-transparent fixed bottom-8 right-8 text-[#fff] p-3 border-2 border-[#fff] font-semibold'>Skip Tutorial</button>
            </div>
        )
        : null
}

export default ToolTipContainer
