import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineSearch } from "react-icons/ai";

export const SearchBar = ({handleSearchFilter})=>{
    const [searchText,setSearchText] = useState('')
    const inputRef=useRef(null)
    const handleSearch = (e)=>{
        if (e.key === 'Enter') {
            handleSearchFilter(inputRef.current.value)
        }else if(e.type=='click' || e.type=='change'){
            if(inputRef){
                handleSearchFilter(inputRef.current.value)
            }
        }
    }
    return(
            <div className='flex w-[250px] max-h-[40px] rounded-l'>
                <input type="text" className="border-[2px] border-[#00000012] bg-white p-[2px] " placeholder="Search" ref={inputRef} onKeyDown={handleSearch} value={searchText} onChange={(e)=>{
                    setSearchText(e.target.value)
                    if(e.target.value==''){
                        handleSearch(e)
                    }
                }}/>
                <div className="flex items-center justify-center px-[10px] bg-[#00000012] cursor-pointer">
                    <AiOutlineSearch size='24px' onClick={handleSearch}/>
                </div>
            </div>
    )
}