import React, { useEffect, useState, useRef } from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ApiService } from '../../services/apiUtility';
import LinearLoader from '../Loader/LinearLoader';

export const RecommendedShadesPopUp = ({ handleRecommendedShades = () => { }, shadeColor,
    selectedCategory }) => {
    const [similarityPercent, setSimilarityPercent] = useState(80)
    const [selectedColor, setSelectedColor] = useState('0,0,0')
    const [recommendedColors, setRecommendedColors] = useState([])
    const [similarityError, setSimilarityError] = useState(null);
    const [showLoader,setShowLoader] = useState(false)
    const searchRef = useRef(null)

    useEffect(() => {
        const rgb = extractRGB(shadeColor.color)
        const rgbValue = rgb.r + ',' + rgb.g + ',' + rgb.b + ''
        setSelectedColor(rgbValue)
        searchForSimilarColors(rgbValue)
    }, [shadeColor])
    const extractRGB = (rgbString) => {
        const regex = /\((.*?)\)/;
        const match = rgbString.match(regex);

        if (match) {
            const values = match[1].split(',').map(value => parseInt(value));
            return {
                r: values[0],
                g: values[1],
                b: values[2]
            };
        } else {
            return null;
        }
    }
    const validateSimilarity = () => {
        if (similarityPercent > 100 || similarityPercent < 0) {
            return false
        } else {
            return true
        }
    }

    const searchForSimilarColors = async (color) => {
        setShowLoader(true)
        if (!validateSimilarity()) {
            setSimilarityError("Similarity percent should be between 0-100");
            return;
        }
        const category = selectedCategory.category
        const response = await ApiService.getRecommendedProducts(category, color, similarityPercent)
        const colors = []
        if (response) {
            response[category].map((product, index) => {
                return colors.push(
                    {
                        color: product.variant_color,
                        variant_sku: product.variant_sku
                    })
            })
        }
        setRecommendedColors(colors)
        setShowLoader(false)
    }
    return (
        <>
            <div className="cursor-default justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className=" w-[80%] my-6">
                    <div className="border-0 rounded-lg shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none gap-[10px] pb-[10px]">
                        <div className="flex items-start justify-between px-3 pt-1">
                            <button
                                className="p-1 ml-auto float-right"
                                onClick={() => handleRecommendedShades(false, null)}
                            >
                                <AiOutlineCloseCircle size={22} />
                            </button>
                        </div>
                        <div className="px-[20px] pb-[15px] flex-auto">
                            <div className='flex gap-[20px] w-full items-end'
                            >
                                <div className='flex flex-col gap-[5px] w-[60%]'>
                                    <div>Similarity Percent</div>
                                    <div>
                                        <input type="number" name="" id="" max="100" min="0" value={similarityPercent} className='w-full bg-white border-[1px] py-[5px] pl-[10px]' onChange={(e) => {
                                            setSimilarityPercent(e.target.value)
                                            setSimilarityError(null)
                                        }} required />
                                        {
                                            similarityError ?
                                                <label className="label">
                                                    <span className="label-text-alt text-red-500">{similarityError}</span>
                                                </label> : ''
                                        }
                                    </div>
                                </div>
                                <button ref={searchRef} type='button' className='text-white bg-black px-[20px] text-center h-[36px]' onClick={(e)=>searchForSimilarColors(selectedColor)}>
                                    Search
                                </button>
                            </div>
                        </div>
                        {
                            // showRecommendedColors ?
                                recommendedColors.length > 0 ?
                                    (
                                        <div className='px-[20px] pb-[20px]'>
                                            <div className='border-[1px] rounded p-[20px] w-full'>
                                                <div className='mb-[10px] text-[16px] font-medium'>Recommended Colors</div>
                                                <div className='flex flex-wrap gap-[20px]'>
                                                    {
                                                        recommendedColors.map((color, index) => {
                                                            return <div key={index} className='cursor-pointer flex flex-col gap-[10px] text-[14px] h-[130px] w-[130px] p-[10px] border-[1px] rounded justify-center'>
                                                                <div className='w-full flex justify-center'>
                                                                    <div style={{ background: `${color.color.split(',').map(rgb => rgb.trim()).length > 3 ? `linear-gradient(to right,${color.color})` : `${color.color}`}` }} className='w-[30px] h-[30px]'></div>
                                                                </div>
                                                                <div className='text-center overflow-hidden text-ellipsis'>{color.color}</div>
                                                            </div>
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    ) :
                                    showLoader?
                                    <LinearLoader/>:
                                    <div className='p-[15px] text-center'>
                                        No recommended colors found.
                                    </div> 
                                    // : ''
                        }

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}