import React from 'react'
import loader from '../../assets/json-loaders/loader.json'
import Lottie from "lottie-react";

const CircularLoader = () => {
  return (
    <div className='absolute w-[90%] h-full bg-[#ffffffd1] top-[0px] flex flex-col justify-center items-center'>
        <Lottie animationData={loader} loop={true} className='h-[150px]'/>
        <div className="mt-[-45px] text-center">
            <span className='font-medium'>Please wait...</span>
        </div>
    </div>
  )
}

export default CircularLoader