import React from 'react'
import SkinConcernMessaging from '../../Components/Settings/SkinConcernMessaging'

const SkinConcernMessagingPage = () => {
    return (
        <div className='h-full'>
            <SkinConcernMessaging />
        </div>
    )
}

export default SkinConcernMessagingPage
