import React from 'react'
import ConsentInformation from '../../Components/Settings/ConsentInformation'

const ConsentInformationPage = () => {
  return (
    <ConsentInformation/>
  )
}

export default ConsentInformationPage
