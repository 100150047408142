import React, { useContext, useState, useEffect } from 'react'
import ThemeColor from './ThemeColor'
import { AppContext } from '../../context/AppContextProvider'
import { ApiService } from '../../services/apiUtility'
import { useNavigate } from 'react-router-dom'

const Customization = () => {
  const { brandSettings, getBrandSettings, setShowNotification, setBrandSettings } = useContext(AppContext)
  const [takePhoto, setTakePhoto] = useState(false)
  const [skinAnalysis, setSkinAnalysis] = useState(false)
  const [comparisonMode, setComparisonMode] = useState(false)
  const [createLooks, setCreateLooks] = useState(false)
  const [manageLooks, setManageLooks] = useState(false)
  const [liveCamera, setLiveCamera] = useState(false)
  const [uploadModel, setUploadModel] = useState(false)
  const [chooseModel, setChooseModel] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setTakePhoto(brandSettings?.setting_meta_json?.customization_feature?.takePhoto)

    setSkinAnalysis(brandSettings?.setting_meta_json?.customization_feature?.skinAnalysis)


    setComparisonMode(brandSettings?.setting_meta_json?.customization_feature?.comparisonMode)

    setCreateLooks(brandSettings?.setting_meta_json?.customization_feature?.createLooks)

    setManageLooks(brandSettings?.setting_meta_json?.customization_feature?.manageLooks)


    setLiveCamera(brandSettings?.setting_meta_json?.customization_feature?.liveCamera)

    setUploadModel(brandSettings?.setting_meta_json?.customization_feature?.uploadModel)
    
    setChooseModel(brandSettings?.setting_meta_json?.customization_feature?.chooseModel)

  }, [brandSettings])

  const handleToggle = (id) => {
    if (id == 'take-photo') {
      handleTakePhotoChange()
    } else if (id == 'comparison-mode') {
      handleComparisonModeChange()
    } else if (id == 'skin-analysis') {
      handleSkinAnalysisChange()
    } else if (id == 'create-looks') {
      handleCreateLooks()
    } else if (id == 'manage-looks') {
      handleManageLooks()
    } else if (id == 'live-camera') {
      handleLiveCamera()
    } else if (id == 'upload-model') {
      handleUploadModel()
    } else if (id == 'choose-model') {
      handleChoosemodel()
    }
  }
  const handleSkinAnalysisChange = () => {
    setSkinAnalysis(!skinAnalysis)
  }

  const handleComparisonModeChange = () => {
    setComparisonMode(!comparisonMode)
  }

  const handleTakePhotoChange = () => {
    setTakePhoto(!takePhoto)
  }

  const handleCreateLooks = () => {
    setCreateLooks(!createLooks)
  }

  const handleManageLooks = () => {
    setManageLooks(!manageLooks)
  }

  const handleLiveCamera = () => {
    if(!chooseModel){
      setShowNotification({ open: true, message: 'At least one of the mode (live camera or choose model should be enabled)', type: 'error',hideAutomatically:true })
    }else{
      setLiveCamera(!liveCamera)
    }
  }

  const handleUploadModel = () => {
    setUploadModel(!uploadModel)
  }

  const handleChoosemodel = () => {
    if(!liveCamera){
      setShowNotification({ open: true, message: 'At least one of the mode (live camera or choose model should be enabled)', type: 'error',hideAutomatically:true })
    }else{
      setChooseModel(!chooseModel)
    }
  }
  const customization = [
    { label: 'Take Photo', variable: takePhoto, id: 'take-photo' },
    { label: 'Comparison Mode', variable: comparisonMode, id: 'comparison-mode' },
    { label: 'Skin Analysis', variable: skinAnalysis, id: 'skin-analysis' },
    { label: 'Create Looks', variable: createLooks, id: 'create-looks' },
    { label: 'Manage Looks', variable: manageLooks, id: 'manage-looks' },
    { label: 'Live Camera', variable: liveCamera, id: 'live-camera' },
    { label: 'Upload Model', variable: uploadModel, id: 'upload-model' },
    { label: 'Choose Model', variable: chooseModel, id: 'choose-model' }
  ]

  const handleSaveCustomization = async () => {
    setBrandSettings((brandSettings) => {
      const settings = { ...brandSettings.setting_meta_json }
      settings['customization_feature'] = {
        takePhoto: takePhoto,
        skinAnalysis: skinAnalysis,
        comparisonMode: comparisonMode,
        createLooks: createLooks,
        manageLooks: manageLooks,
        liveCamera: liveCamera,
        uploadModel: uploadModel,
        chooseModel: chooseModel
      }
      const params = {
        brandId: ApiService.brandId,
        setting: {
          json: settings
        }
      }
      ApiService.updateSettings(params).then(res => {
        if (res && res.status == 200) {
          setShowNotification({ open: true, message: 'Customization changes saved successfully', type: 'success',hideAutomatically:true })
        } else if (res && res.status == 403) {
          localStorage.clear()
          navigate('/authentication/sign-in')
          setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
        } else {
          setShowNotification({ open: true, message: res.message, type: 'error',hideAutomatically:true })
        }
      })
      brandSettings.setting_meta_json = settings
      return { ...brandSettings }
    })
  }
  return (
    <div className='flex text-[16px] flex-col my-[20px] w-full px-[25px] py-[20px] bg-[#fff] min-h-[calc(100%_-_40px)]'>
      <div className='w-[50%]'>
        <div className='font-medium text-[16px] px-[20px]'>
          Application Customization :
        </div>
        <div className='w-full py-[20px]'>
          <div className='grid px-[20px]'>
            {
              customization.map((customizationOption, index) => {
                return (
                  <div key={index} className="flex justify-between mb-[20px]">
                    <label
                      htmlFor={customizationOption.id}
                      className="mt-px mb-0 cursor-pointer select-none"
                    >
                      {customizationOption.label}
                    </label>
                    <div className="relative inline-block h-4 w-8 cursor-pointer rounded-full">
                      <input
                        id={customizationOption.id}
                        type="checkbox"
                        checked={customizationOption.variable}
                        onChange={() => handleToggle(customizationOption.id)}
                        className={`${customizationOption.variable ? 'bg-gray-500' : 'bg-gray-400'} peer absolute h-4 w-8 cursor-pointer appearance-none rounded-full  transition-colors duration-300`}
                      />
                      <label
                        htmlFor={customizationOption.id}
                        className={`${customizationOption.variable ? 'bg-black' : 'bg-white'} before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100  shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity peer-checked:translate-x-full peer-checked:border-gray-500 peer-checked:before:bg-gray-500`}
                      >
                        <div
                          className="top-2/4 left-2/4 inline-block -translate-x-2/4 -translate-y-2/4 rounded-full p-5"
                          data-ripple-dark="true"
                        ></div>
                      </label>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="flex w-full justify-center">
            <button className="bg-black text-white p-[5px_20px]" onClick={handleSaveCustomization}>
              <span>Save</span>
            </button>
          </div>
        </div>
        <ThemeColor />

      </div>
      <div>

      </div>
    </div>
  )
}

export default Customization