import React, { useContext,useState,useEffect } from 'react'
import dayjs from 'dayjs';

import { ApiService } from '../../../services/apiUtility';
import LinearLoader from '../../Loader/LinearLoader';
const TopTriedOnMakeup = () => {
    const event ='makeup-selected'
    const [products,setProducts] = useState([])
    const [showLoader,setShowLoader] = useState(false)
    const [firstTimeLoad,setFirstTimeLoad] = useState(true)

    useEffect(() => {
        setShowLoader(true)
        fetchData()
    }, [])

    const fetchData = async ()=>{
        const graphData = JSON.parse(localStorage.getItem('topTriedMakeups'))
        if(firstTimeLoad && graphData){ 
            setProducts(graphData)
            setShowLoader(false)
            setFirstTimeLoad(false)
        }else{
            const startDate =dayjs(dayjs().subtract(1, 'month').format('YYYY-MM-DD')).format('YYYYMMDD')
            const endDate = dayjs(dayjs().format('YYYY-MM-DD')).format('YYYYMMDD')     
            
            const data =await ApiService.amplitudeAnalytics(
                event,'30',
                'totals',startDate,endDate,true,['product_code','category'],'event')
            const productsData =await getProducts(data)
            if(!graphData){
                localStorage.setItem('topTriedMakeups',JSON.stringify(productsData))
            }
            setProducts(productsData)
            setShowLoader(false)
        }
    }

    const getProducts = async (data)=>{
        let objects=[]
        if(data && data.seriesLabels.length>0 && data.seriesCollapsed.length>0){
            const productLength = data.seriesLabels.length
            for(let i=0;i<productLength;i++){
                const productCode = data.seriesLabels[i][1].split(';')[0]
                const category = data.seriesLabels[i][1].split(';')[1].replace(' ','')
                const totalEvents = data.seriesCollapsed[i][0].value
                if(productCode!='(none)'){
                    const productData = await ApiService.getProductData(productCode)
                    let productImage=''
                    for(let product of productData){
                        if(product.variant_image_urls.length>0){
                            productImage=product.variant_image_urls[0]
                        }
                    }
                    const obj={
                        'productCode':productCode,
                        'category':category,
                        'totalEvents':totalEvents,
                        'productImage':productImage
                    }
                    objects.push(obj)
                }
            }
        }
        return objects
        
    }
    return(
        <div className='w-[40%] h-[630px] bg-white shadow-[0_5px_4px_0px_rgba(0,0,0,0.05)] rounded'>
                <div className='px-[20px] flex items-center font h-[15%] border-b-[1px]'>
                        <div className=' text-[16px]'>
                        <span className='font-bold'>Top Tried on Makeup</span>
                        <span> (last 30 days)</span>
                        </div>  
                </div>
                <div className='h-[80%] overflow-y-auto container1'>
                    <div className='w-full'>
                        {
                            showLoader?<LinearLoader/>:
                            products.length>0 ?
                            <table className='w-full h-full'>
                                <thead className='h-[50px] bg-[#F9F8FE]'>
                                    <tr>
                                        <td className='text-center text-[14px] font-bold text-[#626262] whitespace-nowrap'>Products</td>
                                        <td className='text-center text-[14px] font-bold text-[#626262]'>Product Code</td>
                                        <td className='text-center text-[14px] font-bold text-[#626262]'>Totals</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    products.map((data,tableRowIndex) => {
                                        return(!data.productCode.includes('none')?<tr key={tableRowIndex} className="py-[10px]">
                                                <td className='text-[14px] flex justify-center w-full h-[100px] items-center'>
                                                    {
                                                        data.productImage?
                                                        <img src={data.productImage} alt='makeup' className='rounded-full p-[10px] shadow w-[81px] h-[81px]'/>
                                                        :<div className='w-[81px] h-[81px] text-[12px] text-center flex justify-center items-center text-white bg-gray-400 rounded-full'>No image available</div>
                                                    }
                                                   
                                                </td>
                                                <td className='text-center text-[14px] font-normal text-[#666085] w-[55%]'>{data.productCode}</td>
                                                <td className='text-center text-[14px] font-semibold text-[#717474] w-[20%]'>{data.totalEvents}</td>
                                            </tr>:'')
                                    })
                                }
                                </tbody>
                        </table>
                        :<div className='p-[20px] text-center'>No data available</div>
                        }
                        
                    </div>
                    
                </div>
        </div>
    )
}

export default TopTriedOnMakeup