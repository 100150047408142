import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'

const TableDataCell = ({data, dataKey}) => {
  const [val, setVal] = useState(data?data:'')

  const [isImage, setIsImage] = useState()
  const [imagePreview,setImagePreview] = useState({src:'',show:false})
  const isImageAvailable = () => {
    return dataKey.toLowerCase().includes('image') && data
  }

  const previewImage = (event,src) =>{
    if(event.type=='mousedown'){
      setImagePreview({src:src,show:true})
    }else{
      setImagePreview({src:'',show:false})
    }
  }

  const rgbToHex = (rgbValue)=> {
    var rgb = rgbValue.replace(/^rgba?\(|\s+|\)$/g,'').split(',');
    const r = rgb[0]
    const g= rgb[1]
    const b= rgb[2]
    return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
  }

  useEffect(() => {
    setVal(data)
    setIsImage(isImageAvailable())
  }, [data])

  return (
    <>
      {
        isImage ? 
        <div className='relative' onMouseUp={(event)=>previewImage(event,val)}>
          <img
            src={val}
            className="w-[75px] h-[75px] rounded"
            alt="data"
            onMouseDown={(event)=>previewImage(event,val)}
          />
          {
            imagePreview.show?(
              <div className='absolute top-[-24px] left-[0px] z-10 border border-slate-200'>
                  <img src={imagePreview.src} alt="" className='w-[120px] h-[120px]'/>
              </div>
            ):''
          }
        </div>
        :dataKey.toLowerCase()=='variant_color'?
        (<div>
        
        <div className="relative flex gap-[5px] mt-[5px] items-center">
          <input
            type="text"
            className="!outline-none bg-white w-[60%] bg-white text-gray-900 text-sm rounded py-4"
            value={data}
            readOnly={true} 
          />
          <div 
          style={{backgroundColor:data}}
            className="h-[40px] w-[40px]"
            // value={rgbToHex(data)} 
            
            ></div>
        </div>
      </div>)
        :dataKey.toLowerCase()=='variant_sku' || dataKey.toLowerCase()=='product_code' || dataKey.toLowerCase()=='product_title'?
        <textarea 
          type="text" 
          value={val} 
          className='!outline-none w-full h-[70px] bg-white text-[15px]' 
          readOnly
        />:
        <input 
          type="text" 
          value={val} 
          className='!outline-none w-full h-[70px] bg-white text-[15px]' 
          readOnly={true}
        />
      }
    </>
  )
}




const LooksTableData = ({tableData = []}) => {
  const capitalizeFirstLetter = (heading)=>{
    const labelArray = heading.split(" ")

    const label = labelArray.map((word) => { 
      return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");

    return label
  }
  const table=tableData.length>0?
    <div className="w-[100%]">
          <div className='container1 overflow-x-auto'>
            <table className='border-[1px] border-[rgba(224,224,224,1)]'>
              <thead>
                {<tr>
                  {
                    _.keys(tableData[0]).map((key) => {
                      return (<th key={key} className='px-[16px] py-[20px] w-[220px] min-w-[220px] text-start border-b-[1px] border-[rgba(224,224,224,1)]'>
                        <div className={`${key.toLowerCase().includes('id')?'w-[100px] min-w-[100px]':'w-[220px] min-w-[220px]'} font-medium`}>{capitalizeFirstLetter(key.replaceAll('_',' '))}</div>
                      </th>)
                    }
                    )
                  }
                </tr>
                }
                </thead>
                <tbody>
                {
                  tableData.map((data,tableRowIndex) => {
                    
                    return(
                      <tr key={tableRowIndex} className="border-b-[1px]">
                      {
                        Object.keys(data).map((key,index)=>{
                          return <td key={index} className='py-[5px] px-[16px]'>
                            <TableDataCell
                              data={data!=null && data[key]!=null?data[key]:''}
                              dataKey={key}
                              id={tableRowIndex}
                              className='border-b-[1px]'
                            ></TableDataCell>
                          </td>
                        })
                      }
                      </tr>)
                  }
                )}
                </tbody>
            </table>
          </div>
    </div>:
    <div className='w-full flex justify-center'>
        <div className='w-[30%] h-[70%] flex justify-center items-start'>
            No Product found
        </div>
    </div>
  
  return (
    <>
      {table}
    </>
  )
}

export default LooksTableData