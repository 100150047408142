import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { ApiService } from '../../services/apiUtility'

const CreateCategory = () => {
    const finishes = ['Matte', 'Metallic', 'Glossy', 'Satin', 'Lipgloss', 'Shimmer', 'LipglossShimmer', 'Glitter']

    const features = ['LipstickOneTone', 'MascaraShortBottom', 'MascaraShortTop',
        'MascaraShortTopBottom', 'MascaraMediumBottom', 'MascaraMediumTop',
        'MascaraMediumTopBottom', 'MascaraLongBottom', 'MascaraLongTop',
        'MascaraLongTopBottom', 'BlushApple', 'BlushCheekbone', 'BlushContour',
        'FoundationFull', 'FoundationT-Zone', 'EyelinerTop', 'EyelinerBottom',
        'EyelinerTopBottom', 'EyelinerWinged', 'Lipliner', 'Concealer', 'EyeshadowLid',
        'EyeshadowContour', 'EyeshadowHighlight', 'EyeshadowSmokey', 'EyebrowsThin',
        'EyebrowsMedium', 'EyebrowsThick', 'FaceSoftener', 'LipGloss', 'Contour',
        'Highlighter', 'HighlighterForeheadChin', 'HighlighterForeheadTemplesChin',
        'LipstickOmbreOuter', 'LipstickOmbreInner', 'BlushOval', 'BlushRound',
        'BlushLong', 'ContourOval', 'ContourOvalLight', 'ContourOvalMedium',
        'ContourOvalHeavy', 'ContourRound', 'ContourSquare', 'ContourRectangle',
        'ContourOblong', 'ContourHeart', 'EyelinerDoubleFlick', 'EyelinerRetro',
        'EyebrowLine', 'EyelinerBox', 'EyeshadowVerticalInside',
        'EyeshadowVerticalOutside', 'LipstickPetal', 'FacePaintOneSide',
        'FacePaintTemples', 'FacePaintForehead', 'EyelinerDoubleMod'
    ]

    const { setShowNotification } = useContext(AppContext)
    const [categoryKey, setCategoryKey] = useState('')
    const [categoryParent, setCategoryParent] = useState('')
    const [categoryIcon, setCategoryIcon] = useState('')
    const inputRef = useRef(null)
    const [showUploadLoader, setShowUploadLoader] = useState(false)
    const [categories, setCategories] = useState([])
    const [tab, setTab] = useState('createCategory')
    const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(0)
    const [selectedFinish, setSelectedFinish] = useState('')
    const [selectedFeature, setSelectedFeature] = useState('')
    const [selectedCategoryParent, setSelectedCategoryParent] = useState('');
    const [keyError, setKeyError] = useState(null)
    const [keyParentError, setKeyParentError] = useState(null)

    const capitalizeFirstLetter = (key) => {
        const labelArray = key?.split("_")

        const label = labelArray?.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");

        return label
    }

    useEffect(() => {
        ApiService.getCategories().then(ele => {
            setCategories(ele)
        })
    }, [])

    useEffect(() => {
        if (tab == 'createCategory') {
            setSelectedFinish('')
            setSelectedFeature('')
        } else {
            setSelectedCategoryIdx(0)
        }
    }, [tab])

    useEffect(() => {
        if (tab == 'updateCategory') {
            setSelectedFinish(categories[selectedCategoryIdx]?.categoryFinish || '')
            setSelectedFeature(categories[selectedCategoryIdx]?.categoryFeature || '')
        }
    }, [tab, selectedCategoryIdx])
    const cleanParams = (categoryParams) => {
        for (let category of categoryParams) {
            for (let key of Object.keys(category)) {
                if (category[key] == '') {
                    delete category[key]
                }
            }
        }
        return categoryParams
    }

    const validateKey = (key) => {
        const validIdentifierRegex = /^[A-Za-z0-9]+$/;

        return validIdentifierRegex.test(key);

    }

    const handleCreateCategory = async (event) => {
        if (categoryKey == '') {
            setShowNotification({ open: true, message: 'Category name cannot be empty', type: 'error', hideAutomatically: true })
            return
        }

        event.preventDefault()
        const label = capitalizeFirstLetter(categoryKey)
        const Parentlabel = capitalizeFirstLetter(categoryParent || null)
        const categoryParams = [
            {
                key: categoryKey,
                label: label,
                icon: categoryIcon || '',
                categoryFeature: selectedFeature,
                categoryFinish: selectedFinish,
                categoryParent: Parentlabel
            }
        ]
        const cleanedUpCategoryParams = cleanParams(categoryParams)
        const params = {
            brandId: ApiService.brandId,
            categories: cleanedUpCategoryParams
        }
        const res = await ApiService.createCategory(params)
        if (res && res.status == 200) {
            ApiService.getCategories().then(ele => {
                setCategories(ele)
            })
            resetStates()
            setShowNotification({ open: true, message: 'Category created successfully', type: 'success', hideAutomatically: true })
        } else {
            setShowNotification({ open: true, message: res.message, type: 'error', hideAutomatically: true })
        }
    }
    const handleUpdateCategory = async (event) => {
        event.preventDefault()
        const categoryParams = [
            {
                key: categories[selectedCategoryIdx]?.category,
                label: capitalizeFirstLetter(categories[selectedCategoryIdx]?.label),
                icon: categories[selectedCategoryIdx]?.icon || '',
                categoryFeature: selectedFeature,
                categoryFinish: selectedFinish,
                categoryParent: categories[selectedCategoryIdx]?.categoryParent
            }
        ]
        const cleanedUpCategoryParams = cleanParams(categoryParams)
        const params = {
            brandId: ApiService.brandId,
            categories: cleanedUpCategoryParams
        }
        const res = await ApiService.createCategory(params)
        if (res && res.status == 200) {
            ApiService.getCategories().then(ele => {
                setCategories(ele)
                const index = ele.findIndex((ele) => ele.category == categories[selectedCategoryIdx]?.category)
                setSelectedCategoryIdx(index)
            })
            setShowNotification({ open: true, message: 'Category updated successfully', type: 'success', hideAutomatically: true })
        } else {
            setShowNotification({ open: true, message: res.message, type: 'error', hideAutomatically: true })
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const getUploadedImageUrl = async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const res = await ApiService.getUploadedImageUrl(formData)
        return res
    }
    const handleDrop = async (event) => {
        event.preventDefault()
        const acceptedImageTypes = ['image/png', 'image/jpeg'];
        const file = event.dataTransfer.files[0];
        if (!file) {
            return
        }
        const fileType = file?.type;
        if (acceptedImageTypes.includes(fileType)) {
            setShowUploadLoader(true)
            return await getUploadedImageUrl(file)
        } else {
            setShowNotification({ open: true, message: 'Files with .png and .jpeg extensions are only allowed', type: 'error', hideAutomatically: true })
            return tab == 'createCategory' ? require("../../assets/images/uploadIcon.jpg") : categories[selectedCategoryIdx]?.icon
        }

    }
    const handleDropWrapper = async (event, setFunction = () => { }) => {
        setFunction(await handleDrop(event))
    }

    const handleBrowseFiles = async (event) => {
        const file = event.target.files[0];
        setShowUploadLoader(true)
        const imageUrl = await getUploadedImageUrl(file)
        event.target.value = null
        return imageUrl
    }
    const handleBrowseFilesWrapper = async (event, setFunction = () => { }) => {
        setFunction(await handleBrowseFiles(event))
    }

    const resetStates = () => {
        setCategoryKey('')
        setCategoryParent('')
        setSelectedFeature('')
        setSelectedFinish('')
        setCategoryIcon('')
    }

    // useEffect(() => {
    //     const updatedSelectValue = categories.find((ele) => ele.categoryParent === selectedCategoryParent)?.category;
    //     if (updatedSelectValue) {
    //         setSelectedCategoryIdx(categories.indexOf(categories.find((ele) => ele.categoryParent === selectedCategoryParent)));
    //     }
    // }, [selectedCategoryParent]);

    const changeCategoryName = (e) => {
        const newChangedArr = categories.map(item => {
            const newCategoryParent = item.categoryParent === categories[selectedCategoryIdx]?.categoryParent ? e.target.value : item.categoryParent

            return {
                ...item,
                categoryParent: newCategoryParent
            }
        })
        setSelectedCategoryParent(e.target.value)
        setCategories([...newChangedArr])
    }
    // console.log("categories[selectedCategoryIdx]?.categoryParent", categories)
    return (
        <>
            <div className='mt-[30px]'>
                <div className='flex flex-col gap-[10px] rounded w-full min-h-full h-max'>
                    <div className='flex w-full justify-center h-[54px] text-[14px] font-medium cursor-pointer'>
                        <div className={`${tab === 'createCategory' ? 'bg-black text-white' : 'bg-white font-medium'} 
                    cursor-pointer min-h-[54px] w-[362px] text-[16px] font-medium flex items-center justify-center`}
                            onClick={() => setTab('createCategory')}
                        >
                            Create Category
                        </div>
                        <div className={`${tab === 'updateCategory' ? 'bg-black text-white' : 'bg-white font-medium'} 
                    cursor-pointer min-h-[54px] w-[362px] text-[16px] font-medium flex items-center justify-center`}
                            onClick={() => setTab('updateCategory')}
                        >
                            Update Category
                        </div>
                    </div>
                    {
                        tab == 'createCategory' ?
                            (
                                <div className='flex flex-col gap-[30px] items-center w-full h-[calc(100%_-_40px)] my-[20px] bg-[#fff]'>
                                    <div className='py-[20px] flex flex-col items-center justify-center gap-[20px] w-full'>
                                        <div className='border-[1px] rounded flex flex-col p-[20px] w-[80%]'>
                                            <div className='mb-[15px]'>
                                                <span className='ml-[5px] text-[14px] font-medium'>Category name</span>
                                                <div>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter category name"
                                                        className="h-[35px] mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                                                        required
                                                        value={categoryKey}
                                                        onChange={(event) => {
                                                            setKeyError()
                                                            const value = event.target.value
                                                            if (value.trim() === '') {
                                                                setKeyError();
                                                            } else {
                                                                const isKeyValid = validateKey(value);
                                                                if (!isKeyValid) {
                                                                    setKeyError('Key cannot contain special characters or space');
                                                                    return;
                                                                }
                                                            }
                                                            setCategoryKey(value)
                                                        }}
                                                    />
                                                    {keyError ? (
                                                        <label className="label">
                                                            <span className="label-text-alt text-red-500">{keyError}</span>
                                                        </label>
                                                    ) : null}
                                                </div>
                                                <div className='mt-[15px]'>
                                                    <span className='ml-[5px] text-[14px] font-medium'>Category Parent</span>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter category parent"
                                                            className="h-[35px] mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                                                            required
                                                            value={categoryParent}
                                                            onChange={(event) => {
                                                                setKeyParentError()
                                                                const value = event.target.value
                                                                if (value.trim() === '') {
                                                                    setKeyParentError();
                                                                } else {
                                                                    const isKeyValid = validateKey(value);
                                                                    if (!isKeyValid) {
                                                                        setKeyParentError('Parent cannot contain special characters or space');
                                                                        return;
                                                                    }
                                                                }
                                                                setCategoryParent(value)
                                                            }}
                                                        />
                                                        {keyParentError ? (
                                                            <label className="label">
                                                                <span className="label-text-alt text-red-500">{keyParentError}</span>
                                                            </label>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className='mt-[15px]'>
                                                    <span className='ml-[5px] text-[14px] font-medium'>Features</span>
                                                    <select value={selectedFeature} onChange={(e) => setSelectedFeature(e?.target?.value)} className='mt-[10px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                                                        <option value=''>Select Feature</option>
                                                        {features.map((ele, idx) => {
                                                            return <option key={idx} value={ele}>{ele}</option>
                                                        })}
                                                    </select>

                                                </div>

                                                <div className='mt-[15px]'>
                                                    <span className='ml-[5px] text-[14px] font-medium'>Finish</span>
                                                    <select value={selectedFinish} onChange={(e) => setSelectedFinish(e?.target?.value)} className='mt-[10px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                                                        <option value=''>Select Finish</option>
                                                        {finishes.map((ele, idx) => {
                                                            return <option key={idx} value={ele}>{ele}</option>
                                                        })}
                                                    </select>

                                                </div>
                                                <div className='mt-[15px] ml-[5px] text-[14px] font-medium'>Category icon</div>
                                                <div className='mt-[10px] bg-[#fff] rounded'>
                                                    <div className='border-dashed rounded border-[2px] border-[#DCDBDB]
                              w-full h-full flex py-[20px]
                              flex-col items-center justify-center font-medium gap-[10px]'
                                                        onDragOver={handleDragOver}
                                                        onDrop={(event) => {
                                                            handleDropWrapper(event, setCategoryIcon)
                                                        }}
                                                    >
                                                        <div className='py-[10px] text-center max-w-[200px] border-dashed rounded border-[2px] w-full h-full flex items-center justify-center'>
                                                            <img src={categoryIcon || require("../../assets/images/uploadIcon.jpg")} alt="" />
                                                        </div>
                                                        <input type="file" hidden
                                                            onChange={(event) => {
                                                                handleBrowseFilesWrapper(event, setCategoryIcon)
                                                            }}
                                                            accept='image/png,image/jpeg'
                                                            ref={inputRef}
                                                        />
                                                        <div className='text-center text-[14px] px-[45px] whitespace-nowrap'>Drag & Drop your file here
                                                            <div className='mt-[14px]'>
                                                                Or
                                                            </div>
                                                            <button className='mt-[13px] cursor-pointer rounded border-[1px] border-[#130F24] w-[155px] h-[38px]'
                                                                onClick={() => {
                                                                    inputRef.current.click()
                                                                }}
                                                            >
                                                                Browse Files
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='w-full flex justify-center'>
                                                <button onClick={handleCreateCategory} className='bg-black text-[14px] text-white px-[15px] py-[3px]'

                                                >Create</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            (
                                <div className='flex flex-col gap-[30px] items-center w-full h-[calc(100%_-_40px)] my-[20px] bg-[#fff]'>
                                    <div className='py-[20px] flex flex-col items-center justify-center gap-[20px] w-full'>
                                        <div
                                            className='border-[1px] rounded flex flex-col p-[20px] w-[80%]'>
                                            {/* <div className='mb-[15px]'>
                                                <div>
                                                    <span className='ml-[5px] text-[14px] font-medium'>Category parent</span>
                                                    <select value={selectedCategoryParent} onChange={(e) => {
                                                        setSelectedCategoryParent(e.target.value);
                                                    }} className='mt-[10px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                                                        <option value="">No Category Found</option>
                                                        {[...new Set(categories
                                                            .filter((ele) => ele.categoryParent !== undefined && ele.categoryParent !== null)
                                                            .map((ele) => ele.categoryParent))]
                                                            .map((categoryParent, idx) => {
                                                                return <option key={idx} defaultChecked={selectedCategoryParent} value={categoryParent}>{categoryParent}</option>;
                                                            })}
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className='mt-[15px]'>
                                                <span className='ml-[5px] text-[14px] font-medium'>Category</span>
                                                <select value={categories[selectedCategoryIdx]?.category} onChange={(e) => setSelectedCategoryIdx(e?.target?.selectedIndex)} className='mt-[10px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                                                    {categories.map((ele, idx) => {
                                                        return <option key={idx} value={ele.category}>{ele.label}</option>
                                                    })}
                                                </select>

                                            </div>
                                            <div className=''>

                                                <div className='mt-[15px] '>
                                                    <span className='ml-[5px] text-[14px] font-medium'>Update Category Name</span>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter updated name"
                                                            className="h-[35px] mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                                                            required
                                                            value={categories[selectedCategoryIdx]?.label}
                                                            onChange={(event) => {
                                                                setCategories((prev) => {
                                                                    prev[selectedCategoryIdx] = {
                                                                        ...prev[selectedCategoryIdx],
                                                                        label: event?.target?.value
                                                                    }
                                                                    return [...prev]
                                                                })
                                                            }}
                                                        />

                                                    </div>
                                                </div>

                                                <div className='mt-[15px]'>
                                                    <span className='ml-[5px] text-[14px] font-medium'>Update Category Parent Name</span>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter updated Parent name"
                                                            className="h-[35px] mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                                                            required
                                                            // defaultValue={categories[selectedCategoryIdx]?.categoryParent}
                                                            value={categories[selectedCategoryIdx]?.categoryParent || ''}
                                                            onChange={changeCategoryName}
                                                        />

                                                    </div>
                                                </div>

                                                <div className='mt-[15px]'>
                                                    <span className='ml-[5px] text-[14px] font-medium'>Change Feature {categories[selectedCategoryIdx]?.feature || ''}</span>
                                                    <select value={selectedFeature} onChange={(e) => setSelectedFeature(e?.target?.value)} className='mt-[10px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                                                        <option value=''>Select Feature</option>
                                                        {features.map((ele, idx) => {
                                                            return <option key={idx} value={ele}>{ele}</option>
                                                        })}
                                                    </select>

                                                </div>

                                                <div className='mt-[15px]'>
                                                    <span className='ml-[5px] text-[14px] font-medium'>Change Finish {categories[selectedCategoryIdx]?.finish || ''}</span>
                                                    <select value={selectedFinish} onChange={(e) => setSelectedFinish(e?.target?.value)} className='mt-[10px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                                                        <option value=''>Select Finish</option>
                                                        {finishes.map((ele, idx) => {
                                                            return <option key={idx} value={ele}>{ele}</option>
                                                        })}
                                                    </select>

                                                </div>
                                                <div className='mt-[15px] ml-[5px] text-[14px] font-medium'>Category icon</div>
                                                <div className='mt-[10px] bg-[#fff] rounded'>
                                                    <div className='border-dashed rounded border-[2px] border-[#DCDBDB]
                              w-full h-full flex py-[20px]
                              flex-col items-center justify-center font-medium gap-[10px]'
                                                        onDragOver={handleDragOver}
                                                        onDrop={(event) => {
                                                            handleDropWrapper(event, (url) => {
                                                                setCategories((prev) => {
                                                                    prev[selectedCategoryIdx] = {
                                                                        ...prev[selectedCategoryIdx],
                                                                        icon: url
                                                                    }
                                                                    return [...prev]
                                                                })
                                                            })
                                                        }}
                                                    >
                                                        <div className='py-[10px] text-center max-w-[200px] border-dashed rounded border-[2px] w-full h-full flex items-center justify-center'>
                                                            <img src={categories[selectedCategoryIdx]?.icon || require("../../assets/images/uploadIcon.jpg")} alt="" />
                                                        </div>
                                                        <input id={categories[selectedCategoryIdx]?.category} type="file" hidden
                                                            accept='image/png,image/jpeg'
                                                            onChange={(event) => {
                                                                // console.log("changed")
                                                                handleBrowseFilesWrapper(event, (url) => {
                                                                    // console.log(url)
                                                                    setCategories((prev) => {
                                                                        prev[selectedCategoryIdx] = {
                                                                            ...prev[selectedCategoryIdx],
                                                                            icon: url
                                                                        }
                                                                        return [...prev]
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                        <div className='text-center text-[14px] px-[45px] whitespace-nowrap'>Drag & Drop your file here
                                                            <div className='mt-[14px]'>
                                                                Or
                                                            </div>
                                                            <button className='mt-[13px] cursor-pointer rounded border-[1px] border-[#130F24] w-[155px] h-[38px]'
                                                                onClick={() => {
                                                                    let element = document?.getElementById?.(categories[selectedCategoryIdx]?.category)
                                                                    // console.log(element)
                                                                    element?.click?.()
                                                                }}
                                                            >
                                                                Browse Files
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='w-full flex justify-center'>
                                                <button onClick={handleUpdateCategory} className='bg-black text-[14px] text-white px-[15px] py-[3px]'>
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>

        </>
    )
}

export default CreateCategory