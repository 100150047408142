import { createContext, useState } from "react";

const CreateLooksContext = createContext({
    referenceImage: {
        type: '',
        imageUrl: null,
        name: ''
    },
    setReferenceImage: () => { },
    referenceVideo: {
        type: '',
        videoUrl: null,
        name: '',
        file: null
    },
    setReferenceVideo: () => {}
})

const CreateLooksProvider = ({
    children
}) => {
    const [referenceImage, setReferenceImage] = useState({
        type: '',
        imageUrl: null,
        name: ''
    })
    const [referenceVideo, setReferenceVideo]=useState({
        type: '',
        videoUrl: null,
        name: '',
        file: null
    })
    return (
        <CreateLooksContext.Provider value={{ referenceImage, setReferenceImage, referenceVideo, setReferenceVideo }}>
            {children}
        </CreateLooksContext.Provider>
    )
}

const ManageLooksContext = createContext({
    openProductCatalog: false,
    setOpenProductCatalog: () => { },
    color: '',
    setColor: () => { },
    searchType: 'text_picker',
    setSearchType: () => { },
    productsArr: {
        mainArray: [],
        searchedArray: null
    },
    setProductsArr: () => { },
    changedLookValues: {},
    setChangedLookValues: () => { },
    preSelectedProduct: [],
    setPreSelectedProduct: () => { }
})

const ManageLooksProvider = ({ children }) => {
    const [openProductCatalog, setOpenProductCatalog] = useState(false)
    const [color, setColor] = useState(false)
    const [searchType, setSearchType] = useState('text_picker')
    const [productsArr, setProductsArr] = useState({
        mainArray: [],
        searchedArray: null
    })
    // Convert previewProduct from SetState to Context API
    const [previewProduct, setPreviewProduct] = useState({})

    const [preSelectedProduct, setPreSelectedProduct] = useState([])

    // Used to store look's changed values
    const [changedLookValues, setChangedLookValues] = useState({
        name: '',
        referenceImage: '',
        collectionName: ''
    })

    return (
        <ManageLooksContext.Provider value={{
            openProductCatalog, setOpenProductCatalog,
            color, setColor,
            searchType, setSearchType,
            productsArr, setProductsArr,
            previewProduct, setPreviewProduct,
            changedLookValues, setChangedLookValues,
            preSelectedProduct, setPreSelectedProduct
        }}>
            {children}
        </ManageLooksContext.Provider>
    )
}

export {
    CreateLooksContext,
    CreateLooksProvider,
    ManageLooksContext,
    ManageLooksProvider
}