import React, { useState } from 'react'
import ManageLooks from '../../../Components/InventoryManagement/Looks/ManageLooks/ManageLooks'
import { useNavigate, useSearchParams } from 'react-router-dom';
import EditLook from '../../../Components/InventoryManagement/Looks/ManageLooks/EditLookContainer';
import { ManageLooksProvider } from '../../../context/LooksContext';


const ManageLooksPage = () => {
    const [searchParams] = useSearchParams();
    const look = searchParams.get('look_key')
    const collection = searchParams.get('collection_key')
    const [collectionKey,setCollectionKey]= useState('')
    const navigate = useNavigate()

    const handleBackButton = (collectionKey)=>{
        navigate('/manage-looks')
        setCollectionKey(collectionKey)
    }

    return look
        ? <ManageLooksProvider>
            <EditLook look_key={look} handleBackButton={handleBackButton} collection_key={collection}/>
        </ManageLooksProvider>
        : <ManageLooks collection_key={collectionKey}/>

}

export default ManageLooksPage