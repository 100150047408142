import React, { useState } from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai";

const ShadeCard = ({ data, index, handleRemoveShade = () => { },
    shadesCardRef, handleShadeUpdate,handleRecommendedShades,
    setSelectedShadeColorForRecommendedShade }) => {
    const [color, setColor] = useState(data.color)
    const [colorName, setColorName] = useState(data.colorName)

    const hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    const rgbToHex = (rgbValue) => {
        var rgb = rgbValue.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
        const r = rgb[0]
        const g = rgb[1]
        const b = rgb[2]
        return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
    }

    const handleShadeColor = (e) => {
        const rgbObj = hexToRgb(e.target.value)
        const rgbValue = `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
        setColor(rgbValue)

        const shade = { ...data }
        shade.color = rgbValue
        shade.colorName = colorName
        handleShadeUpdate(shade)
    }

    const handleShowRecommendedShades = (value,color,index) => {
        handleRecommendedShades(value)
        setSelectedShadeColorForRecommendedShade({
            id:index,
            color:color
        })
    }

    return (
        <div className='m-[20px]' ref={shadesCardRef}>
            <div
                className="rounded p-[20px] flex flex-col gap-[20px] w-[350px] border relative"
            >
                <div className='absolute top-[5px] right-[5px] cursor-pointer mb-[5px]' onClick={() => handleRemoveShade(index, color)}>
                    <AiOutlineCloseCircle size={22} />
                </div>
                <div className='flex flex-col gap-[5px]'>
                    <div className='font-semibold '>Shade Color</div>
                    <div className='flex gap-[15px] items-center text-[14px] text-slate-500'>
                        <input
                            type="color"
                            id={`key-${index}`}
                            value={rgbToHex(color)}
                            onChange={(e) => {
                                handleShadeColor(e)
                            }}
                            className={`w-[50px] h-[50px] ${data.colorSelectionDisabled ? '' : 'cursor-pointer'}`}
                            disabled={data.colorSelectionDisabled}
                        />
                        <div>{color}</div>

                        <button className='bg-black text-white p-[5px] text-[10px]' onClick={()=>handleShowRecommendedShades(true,color,index)}>
                            Recommended Colors
                        </button> 

                    </div>
                </div>
                <div className='flex flex-col gap-[5px]'>
                    <div className='font-semibold'>Shade Color Name</div>
                    <div>
                        <input type="text"
                            value={colorName}
                            onChange={(e) => {
                                setColorName(e.target.value)

                                const shade = { ...data }
                                shade.color = color
                                shade.colorName = e.target.value
                                handleShadeUpdate(shade)
                            }}
                            required
                            className={`w-full text-slate-500 bg-white py-[10px] pl-[5px] rounded ${data.readOnly ? '' : 'border-[1px] outline-0'}`}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShadeCard