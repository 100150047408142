import React, { useRef, useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { ApiService } from '../../services/apiUtility'
import UploadLoader from '../Loader/UploadLoader'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const BrandLogo = () => {
    const { setShowNotification, brandSettings, getBrandSettings, setBrandSettings } = useContext(AppContext)
    const [uploadedLogo, setUploadedLogo] = useState('')
    const [darkUploadedLogo,setDarkUploadedLogo] = useState('')
    const inputRef = useRef(null)
    const [showUploadLoader, setShowUploadLoader] = useState(false)
    const [logoType,setLogoType] = useState(1)    //light being 1 and dark being 2
    const navigate = useNavigate()

    useEffect(() => {
        setUploadedLogo(brandSettings && brandSettings.setting_meta_json
            && brandSettings.setting_meta_json.logo
            ? brandSettings.setting_meta_json.logo : '')
        setDarkUploadedLogo(brandSettings && brandSettings.setting_meta_json
            && brandSettings.setting_meta_json.darkLogo
            ? brandSettings.setting_meta_json.darkLogo : '')
    }, [brandSettings])

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const handleDrop = async (event) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0];
        setShowUploadLoader(true)
        const imageUrl = await getUploadedImageUrl(file)
        if (imageUrl) {
            uploadImage(imageUrl)
        }
    }

    const handleBrowseFiles = async (event) => {
        const file = event.target.files[0];
        setShowUploadLoader(true)
        const imageUrl = await getUploadedImageUrl(file)
        if (imageUrl) {
            uploadImage(imageUrl)
        }
        event.target.value = null
    }

    const uploadDefaultLogo = async () => {
        // setShowUploadLoader(true)
        const defaultLogoUrl = await getDefaultLogoUrl(require('../../assets/images/default_Logo_Mirrar.png'))
        if (defaultLogoUrl) {
            uploadImage(defaultLogoUrl, true)
        }
    }

    const getDefaultLogoUrl = async (filePath) => {
        try {
            const response = await fetch(filePath);
            if (!response.ok) {
                throw new Error('File not found');
            }
            const fileBlob = await response.blob();
            const file = new File([fileBlob], 'default_Logo_Mirrar.png', { type: fileBlob.type });

            const res = await getUploadedImageUrl(file)
            return res
        } catch (error) {
            setShowNotification({ open: true, message: 'There is some error', type: 'error',hideAutomatically:true })
        }
    }

    const getUploadedImageUrl = async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const res = await ApiService.getUploadedImageUrl(formData)
        return res
    }

    const uploadImage = async (imageUrl, defaultBrand) => {
        setBrandSettings((brandSettings) => {
            const settings = { ...brandSettings.setting_meta_json }
            if(logoType==1){
                settings['logo'] = imageUrl
            }else{
                settings['darkLogo'] = imageUrl
            }
            console.log(settings)
            
            const params = {
                brandId: ApiService.brandId,
                setting: {
                    json: settings
                }
            }
            ApiService.updateSettings(params).then((res) => {
                if (res && res.status == 200) {
                    if (defaultBrand) {
                        setShowNotification({ open: true, message: 'Default brand logo set successfully', type: 'success',hideAutomatically:true })
                    } else {
                        setShowNotification({ open: true, message: 'Brand logo uploaded successfully', type: 'success',hideAutomatically:true })
                    }
                } else if (res && res.status == 403) {
                    localStorage.clear()
                    navigate('/authentication/sign-in')
                    setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
                } else {
                    setShowNotification({ open: true, message: 'There is some error while saving settings', type: 'error',hideAutomatically:true })
                }
                setShowUploadLoader(false)
            })
            brandSettings.setting_meta_json = settings
            return {...brandSettings}
        })
    }

    return (
        <div className='flex flex-col gap-[30px] items-center my-[20px] w-full h-[calc(100%_-_40px)] p-[25px] bg-[#fff]'>
            <div className='flex gap-[20px] w-[70%] items-center justify-center'>
                <div className='font-medium'>Logo For:</div>
                <select value={logoType} onChange={(e) => setLogoType(e?.target?.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-[30%] p-2.5'>
                    <option value='1'>Light Background</option>
                    <option value='2'>Dark Background</option>
                </select>
            </div>
            <div className='flex relative w-[250px] h-[40%] border-[1px] h-max border-[#0001] p-[30px] hover:shadow-lg'>
                <div className='flex w-full items-center justify-center '>
                    {
                        uploadedLogo && !uploadedLogo.includes('default_Logo_Mirrar') ?
                            <div className='absolute top-[10px] right-[8px] cursor-pointer' onClick={uploadDefaultLogo}>
                                <MdOutlineDeleteOutline size='20' />
                            </div> : ''
                    }


                    {showUploadLoader ? <UploadLoader /> : <img src={
                        logoType==1 ? uploadedLogo ? uploadedLogo : require('../../assets/images/no-image.png') 
                        :darkUploadedLogo?darkUploadedLogo: require('../../assets/images/no-image.png')} alt="background" className='object-contain w-[180px] h-[180px]' />}
                </div>
            </div>

            <div className='mt-[20px]  h-[500px] max-h-[500px] bg-[#fff] rounded'>
                <div>
                    <div className='border-dashed rounded border-[2px] border-[#DCDBDB]
                        max-w-[400px] w-full h-full max-h-[350px] m-auto flex py-[20px]
                        flex-col items-center justify-center font-medium gap-[10px]'
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <div className='py-[10px] text-center max-w-[40px]'>
                            <img src={require("../../assets/images/uploadIcon.jpg")} alt="" />
                        </div>
                        <input type="file" hidden
                            onChange={handleBrowseFiles}
                            accept="image/png, image/jpg, image/jpeg"
                            ref={inputRef}
                        />
                        <div className='text-center text-[14px] px-[45px] whitespace-nowrap'>Drag & Drop your file here
                            <div className='mt-[14px]'>
                                Or
                            </div>
                            <button className='mt-[13px] cursor-pointer rounded border-[1px] border-[#130F24] w-[155px] h-[38px]'
                                onClick={() => {
                                    inputRef.current.click()
                                }}
                            >
                                Browse Files
                            </button>
                        </div>
                    </div>
                </div>
                <div className='mt-[8px] text-center w-full text-[10px] text-[#717474]'>Supports: JPG,JPEG,PNG</div>
            </div>
        </div>
    )
}

export default BrandLogo