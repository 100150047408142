import React from 'react'

const UsersList = ({ users, handleRemoveUser = () => { } }) => {
    return (
        <div className='pt-[30px]'>
            {users.length > 0 ?
                <div className="w-[100%]">
                    <div id='variantTable' className='flex justify-center'>
                        <table className='w-[90%] border-[1px] border-[rgba(224,224,224,1)]'>
                            <thead>
                                <tr className='border-b-[1px] text-[16px]'>
                                    <th className='px-[10px] py-[20px] w-[10px] max-w-[10px] text-center'>
                                        <div className=' font-medium'>SNo.</div>
                                    </th>
                                    <th className='px-[16px] py-[20px] w-[80px] min-w-[80px] text-center'>
                                        <div className=' font-medium'>User Name</div>
                                    </th>
                                    <th className='px-[16px] py-[20px] w-[80px] min-w-[80px] text-center'>
                                        <div className='font-medium'>User Email </div>
                                    </th>
                                    <th className='px-[16px] py-[20px] w-[80px] min-w-[80px] text-center'>
                                        <div className=' font-medium'>Action</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((user, index) => {
                                        return (<tr key={index} className="text-[14px]">
                                            <td className='py-[10px] px-[16px]'>
                                                <div className='text-center'>
                                                    {index + 1}.
                                                </div>
                                            </td>
                                            <td className='py-[10px] px-[16px]'>
                                                <div className='text-center'>
                                                    {user.user.userName}
                                                </div>
                                            </td>
                                            <td className='py-[10px] px-[16px]'>
                                                <div className='text-center'>
                                                    {user.user.userEmail}
                                                </div>
                                            </td>
                                            <td className='py-[10px] px-[16px] flex justify-center'>
                                                <button className='text-center bg-black text-white px-[20px] py-[8px] rounded' onClick={() => handleRemoveUser(user)}>
                                                    Remove
                                                </button>
                                            </td>

                                        </tr>)
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div> :
                <div className='w-full flex justify-center'>
                    <div className='flex justify-center items-start'>
                        There are no users for this brandId.
                    </div>
                </div>
            }
        </div>
    )
}

export default UsersList