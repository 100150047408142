import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
const PrivateRoute = ({Component}) => {
    const navigate = useNavigate()
    const userData = JSON.parse(localStorage.getItem('userData'));
    useEffect(()=>{
        if(!userData){
            navigate('/authentication/sign-in')
        }
    },[])
    return (
        userData?
        <Component />
        :''
    )
     
}

export default PrivateRoute