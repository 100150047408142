import React,{useState} from 'react'
import MakeupSelected from './MakeupSelected';
import TriedOnMakeup from './TriedOnMakeup';
import TopTriedOnMakeup from './TopTriedOnMakeup';
import WishlistedMakeup from './WishlistedMakeup';
const Dashboard = () => {
  const productsCount = JSON.parse(localStorage.getItem('productsCount'))?
  JSON.parse(localStorage.getItem('productsCount')):{total:0,maxProducts:10000}
  const [totalMakeupTryOns,setTotalMakeupTryOns] = useState({
      sum:0,
      eventType:'Event Totals'
  })

  const handleTotalMakeupTryOns = (sum,type)=>{
    setTotalMakeupTryOns({sum:sum,eventType:type})
  }
  return (
    <div className='h-full'>
        <div className='font-semibold py-[19px] text-[16px]'>DASHBOARD</div>
        <div className='flex flex-col rounded w-full h-full gap-[20px]'>
          <div className='flex gap-[20px]'>
            <div className='flex flex-col w-[50%] h-full gap-[20px]'>
              <div className='flex text-[#130F24] items-center justify-evenly bg-white w-full h-[128px] rounded'>
                <div className='w-[60%] flex flex-col items-center'>
                  <div className=''>
                    <span className='font-bold text-[24px]'>{productsCount.total}</span>
                    <span className='text-[14px]'>/{productsCount.maxProducts}</span>
                  </div>
                  <div className='text-[14px]'>
                    Total Variants Uploaded
                  </div>
                </div>
                <div className='w-[40%] flex justify-center'>
                  <img src={require('../../../assets/images/makeupUploaded.jpg')} alt="" />
                </div>
              </div>
              <div className='flex text-[#130F24] items-center justify-evenly bg-white w-full h-[128px] rounded'>
                <div className='w-[60%] flex flex-col items-center'>
                  <div>
                    <span className='font-bold text-[24px]'>{totalMakeupTryOns.sum}</span>
                    <span className='text-[12px]'>({totalMakeupTryOns.eventType})</span>
                  </div>
                  <div className='text-[14px]'>
                    Total Makeup Tryons
                  </div>
                </div>
                <div className='w-[40%] flex justify-center'>
                  <img src={require('../../../assets/images/makeupTryons.jpg')} alt="" />
                </div>
              </div>
              <MakeupSelected handleTotalMakeupTryOns={handleTotalMakeupTryOns}/>
            </div>
            <div className='flex flex-col gap-[20px] w-[50%]'>
              <div className='h-[69px] flex justify-between px-[25px] items-center bg-white rounded'>
                <div className='text-[16px] font-bold'>How to use</div>
                <div className='cursor-pointer'>
                  <img src={require('../../../assets/images/question.jpg')} alt="" />
                </div>
              </div>
              <TriedOnMakeup/>
            </div>
          </div>
          <div className='flex gap-[20px] pb-[20px]'>
            <WishlistedMakeup/>
            <TopTriedOnMakeup/>
          </div>
        </div>
    </div>
  )
}

export default Dashboard