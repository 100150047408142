import React, { useContext } from 'react'
import CloseCatalogIcon from '../../../../assets/images/close-catalog.svg'
import DeleteCatalogIcon from '../../../../assets/images/catalog-delete-icon.svg'
import EditCatalogIcon from '../../../../assets/images/catalog-edit-icon.svg'
import { ManageLooksContext } from '../../../../context/LooksContext'

const ManageLooksProductCatalog = ({
    setOpenProductCatalog,
    lookData,
    productsData,
    setPreSelectedCategory,
    removeFromProductArray
}) => {

    const { setColor, setSearchType } = useContext(ManageLooksContext)

    const editProduct = (productCategory, color) => {
        setPreSelectedCategory({
            category: productCategory.category,
            label: productCategory.label
        })
        setSearchType('color_picker')
        setColor(color)
        setOpenProductCatalog(false)
    }

    const deleteProduct = (product) => {
        removeFromProductArray(product)
        setOpenProductCatalog(false)
    }

    return (
        <div className='fixed h-full w-full z-50 top-0 left-0'>
            <div className=' absolute h-full w-full z-0 bg-[rgba(231,231,231,0.4)] top-0 left-0  backdrop-blur-sm' onClick={() => setOpenProductCatalog(false)}>&nbsp;</div>
            <div className='rounded-[4px] flex gap-5 px-3 py-4 fixed h-3/5 w-3/4 bg-[#fff] top-1/2 left-1/2 drop-shadow-[0px_4px_10px_0px_rgba(0,0,0,0.25)] -translate-x-1/2 -translate-y-1/2'>
                <div className='w-1/3 flex flex-col gap-2'>
                    <span className='block h-[16px] text-[12px]'>Reference Look</span>
                    <div className='w-full flex-grow h-[90%] rounded-[4px] p-2 bg-[#F5F5F5]'>
                        <img className='object-contain h-[90%] m-auto rounded-[4px]' src={lookData.looks_image[0]} alt={lookData.looks_label} />
                        <span className='block h-[10%] text-[16px]'>{lookData.looks_label}</span>
                    </div>
                </div>
                <div className='w-2/3 flex flex-col gap-2'>
                    <span className='block h-[16px] text-[12px]'>Product Catalog</span>
                    <div className='relative p-2 overflow-y-auto gap-[13px] flex bg-[#F5F5F5] flex-wrap flex-grow scrollbar-container3 rounded-[4px] h-[90%]'>
                        {productsData?.length
                            ? productsData.map((product, index) => {
                                const { category, variant } = product
                                return (
                                    <div className='w-[calc((100%/4)-15px)] h-fit bg-[#fff] rounded-[4px] flex flex-col' key={index}>
                                        <div className='flex'>
                                            <img className='flex-grow w-4/5 rounded-tl-[4px]' src={variant.variant_thumbnails[0]} alt={variant.variant_sku} />
                                            <div style={{ backgroundColor: variant.variant_color }} className={`w-1/5 relative  rounded-tr-[4px]`}>
                                                <p className=' line-clamp-2 absolute top-1/2 left-1/2 px-2 py-[1px] font-semibold -translate-x-1/2 -translate-y-1/2 -rotate-90 w-fit bg-[rgba(0,0,0,0.3)] text-[8px] text-[#fff] tracking-[4px]'>{category.label}</p>
                                            </div>
                                        </div>
                                        <p className='text-[11px] px-2 h-[33px] my-2 line-clamp-2 '>{variant.variant_sku}</p>
                                        <div className='flex flex-grow items-stretch bg-[#E8E7F0] py-[5px] rounded-b-[4px]'>
                                            <div className='w-1/2 flex items-center justify-center cursor-pointer'>
                                                <img src={EditCatalogIcon} alt='edit button' className='cursor-pointer h-[15px]' onClick={() => editProduct(category, variant.variant_color)} />
                                            </div>
                                            <div className='w-1/2 flex items-center border-l-[1px] border-[#ACABAB] justify-center'>
                                                <img src={DeleteCatalogIcon} alt='delete button' className='cursor-pointer h-[15px]' onClick={() => deleteProduct(product)} />
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                            : <div className='absolute top-1/2 left-1/2 -translate-x-1/2 text-[20px] -translate-y-1/2'>
                                No Products Selected
                            </div>
                        }
                    </div>
                </div>
                <img src={CloseCatalogIcon} alt='close button' className=' absolute top-4 right-4 w-[12px] h-[12px] cursor-pointer' onClick={() => setOpenProductCatalog(false)} />
            </div>
        </div>
    )
}

export default ManageLooksProductCatalog
