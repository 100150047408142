import React, { useState, useEffect, useContext } from 'react'
import { ApiService } from '../../services/apiUtility'
import { CategoryTab } from '../InventoryManagement/CalibrateInventory/CategoryTab'
import { AppContext } from '../../context/AppContextProvider'
import { useNavigate } from 'react-router-dom'

const IntensitySliderSettings = () => {
    const { brandSettings, getBrandSettings, setShowNotification, setBrandSettings } = useContext(AppContext)
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState({})
    const [minimum, setMinimum] = useState('')
    const [maximum, setMaximum] = useState('')
    const [minError,setMinError] = useState(null)
    const [maxError,setMaxError] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        setIntensitySliderMinMax(selectedCategory?.category)
    }, [brandSettings, selectedCategory])

    useEffect(() => {
        fetchCategories()
    }, [])

    const fetchCategories = async () => {
        const categoriesData = await ApiService.getCategories()
        if(categoriesData){
            setCategories(categoriesData)
            setSelectedCategory(categoriesData[0])
        }

    }

    const handleCategoryOnClick = async (category) => {
        setSelectedCategory(category)
    }

    const setIntensitySliderMinMax = (category) => {
        if (brandSettings && brandSettings.setting_meta_json && brandSettings.setting_meta_json.intensity_slider) {
            const intensityslider = brandSettings.setting_meta_json.intensity_slider
            const min = intensityslider[category] ? intensityslider[category].min : ''
            const max = intensityslider[category] ? intensityslider[category].max : ''
            setMinimum(min)
            setMaximum(max)
        }
    }

    const isValidateMinAndMax = ()=>{
        if(minimum<0){
            setMinError('Minimum value cannot be below 0')
            return false
        }else if(maximum>100){
            setMaxError('Maximum value cannot be above 100')
            return false
        }
        return true
    }

    const saveSettings = async () => {
        if(!isValidateMinAndMax()){
            return
        }
        setBrandSettings((brandSettings) => {
            const settings = { ...brandSettings.setting_meta_json }
            if (settings.intensity_slider) {
                settings.intensity_slider = {
                    ...settings.intensity_slider,
                    [selectedCategory.category]: {
                        min: Number(minimum),
                        max: Number(maximum)
                    }
                }
            } else {
                settings['intensity_slider'] = {
                    [selectedCategory.category]: {
                        min: minimum,
                        max: maximum
                    }
                }
            }
            const params = {
                brandId: ApiService.brandId,
                setting: {
                    json: settings
                }
            }
            ApiService.updateSettings(params).then((res) => {
                if (res && res.status == 200) {
                    setShowNotification({ open: true, message: 'Settings saved successfully', type: 'success',hideAutomatically:true })
                } else if (res && res.status == 403) {
                    localStorage.clear()
                    navigate('/authentication/sign-in')
                    setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
                } else {
                    setShowNotification({ open: true, message: 'There is some error while saving settings', type: 'error',hideAutomatically:true })
                }
            })
            brandSettings.setting_meta_json = settings
            return {...brandSettings}
        })
    }

    return (
        <div className='flex flex justify-center w-full h-[calc(100%_-_40px)] my-[20px] bg-[#fff]'>
            {
                categories.length>0?
                (
<div className='py-[20px] flex flex-col gap-[20px] w-[40%]'>
                <div className='text-center font-medium text-[16px]'>
                    Intensity Slider Settings
                </div>
                <div className='border-[1px] rounded flex flex-col p-[20px]'>
                    <div className='mb-[15px]'>
                        <div className='text-[14px] font-medium mb-[10px]'>Category</div>
                        <CategoryTab categories={categories} selectedCategory={selectedCategory} handleCategoryOnClick={handleCategoryOnClick} />
                    </div>
                    <div className='mb-[15px]'>
                        <span className='text-[14px] font-medium'>Minimum</span>
                        <div>
                            <input
                                type="number"
                                placeholder="Enter slider's minimum value"
                                className="h-[35px] mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                                required
                                min="0"
                                max="100"
                                value={minimum}
                                onChange={(event) => {
                                    setMinError(null)
                                    setMinimum(event.target.value)
                                }}
                            />
                            {
                                minError?
                                <label className="label">
                                    <span className="label-text-alt text-red-500">{minError}</span>
                                </label>:''
                            }
                        </div>
                    </div>
                    <div className='mb-[15px]'>
                        <span className='text-[14px] font-medium'>Maximum</span>
                        <div>
                            <input
                                type="number"
                                placeholder="Enter slider's maximum value"
                                className="h-[35px] mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                                required
                                min="0"
                                max="100"
                                value={maximum}
                                onChange={(event) => {
                                    setMaxError(null)
                                    setMaximum(event.target.value)
                                }}
                            />
                            {
                                maxError?
                                <label className="label">
                                    <span className="label-text-alt text-red-500">{maxError}</span>
                                </label>:''
                            }
                        </div>
                    </div>
                    <div className='w-full flex justify-center'>
                        <button className='bg-black text-[14px] text-white px-[15px] py-[3px]'
                            onClick={saveSettings}
                        >Save Settings</button>
                    </div>
                </div>
            </div>
                ):<div>No categories found.</div>
            }
            
        </div>
    )
}

export default IntensitySliderSettings