import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const LookCreateSuccess = () => {

    const [searchParams] = useSearchParams()
    const [message, setMessage] = useState("")
    const navigate = useNavigate()
    const editPossible = searchParams.get('edit_success')
        ? searchParams.get('edit_success') === "true"
        : false
    const handleClick = () => {
        navigate('/manage-looks')
    }

    useEffect(() => {
        if (editPossible) {
            setMessage("Your look has been edited successfully")
        } else {
            setMessage("Your look is successfully created")
        }
    }, [searchParams])


    return (
        <div className='flex justify-center items-center w-full h-full'>
            <div className='flex flex-col justify-center items-center gap-[10px]'>
                <div>
                    <img src={require('../../../../assets/images/success.jpg')} alt="" />
                </div>
                <div className='text-[30px] font-bold'>
                    Congratulations!
                </div>
                <div className='text-[18px] font-light'>
                    {message}
                </div>
                <div>
                    <button className='w-[174px] h-[50px] rounded bg-black text-white' onClick={handleClick}>OK</button>
                </div>
            </div>
        </div>
    )
}

export default LookCreateSuccess