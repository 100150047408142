import React, { useContext, useState,useEffect } from "react";
import { MdOutlineVisibility,MdOutlineVisibilityOff } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../../services/apiUtility";
import { AppContext } from "../../../context/AppContextProvider";
import DialogComponent from "../../Dialog/Dialog";
import CircularLoader from "../../Loader/CircularLoader";
import NotificationBar from "../../Dialog/NotificationBar";

const SignInComponent = () => {
  const navigate = useNavigate()
  const {setLoading,setAuthTokens,showNotification} = useContext(AppContext)
  const search = useLocation().search;
  const [email, setEmail] = useState(new URLSearchParams(search).get("email")?new URLSearchParams(search).get("email"):"");
  const [brandName, setBrandName] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader,setShowLoader] = useState(false)
  const [errorDialog,setErrorDialog] = useState({show:false,message:''})
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    const [emailPrefix, emailDomain] = email.split('@');

    const isValidEmail = pattern.test(email);
    const hasValidDomain = emailDomain && emailDomain.includes('.');

    return isValidEmail && hasValidDomain;
  };

  const handleSignUp = ()=>{
    navigate('/authentication/sign-up')
  }

  const handleForgotPassword = ()=>{
    navigate('/authentication/forgot-password')
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
        setEmailError("Invalid email format");
        return;
    }
    setShowLoader(true)
    setEmailError(null);
    const loginParam={
        email,
        brandName,
        password
    }
    const response =await ApiService.adminLogin(loginParam)
    if(response && response.status==200){
      const admindata = response.data
      setLoading(false)
      setAuthTokens(admindata.token)

      ApiService.brandId=admindata.brandDetails.uuid

      const userData = admindata.user
      const country=await getUserLocation();
      localStorage.setItem('authTokens',JSON.stringify(admindata.token))
      localStorage.setItem('userData',JSON.stringify(userData))
      localStorage.setItem('brandData',JSON.stringify(admindata))
      localStorage.setItem('loginTime',JSON.stringify(new Date().toLocaleString()))
      localStorage.setItem('productsCount',JSON.stringify({
        maxProducts:admindata.brandDetails.brand_max_products,
        total: admindata.brandDetails.totalVariants.aggregate.count
      }))
      localStorage.setItem('loginDevice',JSON.stringify({
        platform:window?.navigator?.userAgentData?.platform,
        isMobile:window?.navigator?.userAgentData?.mobile
      }))
      localStorage.setItem('loginPlace',JSON.stringify(country))
      setShowLoader(false)
      setBrandName('')
      setEmail('')
      setPassword('')
      navigate(`/home`);
    }else{
      setShowLoader(false)
      handleErrorDialog({show:true,message:response.message})
    }
  };

  const handleErrorDialog =(val)=>{
    setErrorDialog(val)
  }

  const getUserLocation = async () => {
    try {
      const response = await fetch('https://get.geojs.io/v1/ip/geo.json');
      const data = await response.json();
      return data
    } catch (error) {
      console.log('Error occurred while retrieving location:', error.message);
    }
  };



  useEffect(()=>{
    const userData= localStorage.getItem('userData')
    if(userData){
      navigate(`/home`);
    }
  },[])

  return (
    <div className='relative w-[40%] py-[50px] rounded bg-[#fff]'>
        <div className="relative text-center">
            <div className="text-[30px] font-extrabold">Welcome to mirrAR</div>
            <div className="text-[#717474] text-center text-[18px]">
                Sign in to continue to MirrAR
            </div>
        </div>
       
        <div className="mt-[30px]">
            <form onSubmit={handleSubmit} className='h-[45%] w-[75%] flex flex-col max-w-[450px] m-auto'>
                <div className='mb-[15px]'>
                  <span className='text-[18px] text-[#000000] font-light'>Email</span>
                  <div>
                    <input 
                      type="text" 
                      placeholder="Enter email" 
                      className="h-[46px] mt-[5px] bg-white  border border-gray-300 text-[14px] rounded w-full p-4" 
                      required
                      value={email}
                      onChange={(event) => {
                        setEmailError('')
                        setEmail(event.target.value)
                      }}
                      id="email"
                      name="email"
                    />
                    {
                      emailError?
                      <label className="label">
                        <span className="label-text-alt text-red-500">{emailError}</span>
                      </label>:''
                    } 
                  </div>
                </div>
                <div className='mb-[15px]'>
                  <span className='text-[18px] text-[#000000] font-light'>Brand Name</span>
                  <div>
                    <input 
                      type="text" 
                      className="h-[46px] mt-[5px] border bg-white border-gray-300 text-[14px] rounded w-full p-4" 
                      required
                      placeholder="Enter brand name"
                      id="brandName"
                      name="brandName"
                      value={brandName}
                      onChange={(event) => setBrandName(event.target.value)}
                    />
                  </div>
                </div>
                <div className='mb-[15px]'>
                  <span className='text-[#000000] text-[18px] font-light'>Password</span>
                  <div className="relative">
                    <input 
                      type={showPassword ? "text" : "password"}
                      className="h-[46px] mt-[5px] bg-white border border-gray-300 text-[14px] rounded w-full p-4" 
                      required
                      placeholder="Enter password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <div className="absolute top-[20px] right-[25px] cursor-pointer" onClick={handleClickShowPassword}>
                      {showPassword ? <MdOutlineVisibilityOff size='25px'/> : <MdOutlineVisibility size='25px'/>}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end font-medium text-[12px] text-[#717474]">
                  {/* <div className="flex items-center gap-[10px] text-[12px] text-[#000000]">
                    <input type="checkbox" name="" id="" className="checkbox w-[10px] h-[10px]"/>
                    <span>Remember Me</span>
                  </div> */}
                    <span className="cursor-pointer"
                        onClick={handleForgotPassword}
                    >
                      Forgot Password?
                    </span>
                </div>
                <div className="flex w-full justify-center mt-[20px] h-[50px]">
                    <button
                      className="bg-black rounded text-white w-full py-[15px] text-[18px] font-medium"
                    >
                      <span>Login</span>  
                    </button>
                </div>
                {/* <div className="w-full flex text-[12px] justify-center py-[10px]">
                  Don't have an account? 
                  <span className="pl-[2px]  cursor-pointer text-[#4525DD]"
                        onClick={handleSignUp}
                    >
                      Sign up
                    </span>
                </div> */}
            </form>
        </div>
        {errorDialog.show?
          <DialogComponent 
            handleErrorDialog={handleErrorDialog}
            message={errorDialog.message}
          />:''}
        {showLoader?<CircularLoader/>:''}
        
    </div>
  )
}

export default SignInComponent