import React, { useState,useEffect, useContext } from 'react'
import { BiArrowBack } from "react-icons/bi";
import TableData from './TableData';
import { ApiService } from '../../../../services/apiUtility';
import { AppContext } from '../../../../context/AppContextProvider';
import { SearchBar } from '../SearchBar';
import { ProductType } from '../ProductType';
import Pagination from '../Pagination'
import { EntriesSelection } from '../EntriesSelection';

const VariantsTable = ({product,handleShowOrHideVariantsTable,handleProductSearchFilter}) => {
    const {setShowNotification} = useContext(AppContext)
    const [productVariantsTableData,setProductVariantsTableData] = useState([])
    const [productVariantsTableDataCopy,setProductVariantsTableDataCopy] = useState([])
    const [showSaveButton,setShowSaveButton] = useState(false)
    
    const [updatedProductDataValue,setUpdatedProductDataValue] = useState([])
    const [searchValue,setSearchValue] = useState('')
    const [searchResults,setSearchResults] = useState([])

    const [statusType,setStatusType] = useState('all')

    const [entries,setEntries] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const variantsPerPage = 10;
    
    const hideVariantsTable = ()=>{
        handleShowOrHideVariantsTable(false)
        handleProductSearchFilter('')
    }

    useEffect(()=>{
        fetchProductVariants()
    },[])

    const fetchProductVariants = async ()=>{
        const variants =  await ApiService.getProductVariants(product.productId,true,1)
        createEntriesOptions(variants)
        createTableData(variants)
    } 

    const handleSearchFilter =(searchText)=>{
        setCurrentPage(1)
        setSearchValue(searchText)
        handleSearch(searchText)
      }

    const handleSearch = (searchText)=>{
            const results=productVariantsTableDataCopy.filter((variant)=>{
                if(statusType=='all'){
                    return variant.variant_sku.toLowerCase().includes(searchText.toLowerCase())
                }else{
                    return variant.variant_sku.toLowerCase().includes(searchText.toLowerCase()) && String(variant.variant_status)==statusType
                }
            })
            setSearchResults(results)
            setProductVariantsTableData(results)
    }

    const handleProductStatus = (val)=>{
        setCurrentPage(1)
        setStatusType(val)
        const results=productVariantsTableDataCopy.filter((variant)=>{
            if(val=='all'){
                return variant.variant_sku.toLowerCase().includes(searchValue.toLowerCase())
            }else{
                return variant.variant_sku.toLowerCase().includes(searchValue.toLowerCase()) && String(variant.variant_status)==val
            }

        })
        setProductVariantsTableData(results)
        setSearchResults(results)
    }

    const createTableData = (productVariants)=>{
        let data=[]
        for(let variant of productVariants){
            let obj={
                'variant_id':variant.variantId,
                'variant_sku':variant.variantSku,
                'variant_color':variant.variantColor,
                'variant_color_name':variant.variantColorName,
                'variant_status':variant.variantStatus,
                'variant_image_urls':[variant.variantImageUrls[0]],
                'variant_price':variant.variantPrice,
                'variant_feature':variant.variantFeature,
                'variant_finish':variant.variantFinish,
                'variant_description':variant.variantDescription,
            }
            data.push(obj)
        }
        setProductVariantsTableDataCopy(data)
        const lastIndex = currentPage * variantsPerPage
        const firstIndex = lastIndex - variantsPerPage
        const variants = data.slice(firstIndex,lastIndex)
        setProductVariantsTableData(variants)
    }

    const capitalizeFirstLetter = (value)=>{
        const labelArray = value.split("_")
    
        const label = labelArray.map((word,index) => {
            if(index>0){
                return word[0].toUpperCase() + word.substring(1);
            }
        }).join("");
    
        return labelArray[0]+label
    }

    const handleShowSaveButton = (val)=>{
        setShowSaveButton(val)
    }

    const handleUpdateData = ({val,index,key}) => {
        if(!key.includes('id')){
          handleShowSaveButton(true)
          const tableData=[...productVariantsTableData]
          if(key.includes('image')){
            tableData[index][key]=[val]
          }else{
            tableData[index][key]=val
          }
          setProductVariantsTableData(tableData)

          const updatedData=[...updatedProductDataValue]
          const updatedDataIndex = updatedData.findIndex(data => data.variantId === productVariantsTableData[index]['variant_id']);
          if(updatedDataIndex>=0){
            //already exist
            const objKey=capitalizeFirstLetter(key)
            if(key.includes('image')){
                updatedData[updatedDataIndex][objKey]=[val]
            }
            else{
                updatedData[updatedDataIndex][objKey]=val
            }
            setUpdatedProductDataValue(updatedData)
          }else{
            //doesnt exist
            const data= tableData[index]
            let obj={}
            Object.keys(data).map((key,index)=>{
                const objKey=capitalizeFirstLetter(key)
                if(key.includes('image')){
                    obj[objKey] = [data[key][0]]
                }
                else{
                    obj[objKey] = data[key]
                }
            })
            const arr=[]
            arr.push(obj)
            setUpdatedProductDataValue([...updatedProductDataValue,...arr])
          }
        }
    };

    const saveChanges = async()=>{
        const params = {
            "variant":updatedProductDataValue
        }
        const res = await ApiService.enableOrDisableOrEditVariants(params)
        if(res && res.variant && res.variant.success.length>0){
            fetchProductVariants()
          setShowNotification({open:true,message:'Changes saved successfully',type:'success',hideAutomatically:true})
        }else{
            setShowNotification({open:true,message:'Changes not saved',type:'error',hideAutomatically:true})
        }
        handleShowSaveButton(false)
    }

    const updateDataOnPageChange = (data,firstIndex,lastIndex)=>{
        const variants = data.slice(firstIndex,lastIndex)
        setProductVariantsTableData(variants)
    }

    const handleDeleteVariant=async (variant)=>{
        const params={
            "variantIds":[ `${variant.variant_id}` ]
          }
          const res = await ApiService.deleteVariants(params)
          if(res.returning.length>0){
                fetchProductVariants()
                setShowNotification({open:true,message:'Variant removed successfully',type:'success',hideAutomatically:true})
          }else{
              setShowNotification({open:true,message:'There is some error',type:'error',hideAutomatically:true})
          }
    }

    const createEntriesOptions=(productData)=>{
        const entriesArray = []
        let entry
        for(let i=1;i<Math.ceil(productData.length/variantsPerPage)+1;i++){
            if(i==Math.ceil(productData.length/variantsPerPage)){
                entry = (i-1)*variantsPerPage+1 + ' - ' + productData.length
            }else{
                entry = (i-1)*variantsPerPage+1 + ' - ' + i*variantsPerPage
            }
            
            entriesArray.push(entry)
        }
        setEntries(entriesArray)
    }

    const handleEntrySelection = (value)=>{
        setCurrentPage(value)
    }

    return (
        <div className='flex flex-col gap-[10px]'>
            <div className='flex flex-col gap-[20px] mb-[47px]'>
                <div className='flex items-center gap-[10px] mt-[10px]' >
                    <BiArrowBack onClick={hideVariantsTable} className='cursor-pointer'/>Back
                </div>
                <div className='font-semibold'>
                    PARENT SKU: {product.productHandle}
                </div>
            </div>

            <div>
                <div className='flex justify-center text-[14px] font-normal gap-[60px] mb-[15px]'>
                    {
                        entries.length>0?
                        <div className='flex gap-[10px] items-center'>
                            <span className=''>Show</span>  
                            <EntriesSelection entries={entries} handleEntrySelection={handleEntrySelection}/>
                            <span className=''>entries</span>  
                        </div>:''
                    }
                    <div className='flex gap-[10px] items-center'>
                        <span className=''>Status:</span>  
                        <ProductType handleProductStatus={handleProductStatus} type='Variants'/>
                    </div>
                    <SearchBar handleSearchFilter={handleSearchFilter}/>
                </div>
                <TableData tableData={productVariantsTableData} 
                    handleUpdateData={handleUpdateData} 
                    handleDeleteVariant={handleDeleteVariant}
                    handleShowSaveButton={handleShowSaveButton}
                    showSaveButton={showSaveButton}
                    saveChanges={saveChanges}
                />
                <Pagination
                    tableData = {productVariantsTableDataCopy}
                    updateDataOnPageChange={updateDataOnPageChange} 
                    searchText={searchValue}
                    searchResults={searchResults}
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage}
                    recordsPerPage = {variantsPerPage}
                    type={'table data'}
                    statusType={statusType}
                />
            </div>
        </div>
    )
}

export default VariantsTable