import { React, createContext, useState, useEffect } from 'react';
import { ApiService } from '../services/apiUtility';
import { BACKEND_HOST } from '../constants/backend';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [authTokens, setAuthTokens] = useState(localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    const [showNotification, setShowNotification] = useState({ open: false, type: 'success', message: '' })
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const [brandSettings, setBrandSettings] = useState({})
    const [openSideBar, setOpenSideBar] = useState(true)
    const [looksTempStorage, setLooksTempStorage] = useState(localStorage.getItem('looks_info') ? JSON.parse(localStorage.getItem('looks_info')) : {})

    useEffect(() => {
        if (authTokens) {
            let timeInterval = new Date(authTokens.tokenExpiresAt) - new Date() - (10 * 60 * 1000)
            timeInterval = Math.min(timeInterval, Math.pow(2, 30))

            setTimeout(() => {
                updateToken()
            }, timeInterval)
        }
    }, [authTokens, loading])

    useEffect(() => {
        if (authTokens && authTokens.accessToken) {
            getBrandSettings()
        }
    }, [authTokens, loading])

    const getBrandSettings = async () => {
        const res = await ApiService.fetchBrandSettings()
        const defaultIntensitySliderSettings = await setDefaultIntensitySliderSettings()
        let consent_information = 'I consent to have my image processed solely for the purpose of using this virtual try-on. To learn more about how we protect your personal data, read our Privacy Notice.'
        if (!res) {
            const defaultLogoUrl = await getUploadedImageUrl(require('../assets/images/default_Logo_Mirrar.png'))
            const defaultModelImage1 = await getUploadedImageUrl(require('../assets/images/defaultModelImage1.jpg'))
            const defaultModelImage2 = await getUploadedImageUrl(require('../assets/images/defaultModelImage2.jpg'))
            const defaultModelImage3 = await getUploadedImageUrl(require('../assets/images/defaultModelImage3.jpg'))
            const defaultModelImage4 = await getUploadedImageUrl(require('../assets/images/defaultModelImage4.jpg'))
            const params = {
                "brandId": ApiService.brandId,
                "json": {
                    logo: defaultLogoUrl,
                    gallery_mode_image: [defaultModelImage1, defaultModelImage2, defaultModelImage3, defaultModelImage4],
                    intensity_slider: defaultIntensitySliderSettings,
                    background_image: '',
                    themeColor: {
                        primaryColor: '#000000',
                        secondaryColor: '#FFFFFF'
                    },
                    customization_feature: {
                        takePhoto: true,
                        skinAnalysis: false,
                        comparisonMode: true,
                        createLooks: false,
                        manageLooks: false,
                        liveCamera: true,
                        uploadModel: true,
                        chooseModel: true
                    },
                    cart_info: {
                        "add_to_cart_webhook": "",
                        "bag_navigation_link": ""
                    },
                    consent_information
                }
            }
            const response = await ApiService.createSettings(params)
            if (response) {
                setBrandSettings(response)
            }
        } else {
            if (res?.setting_meta_json) {
                res.setting_meta_json.intensity_slider = {
                    ...(defaultIntensitySliderSettings || {}),
                    ...(res.setting_meta_json.intensity_slider || {})
                }
            }
            if (!res?.setting_meta_json?.consent_information) {
                res.setting_meta_json.consent_information = consent_information
            }
            if (!res?.setting_meta_json?.customization_feature?.liveCamera) {
                res.setting_meta_json.customization_feature['liveCamera'] = true
                console.log(res.setting_meta_json)
                const response = await setDefaultCameraModeEnable(res.setting_meta_json)
                setBrandSettings(response)
            }
            if (!res.setting_meta_json?.logo || !res.setting_meta_json?.gallery_mode_image) {
                const response = await setDefaultLogoAndModelImage(res)
                setBrandSettings(response)
            } else {
                setBrandSettings(res)
            }

        }
    }

    const setDefaultCameraModeEnable = async (existingSetting) => {
        console.log(existingSetting)
        const params = {
            brandId: ApiService.brandId,
            setting: {
                json: {
                    ...existingSetting
                }
            }
        }
        console.log(params)
        const res = await ApiService.updateSettings(params)
        if (res && res.status == 200) {
            const res = await ApiService.fetchBrandSettings()
            console.log(res)
            return res
        } else if (res && res.status == 403) {
            localStorage.clear()
            window.location.href = `${window.location.origin}/authentication/sign-in`
            setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
        } else {
            setShowNotification({ open: true, message: 'There is some error while uploading Model image', type: 'error',hideAutomatically:true })
        }
    }

    const setDefaultLogoAndModelImage = async (existingSetting) => {
        const defaultLogoUrl = await getUploadedImageUrl(require('../assets/images/default_Logo_Mirrar.png'))
        const defaultModelImage1 = await getUploadedImageUrl(require('../assets/images/defaultModelImage1.jpg'))
        const defaultModelImage2 = await getUploadedImageUrl(require('../assets/images/defaultModelImage2.jpg'))
        const defaultModelImage3 = await getUploadedImageUrl(require('../assets/images/defaultModelImage3.jpg'))
        const defaultModelImage4 = await getUploadedImageUrl(require('../assets/images/defaultModelImage4.jpg'))
        const params = {
            brandId: ApiService.brandId,
            setting: {
                json: {
                    logo: defaultLogoUrl,
                    gallery_mode_image: [defaultModelImage1, defaultModelImage2, defaultModelImage3, defaultModelImage4],
                    background_image: '',
                    ...(existingSetting?.setting_meta_json || {})
                }
            }
        }
        const res = await ApiService.updateSettings(params)
        if (res && res.status == 200) {
            const res = await ApiService.fetchBrandSettings()
            return res
        } else if (res && res.status == 403) {
            localStorage.clear()
            window.location.href = `${window.location.origin}/authentication/sign-in`
            setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
        } else {
            setShowNotification({ open: true, message: 'There is some error while uploading Model image', type: 'error',hideAutomatically:true })
        }
    }

    const getUploadedImageUrl = async (filePath, name) => {
        try {
            const response = await fetch(filePath);
            if (!response.ok) {
                throw new Error('File not found');
            }
            const fileBlob = await response.blob();
            const file = new File([fileBlob], name, { type: fileBlob.type });
            const formData = new FormData();
            formData.append("file", file);
            const res = await ApiService.getUploadedImageUrl(formData)
            return res
        } catch (error) {
            setShowNotification({ open: true, message: 'There is some error', type: 'error',hideAutomatically:true })
        }
    }

    const setDefaultIntensitySliderSettings = async () => {
        const categoriesData = await ApiService.getCategories()
        let defaultIntensitySliderSettings = {}
        if (categoriesData) {
            categoriesData.map((category, index) => {
                defaultIntensitySliderSettings[category.category] = {
                    min: 0,
                    max: 100
                }
            })
        }
        return defaultIntensitySliderSettings
    }

    const updateToken = async () => {
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authTokens?.accessToken}`,
            },
            body: JSON.stringify({ 'refreshToken': authTokens?.refreshToken })
        }
        let fetchedData = await fetch(
            `${BACKEND_HOST}/user/refresh-token`, params
        ).then((response) => {
            if (response.status == 200) {
                return response.json();
            }
            throw new Error(response)
        })
            .catch((err) => {
                localStorage.clear()
                window.location.href = `${window.location.origin}/authentication/sign-in`
            })
        if (fetchedData && fetchedData.data) {
            setAuthTokens(fetchedData.data)
            localStorage.setItem('authTokens', JSON.stringify(fetchedData.data))
        }
        if (loading) {
            setLoading(false)
        }
    }

    useEffect(() => {
        localStorage.setItem('looks_info', JSON.stringify(looksTempStorage))
    }, [looksTempStorage])

    return <AppContext.Provider value={{
        loading, setLoading,
        authTokens, setAuthTokens,
        showNotification, setShowNotification,
        openDeleteConfirmation, setOpenDeleteConfirmation,
        brandSettings, getBrandSettings, setBrandSettings,
        openSideBar, setOpenSideBar,
        looksTempStorage, setLooksTempStorage
    }}>
        {children}
    </AppContext.Provider>
}

export { AppContext, AppContextProvider };