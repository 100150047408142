import * as $ from "jquery/dist/jquery.min.js";
class CardService {
  constructor() {
    this.selectedVariant = {};
    this.selectedItemClass = "border-black";
    this.eventTarget=new EventTarget()
  }
  getKey(variant,category) {
    return variant ? variant.variant_id + "-" + category : null;
  }
  deselectSpecificCategory(category) {
    $(`[data-category="${category}"]`).removeClass(this.selectedItemClass);
  }
  selectSpecificId(id) {
    $(`[data-identifier="${id}"]`).addClass(this.selectedItemClass);
  }
  deSelectSpecificId(id) {
    $(`[data-identifier="${id}"]`).removeClass(this.selectedItemClass);
  }
  setVariantSelected(category, variant) {
    this.selectedVariant[category] = variant;
  }
  removeVariantSelected(category) {
    this.selectedVariant[category] = null;
  }
  getVariantSelected(category) {
    return this.selectedVariant[category];
  }
  higlightSelectedVariant(variant,category) {
    try {
      console.log(variant,this.selectedVariant)
      console.log(this.selectedVariant[category].variant_sku,variant.variant_sku,this.selectedVariant[category].variant_parent_id,variant.variant_parent_id)
      return this.selectedVariant[category]?.variant_id === variant?.variant_id && this.selectedVariant[category]?.variant_parent_id === variant?.variant_parent_id
        ? this.selectedItemClass
        : "";
    } catch (error) {
      return "";
    }
  }
  handleCalibrationSaved(){
    this.eventTarget.dispatchEvent(new Event('calibration-saved'));
  }
}

let cardService = new CardService();

export { cardService as CardService };
