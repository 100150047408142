import React, {useState,useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../services/apiUtility";
import { AppContext } from "../../../context/AppContextProvider";
import NotificationBar from "../../Dialog/NotificationBar";

const ForgotPassword = () => {
  const {showNotification,setShowNotification} = useContext(AppContext)
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  
  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    const [emailPrefix, emailDomain] = email.split('@');

    const isValidEmail = pattern.test(email);
    const hasValidDomain = emailDomain && emailDomain.includes('.');

    return isValidEmail && hasValidDomain;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
        setEmailError("Invalid email format");
        return;
    }
    setEmailError(null);
    const params = {
      "email":email,
      "nextPath":`${window.location.origin}/authentication/reset-password`
    }
    
    const res= await ApiService.forgetPassword(params)
    if(res && res.status==200){
      setEmail('')
      setShowNotification({ open: true, message: res.message, type: 'success',hideAutomatically:true })
    }else{
      setShowNotification({ open: true, message: res.message, type: 'error',hideAutomatically:true })
    }
  };

  useEffect(()=>{
    const userData= localStorage.getItem('userData')
    if(userData){
      navigate(`/home`);
    }
  },[])

  return (
    <div className='relative w-[40%] py-[50px] rounded bg-[#fff]'>
        <div className="text-center">
            <h1>Forget Password</h1>
        </div>
        <div className="mt-[60px]">
            <form onSubmit={handleSubmit} className='h-[45%] w-[75%] flex flex-col max-w-[450px] m-auto'>
                <div className='mb-[15px]'>
                  <span className='ml-[5px] text-[#00000093] font-medium'>Email</span>
                  <div>
                    <input 
                      type="text" 
                      placeholder="Enter email" 
                      className="h-[50px] mt-[5px] bg-white border border-gray-300 text-gray-900 text-sm rounded w-full p-4" 
                      required
                      value={email}
                      onChange={(event) => {
                        setEmailError('')
                        setEmail(event.target.value)
                      }}
                      id="email"
                      name="email"
                    />
                    {
                      emailError?
                      <label className="label">
                        <span className="label-text-alt text-red-500">{emailError}</span>
                      </label>:''
                    } 
                  </div>
                </div>
                <div className="flex w-full justify-center mt-[20px]">
                    <button
                      className="bg-black rounded text-white w-full h-[50px] font-medium"
                    >
                      <span>Submit</span>  
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default ForgotPassword