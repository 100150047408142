import React, { useContext, useEffect, useState } from 'react'
import { ApiService } from '../../../services/apiUtility'
import LinearLoader from '../../Loader/LinearLoader'

import { Categories } from './Categories';
import { SearchBar } from './SearchBar';
import { ProductCard } from './ProductCard';
import Pagination from './Pagination';
import VariantsTable from './ManageProductVariants/VariantsTable';
import DeleteConfirmation from '../../Dialog/DeleteConfirmation';
import { AppContext } from '../../../context/AppContextProvider';
import { Actions } from './Actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProductType } from './ProductType';
import { EntriesSelection } from './EntriesSelection';

const ManageInventory = () => {
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();
    const { setShowNotification } = useContext(AppContext)
    const [selectedCategory, setSelectedCategory] = useState('')
    const [categoriesForTab, setCategoriesForTab] = useState('')
    const [categories, setCategories] = useState([])
    const [showCategories, setShowCategories] = useState(false)
    const [categoryProducts, setCategoryProducts] = useState([])
    const [categoryProductsCopy, setCategoryProductsCopy] = useState([])

    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])

    const [selectedProduct, setSelectedProduct] = useState({})
    const [selectedProducts, setSelectedProducts] = useState([])

    const [showVariantsTable, setShowVariantsTable] = useState(false)

    const [statusType, setStatusType] = useState('all')

    const { openDeleteConfirmation, setOpenDeleteConfirmation } = useContext(AppContext)

    const [showLoader, setShowLoader] = useState(false)
    const [entries, setEntries] = useState([])

    const [isSelectAll, setIsSelectAll] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 20;
    const lastIndex = currentPage * productsPerPage
    const firstIndex = lastIndex - productsPerPage

    useEffect(() => {
        fetchCategories()
    }, [])
    useEffect(() => {
        let categoryKey = searchParams.get('category')
        if (!categoryKey) {
            if (selectedCategory?.category?.category) {
                setSearchParams({category:selectedCategory?.category?.category})
                // navigate(`./${selectedCategory?.category?.category}`, { relative: true })
            } else {
                console.log('Param is missing but selected cat is also missing')
            }
        }
    }, [searchParams])

    const fetchCategories = async () => {
        setShowLoader(true)
        let categoriesValues = []
        const categoriesData = await ApiService.getCategories()
        if (categoriesData.length > 0) {
            console.log(categoriesData)
            let category = categoriesData[0]
            let categoryKey = searchParams.get('category')
            if (categoryKey) {
                let categoryFromUrl = (categoriesData.find((ele) => {
                    return ele?.category?.toLowerCase?.() === categoryKey?.toLowerCase?.()
                }))
                if (categoryFromUrl) {
                    category = categoryFromUrl
                } else if (category.category) {
                    setSearchParams({ category: category.category })
                }
            } else {
                setSearchParams({ category: category.category })
            }
            const products = await ApiService.getCategoryProducts(category.category, true, 1)
            if (products) {
                categoriesValues.push({ category: category, productData: products.data })
            } else {
                localStorage.clear()
                navigate('/authentication/sign-in')
                setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error', hideAutomatically: true })
            }
            setCategoriesForTab(categoriesData)
            if (categoriesValues.length > 0) {
                setCategories(categoriesValues)
                setCategoryProductsCopy(categoriesValues[0].productData)
                const data = categoriesValues[0].productData.slice(firstIndex, lastIndex)
                const defaultCategory = categoriesValues[0]
                setSelectedCategory(defaultCategory)
                setCategoryProducts(data)
                createEntriesOptions(categoriesValues[0].productData)

                setStatusType('all')
                setShowCategories(true)
            }
        }
        setShowLoader(false)

    }

    const handleCategoryOnClick = async (category, getlatestProducts) => {
        setCurrentPage(1)
        let categoryWithData = {}
        let updatedCategories = []
        const index = categories.findIndex((ele) => {
            return ele.category.category == category.category
        })
        if (index < 0 || getlatestProducts) {
            const products = await ApiService.getCategoryProducts(category.category, true, 1)
            if (products) {
                categoryWithData = { category: category, productData: products.data }
                if (getlatestProducts) {
                    updatedCategories = [...categories]
                    const index = updatedCategories.findIndex((categoryData) => categoryData.category.category == category.category)
                    if (index > -1) {
                        updatedCategories[index] = categoryWithData
                    }
                } else {
                    updatedCategories = [...categories, categoryWithData]
                }
                setCategories(updatedCategories)
            }

        } else {
            categoryWithData = categories[index]
        }
        const data = categoryWithData.productData.slice(firstIndex, lastIndex)
        createEntriesOptions(categoryWithData.productData)
        let categoryKeyForParam = categoryWithData?.category?.category
        setSearchParams({ category: categoryKeyForParam })
        setSelectedCategory(categoryWithData)
        setCategoryProducts(data)
        setCategoryProductsCopy(categoryWithData.productData)
        if (index < 0 || getlatestProducts) {
            handleSearch(searchValue, categoryWithData, updatedCategories)
        } else {
            handleSearch(searchValue, categoryWithData, categories)
        }
        handleUnSelectProducts()
    }

    const createEntriesOptions = (productData) => {
        const entriesArray = []
        let entry
        for (let i = 1; i < Math.ceil(productData.length / productsPerPage) + 1; i++) {
            if (i == Math.ceil(productData.length / productsPerPage)) {
                entry = (i - 1) * productsPerPage + 1 + ' - ' + productData.length
            } else {
                entry = (i - 1) * productsPerPage + 1 + ' - ' + i * productsPerPage
            }

            entriesArray.push(entry)
        }
        setEntries(entriesArray)
    }

    const handleSearchFilter = (searchText) => {
        setCurrentPage(1)
        setSearchValue(searchText)
        handleSearch(searchText, selectedCategory, categories)
    }

    const handleSearch = (searchText, selectedCategory, categories) => {
        const selectedCategoryProducts = categories.filter((category, index) => {
            return selectedCategory.category.label == category.category.label
        })

        const searchResults = selectedCategoryProducts[0].productData.filter((product) => {
            if (statusType == 'all') {
                return product.productHandle.toLowerCase().includes(searchText.toLowerCase()) || product.productTitle && product.productTitle.toLowerCase().includes(searchText.toLowerCase())
            } else {
                return (product.productHandle.toLowerCase().includes(searchText.toLowerCase()) || product.productTitle && product.productTitle.toLowerCase().includes(searchText.toLowerCase())) && String(product.productStatus) == statusType
            }

        })
        setSelectedCategory({ ...selectedCategory, productData: searchResults })
        setCategoryProducts(searchResults)
        setSearchResults({ ...selectedCategory, productData: searchResults })
        createEntriesOptions(searchResults)
    }

    const handleProductStatus = (val) => {
        setCurrentPage(1)
        setStatusType(val)

        const selectedCategoryProducts = categories.filter((category, index) => {
            return selectedCategory.category.label == category.category.label
        })
        const searchResults = selectedCategoryProducts[0].productData.filter((product) => {
            if (val == 'all') {
                return product.productHandle.toLowerCase().includes(searchValue.toLowerCase())
            } else {
                return product.productHandle.toLowerCase().includes(searchValue.toLowerCase()) && String(product.productStatus) == val
            }
        })
        setSelectedCategory({ ...selectedCategory, productData: searchResults })
        setCategoryProducts(searchResults)
        setSearchResults({ ...selectedCategory, productData: searchResults })
        createEntriesOptions(searchResults)
    }

    const updateDataOnPageChange = (data, firstIndex, lastIndex) => {
        const products = data.productData.slice(firstIndex, lastIndex)
        const categoryProducts = { ...data, productData: products }
        setSelectedCategory(categoryProducts)
        setCategoryProducts(products)
    }

    const handleCheckboxClick = (e) => {
        const { id, checked } = e.target;
        if (!checked) {
            const updatedSelectedProducts = selectedProducts.filter(product => {
                return product.productId != id
            })
            setSelectedProducts(updatedSelectedProducts);
        } else {
            const updatedSelectedProducts = [...selectedProducts, {
                productId: id
                // ,subBrandId:e.target.dataset.subbrandid
            }]
            setSelectedProducts(updatedSelectedProducts);
        }
    };

    const handleUnSelectProducts = () => {
        setIsSelectAll(false)
        setSelectedProducts([])
    }

    const handleShowOrHideVariantsTable = (val, product) => {
        setShowVariantsTable(val)
        setShowCategories(!val)
        if (val) {
            setSelectedProduct(product)
        } else {
            setSelectedProduct({})
        }
    }

    const enableOrDisableProduct = async (params, subBrandId) => {
        const res = await ApiService.enableOrDisableProducts(params, subBrandId)
        return res
    }

    const deleteProduct = async (product) => {
        setOpenDeleteConfirmation(true)
        setSelectedProduct(product)
    }

    const onDeleteConfirm = async () => {
        let productsIds = []
        if (Object.keys(selectedProduct).length > 0) {
            productsIds.push(selectedProduct.productId)
        } else if (selectedProducts.length > 0) {
            for (let product of selectedProducts) {
                productsIds.push(product.productId)
            }
        }
        const params = {
            "productIds": productsIds
        }
        const res = await ApiService.deleteProducts(params)
        if (res.returning.length > 0) {
            handleCategoryOnClick(selectedCategory.category, true)
            handleUnSelectProducts()
            if (Object.keys(selectedProduct).length > 1) {
                setShowNotification({ open: true, message: 'Selected products deleted successfully', type: 'success', hideAutomatically: true })
            } else {
                setShowNotification({ open: true, message: 'Selected product deleted successfully', type: 'success', hideAutomatically: true })
            }
        } else {
            setShowNotification({ open: true, message: 'There is some error.', type: 'error', hideAutomatically: true })
        }
    }

    const handleEntrySelection = (value) => {
        setCurrentPage(value)
    }

    const handleSelectAll = () => {
        setIsSelectAll(!isSelectAll);
        if (isSelectAll) {
            handleUnSelectProducts()
        } else {
            if (statusType == 'all' && searchValue == '') {
                const selectedProducts = categoryProductsCopy.map(product => {
                    return { productId: product.productId }
                })
                setSelectedProducts(selectedProducts);
            } else {
                const selectedProducts = searchResults.productData.map(product => {
                    return { productId: product.productId }
                })
                setSelectedProducts(selectedProducts);
            }
        }
    }

    return (
        <div className='h-[95%]'>
            <div className='text-[16px] font-semibold py-[19px]'>
                MANAGE INVENTORY
            </div>
            {
                showCategories ?
                    <div className='flex ml-[25px] pb-[2px] gap-[10px] overflow-x-auto container2 mb-[24px] h-[50px]'>
                        {
                            categoriesForTab.length > 0 && categoriesForTab.map((category, index) => {
                                return <Categories category={category} key={index} selectedCategory={selectedCategory} handleCategoryOnClick={handleCategoryOnClick} type={'products'} />
                            })
                        }
                    </div> : ''
            }
            <div className='text-[16px] bg-[#fff] px-[20px] rounded w-full min-h-[95%] h-max'>
                {
                    openDeleteConfirmation ?
                        <DeleteConfirmation onDeleteConfirm={onDeleteConfirm} /> : ''
                }
                {
                    categories.length > 0 ?
                        !showVariantsTable ?

                            <div className='w-full h-full flex flex-col items-center'>
                                <div className='sticky top-[-2px] w-full z-10'>
                                    <div className='bg-white'>
                                        <div className='py-[20px] w-full border-b-[1px] h-max border-[#0001]'>

                                            <div className='flex justify-center text-[14px] font-normal gap-[40px] w-full'>
                                                {
                                                    entries.length > 0 ?
                                                        <div className='flex gap-[10px] items-center'>
                                                            <span className=''>Show</span>
                                                            <EntriesSelection entries={entries} handleEntrySelection={handleEntrySelection} />
                                                            <span className=''>entries</span>
                                                        </div> : ''
                                                }
                                                <div className='flex gap-[10px] items-center'>
                                                    <span className=''>Status:</span>
                                                    <ProductType handleProductStatus={handleProductStatus} type='Products' statusType={statusType} />
                                                </div>
                                                <Actions selectedProducts={selectedProducts}
                                                    handleUnSelectProducts={handleUnSelectProducts}
                                                    enableOrDisableProduct={enableOrDisableProduct}
                                                    handleCategoryOnClick={handleCategoryOnClick}
                                                    fetchCategories={fetchCategories}
                                                    selectedCategory={selectedCategory}
                                                    type='products'
                                                />
                                                <SearchBar handleSearchFilter={handleSearchFilter} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full flex justify-between items-center mt-[20px]'>
                                    <div className='flex items-center gap-[10px]'>
                                        <span className='text-[16px] font-bold'>Select All: </span>
                                        <input
                                            type='checkbox'
                                            onChange={handleSelectAll}
                                            checked={isSelectAll}
                                            className='w-[20px] h-[20px] accent-white checkbox'
                                        />
                                    </div>
                                    <div>
                                        Currently Selected {selectedCategory && selectedCategory.category && selectedCategory.category.label ? selectedCategory.category.label : ''}: {selectedProducts.length}
                                    </div>

                                </div>
                                <div className='w-full'>
                                    <div className='grid grid-cols-[repeat(auto-fit,280px)] gap-x-[20px] gap-y-[40px] my-[20px] justify-evenly w-full h-full'>
                                        {
                                            categoryProducts.map((product, index) => {
                                                return <ProductCard product={product} key={index}
                                                    handleShowOrHideVariantsTable={handleShowOrHideVariantsTable}
                                                    enableOrDisableProduct={enableOrDisableProduct}
                                                    fetchCategories={fetchCategories}
                                                    deleteProduct={deleteProduct}
                                                    handleCheckboxClick={handleCheckboxClick}
                                                    selectedProducts={selectedProducts}
                                                    handleCategoryOnClick={handleCategoryOnClick}
                                                    selectedCategory={selectedCategory}
                                                />
                                            })
                                        }
                                    </div>
                                </div>
                                <Pagination selectedCategory={selectedCategory}
                                    categories={categories}
                                    updateDataOnPageChange={updateDataOnPageChange}
                                    searchText={searchValue}
                                    searchResults={searchResults}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    recordsPerPage={productsPerPage}
                                    type={'products'}
                                    statusType={statusType}
                                />
                            </div>
                            : <VariantsTable product={selectedProduct} handleShowOrHideVariantsTable={handleShowOrHideVariantsTable} handleProductSearchFilter={handleSearchFilter} />
                        : showLoader ? <LinearLoader />
                            : <div className='py-[20px] flex justify-center'>No product category found.</div>
                }

            </div>
        </div>
    )
}

export default ManageInventory