import React, { useContext, useEffect, useRef, useState } from 'react'
import LooksTooltipIcon from '../../../../assets/images/looks-information-icon.svg'
import { ApiService } from '../../../../services/apiUtility';

import DropDownIcon from '../../../../assets/images/dropdown.svg'
import ZoomoutIcon from '../../../../assets/images/zoom_in_icon.svg'
import ZoomInIcon from '../../../../assets/images/zoom_out_icon.svg'

import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { ManageLooksContext } from '../../../../context/LooksContext';
import { AppContext } from '../../../../context/AppContextProvider';
import { Cropper } from 'react-cropper';

const ManageLooksLookOverview = ({
    lookData,
    setOpenTooltip
}) => {

    const fileFormats = 'image/png, image/jpeg, image/jpg'
    const fileFormatsVideo = 'video/mp4, video/webm'

    const { changedLookValues, setChangedLookValues } = useContext(ManageLooksContext)

    const { setShowNotification } = useContext(AppContext)

    const [looksCategories, setLooksCategories] = useState([])      // Array to store the Look Categories
    const [selectedCategoryDropdown, setSelectedCategoryDropdown] = useState(false) // Used to check if the Category popup is open or not
    const [images, setImages] = useState()                          // UseState to send image value to Cropper component
    const [videoFile, setVideoFile] = useState(null)                // State to store uploaded video file
    const [originalZoomRatio, setOriginalZoomRatio] = useState()    // Used to store original zoom ratio value
    const [zoom, setZoom] = useState(0)                             // Used to store zoom value
    const [ready, setReady] = useState(false)                       // Used to check if the cropper component is ready or not

    const inputFileRef = useRef(null)
    const inputVideoRef = useRef(null)
    const cropperRef = useRef(null)

    const fetchLooksCategories = async () => {
        const res = await ApiService.fetchLooksCategories()
        if (res) {
            let categories = []
            for (let category of res.lookCollection) {
                const categoryObj = {
                    value: category.collectionKey,
                    label: category.collectionLabel
                }
                categories.push(categoryObj)
            }
            setLooksCategories(categories)
        }
    }

    const zoomReady = () => {
        //Event listener to check if the cropper is ready or not
        setReady(true)
        setZoom(cropperRef?.current?.cropper?.canvasData?.width / cropperRef?.current?.cropper?.canvasData?.naturalWidth)
    }

    const changeImage = (e) => {
        //Function to take in the image and send it to Cropper component 
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (!files?.[0]) return
        if (!fileFormats.includes(files[0].type)) {
            setShowNotification({ open: true, type: 'failure', message: "Please image in PNG, JPEG or JPG format",hideAutomatically:true })
            return
        }
        if (files[0].size / 1000 >= 2048) {
            setShowNotification({ open: true, type: 'failure', message: "Please image of size below than 2MB",hideAutomatically:true })
            return
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImages(reader.result);
        };
        setChangedLookValues({
            ...changedLookValues,
            referenceImage: {
                type: files[0].type,
                name: files[0].name,
            }
        })
        reader.readAsDataURL(files[0]);
    };

    const changeVideo = (e) => {
        e.preventDefault();
        const files = e.target.files || e.dataTransfer.files;
        const file = files?.[0];
    
        if (!file || !fileFormatsVideo.includes(file.type)) {
          setShowNotification({
            open: true,
            type: 'failure',
            message: 'Please upload a video in MP4 or WebM format',
            hideAutomatically: true
          });
          return;
        }
    
        if (file.size / 1024 >= 51200) { 
          setShowNotification({
            open: true,
            type: 'failure',
            message: 'Please upload a video below 50MB',
            hideAutomatically: true
          });
          return;
        }
    
        const reader = new FileReader();
        reader.onload = () => {
            const videoUrl = reader.result;

            setVideoFile(videoUrl);
            setChangedLookValues(prev => ({
              ...prev,
              referenceVideo: { type: file.type, name: file.name, videoUrl: videoUrl },
            }));

            console.log("videoFile (onload)", videoUrl);
          };
        reader.readAsDataURL(file);
      };
    
    
      const deleteVideo = () => {
        setChangedLookValues(prev => ({ ...prev, referenceVideo: '' }));
        inputVideoRef.current.value = null;
        setVideoFile(null);
      };      

    useEffect(() => {
        //Function to get and set all categories of the look
        fetchLooksCategories()
    }, [])

    const getCropData = () => {
        //Function to set the cropped image
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setChangedLookValues({
                ...changedLookValues,
                referenceImage: {
                    ...changedLookValues.referenceImage,
                    imageUrl: cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
                }
            });
        }
    };
    const handleZoomIn = () => {
        //Function to zoom in the image
        if (!originalZoomRatio) setOriginalZoomRatio(zoom)
        setZoom((prevZoom) => prevZoom + 0.2);
    };

    const handleZoomOut = () => {
        //Function to zoom out the image
        if (!originalZoomRatio) setOriginalZoomRatio(zoom)
        setZoom((prevZoom) => Math.max(originalZoomRatio, prevZoom - 0.2));
    };

    const deleteImage = () => {
        //Function to delete the new image applied
        setChangedLookValues({
            ...changedLookValues,
            referenceImage: ''
        })
        inputFileRef.current.value = null
        setImages()
    }

    const zoomOutSet = (event) => {
        //Function to make sure that we do not zoom out outside the image
        if (!originalZoomRatio) {
            setOriginalZoomRatio(event.detail.oldRatio)
            setZoom(event.detail.oldRatio)
        }
        if (event.detail.ratio < (originalZoomRatio || event.detail.oldRatio)) {
            event.preventDefault()
        }
    }

    useEffect(() => {
        const container = cropperRef?.current
        container?.addEventListener("ready", zoomReady)
        if (ready) {
            container?.addEventListener("zoom", zoomOutSet)
        }
        return () => {
            container?.removeEventListener("ready", zoomReady)
            container?.removeEventListener("zoom", zoomOutSet)
        }
    }, [images, ready, originalZoomRatio])

    console.log("zoom", zoom);

    return (
        <div className='flex flex-col h-full'>
            <div className='mb-3 min-w-fit m-auto h-48 relative rounded-t-md '>
                {
                    changedLookValues?.referenceImage?.imageUrl
                        ? <FiTrash2
                            className='absolute right-2 top-2 p-1 fill-white cursor-pointer w-6 h-6 rounded-full bg-[rgba(0,0,0,0.6)]'
                            onClick={deleteImage}
                        />
                        : <FiEdit2
                            className='absolute right-2 top-2 p-1 fill-white cursor-pointer w-6 h-6 rounded-full bg-[rgba(0,0,0,0.6)]'
                            onClick={() => inputFileRef?.current?.click()}
                        />
                }
                <input type='file' id='manage-looks-image' onChange={changeImage} accept={fileFormats} hidden ref={inputFileRef} />
                <img className='object-contain h-full' src={changedLookValues?.referenceImage?.imageUrl || lookData.looks_image[0]} alt={lookData.looks_label} id='look_image' />
                {
                    images && !changedLookValues?.referenceImage?.imageUrl &&
                    <div className='flex flex-col absolute w-[25vw] z-[1] h-auto shadow-[0_4px_10px_0_rgba(0,0,0,0.25)] h-100 top-[30px] left-[30px] bg-[#fff] p-[16px] border border-solid border-[#D9D9D9]'>
                        <p className='text-[14px] pb-[14px]'>Drag  the image to adjust</p>
                        <Cropper
                            ref={cropperRef}
                            style={{ width: "100%", height: 'auto' }}
                            aspectRatio={1}
                            preview=".img-preview"
                            src={images}
                            minCanvasHeight={100}
                            viewMode={1}
                            guides={true}
                            zoomTo={zoom}
                            cropBoxMovable={true}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                        >
                        </Cropper>
                        <div className="absolute right-[20px] top-[65px] flex  bg-[#000000] items-center text-[#FFFFFF] px-[12px] py-[4px] rounded gap-[10px]">
                            <img className='h-[15px] cursor-pointer' src={ZoomoutIcon} onClick={handleZoomIn} alt='zoom in'></img>
                            <span>|</span>
                            <img className='h-[15px] cursor-pointer' src={ZoomInIcon} onClick={handleZoomOut} alt='zoom out'></img>
                        </div>
                        <button type='button' className='mt-[16px] rounded ml-[auto] px-[32px] py-[8px] bg-[#413E4F] text-[#FFFFFF]'
                            onClick={getCropData}>Done</button>
                    </div>
                }
            </div>
            <div className='min-w-fit m-auto h-48 relative rounded-t-md'>
                {changedLookValues?.referenceVideo?.videoUrl ? (
                <FiTrash2
                    className='absolute right-2 top-2 p-1 fill-white cursor-pointer w-6 h-6 rounded-full bg-[rgba(0,0,0,0.6)] z-[10000]'
                    onClick={deleteVideo}
                />
                ) : (
                <FiEdit2
                    className='absolute right-2 top-2 p-1 fill-white cursor-pointer w-6 h-6 rounded-full bg-[rgba(0,0,0,0.6)] z-[10000]'
                    onClick={() => inputVideoRef?.current?.click?.()}
                />
                )}
                <input
                type='file'
                id='manage-looks-video'
                onChange={changeVideo}
                accept={fileFormatsVideo}
                hidden
                ref={inputVideoRef}
                />
                <video
                id='look_video'
                className='object-contain h-full'
                // autoPlay
                controls
                muted
                loop
                src={changedLookValues?.referenceVideo?.videoUrl || lookData.looks_video?.[0]}
                />
            </div>
            <div className='look_info_container px-2'>
                <div className='label-container mb-4'>
                    <label className=' inline-block text-[12px] mb-2 text-[#353535] placeholder:text-[#353535]'>Look Name</label>
                    <input id="look_name" type='text'
                        placeholder={lookData.looks_label}
                        className='bg-inherit placeholder:text-[#333] look_input outline-none text-[16px] w-full border-b-[0.5px] border-[##D9D9D9] pb-1'
                        onChange={e => setChangedLookValues({ ...changedLookValues, name: e.target.value })}
                    />
                </div>
                <div className='mb-[40px]'>
                    <p className='inline-block text-[12px] mb-2 text-[#353535]'>Look Collection</p>
                    <div id="look_collection_name" className='relative cursor-pointer border-[##D9D9D9] pb-1 border-b-[0.5px]' onClick={() => setSelectedCategoryDropdown(!selectedCategoryDropdown)} >
                        <span className={`bg-inherit look_input text-[16px] w-full disabled:bg-transparent`}>{changedLookValues?.collectionName?.label || lookData.collectionInfo.collectionLabel}</span>
                        <span><img src={DropDownIcon} alt='dropdown-icon' className={`absolute !w-3 right-[10px] top-[6px] transition-all ${selectedCategoryDropdown ? 'rotate-180' : 'rotate-0'}`} /></span>
                        {
                            selectedCategoryDropdown
                                ? <div className='absolute w-full bottom-full rounded-t-[4px] p-3 mb-3 shadow-[0px_-2px_4px_rgba(0,0,0,0.25)] border border-[#D9D9D9] border-b-0 z-10 bg-[#fff]'>
                                    <ul className='overflow-y-auto max-h-24 scrollbar-container3 flex flex-col gap-2'>
                                        {
                                            looksCategories.length
                                                ? looksCategories.map((category, index) =>
                                                    <li
                                                        className='cursor-pointer text-[14px] p-1 rounded-lg mr-3 hover:bg-[#F9F8FE]'
                                                        key={index}
                                                        onClick={() => setChangedLookValues({ ...changedLookValues, collectionName: category })}
                                                    >{category.label}</li>
                                                )
                                                : null
                                        }
                                    </ul>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className='flex-grow'>&nbsp;</div>
            <div onClick={() => setOpenTooltip(prevData => !prevData)} className='cursor-pointer flex m-3 gap-3 p-2 rounded-[8px] bg-[#D9D9D9]'>
                <img src={LooksTooltipIcon} alt='icon' />
                <span>How to create a look?</span>
            </div>
        </div>
    )
}

export default ManageLooksLookOverview
