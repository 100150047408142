import React from 'react'
import dayjs from 'dayjs';
import { Pie,Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement
);

export const options = {
  responsive:true,
  maintainAspectRatio:true,
  aspectRatio:2/1,
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        color: "black"
      },
    },
    y: {
      ticks: {
        color: "black"
      },
    }
  },
  plugins:{
    legend:{
      labels: {
        color: "black",
        padding:20,
      },
      position:'bottom',
    }
  },
  tooltips: {
    backgroundColor: 'white', // Set your desired background color here
  },
}
const Chart = ({chartData,eventType}) => {
  let data={
    labels:'',
    datasets:[]
  }

  let xValues= chartData && chartData.xValues && chartData.xValues.map((value)=>dayjs(value).format('DD MMM'))
  if(xValues){
    data.labels=xValues
    const datasetObj = {
      label:eventType,
      data: chartData.series[0],
      borderColor: ['black'],
      backgroundColor: ['black'],
      borderWidth: 1,
      pointRadius: 5,
      tension: 0.4
    }
    data.datasets.push(datasetObj)
  }
  return (
    <div className='w-full h-full flex justify-center items-center my-3.5 px-[10px]'>
        <Bar data={data} options={options}/>
    </div>
  )
}

export default Chart