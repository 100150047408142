import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../context/AppContextProvider'
import { ApiService } from '../../../../services/apiUtility'
import UploadImageFolder from './UploadImageFolder'
import ShowUploadedImages from './ShowUploadedImages'
import { CategoryTab } from '../../CalibrateInventory/CategoryTab'

const UploadFolder = () => {
  const {setShowNotification} = useContext(AppContext)
  
  const [categories,setCategories] = useState([])
  const [uploadedImages,setUploadedImages] = useState([])
  const [selectedCategory,setSelectedCategory] = useState({})

  useEffect(()=>{
    fetchCategories()
  },[])

  const fetchCategories = async ()=>{
    const categoriesData = await ApiService.getCategories()
    setCategories(categoriesData) 
    setSelectedCategory(categoriesData[0])  
  }
  
  const handleCategoryOnClick = (category)=>{
    setSelectedCategory(category)
  }

  const handleUploadedImageFolder = (fileListArray) =>{
    setUploadedImages(fileListArray)
  }

  const removeUploadedImages = ()=>{
    setUploadedImages([])
  }
  return (
    <div>
                <div className='pb-[10px] px-[20px] flex flex-col gap-[20px] w-full h-max'>
                  <div className='flex gap-[20px] w-full h-max'>
                    <div className='w-[402px] h-[180px] my-[20px] flex flex-col py-[25px] gap-[20px] bg-white'>
                      <div className='font-medium text-[16px] px-[25px]'>
                        Categories
                      </div>
                      <div className='border-[1px]'></div>
                      {
                        categories.length>0?
                        <div className='px-[25px]'>
                            <CategoryTab categories={categories} selectedCategory={selectedCategory} handleCategoryOnClick={handleCategoryOnClick}/>
                        </div>
                        :<div className='text-center'> No category found</div>
                      }
                      
                    </div>

                    <div className='w-[60%]'>
                      <UploadImageFolder selectedCategory={selectedCategory} handleUploadedImageFolder={handleUploadedImageFolder}/>
                    </div>
                  </div>
                  {
                    uploadedImages.length>0?
                        <ShowUploadedImages uploadedImages={uploadedImages} selectedCategory={selectedCategory} removeUploadedImages={removeUploadedImages}/>
                    :''
                  }
                </div>
            
           
    </div>
  )
}

export default UploadFolder