import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContextProvider";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/apiUtility";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { setShowNotification } = useContext(AppContext)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [newPasswordError,setNewPasswordError] = useState(null)
  const [confirmPasswordError,setConfirmPasswordError] = useState(null)

  const validatePassword = () => {
    return confirmPassword == newPassword
  };

  const validatePasswordLength = ()=>{
    if(newPassword.length<8 || newPassword.length>12){
      setNewPasswordError('Password length should be between 8 and 12 characters')
      return false
    }
    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!validatePasswordLength()){
      return
    }
    if (!validatePassword()) {
      setShowNotification({ open: true, message: 'New Password and Confirm Password do not match', type: 'error',hideAutomatically:true })
      return;
    }
    const params = {
      "oldPassword":oldPassword,
      "newPassword":newPassword,
      "confirmPassword":confirmPassword
    }
    const response = await ApiService.changePassword(params)
    if(response && response.status=='200'){
      setShowNotification({ open: true, message: 'Password changed successfully', type: 'success',hideAutomatically:true })
      setOldPassword('')
      setNewPassword('')
      setConfirmPassword('')
    }else if(response && response.status=='403'){
      localStorage.clear()
      navigate('/authentication/sign-in')
      setShowNotification({open:true,message:'Unauthorized! You have been logged out',type:'error',hideAutomatically:true})
    }else{
      setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true })
    }
  };

  const logout = () => {
    localStorage.clear()
    navigate('/authentication/sign-in')
    setShowNotification({ open: true, message: 'Logged out successfully', type: 'success',hideAutomatically:true })
  }
  const getItem = JSON.parse((localStorage.getItem('loginDevice') || '{}'))?.platform

  return (
    <div className='flex-1 mt-[20px] relative py-[25px] px-[50px] w-full h-[calc(100%_-_40px)] bg-[#fff]'>
    <div className="mt-[43px] ">
        <form onSubmit={handleSubmit} className='h-[45%] full'>
          <div className="flex justify-between w-full">
            <div className="w-[30%]">
              <span className='ml-[5px] text-[16px]'>Current Password*</span>
              <div>
                <input 
                  type="password" 
                  placeholder="Enter current password" 
                  className="w-[100%] h-[54px] mt-[10px] border bg-white border-gray-300 text-[#D9D9D9] text-[14px] rounded pl-[20px] focus:text-black" 
                  required
                  value={oldPassword}
                  onChange={(event) => setOldPassword(event.target.value)}
                />
              </div>
            </div>
            <div className="w-[30%]">
              <span className='ml-[5px] text-[16px]'>New Password*</span>
              <div>
                <input 
                  type="password" 
                  placeholder="Enter new password" 
                  className="w-[100%] h-[54px] mt-[10px] border bg-white border-gray-300 text-[#D9D9D9] text-[14px] rounded pl-[20px] focus:text-black" 
                  required
                  value={newPassword}
                  onChange={(event) => {
                    setNewPassword(event.target.value)
                    setNewPasswordError(null)
                  }}
                />
                {
                  newPasswordError?
                  <label className="label">
                      <span className="label-text-alt text-red-500">{newPasswordError}</span>
                  </label>:''
                }
              </div>
            </div>
            <div className="w-[30%]">
              <span className='ml-[5px] text-[16px]'>Confirm Password*</span>
              <div>
                <input 
                  type="password" 
                  placeholder="Confirm Password" 
                  className="w-[100%] h-[54px] mt-[10px] border bg-white border-gray-300 text-[#D9D9D9] text-[14px] rounded pl-[20px] focus:text-black" 
                  required
                  value={confirmPassword}
                  onChange={(event) => {
                      setConfirmPassword(event.target.value)
                      setConfirmPasswordError(null)
                    }
                  }
                />
                {
                  confirmPasswordError?
                  <label className="label">
                      <span className="label-text-alt text-red-500">{confirmPasswordError}</span>
                  </label>:''
                }
              </div>
            </div>
          </div>
          <div className="flex w-full justify-end mt-[41px]">
              <button
                className="bg-black text-[16px] rounded text-white h-[50px] w-[176px] font-medium"
              >
                <span>Change Password</span>  
              </button>
          </div>
        </form>
        <div className="absolute bottom-[10px] w-[90%] flex flex-col gap-[20px]">
          <div className="text-[16px]">Login History</div>
          <div className="border-[1px]"></div>
          <div className="flex justify-between">
            <div className="flex gap-[20px] items-center">
              <div>
                <img src={require('../../assets/images/desktop.jpg')} alt="" width="76px" height='76px' />
              </div>
              <div className="flex flex-col">
                <div className="text-[14px]">{JSON.parse(localStorage.getItem('loginDevice') || '{}').platform}</div>
                <div className="text-[14px] text-[#B5B6B6]">
                  {
                    localStorage.getItem('loginPlace')!='undefined' ? 
                    <div>
                      <span>{JSON.parse(localStorage.getItem('loginPlace')).city}, </span>
                      <span>{JSON.parse(localStorage.getItem('loginPlace')).country} </span> 
                    </div>:''
                  }
                  <span>{JSON.parse(localStorage.getItem('loginTime') || '{}')}</span>
                </div>
              </div>
            </div>
            <div className="text-[14px] text-[#2C80C9] cursor-pointer flex items-center mr-[25px]"
              onClick={logout}
            >Logout</div>
          </div>
        </div>
    </div>
</div>
  )
}

export default ChangePassword