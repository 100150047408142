import React, { useContext, useRef } from 'react'
import { BiEdit } from "react-icons/bi";
import { MdOutlinePhonelinkOff, MdOutlinePhonelink, MdOutlineDeleteOutline } from "react-icons/md";
import { ApiService } from '../../../../services/apiUtility';
import { AppContext } from '../../../../context/AppContextProvider';
import { useNavigate, createSearchParams } from 'react-router-dom';
const LooksCard = ({ look, handleCheckboxClick,
    selectedLooks, handleLooksStatus, selectedCategory,setSelectedLook }) => {

    const navigate = useNavigate()
    const { setShowNotification,setOpenDeleteConfirmation } = useContext(AppContext)
    const viewTooltipRef = useRef(null)
    const deleteTooltipRef = useRef(null)
    const enableTooltipRef = useRef(null)
    const disableTooltipRef = useRef(null)
    const handleIsChecked = () => {
        var data = selectedLooks.find(function (ele) {
            return ele.key == look.key
        });
        if (data) {
            return true
        } else {
            return false
        }
    }

    const handleEditLooks = () => {

        const params = {
            look_key: look.key,
            collection_key:selectedCategory.value
        }

        navigate({
            pathname: '/manage-looks',
            search: `?${createSearchParams(params)}`,
        });
    }

    const handleEnableOrDisableLooks = async (value) => {
        const param = {
            brandId: ApiService.brandId,
            looks: [{
                key: look.key,
                label: look.label,
                image: [look.image[0]],
                video: look.video && look.video.length ? [look.video?.[0]] : null,
                status: value
            }],
        }
        const res = await ApiService.createLook(param)
        if (res) {
            handleLooksStatus(look,value)
            setShowNotification({ open: true, message: `Look ${value ? 'enabled' : 'disabled'}`, type: 'success',hideAutomatically:true })
        } else {
            setShowNotification({ open: true, message: 'There is some error', type: 'error',hideAutomatically:true })
        }
    }

    const handleDeleteLook = ()=>{
        setSelectedLook(look)
        setOpenDeleteConfirmation(true)
    }

    return (
        <div className='flex flex-col w-[280px] rounded border-[1px] h-max border-[#0001] hover:shadow-lg'>
            <div className='flex flex-col gap-[10px] p-[20px]'>
                <div className='flex gap-[20px]'>
                    <input
                        type='checkbox'
                        id={look.key}
                        data-look={JSON.stringify(look)}
                        onChange={handleCheckboxClick}
                        checked={handleIsChecked()}
                        className='w-[20px] h-[20px] accent-white checkbox'
                    />
                    <div className='w-[70%] text-[12px] text-center'>Active Product count: {look?.active_product_count?.aggregate?.count}</div>
                </div>
                <div className='flex justify-start items-center relative'>
                    {
                        !look.status ?
                            (
                                <div className='absolute w-[200px] top-0 right-0 bottom-0 left-0 z-1 flex items-center justify-center rounded bg-[rgba(0,0,0,.5)]'>
                                    <p className="p-[10px] text-red bg-[#ffe4e1] rounded ">DISABLED</p>
                                </div>
                            ) : ''
                    }
                    <div>
                        {
                            look.image[0]?
                                <img src={look.image[0]} alt="product" className='w-[200px] h-[200px] object-contain' />
                            :<div className='w-[200px] h-[200px] flex justify-center items-center text-white bg-gray-400'>No image available</div>
                        }
                        
                    </div>
                    <div className='flex flex-col border-[2px] absolute top-0 right-[-10px] '>
                        <div className='p-[8px] relative flex justify-center items-center border-b-[2px] cursor-pointer'
                            onClick={handleEditLooks}
                            onMouseEnter={(e) => {
                                viewTooltipRef.current.classList.add('visible')
                                viewTooltipRef.current.classList.remove('invisible')
                            }}
                            onMouseLeave={(e) => {
                                viewTooltipRef.current.classList.add('invisible')
                                viewTooltipRef.current.classList.remove('visible')
                            }}
                        >
                            <BiEdit size={20} />
                            <div ref={viewTooltipRef} className="tooltip text-[12px] rounded-[4px]  text-[white] py-[5px] px-[10px] whitespace-nowrap font-normal bg-black block invisible  absolute z-30  right-[40px] ">
                                Edit Looks
                            </div>
                        </div>
                        <div className='p-[8px] relative flex justify-center items-center border-b-[2px] cursor-pointer'
                                onClick={handleDeleteLook}
                                onMouseEnter={(e) => {
                                    deleteTooltipRef.current.classList.add('visible')
                                    deleteTooltipRef.current.classList.remove('invisible')
                                }}
                                onMouseLeave={(e) => {
                                    deleteTooltipRef.current.classList.add('invisible')
                                    deleteTooltipRef.current.classList.remove('visible')
                                }}
                            >
                                <MdOutlineDeleteOutline size={20}/>
                                <div ref={deleteTooltipRef} className="tooltip text-[12px] rounded-[4px]  text-[white] py-[5px] px-[10px] whitespace-nowrap font-normal bg-black block invisible  absolute z-30  right-[40px] ">
                                    Delete
                                </div>
                            </div>
                        <div className='p-[8px] relative flex justify-center items-center border-b-[2px] cursor-pointer'
                            onClick={() => { handleEnableOrDisableLooks(true) }}
                            onMouseEnter={(e) => {
                                enableTooltipRef.current.classList.add('visible')
                                enableTooltipRef.current.classList.remove('invisible')
                            }}
                            onMouseLeave={(e) => {
                                enableTooltipRef.current.classList.add('invisible')
                                enableTooltipRef.current.classList.remove('visible')
                            }}
                        >
                            <MdOutlinePhonelink size={20} />
                            <div ref={enableTooltipRef} className="tooltip text-[12px] rounded-[4px]  text-[white] py-[5px] px-[10px] whitespace-nowrap font-normal bg-black block invisible  absolute z-30  right-[40px] ">
                                Enable
                            </div>
                        </div>
                        <div className='p-[8px] relative flex justify-center items-center cursor-pointer'
                            onClick={() => { handleEnableOrDisableLooks(false) }}
                            onMouseEnter={(e) => {
                                disableTooltipRef.current.classList.add('visible')
                                disableTooltipRef.current.classList.remove('invisible')
                            }}
                            onMouseLeave={(e) => {
                                disableTooltipRef.current.classList.add('invisible')
                                disableTooltipRef.current.classList.remove('visible')
                            }}
                        >
                            <MdOutlinePhonelinkOff size={20} />
                            <div ref={disableTooltipRef} className="tooltip text-[12px] rounded-[4px]  text-[white] py-[5px] px-[10px] whitespace-nowrap font-normal bg-black block invisible  absolute z-30  right-[40px] ">
                                Disable
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-[15px] flex gap-[20px] items-center justify-between'>
                    <span className='w-full overflow-hidden whitespace-nowrap text-ellipsis text-center'>{look.label}</span>
                </div>
            </div>
        </div>
    )
}

export default LooksCard