import React, { useContext, useEffect, useState } from 'react';
import { ApiService } from '../../services/apiUtility';
import { AppContext } from '../../context/AppContextProvider';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloseCircle } from "react-icons/ai";

const Filters = () => {
  const { setShowNotification } = useContext(AppContext);
  const [keyError,setKeyError] = useState({
    index:'',
    errorMessage:''
  })
  const [showSaveButton,setShowSaveButton] = useState(false)
  const [formData, setFormData] = useState([
    {
      key: '',
      type: 'checkbox',
      label: '',
      default: '',
      filterOrder: 1,
    },
  ]);
  const [formDataCopy,setFormDataCopy] = useState([
    {
      key: '',
      type: 'checkbox',
      label: '',
      default: '',
      filterOrder: 1,
    },
  ])
  const navigate = useNavigate()

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(()=>{
    const isSameData = JSON.stringify(formData)===JSON.stringify(formDataCopy)
    if(isSameData){
      setShowSaveButton(false)
    }else{
      setShowSaveButton(true)
    }
  },[formData])


  const fetchFilters = async () => {
    const response = await ApiService.getFilters();
    if (response && response.status === 200) {
        if(response?.data?.availableFilterJson){
            const formsData = Object.entries(response.data.availableFilterJson).map(
                ([key, value]) => ({
                  key: key,
                  type: value.type,
                  label: value.label,
                  default: value.default,
                  filterOrder: value.filterOrder,
                })
              );
              formsData.sort((a, b) => a.filterOrder - b.filterOrder);
              setFormData(formsData);
              setFormDataCopy(formsData)
        }
    } else {
      setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true });
    }
  };

  const handleInputChange = (index, field, value) => {
    
    if (field === 'key') {
      if (value.trim() === '') {
        setKeyError({
          index: '',
          errorMessage: '',
        });
      } else {
        const isKeyValid = validateKey(value);
        if (!isKeyValid) {
          setKeyError({
            index: index,
            errorMessage: 'Key cannot contain special characters or space',
          });
          return;
        }
      }
    }
    
    const updatedFormData = JSON.parse(JSON.stringify(formData));
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);
  };

  const validateKey = (key)=>{
    const validIdentifierRegex = /^[A-Za-z0-9]+$/;

    return validIdentifierRegex.test(key);
    
  }

  const handleAddClick = () => {
    setFormData([
      ...formData,
      {
        key: '',
        type: 'checkbox',
        label: '',
        default: '',
        filterOrder: formData.length+1,
      },
    ]);
  };

  const handleDeleteClick = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };

  const handleSave = async(event,formData) => {
    if(event){
      event.preventDefault();
    }
    const resultObject = {}

    formData.forEach((item,index) => {
        resultObject[item.key] = {
            type: item.type,
            label: item.label,
            default: item.default,
            filterOrder: item.filterOrder
        };
    });
    
    const params={
        availableFilterJson:resultObject
    }
    const response = await ApiService.upsertFilters(params)
    if(response && response.status==200){
        setShowNotification({ open: true, message:response.message, type: 'success',hideAutomatically:true });
        setFormDataCopy(formData)
    }else if(response.status==403){
        localStorage.clear()
        navigate('/authentication/sign-in')
        setShowNotification({open:true,message:'Unauthorized! You have been logged out.',type:'error',hideAutomatically:true})
    }else{
        setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true });
    }
    setShowSaveButton(false)
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = [...formData];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.forEach((item, index) => {
        item.filterOrder = index+1;
      });
    setFormData(items);
  };

  return (
    <div className="flex flex-col gap-[30px] w-full min-h-[calc(100%_-_40px)] my-[20px] py-[30px] bg-[#fff]">
      <div className='font-semibold text-[18px] ml-[20px]'>Note: Filter by Price is available by default. Rest of the filter criteria can be set from this page.</div>
        <div className='flex justify-center w-full'>
        <form onSubmit={(event)=>handleSave(event,formData)} className='w-[50%] flex flex-col gap-[20px] '>
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="filterForm">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className='w-full flex flex-col items-center gap-[20px]'>
              {formData.map((data, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="border-[1px] rounded p-[20px] flex flex-col gap-[20px] w-full border relative"
                    >
                      <div className='absolute top-[5px] right-[5px] cursor-pointer mb-[5px]' onClick={()=>handleDeleteClick(index,true)}>
                          <AiOutlineCloseCircle size={22}/>
                      </div>
                      <div className='flex flex-col gap-[15px] font-medium'>
                        <div>
                          Key
                          <div className='font-light text-slate-500 text-[12px]'>* Key will be used as a header in product sheet upload</div>
                        </div>
                        <div>
                            <input
                                type="text"
                                id={`key-${index}`}
                                value={data.key}
                                onChange={(e) => {
                                    setKeyError({
                                        index: '',
                                        errorMessage: '',
                                      });
                                    handleInputChange(index, 'key', e.target.value)
                                }}
                                className="w-full bg-white border-[1px] py-[5px] pl-[10px] rounded"
                                placeholder='Enter key'
                                required
                            />
                            {keyError.index === index ? (
                                <label className="label">
                                <span className="label-text-alt text-red-500">{keyError.errorMessage}</span>
                                </label>
                            ) : null}
                        </div>
                    </div>
                    <div className='flex flex-col gap-[15px] font-medium'>
                        <div>
                          Type 
                          <div className='font-light text-slate-500 text-[12px]'>*Type defines the filter view, e.g. checkbox means end user can multi select filter in this section</div>
                        </div>
                        <div>
                            <select
                                id={`type-${index}`}
                                value={data.type}
                                onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                                className="w-full bg-white border-[1px] py-[5px] pl-[10px] rounded"
                            >
                                <option value="radio">Radio</option>
                                <option value="checkbox">Checkbox</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[15px] font-medium'>
                        <div>Label
                          <div className='font-light text-slate-500 text-[12px]'>*Label is what is displayed to end user</div> 
                        </div>
                        <div>
                            <input
                                type="text"
                                id={`label-${index}`}
                                value={data.label}
                                onChange={(e) => handleInputChange(index, 'label', e.target.value)}
                                className="w-full bg-white border-[1px] py-[5px] pl-[10px] rounded"
                                placeholder='Enter label'
                                required
                            />
                        </div>
                    </div>

                    <div className='flex flex-col gap-[15px] font-medium'>
                        <div>Default
                          <div className='font-light text-slate-500 text-[12px]'>*Default value when the value is not passed in the sheet for filter</div>
                        </div>
                        <div>
                            <input
                                type="text"
                                id={`default-${index}`}
                                value={data.default}
                                onChange={(e) => handleInputChange(index, 'default', e.target.value)}
                                className="w-full p-2 bg-white border border-gray-300 rounded-md"
                                placeholder='Enter default value'
                                required
                            />
                        </div>
                    </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="flex justify-end w-full">
        <button onClick={handleAddClick} className="text-white bg-black px-[20px] py-[8px] text-center rounded">
          Add New Filter Form
        </button>
      </div>
      {
        showSaveButton?
        (
          <div>
              <button type='submit' className="text-white bg-black px-[20px] py-[8px] text-center rounded">
                Save
              </button>
          </div>
        ):''
      }
        </form>
        </div>
      
    </div>
  );
};

export default Filters;
