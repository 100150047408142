import React,{useState} from 'react'
import { useEffect } from 'react';
import Select from 'react-select';
export const EntriesSelection = ({entries,handleEntrySelection=()=>{}})=>{
    const [selected,setSetSelected] = useState(null)
    const [options,setOptions] = useState([])
    
    useEffect(()=>{
        let entryOptions=[]
        entries.map((entry,index)=>{
            const obj={
                value:index+1,
                label:entry
            }
            entryOptions.push(obj)
        })
        setOptions(entryOptions)
        setSetSelected(entryOptions[0])
    },[entries])
    
    const customStyles = {
        option: (defaultStyles, state) => ({
          ...defaultStyles,
          textAlign: state.isSelected ? "center" : "center",
        }),
    
        control: (defaultStyles) => ({
          ...defaultStyles,
          textAlign:'center',
        }),
        singleValue: (defaultStyles) => ({ 
            ...defaultStyles,
             textAlign:'center', 
             fontSize:'16px',
             
        }),
      };
    
    const handleEntryClick = (option)=>{
        setSetSelected(option)
        handleEntrySelection(option.value)
    }
    return (
      <div className='w-[120px]'>
        {
          options.length>0 && selected ?
          <Select
              value={selected}
              onChange={handleEntryClick}
              options={options}
              styles={customStyles}
              isSearchable={false} 
              theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f2f2f2',
                    primary: 'black',
                  },
              })}
        />:''
        }
      </div>
      
    )
}