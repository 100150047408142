import React, { useContext, useEffect, useRef, useState } from 'react'
import { ApiService } from '../../services/apiUtility'
import { Categories } from '../InventoryManagement/ManageInventory/Categories'
import { AppContext } from '../../context/AppContextProvider';
import { ListManager } from "react-beautiful-dnd-grid";
import ShadeCard from './ShadeCard';
import { RecommendedShadesPopUp } from './RecommendedShades';

const FindYourShades = () => {
    const { setShowNotification } = useContext(AppContext)
    const containerRef = useRef(null)
    const shadesCardRef = useRef(null)

    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState({})
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [shadesColorToBeDeleted, setShadesColorToBeDeleted] = useState([])
    const [maxCards, setMaxCards] = useState(0)
    const [updatedShades, setUpdatedShades] = useState([])
    const [showRecommendedShades, setShowRecommendedShades] = useState(false)
    const [selectedShadeColorForRecommendedShade,setSelectedShadeColorForRecommendedShade] = useState({})

    const [shades, setShades] = useState([{
        color: '',
        colorName: '',
        orderBy: '',
        categoryKey: '',
        index: '',
        id: '',
        colorSelectionDisabled: true
    }])
    const [shadesCopy, setShadesCopy] = useState([{
        color: '',
        colorName: '',
        orderBy: '',
        categoryKey: '',
        index: '',
        id: '',
        colorSelectionDisabled: true
    }])

    useEffect(() => {
        const isSameData = JSON.stringify(shades) === JSON.stringify(shadesCopy)
        if (isSameData) {
            setShowSaveButton(false)
        } else {
            setShowSaveButton(true)
        }
        if (updatedShades.length > 0) {
            setShowSaveButton(true)
        }
    }, [shades, updatedShades])

    useEffect(() => {
        calculateMaximumNumberOfCards()
        fetchCategories()
    }, [])

    const calculateMaximumNumberOfCards = () => {
        const container = containerRef.current
        const containerWidth = container?.clientWidth
        const shadesCard = shadesCardRef.current
        const shadesCardWidth = shadesCard?.clientWidth
        const padding = Number(container
            ? window.getComputedStyle(container)?.paddingLeft?.replace(/px/gi, "")
            : 0)
        const effectiveContainerWidth = containerWidth - 2 * padding;
        const margin = Number(
            shadesCard
                ? window
                    .getComputedStyle(shadesCard)
                    ?.marginLeft?.replace(/px/gi, "")
                : 0
        );
        const availableWidthForCards = effectiveContainerWidth;
        const numberOfCards = Math.floor(availableWidthForCards / (shadesCardWidth + margin));
        setMaxCards(numberOfCards)
    }

    const fetchCategories = async () => {
        const categoriesData = await ApiService.getCategories()
        if (categoriesData.length > 0) {
            setCategories(categoriesData)
            setSelectedCategory(categoriesData[0])
            fetchShades(categoriesData[0]?.category)
        }
    }

    const fetchShades = async (category) => {
        const shades = []
        const response = await ApiService.getYourShades(category)
        if (response && response.status == 200) {
            const shadesData = response.data
            for (let [index, shade] of shadesData.entries()) {
                let shadeObj = {
                    color: shade.color,
                    colorName: shade.colorName,
                    orderBy: shade.orderBy,
                    categoryKey: shade?.category?.category_key,
                    index: index,
                    id: index + 1,
                    colorSelectionDisabled: true
                }
                shades.push(shadeObj)
            }
            shades.sort((a, b) => a.orderBy - b.orderBy);
            setShades(shades)
            setShadesCopy(shades)
        }
    }

    const handleCategoryOnClick = (category) => {
        setSelectedCategory(category)
        fetchShades(category.category)
        setUpdatedShades([])
        setShadesColorToBeDeleted([])
    }

    const handleAddNewShadeForm = () => {
        const shadesUpdated = JSON.parse(JSON.stringify(updatedShades))
        const shadesData = JSON.parse(JSON.stringify(shades))
        for(let shade of shadesUpdated){
            for(let [index,value] of shadesData.entries()){
                if(shade.id==value.id){
                    shadesData[index]=shade
                }
            }
        }
        setShades([
            ...shadesData,
            {
                color: 'rgb(0,0,0)',
                colorName: '',
                orderBy: shades.length + 1,
                categoryKey: selectedCategory.category,
                index: shades.length,
                id: shades.length + 1,
                colorSelectionDisabled: false
            },
        ]);
    };

    const handleRemoveShade = (index, shadeColor) => {
        const shadesUpdated = JSON.parse(JSON.stringify(shades));
        const updatedShadesArray = [...updatedShades]
        const shadeIndex = updatedShadesArray.findIndex((shade) => shade.id == shadesUpdated[index].id)
        shadesUpdated.splice(index, 1);
        shadesUpdated.forEach((item, ind) => {
            if (index <= ind) {
                item.index -= 1
            }
            updatedShadesArray.map((shade, index) => {
                if (item.id == shade.id) {
                    item.colorName = shade.colorName
                    item.color = shade.color
                }
            })

        });
        if (shadeIndex > -1) {
            updatedShadesArray.splice(shadeIndex, 1)
            setUpdatedShades(updatedShadesArray)
        }
        setShades(shadesUpdated)
        setShadesColorToBeDeleted([...shadesColorToBeDeleted, shadeColor])
    };

    const handleShadeUpdate = (shade) => {
        const shadesUpdated = [...updatedShades]
        const index = shadesUpdated.findIndex((shadeObj) => shadeObj.index == shade.index)
        if (index < 0) {
            shadesUpdated.push(shade)
        } else {
            shadesUpdated[index] = shade
        }
        setUpdatedShades(shadesUpdated)
    }

    const onDragEnd = (sourceIndex, destinationIndex) => {
            const items = [...shades];
            const shadesUpdated = [...updatedShades]
            const [reorderedItem] = items.splice(sourceIndex, 1);
            items.splice(destinationIndex, 0, reorderedItem);
            items.forEach((item, ind) => {
                item.orderBy = ind + 1;
                const index = shadesUpdated.findIndex((shade) => shade.id == item.id)
                if (index < 0) {
                    shadesUpdated.push(item)
                } else {
                    shadesUpdated[index].orderBy = ind + 1
                    shadesUpdated[index].index = ind
                    item.color = shadesUpdated[index].color
                    item.colorName = shadesUpdated[index].colorName
                }
                item.index = ind
            });
            setUpdatedShades(shadesUpdated)
            setShades(items);
    };

    const handleUpdateShades = async () => {
        const shadesUpdated = JSON.parse(JSON.stringify(updatedShades))
        const shadesData = JSON.parse(JSON.stringify(shades))
        for(let shade of shadesUpdated){
            for(let [index,value] of shadesData.entries()){
                if(shade.id==value.id){
                    shadesData[index]=shade
                    shadesData[index].colorSelectionDisabled=true
                }
            }
        }
        setShades(shadesData)
        setShadesCopy(shadesData)
        const newUpdatedShades = JSON.parse(JSON.stringify(updatedShades))
        for (let shade of newUpdatedShades) {
            delete shade.index
            delete shade.id
            delete shade.colorSelectionDisabled
        }
        const params = {
            brandId: ApiService.brandId,
            shades: newUpdatedShades
        }
        setUpdatedShades([])
        const response = await ApiService.upsertShades(params)
        return response
    }

    const handleDisableShades = async () => {
        const params = {
            brandId: ApiService.brandId,
            shades: shadesColorToBeDeleted,
            categoryKey: selectedCategory.category
        }
        setShadesColorToBeDeleted([])
        const response = await ApiService.disableShades(params)
        return response
    }

    const handleSave = async (event) => {
        if (event) {
            event.preventDefault();
        }
        if(showRecommendedShades){
            return
        }
        let response = {}
        if (updatedShades.length > 0) {
            response = await handleUpdateShades()
        }
        if (shadesColorToBeDeleted.length > 0) {
            response = await handleDisableShades()
        }
        if (response && response.status == 200) {
            setShowNotification({ open: true, message: 'Shades saved successfully', type: 'success',hideAutomatically:true })
        } else {
            setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true })
        }
        setShowSaveButton(false)
    }

    const handleRecommendedShades = (value)=>{
        setShowRecommendedShades(value)
    }

    return (
        <div className='h-[95%]'>
            <div className='text-[16px] font-semibold py-[19px]'>
                FIND YOUR SHADES
            </div>
            <div className='flex ml-[25px] pb-[2px] gap-[10px] overflow-x-auto container2 mb-[24px] h-[50px]'>
                {
                    categories.length > 0 && categories.map((category, index) => {
                        return <Categories category={category} key={index} selectedCategory={selectedCategory} handleCategoryOnClick={handleCategoryOnClick} />
                    })
                }
            </div>
            <div className="flex flex-col gap-[30px] w-full min-h-[calc(100%_-_40px)] my-[20px] py-[30px] bg-[#fff]">
                <form onSubmit={(e) => handleSave(e)} ref={containerRef} className='w-full flex flex-col px-[20px]'>
                    {shades.length > 0 ?
                        <ListManager
                            items={shades}
                            direction="horizontal"
                            maxItems={maxCards}
                            render={(item) => (
                                <ShadeCard
                                    data={item}
                                    index={item.index}
                                    handleRemoveShade={handleRemoveShade}
                                    shadesCardRef={shadesCardRef}
                                    handleShadeUpdate={handleShadeUpdate}
                                    handleRecommendedShades={handleRecommendedShades}
                                    setSelectedShadeColorForRecommendedShade={setSelectedShadeColorForRecommendedShade}
                                />
                            )}
                            onDragEnd={onDragEnd}
                        /> :
                        !showSaveButton ? (
                            <div className='mb-[20px]'>
                                No shades found for this category
                            </div>
                        ) : ''
                    }
                    <div className="flex justify-center gap-[40px] w-full">
                        <button onClick={handleAddNewShadeForm} className="text-white bg-black px-[20px] py-[8px] text-center rounded">
                            Add New Shade
                        </button>
                        <div className={`${showSaveButton  ? 'flex' : 'hidden'}`}>
                            <button type='submit' className="text-white bg-black px-[20px] py-[8px] text-center rounded">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div >
            {
                showRecommendedShades ?
                    <RecommendedShadesPopUp handleRecommendedShades={handleRecommendedShades}
                        shadeColor={selectedShadeColorForRecommendedShade} selectedCategory={selectedCategory}
                    /> : ''
            }
        </div >
    )
}

export default FindYourShades


