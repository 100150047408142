import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { ApiService } from '../../services/apiUtility'
import { useNavigate } from 'react-router-dom'
import ReactQuill from 'react-quill'
import { AiFillCloseCircle, AiOutlineCloudUpload } from 'react-icons/ai'
import { MdDeleteForever, MdEdit } from 'react-icons/md'
import SkinAnalysisLanguageCode from './SkinAnalysisLanguageCode'

const defaultTabs = {
    start_screen: [
        { label: "AR Title", placeholder: "Enter AR Title", type: "input", key: "arTitle", defaultValue: "SkinExpert AI" },
        { label: "AR Subtitle", placeholder: "Enter AR Subtitle", type: "input", key: "arSubtitle", defaultValue: "Your journey to Perfect skin Begins here" },
        { label: "Get Started Button Text", placeholder: "Enter 'Get Started Button' Text", type: "input", key: "getStartedButtonText", defaultValue: "Get Started Now" }
    ],
    take_a_picture_screen: [
        { label: "Take Picture Screen Title", placeholder: "Enter 4 Options Title", type: "input", key: "takePictureTitle", defaultValue: "" },
        { label: "Take Picture Screen Description", placeholder: "Enter AR Title", type: "input", key: "takePictureDescription", defaultValue: "" },
        { label: "Take Picture Screen Block 1 Title", placeholder: "Enter Take Picture Screen Block 1 Title", type: "input", key: "takePictureBlock1Title", defaultValue: "" },
        { label: "Take Picture Screen Block 2 Title", placeholder: "Enter Take Picture Screen Block 2 Title", type: "input", key: "takePictureBlock2Title", defaultValue: "" },
        { label: "Take Picture Screen Block 3 Title", placeholder: "Enter Take Picture Screen Block 3 Title", type: "input", key: "takePictureBlock3Title", defaultValue: "" },
        { label: "Take Picture Screen Block 4 Title", placeholder: "Enter Take Picture Screen Block 4 Title", type: "input", key: "takePictureBlock4Title", defaultValue: "" },
        { label: "Take Picture Screen Button Text", placeholder: "Enter 'Take a Picture' Button Text", type: "input", key: "takePictureStartScreenButtonTitle", defaultValue: "" }
    ],
    self_intro_screen: [
        { label: "AR Title", placeholder: "Enter AR Title", type: "input", key: "arTitle", defaultValue: "SkinExpert AI" },
        { label: "Take Picture Screen Subtitle", placeholder: "", type: "quill", key: "takePictureSubtitle", defaultValue: "Assess your skin and get personalised recommendations with advanced <b className=' font-bold'>Artificial Intelligence (AI)</b>" },
        { label: "Informative Options Screen 4 Options Title", placeholder: "Enter 4 Options Title", type: "input", key: "informativeOptionsScreenOptionsTitle", defaultValue: "Get Healthier skin in just 4 steps" },
        { label: "Instruction Block 1 Title", placeholder: "Enter Instruction Block 1 Title", type: "input", key: "informativeOptionsScreenBlock1Title", defaultValue: "Tell us about yourself" },
        { label: "Instruction Block 2 Title", placeholder: "Enter Instruction Block 2 Title", type: "input", key: "informativeOptionsScreenBlock2Title", defaultValue: "Capture your selfie" },
        { label: "Instruction Block 3 Title", placeholder: "Enter Instruction Block 3 Title", type: "input", key: "informativeOptionsScreenBlock3Title", defaultValue: "Analyse your skin" },
        { label: "Instruction Block 4 Title", placeholder: "Enter Instruction Block 4 Title", type: "input", key: "informativeOptionsScreenBlock4Title", defaultValue: "Recommended products" },
        { label: "Start Skin Analysis Button Text", placeholder: "Enter 'Start Skin Analysis' Button Text", type: "input", key: "informativeOptionsScreenButtonTitle", defaultValue: "START SKIN ANALYSIS" }
    ],
    questionnaire_screen: [
        { label: "Questionnaire Screen Title", placeholder: "Enter Questionnaire Screen Title", type: "input", key: "questionnaireScreenTitle", defaultValue: "About yourself" },
        { label: "Questionnaire Screen Subtitle", placeholder: "", type: "quill", key: "questionnaireScreenSubtitle", defaultValue: "SkinExpert AI needs this info to create <br /> personalised results for you </>" }
    ],
    aR_screen: [
        { label: "Capture Button Text", placeholder: "Enter Capture Button Text", type: "input", key: "arScreenCaptureButton", defaultValue: "Capture" },
        { label: "Retake Button Text", placeholder: "Enter Retake Button Text", type: "input", key: "arScreenRetakeButton", defaultValue: "Retake" },
        { label: "Analyze Button Text", placeholder: "Enter Analyze Button Text", type: "input", key: "arScreenAnalyzeButton", defaultValue: "Analyze" },
        { label: "Incorrect Positioning Error Text", placeholder: "Enter Analyze Button Text", type: "input", key: "arScreenIncorrectErrorText", defaultValue: "For best results, adjust lighting and position for all factors to turn green" }
    ],
    analyze_screen: [
        { label: "Analyze Screen Loading Text", placeholder: "Enter Analyze Screen Loading Text", type: "input", key: "analyzeScreenLoadingText", defaultValue: "Analysing your skin..." }
    ],
    result_screen: [
        { label: "Recommendations Button Text", placeholder: "Enter Recommendations Button Text", type: "input", key: "resultScreenRecommendationButton", defaultValue: "Recommendations" },
        { label: "Finish Button Text", placeholder: "Enter Finish Button Text", type: "input", key: "resultScreenFinishButton", defaultValue: "Finish" },
        { label: "Get Recommendation Button Text", placeholder: "Enter Get Recommendation Button Text", type: "input", key: "resultScreenGetRecommendationButton", defaultValue: "Get Recommendations" },
        { label: "Email Report Button Text", placeholder: "Enter Email Report Button Text", type: "input", key: "resultScreenEmailReportButton", defaultValue: "Email Report" }
    ],
    recommendation_screen: [
        { label: "Recommendations Title", placeholder: "Enter Recommendations Button Text", type: "input", key: "recommendationScreenTitle", defaultValue: "Recommendations" },
        { label: "Recommendations Subtitle", placeholder: "Enter Recommendations Subtitle Text", type: "input", key: "recommendationScreenSubtitle", defaultValue: "Based on the analysis of your skin, here are some recommendations for you" },
        { label: "Check Scores Button Text", placeholder: "Enter Finish Button Text", type: "input", key: "resultScreenCheckScoresButton", defaultValue: "Check Scores" },
        { label: "Finish Button Text", placeholder: "Enter Finish Button Text", type: "input", key: "resultScreenFinishButton", defaultValue: "Finish" }
    ],
    thank_your_screen: [
        { label: "Thank You Screen Title", placeholder: "Enter Thank You Screen Title Text", type: "input", key: "thankYouScreenTitle", defaultValue: "Thank you!" },
        { label: "Thank You Screen Subtitle", placeholder: "Enter Thank You Screen Subtitle Text", type: "input", key: "thankYouScreenSubtitle", defaultValue: "We’ll email you our recommended skincare solutions with a discount code from our trusted brands" },
        { label: "Thank You Screen Button Text", placeholder: "Enter Thank You Screen Button Text", type: "input", key: "thankYouScreenButtonText", defaultValue: "Continue" },
    ],
    feedback_screen: [
        { label: "Feedback Screen Title 1", placeholder: "Enter Feedback Screen Title 1 Text", type: "input", key: "feedbackScreenTitle1", defaultValue: "Do you want to leave this page?" },
        { label: "Feedback Screen Title 2", placeholder: "Enter Feedback Screen Title 2 Text", type: "input", key: "feedbackScreenTitle2", defaultValue: "This will exit the current experience." },
        { label: "Feedback Screen Title 3", placeholder: "Enter Feedback Screen Title 3 Text", type: "input", key: "feedbackScreenTitle3", defaultValue: "Before you leave, rate your experience" },
        { label: "Feedback Screen Stay Here Button", placeholder: "Enter Feedback Screen Stay Here Button", type: "input", key: "feedbackScreenStayHereButton", defaultValue: "Stay here" },
        { label: "Feedback Screen Ratings Toggle", type: "radio", key: "feedbackScreenRadioToggle", defaultValue: true },
        { label: "Feedback Screen Submit & Leave", placeholder: "Enter Feedback Screen Submit & Leave", type: "input", key: "feedbackScreenLeaveButton", defaultValue: "Submit & Leave" },
        { label: "Feedback Screen Submit & Leave Redirect URL", placeholder: "Enter Feedback Screen Submit & Leave Redirect URL", type: "URL", key: "feedbackScreenLeaveRedirectURL", defaultValue: "" },
    ],
}

const SkinAnalysis = () => {
    const { brandSettings, setShowNotification, setBrandSettings } = useContext(AppContext)
    const [authenticationType, setAuthenticationType] = useState(0)
    const [recommendationToggle, setRecommendationToggle] = useState(false)
    const [apiDemoToggle, setApiDemoToggle] = useState(true)
    const [recommendationUrlError, setRecommendationUrlError] = useState(null)
    const [currencyCode, setCurrencyCode] = useState(null)
    const navigate = useNavigate()
    const [recommendationUrl, setRecommendationUrl] = useState('')
    const [disclaimer, setDisclaimer] = useState("")
    const [disclaimerHtmlBody, setDisclaimerHtmlBody] = useState(null)
    const [reportToBeEmailedToggle, setReportToBeEmailedToggle] = useState(false)

    const [bccEmails, setBccEmails] = useState([])

    const [affiliatesPartners, setAffiliatesPartners] = useState([])
    const [affiliateInputPopup, setAffiliateInputPopup] = useState(false)
    const [bccEmailPopup, setBccEmailPopup] = useState(false)
    const [singleAffiliateJson, setSingleAffiliateJson] = useState({})
    const [editAffiliate, setEditAffiliate] = useState(false)

    const [customizedData, setCustomizedData] = useState({})

    const [tabList, setTabList] = useState(defaultTabs)

    const [selectedOption, setSelectedOption] = useState(Object.keys(tabList)[0])

    const recommendationToggleObj = {
        label: 'Recommendation', variable: recommendationToggle, id: 'recommendation-url'
    }
    const reportToBeEmailedToggleObj = {
        label: 'Report to be Emailed', variable: reportToBeEmailedToggle, id: 'report-to-be-emailed'
    }

    useEffect(() => {
        if (Object.keys(brandSettings || {}).length) {
            setRecommendationUrl(brandSettings?.setting_meta_json?.skinAnalysis?.recommendationUrl ? brandSettings.setting_meta_json.skinAnalysis.recommendationUrl : '')
            setAuthenticationType(brandSettings?.setting_meta_json?.skinAnalysis?.authenticationType ? brandSettings.setting_meta_json.skinAnalysis.authenticationType : 1)
            setDisclaimer(brandSettings?.setting_meta_json?.skinAnalysis?.disclaimer ? brandSettings?.setting_meta_json?.skinAnalysis?.disclaimer == "" ? "Note : The information provided is for general educational purposes only and should not be used for professional medical advice." : brandSettings.setting_meta_json.skinAnalysis.disclaimer : "Note : The information provided is for general educational purposes only and should not be used for professional medical advice.")
            setRecommendationToggle(brandSettings?.setting_meta_json?.skinAnalysis?.recommendationToggle)
            setReportToBeEmailedToggle(brandSettings?.setting_meta_json?.skinAnalysis?.reportToBeEmailedToggle)
            setCustomizedData(brandSettings?.setting_meta_json?.skinAnalysis?.dynamicContent || {})
            setAffiliatesPartners(brandSettings?.setting_meta_json?.skinAnalysis?.thankYouScreenBrands || [])
            setCurrencyCode(brandSettings?.setting_meta_json?.skinAnalysis?.currencyCode || null)
            setDisclaimerHtmlBody(brandSettings?.setting_meta_json?.skinAnalysis?.disclaimerHtmlBody || "<p><br></p>")
            setApiDemoToggle(brandSettings?.setting_meta_json?.skinAnalysis?.apiDemoToggle === "undefined" ? true : brandSettings?.setting_meta_json?.skinAnalysis?.apiDemoToggle)
            setBccEmails(brandSettings?.setting_meta_json?.skinAnalysis?.bccEmails || [])
        }
        if (brandSettings?.setting_meta_json?.skinAnalysis?.authenticationType) {
            switch (brandSettings?.setting_meta_json?.skinAnalysis?.authenticationType) {
                case "1":
                    setTabList(prevVal => {
                        return {
                            ...defaultTabs,
                            start_screen_authentication: [
                                { label: "Authentication Label", placeholder: "Enter Authentication Label", type: "input", key: "startScreenAuthenticationLabel", defaultValue: "Enter your name and email to get detailed report." },
                                { label: "Input Name Placeholder", placeholder: "Enter Input Name Placeholder", type: "input", key: "startScreenAuthenticationNamePlaceholder", defaultValue: "Enter your name" },
                                { label: "Input Email Placeholder", placeholder: "Enter Input Email Placeholder", type: "input", key: "startScreenAuthenticationEmailPlaceholder", defaultValue: "Enter your Email" },
                            ]
                        }
                    })
                    break
                case "2":
                    setTabList(prevVal => {
                        return {
                            ...defaultTabs,
                            end_screen_authentication: [
                                { label: "Authentication Text Line 1", placeholder: "Enter Authentication Text Line 1", type: "input", key: "endScreenAuthenticationDescriptionLine1", defaultValue: "Check your full scores" },
                                { label: "Authentication Text Line 2", placeholder: "Enter Authentication Text Line 2", type: "input", key: "endScreenAuthenticationDescriptionLine2", defaultValue: "Enter your name and email to see the scores" },
                                { label: "Input Name Placeholder", placeholder: "Enter Input Name Placeholder", type: "input", key: "endScreenAuthenticationNamePlaceholder", defaultValue: "Enter your name" },
                                { label: "Input Email Placeholder", placeholder: "Enter Input Email Placeholder", type: "input", key: "endScreenAuthenticationEmailPlaceholder", defaultValue: "Enter your email" },
                                { label: "Continue Button Text", placeholder: "Enter Continue Button Text", type: "input", key: "endScreenAuthenticationContinueText", defaultValue: "Continue" },
                            ]
                        }
                    })
                    break
                default:
                    setTabList({ ...defaultTabs })
                    break
            }
        }

    }, [brandSettings])

    const isValidUrl = () => {
        if (recommendationUrl != '') {
            const urlPattern = new RegExp('^(https?:\\/\\/)' + // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
                '(\\#[-a-z\\d_]*)?$', 'i');
            if (!urlPattern.test(recommendationUrl)) {
                setRecommendationUrlError('Invalid url')
                return false
            }
        }
        return true
    }

    const handleSave = () => {
        if (!isValidUrl()) {
            return
        }
        setBrandSettings((brandSettings) => {
            const settings = { ...brandSettings.setting_meta_json }
            settings['skinAnalysis'] = {
                authenticationType: authenticationType,
                recommendationUrl: recommendationToggle ? recommendationUrl : "",
                recommendationToggle: recommendationToggle,
                apiDemoToggle: apiDemoToggle,
                disclaimer: disclaimer,
                disclaimerHtmlBody: disclaimerHtmlBody === "<p><br></p>" ? null : disclaimerHtmlBody,
                reportToBeEmailedToggle: authenticationType == '0' ? false : reportToBeEmailedToggle,
                dynamicContent: customizedData || {},
                thankYouScreenBrands: affiliatesPartners,
                currencyCode: currencyCode,
                bccEmails: bccEmails
            }

            const params = {
                brandId: ApiService.brandId,
                setting: {
                    json: settings
                }
            }
            ApiService.updateSettings(params).then(res => {
                if (res && res.status == 200) {
                    setShowNotification({ open: true, message: 'Changes saved successfully', type: 'success', hideAutomatically: true })
                } else if (res && res.status == 403) {
                    localStorage.clear()
                    navigate('/authentication/sign-in')
                    setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error', hideAutomatically: true })
                } else {
                    setShowNotification({ open: true, message: res.message, type: 'error', hideAutomatically: true })
                }
            })
            brandSettings.setting_meta_json = settings
            return { ...brandSettings }
        })
    }


    const createDynamicContentDiv = (label, placeholder, key, type, defaultValue) => {
        switch (type) {
            case "quill":
                return <div className='flex flex-col mt-[25px] gap-[5px]'>
                    <span className="min-w-[20%]">{label}</span>
                    <ReactQuill
                        defaultValue={customizedData?.[key] || defaultValue}
                        className='mt-1 w-full'
                        modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline']
                            ],
                        }}
                        placeholder={placeholder}
                        onChange={e => changeCustomizedData(key, e, type)}
                    />
                </div>
            case "radio":
                return <div className="flex mt-[25px] items-center gap-[25px]">
                    <label
                        htmlFor={label?.replace(" ", "_")}
                        className="cursor-pointer min-w-[20%] select-none"
                    >
                        {label}
                    </label>
                    <div className="relative inline-block h-4 w-8 cursor-pointer rounded-full">
                        <input
                            id={label?.replace(" ", "_")}
                            type="checkbox"
                            defaultChecked={customizedData?.[key] || defaultValue}
                            onChange={e => changeCustomizedData(key, e.target.checked, type)}
                            className={`${customizedData?.[key] ? 'bg-gray-500' : 'bg-gray-400'} peer absolute h-4 w-8 cursor-pointer appearance-none rounded-full  transition-colors duration-300`}
                        />
                        <label
                            htmlFor={label?.replace(" ", "_")}
                            className={`${customizedData?.[key] ? 'bg-black' : 'bg-white'} before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100  shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity peer-checked:translate-x-full peer-checked:border-gray-500 peer-checked:before:bg-gray-500`}
                        >
                            <div
                                className="top-2/4 left-2/4 inline-block -translate-x-2/4 -translate-y-2/4 rounded-full p-5"
                                data-ripple-dark="true"
                            ></div>
                        </label>
                    </div>
                </div>
            case "URL":
                return <div className='flex items-center mt-[25px] gap-[20px]'>
                    <span className="min-w-[20%]">{label}</span>
                    <input
                        type="text"
                        placeholder={placeholder}
                        className="h-[35px] bg-white border-[1px] border-black text-[14px] w-1 flex-grow rounded p-4 outline-none"
                        defaultValue={customizedData?.[key] || defaultValue}
                        onChange={(event) => {
                            if (!isURL(event?.target?.value)) {
                                event.target.style.borderColor = "red"
                                return
                            } else {
                                event.target.style.borderColor = "black"
                                changeCustomizedData(key, event.target.value)
                            }
                        }}
                    />
                </div>

            case "input":
            default:
                return <div className='flex items-center mt-[25px] gap-[20px]'>
                    <span className="min-w-[20%]">{label}</span>
                    <input
                        type="text"
                        placeholder={placeholder}
                        className="h-[35px] bg-white border-[1px] border-black text-[14px] w-1 flex-grow rounded p-4"
                        defaultValue={customizedData?.[key] || defaultValue}
                        onChange={(event) => {
                            changeCustomizedData(key, event.target.value)
                        }}
                    />
                </div>
        }
    }

    const changeCustomizedData = (key, value, type) => {
        let term = value
        // if (typeof term === "string" && (type === "input" || type === "URL" || !type)) {
        //     term = value.replace(/\s+/g, ' ').trim()
        // }

        if (term || type === "radio") {
            setCustomizedData(prevValue => {
                return {
                    ...prevValue,
                    [key]: term
                }
            })
        } else {
            setCustomizedData(prevValue => {
                const data = prevValue
                delete data[key]
                return data
            })
        }
    }

    const isURL = (url) => {
        const urlPattern = new RegExp('^(https?:\\/\\/)' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i');

        return urlPattern.test(url)
    }

    const addAPartner = async () => {
        if (!singleAffiliateJson?.image || !singleAffiliateJson?.url) return
        // if (!isURL(singleAffiliateJson.url)) return
        if (editAffiliate) {
            setAffiliatesPartners(prevValue => {
                prevValue[singleAffiliateJson?.index] = {
                    image: singleAffiliateJson.image,
                    url: singleAffiliateJson?.url
                }
                return prevValue
            })
        } else {
            setAffiliatesPartners(prevValue => {
                return [...prevValue, {
                    image: singleAffiliateJson.image,
                    url: singleAffiliateJson?.url
                }]
            })
        }
        closePartnerPopup()
    }

    const deletePartner = (item) => {
        const array = affiliatesPartners
        const index = affiliatesPartners.findIndex((partner) => partner.image === item.image && partner.url === item.url)
        array.splice(index, 1)
        setAffiliatesPartners([...array])
        return
    }

    const editPartner = (item, index) => {
        setSingleAffiliateJson({ ...item, index })
        setEditAffiliate(true)
        setAffiliateInputPopup(true)
    }

    const closePartnerPopup = () => {
        setEditAffiliate(false)
        setAffiliateInputPopup(false)
        setSingleAffiliateJson({})
    }

    const changeImage = async (file) => {
        if (!file) return null
        const formData = new FormData();
        formData.append("file", file);
        const result = await ApiService.getUploadedImageUrl(formData)
        return result
    }

    const addEmailToArray = (json) => {
        setBccEmails(prevVal => {
            return [...prevVal, json]
        })
    }

    const removeEmailFromArray = (item) => {
        const filteredArr = bccEmails.filter((emailObj) => emailObj.email !== item.email)
        setBccEmails(filteredArr)
    }

    const closeBccEmailPopup = () => {
        setBccEmailPopup(false)
    }

    return (
        <div className='flex flex-col gap-[30px] items-center w-full min-h-[calc(100%_-_40px)] my-[20px] py-[30px] bg-[#fff]'>
            <div className='py-[20px] flex flex-col items-center justify-center gap-[20px] w-full'>
                <div className='border-[1px] rounded flex flex-col p-[20px] w-[80%] gap-[25px]'>
                    <div>
                        <label>Authentication Type</label>
                        <select value={authenticationType} onChange={(e) => setAuthenticationType(e?.target?.value)} className='mt-[10px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                            <option value='0'>No authentication</option>
                            <option value='1'>Authentication at startup</option>
                            <option value='2'>Authentication at end</option>
                        </select>
                    </div>
                    <div className="flex justify-between items-center">
                        <label
                            htmlFor="api-demo-toggle"
                            className="cursor-pointer select-none"
                        >
                            Test Mode
                        </label>
                        <div className="relative inline-block h-4 w-8 cursor-pointer rounded-full">
                            <input
                                id="api-demo-toggle"
                                type="checkbox"
                                checked={apiDemoToggle}
                                onChange={(e) => {
                                    setApiDemoToggle(e.target.checked)
                                }}
                                className={`${apiDemoToggle ? 'bg-gray-500' : 'bg-gray-400'} peer absolute h-4 w-8 cursor-pointer appearance-none rounded-full  transition-colors duration-300`}
                            />
                            <label
                                htmlFor="api-demo-toggle"
                                className={`${apiDemoToggle ? 'bg-black' : 'bg-white'} before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100  shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity peer-checked:translate-x-full peer-checked:border-gray-500 peer-checked:before:bg-gray-500`}
                            >
                                <div
                                    className="top-2/4 left-2/4 inline-block -translate-x-2/4 -translate-y-2/4 rounded-full p-5"
                                    data-ripple-dark="true"
                                ></div>
                            </label>
                        </div>
                    </div>

                    <div className='flex flex-col gap-[10px]'>
                        <div className="flex justify-between items-center">
                            <label
                                htmlFor={recommendationToggleObj.id}
                                className="cursor-pointer select-none"
                            >
                                {recommendationToggleObj.label}
                            </label>
                            <div className="relative inline-block h-4 w-8 cursor-pointer rounded-full">
                                <input
                                    id={recommendationToggleObj.id}
                                    type="checkbox"
                                    checked={recommendationToggleObj.variable}
                                    onChange={() => {
                                        if (recommendationToggle) {
                                            setRecommendationUrl('')
                                        }
                                        setRecommendationToggle(!recommendationToggle)
                                    }}
                                    className={`${recommendationToggleObj.variable ? 'bg-gray-500' : 'bg-gray-400'} peer absolute h-4 w-8 cursor-pointer appearance-none rounded-full  transition-colors duration-300`}
                                />
                                <label
                                    htmlFor={recommendationToggleObj.id}
                                    className={`${recommendationToggleObj.variable ? 'bg-black' : 'bg-white'} before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100  shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity peer-checked:translate-x-full peer-checked:border-gray-500 peer-checked:before:bg-gray-500`}
                                >
                                    <div
                                        className="top-2/4 left-2/4 inline-block -translate-x-2/4 -translate-y-2/4 rounded-full p-5"
                                        data-ripple-dark="true"
                                    ></div>
                                </label>
                            </div>
                        </div>
                        {
                            recommendationToggle ?
                                <>
                                    <div className='flex items-center mt-[25px] gap-[20px] ml-[30px]'>
                                        <span>Recommendation URL:</span>
                                        <input
                                            type="text"
                                            placeholder="Enter Recommendation url"
                                            className="h-[35px] bg-white border-[1px] border-black text-[14px] w-[60%] rounded p-4"
                                            required
                                            value={recommendationUrl}
                                            onChange={(event) => {
                                                setRecommendationUrlError(null)
                                                setRecommendationUrl(event.target.value)
                                            }}
                                        />
                                        {
                                            recommendationUrlError ?
                                                <label className="label">
                                                    <span className="label-text-alt text-red-500">{recommendationUrlError}</span>
                                                </label> : ''
                                        }
                                    </div>
                                    <div className='flex flex-col mt-[25px] gap-[20px] ml-[30px]'>
                                        <span>Currency Denomination:</span>
                                        <SkinAnalysisLanguageCode setCurrencyCode={setCurrencyCode} currencyCode={currencyCode} />
                                        <div className='flex items-center gap-[20px] ml-[30px]'>
                                            <span>Custom Currency Code</span>
                                            <input
                                                type="text"
                                                placeholder="Enter Current Code"
                                                className="h-[35px] bg-white border-[1px] border-black text-[14px] w-[60%] rounded p-4"
                                                required
                                                value={currencyCode}
                                                onChange={(e) => {
                                                    setCurrencyCode(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''
                        }
                    </div>
                    <div className='flex flex-col gap-[20px] w-full'>
                        <div className='flex flex-col w-full'>
                            <div>
                                <span>Disclaimer <span className='text-[12px]'>(Maximum characters limit is 150)</span></span>
                                <div>
                                    <textarea
                                        rows="4" cols="50"
                                        type="text"
                                        placeholder=""
                                        className="mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                                        required
                                        maxLength={150}
                                        value={disclaimer}
                                        onChange={(event) => {
                                            setDisclaimer(event.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[20px] w-full'>
                        <div className='flex flex-col w-full'>
                            <div>
                                <span>Consent Information and Link</span>
                                {
                                    Object.values(brandSettings || {}).length && disclaimerHtmlBody
                                        ? <ReactQuill
                                            defaultValue={disclaimerHtmlBody}
                                            className='mt-1 w-full'
                                            placeholder='Enter Consent Information'
                                            modules={{
                                                toolbar: [
                                                    ['bold', 'italic', 'underline'],
                                                    ["link"]
                                                ],
                                            }}
                                            onChange={e => setDisclaimerHtmlBody(e)}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    {authenticationType && authenticationType != 0
                        ? <>
                            <div className="flex justify-between items-center">
                                <label
                                    htmlFor={reportToBeEmailedToggleObj.id}
                                    className="cursor-pointer select-none"
                                >
                                    {reportToBeEmailedToggleObj.label}
                                </label>
                                <div className="relative inline-block h-4 w-8 cursor-pointer rounded-full">
                                    <input
                                        id={reportToBeEmailedToggleObj.id}
                                        type="checkbox"
                                        checked={reportToBeEmailedToggleObj.variable}
                                        onChange={() => {
                                            setReportToBeEmailedToggle(!reportToBeEmailedToggle)
                                        }}
                                        className={`${reportToBeEmailedToggleObj.variable ? 'bg-gray-500' : 'bg-gray-400'} peer absolute h-4 w-8 cursor-pointer appearance-none rounded-full  transition-colors duration-300`}
                                    />
                                    <label
                                        htmlFor={reportToBeEmailedToggleObj.id}
                                        className={`${reportToBeEmailedToggleObj.variable ? 'bg-black' : 'bg-white'} before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100  shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity peer-checked:translate-x-full peer-checked:border-gray-500 peer-checked:before:bg-gray-500`}
                                    >
                                        <div
                                            className="top-2/4 left-2/4 inline-block -translate-x-2/4 -translate-y-2/4 rounded-full p-5"
                                            data-ripple-dark="true"
                                        ></div>
                                    </label>
                                </div>
                            </div>
                            {reportToBeEmailedToggle
                                ? <>
                                    <div className='p-3 border border-grey rounded w-full'>
                                        <p className='my-[10px]  font-semibold'>Email Addresses to Receive User Email</p>
                                        <div className='flex flex-wrap justify-center gap-x-[15px] gap-y-2 mb-3'>
                                            {
                                                bccEmails?.length
                                                    ? bccEmails?.map((item, index) => (
                                                        <div key={index} className='py-2 px-4 w-[calc(50%-10px)] border rounded-lg flex gap-3 items-center relative'>
                                                            <div className='flex absolute right-0 top-0 -translate-y-1/2'>
                                                                <MdDeleteForever
                                                                    className=' cursor-pointer'
                                                                    onClick={() => removeEmailFromArray(item)}
                                                                />
                                                                {/* <MdEdit
                                                                        className=' cursor-pointer'
                                                                        onClick={() => editPartner(item, index)}
                                                                    /> */}
                                                            </div>
                                                            <p className='w-1/4 truncate' title={item.name}>{item.name}</p>
                                                            <p className='flex-1 w-1 truncate'>{item.email}</p>
                                                        </div>
                                                    ))
                                                    : <div>No Emails Found</div>
                                            }
                                        </div>
                                        <button className='flex items-center p-4 border border-black rounded-lg w-fit' onClick={() => setBccEmailPopup(true)}>
                                            Add a Email
                                        </button>
                                    </div>
                                    {bccEmailPopup
                                        ? <BccEmailPopup bccEmails={bccEmails} addAEmail={addEmailToArray} closePopup={closeBccEmailPopup} />
                                        : null
                                    }
                                </>
                                : null
                            }
                        </>
                        : ''
                    }
                    <div className='p-3 border border-grey rounded'>
                        <p className='my-[10px]  font-semibold'>Dynamic Screen Text</p>
                        <select className='capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                            onChange={e => setSelectedOption(e.target.value)}
                        >
                            {
                                Object.keys(tabList).map((item, index) => (
                                    <option value={item} key={index} className=' capitalize'>{item?.replaceAll("_", " ")}</option>
                                ))
                            }
                        </select>
                        <div key={selectedOption}>
                            {
                                tabList?.[selectedOption]?.map(({ label, placeholder, key, type, defaultValue }) => createDynamicContentDiv(label, placeholder, key, type, defaultValue))
                            }
                        </div>
                    </div>
                    <div className='p-3 border border-grey rounded w-full'>
                        <p className='my-[10px]  font-semibold'>Thank You Screen Affiliates</p>
                        <div className="flex flex-wrap justify-center gap-x-[15px] gap-y-2">
                            {
                                affiliatesPartners?.map((item, index) => (
                                    <div key={index} className='py-2 px-4 w-[calc(50%-10px)] border rounded-lg flex gap-3 items-center relative'>
                                        <div className='flex absolute right-0 top-0 -translate-y-1/2'>
                                            <MdDeleteForever
                                                className=' cursor-pointer'
                                                onClick={() => deletePartner(item)}
                                            />
                                            <MdEdit
                                                className=' cursor-pointer'
                                                onClick={() => editPartner(item, index)}
                                            />
                                        </div>

                                        <img src={item?.image} alt='' className='w-[100px] object-contain' />
                                        <a href={item?.url} target='_target' title={item?.url} className='truncate'>{item?.url}</a>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            affiliatesPartners.length < 8
                                ? <button className='flex items-center mt-4 p-4 border border-black rounded-lg w-fit' onClick={() => setAffiliateInputPopup(true)}>
                                    Add a Partner
                                </button>
                                : null
                        }
                        {
                            affiliateInputPopup
                                ? <div className='fixed w-full h-full left-0 top-0 bg-[rgba(0,0,0,0.4)]'>
                                    <div className='absolute w-3/5 h-3/5 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white py-3 px-4 rounded-lg z-10 flex flex-col justify-evenly items-center'>
                                        <AiFillCloseCircle
                                            className='w-8 h-8 absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 bg-white rounded-full cursor-pointer'
                                            onClick={() => closePartnerPopup()}
                                        />
                                        <div className='flex w-full gap-7 justify-evenly'>
                                            {
                                                singleAffiliateJson?.image
                                                    ? <img className='w-1 flex-grow object-contain object-left' src={singleAffiliateJson?.image} alt='' />
                                                    : null
                                            }
                                            <label htmlFor='image-upload' className='flex cursor-pointer gap-3 items-center content-center w-1/2 justify-center rounded h-auto px-5 py-8 border-[1px] border-black flex-wrap'>
                                                {
                                                    singleAffiliateJson?.image
                                                        ? <span>Click to Update Image</span>
                                                        : <span>Upload Image</span>
                                                }
                                                <AiOutlineCloudUpload className='w-6 h-6' />
                                                <input id='image-upload' type='file' accept="image/png, image/jpeg" hidden
                                                    onChange={async e => {
                                                        const result = await changeImage(e?.target?.files?.[0])
                                                        setSingleAffiliateJson(prevValue => {
                                                            return {
                                                                ...prevValue,
                                                                image: result
                                                            }
                                                        })
                                                    }}
                                                />
                                            </label>
                                        </div>

                                        <div className='flex gap-3 w-full'>
                                            <label htmlFor='image-url' className='flex gap-3 items-center'>Redirect URL for the Image</label>
                                            <input
                                                id='image-url' type='text'
                                                defaultValue={singleAffiliateJson?.url}
                                                className="h-[35px] bg-white border-[1px] border-black text-[14px] w-1 flex-grow rounded p-4 outline-none"
                                                onChange={e => {
                                                    if (!isURL(e?.target?.value)) {
                                                        e.target.style.borderColor = "red"
                                                        return
                                                    } else {
                                                        e.target.style.borderColor = "black"
                                                        setSingleAffiliateJson(prevValue => {
                                                            return {
                                                                ...prevValue,
                                                                url: e?.target?.value
                                                            }
                                                        })
                                                    }

                                                }}
                                            />
                                        </div>
                                        <button type='button' className='py-2 px-8 border border-black rounded' onClick={addAPartner}>Done</button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className='w-full flex justify-center'>
                    <button className='bg-black text-[14px] text-white px-[15px] py-[5px] rounded' onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </div >
    )
}

const BccEmailPopup = ({
    bccEmails = [],
    addAEmail = () => { },
    closePopup = () => { }
}) => {
    const [singleBccEmailJson, setSingleBccEmailJson] = useState({})

    function validateEmail(email) {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const index = bccEmails.findIndex((emailObj) => emailObj.email === email) // To check if the email is not duplicate
        console.log(index, bccEmails);
        if (regex.test(email) && index === -1) {
            return true
        }
        return false;
    }

    const submitEmailForm = () => {
        if (!singleBccEmailJson?.name || !singleBccEmailJson?.email) return
        addAEmail(singleBccEmailJson)
        closePopup()
    }

    return (
        <div>
            <div className='fixed w-full h-full left-0 top-0 bg-[rgba(0,0,0,0.4)]'>
                <div className='absolute w-3/5 max-h-[60%] min-h-[30%] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white py-3 px-4 rounded-lg z-10 flex flex-col justify-evenly items-center'>
                    <AiFillCloseCircle
                        className='w-8 h-8 absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 bg-white rounded-full cursor-pointer'
                        onClick={closePopup}
                    />
                    <div className='flex gap-3 w-full'>
                        <label htmlFor='bcc-name' className='flex gap-3 items-center'>Name of the user</label>
                        <input
                            id='bcc-name' type='text'
                            defaultValue={singleBccEmailJson?.url}
                            className="h-[35px] bg-white border-[1px] border-black text-[14px] w-1 flex-grow rounded p-4 outline-none"
                            onChange={e => {
                                if (!e?.target?.value) {
                                    e.target.style.borderColor = "red"
                                    return
                                } else {
                                    e.target.style.borderColor = "black"
                                    setSingleBccEmailJson(prevValue => {
                                        return {
                                            ...prevValue,
                                            name: e?.target?.value
                                        }
                                    })
                                }

                            }}
                        />
                    </div>
                    <div className='flex gap-3 w-full'>
                        <label htmlFor='bcc-email' className='flex gap-3 items-center'>Email Address</label>
                        <input
                            id='bcc-email' type='text'
                            defaultValue={singleBccEmailJson?.url}
                            className="h-[35px] bg-white border-[1px] border-black text-[14px] w-1 flex-grow rounded p-4 outline-none"
                            onChange={e => {
                                if (!validateEmail(e?.target?.value)) {
                                    e.target.style.borderColor = "red"
                                    return
                                } else {
                                    e.target.style.borderColor = "black"
                                    setSingleBccEmailJson(prevValue => {
                                        return {
                                            ...prevValue,
                                            email: e?.target?.value
                                        }
                                    })
                                }

                            }}
                        />
                    </div>
                    <button type='button' className='py-2 px-8 border border-black rounded' onClick={submitEmailForm}>Done</button>
                </div>
            </div>
        </div>
    )
}

export default SkinAnalysis