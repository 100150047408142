import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { BsExclamationTriangle } from "react-icons/bs";
import { AiOutlineCheck,AiOutlineCloseCircle } from "react-icons/ai";

const NotificationBar = ({message,type}) => {
    const {setShowNotification,showNotification} = useContext(AppContext)
    useEffect(()=>{
        if(showNotification?.hideAutomatically){
            setTimeout(() => {
                setShowNotification({
                    open:false,
                    message:'',
                    type:'',
                    hideAutomatically:true
                })
            }, 8000)
        }
    })

    const hideNotification = ()=>{
        setShowNotification({
            open:false,
            message:'',
            type:'',
            hideAutomatically:true
        })
    }
    return (
        <div className='z-10 w-full flex absolute justify-center top-[15px]'>
            <div className={`text-[15px] rounded flex items-center font-medium shadow-md h-[55px] relative`}>
                <div className='absolute top-[2px] right-[5px] cursor-pointer mb-[5px]' onClick={hideNotification}>
                    <AiOutlineCloseCircle size={22} color={'white'}/>
                </div>
                <div className={`${type=='success'?'bg-[#43ac6a]':'bg-[#C20000]'} rounded-l h-full w-[35px] flex items-center justify-center`}>
                    {
                        type=='success'?(<AiOutlineCheck color='white' strokeWidth='100px' size='17'/>)
                        :<BsExclamationTriangle color='white' size='22'/>
                    }
                </div>
                <div className={`${type=='success'?'bg-[#B0DB7D]':'bg-[#E35335]'} h-full flex items-center px-[35px] justify-center text-white rounded-r min-w-[250px]`}>
                    {message}
                </div>
                
            </div>
        </div>

    )
}

export default NotificationBar