import React,{useEffect,useContext,useState} from  'react'
import { AppContext } from '../../../context/AppContextProvider';
import { ApiService } from '../../../services/apiUtility'
import dayjs from 'dayjs';
import LineChart from './BarChart'
import LinearLoader2 from '../../Loader/LinearLoader2';
import AnalyticsOptionsDropDown from './AnalyticsOptionsDropDown'

const Analytics = () => {
    const {showNotification,setShowNotification} = useContext(AppContext)
    const [analyticsOptions,setAnalyticsOptions] = useState('totals')
    const [timePeriodType,setTimePeriodType] = useState('1')
    const [dateStart,setDateStart] = useState(dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
    const [dateEnd,setDateEnd] = useState(dayjs().format('YYYY-MM-DD'))
    const [chartData,setChartData] = useState({
        'ui-element-clicked':{},
        'user-photo-take':{},
        // 'ui-element-clicked':{},
        // 'makeup-selected':{},
        // 'makeup-applied':{},
        // 'makeup-removed':{},
        // 'mirrar-beautyAR-loaded':{},
        // 'mirrar-beautyAR-close':{},
    })
    const [loader,setShowLoader] = useState(false)
    const [firstTimeLoad,setFirstTimeLoad] = useState(true)

    const handleAnalyticsOption = (type) =>{
        setAnalyticsOptions(type)
    }

    const handleTimePeriodType = (type) =>{
        setTimePeriodType(type)
    }

    const handleDateStart = (date)=>{
        const startDate =dayjs(date).format('YYYYMMDD')
        const endDate = dayjs(dateEnd).format('YYYYMMDD') 
        if((startDate>endDate)){
            setShowNotification({open:true,message:'Invalid date range',type:'error',hideAutomatically:true})
        }else{
            setDateStart(date)
        }
    }

    const handleDateEnd = (date)=>{
        const endDate = dayjs(date).format('YYYYMMDD') 
        const currentDate=dayjs().format('YYYYMMDD') 
        if((endDate>currentDate)){
            setShowNotification({open:true,message:'Invalid date range',type:'error',hideAutomatically:true})
        }else{
            setDateEnd(date)
        }
    }

    useEffect(() => {
        setShowLoader(true)
        fetchData()
    }, [analyticsOptions,timePeriodType,dateStart,dateEnd])

    const fetchData = async ()=>{
        const graphData = JSON.parse(localStorage.getItem('analyticsData'))
        if(firstTimeLoad && graphData){ 
            setChartData(graphData)
            setShowLoader(false)
            setFirstTimeLoad(false)
        }else{
            const startDate =dayjs(dateStart).format('YYYYMMDD')
            const endDate = dayjs(dateEnd).format('YYYYMMDD')     
            let analyticsDataObj = Object.assign({},chartData)
            for(let eventType in chartData){
                const analyticsData =await ApiService.amplitudeAnalytics(
                    eventType,timePeriodType,
                    analyticsOptions,startDate,endDate)
                if(analyticsData){
                    analyticsDataObj[eventType]=analyticsData
                }
            }
            if(!graphData){
                localStorage.setItem('analyticsData',JSON.stringify(analyticsDataObj))
            }
            setChartData(analyticsDataObj)
            setShowLoader(false)
            setFirstTimeLoad(false)
        }
    }
    
    return (
        <div className='h-full flex-1 w-full h-full p-[25px] bg-[#fff]'>
            {loader?<LinearLoader2/>:''}
            <div className='text-[22px] font-medium'>
                Analytics
            </div>
            <AnalyticsOptionsDropDown 
                handleAnalyticsOption={handleAnalyticsOption}
                handleTimePeriodType={handleTimePeriodType}
                handleDateStart={handleDateStart}
                handleDateEnd={handleDateEnd}
                analyticsOptions={analyticsOptions}
                timePeriodType={timePeriodType}
                dateStart={dateStart}
                dateEnd={dateEnd}
            />
            <LineChart chartData={chartData} />
        </div>
    )
}

export default Analytics