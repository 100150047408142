import {  Outlet, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../context/AppContextProvider';
import Header from '../Components/Header/Header';
import { AiOutlineUserAdd,AiOutlineShoppingCart,AiOutlineFileText } from "react-icons/ai";
import { BsFileEarmarkText,BsSliders } from "react-icons/bs";
import { BiCategory,BiArrowBack } from "react-icons/bi";
import { MdOutlinePrivacyTip,MdOutlineSupportAgent,MdOutlineColorLens } from "react-icons/md";
import { RiImageAddLine } from "react-icons/ri";
import { CiSliderVertical } from "react-icons/ci";
import NotificationBar from '../Components/Dialog/NotificationBar';
import { useNavigate } from "react-router-dom";
import { NavLink } from './NavLink';

export const DashboardLayout = () => {
    const {openSideBar,setOpenSideBar} =useContext(AppContext)
    const tooltipRef= useRef(null)
    let location = useLocation();
    const brandName= JSON.parse(localStorage.getItem('brandData'))?.brandDetails?.brand_alias_name
    const {showNotification,setShowNotification,brandSettings} = useContext(AppContext)
    
    const [showSettings,setShowSettings] = useState(false)
    const [showLooks,setShowLooks] = useState(false)
    const navigate = useNavigate(); 

    useEffect(()=>{
        if(location.pathname.includes('settings')){
            setShowSettings(true)
        }else{
            setShowSettings(false)
        }
    })

    useEffect(()=>{
        if(( 
            Object.keys(brandSettings).length>0  && brandSettings?.setting_meta_json 
            && brandSettings.setting_meta_json.customization_feature) 
            && (brandSettings.setting_meta_json.customization_feature.createLooks 
            || brandSettings.setting_meta_json.customization_feature.manageLooks))
        {
            setShowLooks(true)
        }else{
            setShowLooks(false)
        }
    },[brandSettings])

    const inventoryManagementLinks = [
        {label: "Upload Inventory", path: '/upload-inventory',icon:require('../assets/images/upload.jpg'),isReactIcon:false},
        {label: "Calibrate Inventory", path: '/calibrate-inventory',icon:require('../assets/images/calibrate.jpg'),isReactIcon:false},
        {label: "Manage Inventory", path: '/inventory',icon:require('../assets/images/manage.jpg'),isReactIcon:false},
    ]

    const looksLinks = [
        {label: "Create Looks", path: '/create-looks',icon:require('../assets/images/createLook.jpg'),isReactIcon:false},
        {label: "Manage Looks", path: '/manage-looks',icon:require('../assets/images/manageLook.jpg'),isReactIcon:false},
    ]

    const shadesLinks = [
        {label: "Find Your Shades", path: '/find-your-shades',icon:<MdOutlineColorLens size='22' fontWeight='bold'/>,isReactIcon:true}
    ]

    const settingsLinks=[
        {label: "Change Password", path: '/settings/change-password',icon:require('../assets/images/forgetPassword.png'),isReactIcon:false},
        {label: "Customization", path: '/settings/customization',icon:require('../assets/images/customization.jpg'),isReactIcon:false},
        {label: "Invite User", path: '/settings/invite-user',icon:<AiOutlineUserAdd size='22'/>,isReactIcon:true},
        {label: "Color Recommendation", path: '/settings/color-recommendation',icon:<MdOutlineColorLens size='22'/>,isReactIcon:true},
        {label: "Category", path: '/settings/category',icon:<BiCategory size='22'/>,isReactIcon:true},
        {label: "Filters", path: '/settings/filters',icon:<BsSliders size='22'/>,isReactIcon:true},
        {label:"Skin Analysis",path:"/settings/skin-analysis",icon:require('../assets/images/skinAnalysis.png'),isReactIcon:false},
        {label:"Skin Concern & Messaging",path:"/settings/skin-concern-messaging",icon:require('../assets/images/skin-message.png'),isReactIcon:false},
        {label: "Brand Logo", path: '/settings/brand-logo',icon:require('../assets/images/uploadLogo.jpg'),isReactIcon:false},
        {label: "Model Images", path: '/settings/model-images',icon:require('../assets/images/uploadModel.jpg'),isReactIcon:false},
        {label: "Background Image", path: '/settings/background-image',icon:<RiImageAddLine size='22'/>,isReactIcon:true},
        {label: "Intensity Slider Settings", path: '/settings/intensity-slider-settings',icon:<CiSliderVertical size='22'/>,isReactIcon:true},
        {label: "Cart Info", path: '/settings/cart-info',icon:<AiOutlineShoppingCart size='22'/>,isReactIcon:true},
        {label: "Makeup Consent Information", path: '/settings/consent-information',icon:<AiOutlineFileText size='22'/>,isReactIcon:true},
    ]

    const homepageLink=[
        {label: "Dashboard", path: '/home',icon:require('../assets/images/home.jpg'),isReactIcon:false},
        // {label: "Analytics", path: '/analytics',icon:require('../assets/images/analytics.jpg')}
    ]
    const backToHomepageLink={label: "Back To Home Screen", path: '/home',icon:<BiArrowBack/>,isReactIcon:true}

    const toggleSidebar = () =>{
        setOpenSideBar(!openSideBar)
    }

    const handleShowSettings = (val)=>{
        if(val){
            navigate('/settings/change-password')
        }
        setShowSettings(val)
    }

    const handleBackToHomepage = ()=>{
        navigate('/home')
        setShowSettings(false)
    }

    const logout = ()=>{
        localStorage.clear()
        navigate('/authentication/sign-in')
        setShowNotification({open:true,message:'Logged out successfully',type:'success',hideAutomatically:true})
        setOpenSideBar(true)
    }

    const handleWhatsapp = ()=>{
        window.open(`https://api.whatsapp.com/send/?phone=+919650693348&text=Hello Team mirrAR, I am facing issues with my brand ${brandName}&source=&data=`, '_blank')
    }

    return (
        <div className='h-full'>
            {showNotification.open?
                <NotificationBar 
                    message={showNotification.message}
                    type={showNotification.type}
                />:''
            }
            
            <div className='flex h-full'>
                <div className={`${openSideBar?'max-w-[270px] w-[270px]':'max-w-[78px] w-[78px]'} bg-white border-r-[2px] border-r-[#f2f2f2] relative `}>
                    <div className={`${openSideBar?'ml-[30px] ':'flex justify-center'} mt-[30px]`}>
                        <img
                        src={require("../assets/images/default_Logo_Mirrar.png")}
                        alt="Mirrar Logo"
                        className={`${openSideBar?'w-[123px] h-[40px] ':'w-[65px] h-[21px]'} `}
                        />
                    </div>
                    {
                        showSettings?
                        (   <div className={`${openSideBar?'max-w-[270px] w-[270px]':'max-w-[78px] w-[78px]'} pl-[30px] flex flex-col`}>
                                <div className='flex w-full gap-[10px] items-center mt-[20px] text-[14px] font-medium cursor-pointer'>
                                    <div className='w-full' onClick={handleBackToHomepage}>
                                        <NavLink path={backToHomepageLink.path} icon={backToHomepageLink.icon} isReactIcon={backToHomepageLink.isReactIcon} isBack={true}>{openSideBar?backToHomepageLink.label:''}</NavLink>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[18px] w-full mt-[30px] overflow-y-auto h-[calc(100vh_-_145px)] container1 pb-[18px]'> 
                                        {
                                            settingsLinks.map((link,index) => <NavLink key={index} path={link.path} icon={link.icon} isReactIcon={link.isReactIcon}>{openSideBar?link.label:''}</NavLink>)
                                        }
                                        <div className={`${openSideBar ?'hover:bg-[rgba(0,0,0,.04)]':''} text-[#130F24] text-[16px]  gap-[20px] flex w-full items-center cursor-pointer`} 
                                                onClick={()=>window.open('https://www.mirrar.com/privacy', '_blank')}
                                        >
                                            < MdOutlinePrivacyTip size='22'/>
                                            <div>
                                            {openSideBar?'Privacy policy':''}
                                            </div>   
                                        </div>
                                        <div className={`${openSideBar ?'hover:bg-[rgba(0,0,0,.04)]':''} text-[#130F24] text-[16px]  gap-[20px] flex w-full items-center cursor-pointer`} 
                                                onClick={()=>window.open('https://www.mirrar.com/terms', '_blank')}
                                        >
                                            <BsFileEarmarkText size='22'/>    {openSideBar?'Terms and conditions':''}
                                        </div>
                                        <div className={`${openSideBar ?'hover:bg-[rgba(0,0,0,.04)]':''} text-[#130F24] text-[16px] gap-[20px] flex w-full items-center cursor-pointer`} 
                                                onClick={()=>window.open('https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=info@styledotme.com', '_blank')}
                                        >
                                            <MdOutlineSupportAgent size='22'/>    {openSideBar?'Contact Support':''}
                                        </div>
                                        <div className={`${openSideBar ?'hover:bg-[rgba(0,0,0,.04)]':''} text-[#130F24] text-[16px] gap-[20px] flex w-full items-center cursor-pointer`} 
                                                onClick={logout}
                                        >
                                            <img src={require('../assets/images/logout.jpg')} alt='' width='20px' className='h-[20px]'/> {openSideBar?'Logout':''}
                                        </div>
                                </div>
                            </div>
                        )
                        :(
                            <div className={`${openSideBar?'max-w-[270px] w-[270px]':'max-w-[78px] w-[78px]'} pl-[30px] flex flex-col`}>
                                <div className='flex flex-col w-full mt-[28px] gap-[15px]'>
                                    <div className=' text-[12px] text-[#C1C1C1] font-semibold'>
                                            {openSideBar?'Home':''}
                                    </div> 
                                    <div className='w-full'>
                                    {
                                        homepageLink.map((link,index) => <NavLink key={index} path={link.path} icon={link.icon} isReactIcon={link.isReactIcon}>{openSideBar?link.label:''}</NavLink>)
                                    }
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[18px] w-full mt-[56px]'>
                                        <div className=' text-[12px] text-[#C1C1C1] font-semibold'>
                                            {openSideBar?'Inventory Management':<img src={require('../assets/images/threeDots.png') } alt=''/>}
                                        </div> 
                                        {
                                            inventoryManagementLinks.map((link,index) => <NavLink key={index} path={link.path} icon={link.icon} isReactIcon={link.isReactIcon}>{openSideBar?link.label:''}</NavLink>)
                                        }
                                </div>
                                {
                                    showLooks?
                                    <div className='flex flex-col gap-[19px] w-full mt-[56px]'>
                                        <div className=' text-[12px] text-[#C1C1C1] font-semibold'>
                                            {openSideBar?'Looks':<img src={require('../assets/images/threeDots.png') } alt=''/>}
                                        </div> 
                                        {
                                            looksLinks.map((link,index) => {
                                                if((link.label=='Create Looks' && 
                                                    Object.keys(brandSettings).length>0 && brandSettings.setting_meta_json &&
                                                    brandSettings.setting_meta_json.customization_feature && brandSettings.setting_meta_json.customization_feature.createLooks) 
                                                    || (link.label=='Manage Looks' && 
                                                    Object.keys(brandSettings).length>0 && brandSettings.setting_meta_json &&
                                                    brandSettings.setting_meta_json.customization_feature && brandSettings.setting_meta_json.customization_feature.manageLooks) 
                                                ){
                                                    return <NavLink key={index} path={link.path} icon={link.icon} isReactIcon={link.isReactIcon}>{openSideBar?link.label:''}</NavLink>
                                                }
                                                
                                            })
                                        }
                                </div>:''
                                }
                                <div className='flex flex-col gap-[18px] w-full mt-[56px]'>
                                        <div className=' text-[12px] text-[#C1C1C1] font-semibold'>
                                            {openSideBar?'Shades':<img src={require('../assets/images/threeDots.png') } alt=''/>}
                                        </div> 
                                        {
                                            shadesLinks.map((link,index) => <NavLink key={index} path={link.path} icon={link.icon} isReactIcon={link.isReactIcon}>{openSideBar?link.label:''}</NavLink>)
                                        }
                                </div>
                                
                            </div>
                        )
                    }
                    <div className='absolute top-[135px] right-[-10px] cursor-pointer' onClick={toggleSidebar}>
                        <img src={openSideBar?require('../assets/images/shrink.jpg'):require('../assets/images/expand.jpg')} alt="" />
                    </div>  
                </div>
                <div className={`${openSideBar?'w-[calc(100%_-_270px)]':'w-[calc(100%_-_78px)]'}`}>
                    <Header handleShowSettings={handleShowSettings}/>
                    <div id='dashboard_layout_container' className='px-[20px] w-full bg-[#F9F9F9] h-[90%] overflow-y-auto container1'>
                        <Outlet></Outlet>
                        {/* <div className="flex flx-col justify-center fixed bottom-[70px] right-[30px] z-20 ">
                            <div className="whatsapp w-[50px] border-0 rounded-full bg-[#4fce5d] relative cursor-pointer flex justify-center items-center py-[10px]"
                                onClick={handleWhatsapp}
                                onMouseEnter={(e) => {
                                    tooltipRef.current.classList.add('visible')
                                    tooltipRef.current.classList.remove('invisible')
                                }}
                                onMouseLeave={(e) => {
                                    tooltipRef.current.classList.add('invisible')
                                    tooltipRef.current.classList.remove('visible')
                                }}
                            >
                                <svg width="28" height="28" viewBox="0 0 24 24" style={{fill: "#fff"}}>
                                <path
                                    d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                                </svg>
                                <div ref={tooltipRef} className="tooltip text-[14px] rounded-[4px]  text-[white] p-[10px]  font-normal bg-[#435A64] block invisible  absolute z-30 w-[160px] bottom-[60px] right-[6px] ">
                                    We are available from 9:00 to 21:00 IST. Please feel free to reach out & we will get back to you asap!
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                
            </div>
        </div>
    )
}