import React, { useContext, useState } from 'react'
import { SearchBar } from '../../ManageInventory/SearchBar'
import Pagination from '../../ManageInventory/Pagination'
import { ApiService } from '../../../../services/apiUtility'
import { AppContext } from '../../../../context/AppContextProvider'

const ShowUploadedImages = ({uploadedImages,selectedCategory,removeUploadedImages=()=>{}}) => {
    const {setShowNotification} = useContext(AppContext)
    const [images,setImages] = useState(uploadedImages)
    const [imagesCopy,setImagesCopy] = useState(uploadedImages)
    const [selectedImagesSku,setSelectedImagesSku] = useState([])
    const [searchValue,setSearchValue] = useState('')
    const [searchResults,setSearchResults] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 10;

    const handleCheckboxClick = (e)=>{
        const index = (e.target.id);
        if (selectedImagesSku.includes(index)) {
            setSelectedImagesSku(selectedImagesSku.filter((i) => i !== index));
        } else {
            setSelectedImagesSku([...selectedImagesSku, index]);
        }
    }

    const handleSearchFilter =(searchText)=>{
        setCurrentPage(1)
        setSearchValue(searchText)
        handleSearch(searchText)
      }

    const handleSearch = (searchText)=>{
        const results=imagesCopy.filter((image)=>{
            return image.name.split('.')[0].toLowerCase().includes(searchText.toLowerCase())
        })
        setSearchResults(results)
        setImages(results)
    }

    const updateDataOnPageChange = (data,firstIndex,lastIndex)=>{
        const images = data.slice(firstIndex,lastIndex)
        setImages(images)
    }

    const handleDelete = ()=>{
        const remainingImages = imagesCopy.filter((image, index) => !selectedImagesSku.includes(image.name));
        setImages(remainingImages)
        setImagesCopy(remainingImages)
        setSelectedImagesSku([]);
    }

    const handleSave = () =>{
        if(selectedImagesSku.length>0){
            const selectedImages = imagesCopy.filter((image, index) => selectedImagesSku.includes(image.name));
            uploadImageFolder(selectedImages)
        }else{
            uploadImageFolder(imagesCopy)
        }
    }

    const uploadImageFolder = async (fileListArray)=>{
        const delimiter ='@_@'
        let formData = new FormData();
        formData.append("delimiter",delimiter)
        formData.append("categoryId",selectedCategory.id)
        for (let i = 0; i < fileListArray.length; i++) {
            formData.append("files[]", fileListArray[i]);
        }

        const res = await ApiService.uploadImageFolder(formData)
        if(res.status==200){
            removeUploadedImages()
            setShowNotification({open:true,message:res.message,type:'success',hideAutomatically:true})
        }else{
            setShowNotification({open:true,message:'There is some error while uploading product',type:'error',hideAutomatically:true})
        }
    }

    const tableHeading=['S. No','Parent Product Name','Variant SKU','Image','Status','Action']
    return (
        <div>
            <div className='w-full flex justify-end mb-[20px] relative right-[100px]'>
                <SearchBar handleSearchFilter={handleSearchFilter}/>
            </div>
            <div className='overflow-y-auto h-[500px] container1 bg-white'>
                <table className='w-full border-[1px] border-[rgba(224,224,224,1)]'>
                    <thead>
                        <tr className='sticky top-[-2px] bg-white'>
                            {
                                tableHeading.map((heading,index)=>{
                                    return (<th key={index} className={`${heading.toLowerCase().includes('no')?'w-[100px] min-w-[100px]':'w-[210px] min-w-[210px] max-w-[210px]'} px-[16px] py-[20px] text-start border-b-[1px] border-[rgba(224,224,224,1)]`}>
                                    <div className='font-medium'>{heading}</div>
                                </th>)
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            images.map((image,index)=>{
                                return <tr key={index}>
                                    <td className='px-[16px] py-[10px]'>
                                        {(currentPage-1)*imagesPerPage + index+1}
                                    </td>
                                    <td className='w-[180px]  px-[16px] py-[10px]'>
                                        {(image.name.split('.')[0]).split('@_@')[0]}
                                    </td>
                                    <td className='w-[180px]  px-[16px] py-[10px]'>
                                        {(image.name.split('.')[0]).split('@_@')[1]}
                                    </td>
                                    <td className='w-[180px]  px-[16px] py-[10px]'>
                                        {<img src={URL.createObjectURL(image)} alt="" className='h-[70px] w-[70px] '/>}
                                    </td>
                                    <td className='w-[180px]  px-[16px] py-[10px]'>
                                        success
                                    </td>
                                    <td className='w-[180px]  px-[16px] py-[10px]'>
                                        <input type="checkbox" className="w-[20px] h-[20px] checkbox" id={image.name} 
                                        onChange={handleCheckboxClick}
                                        checked={selectedImagesSku.includes(image.name)}
                                    />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                images = {imagesCopy}
                updateDataOnPageChange={updateDataOnPageChange} 
                searchText={searchValue}
                searchResults={searchResults}
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage}
                recordsPerPage = {imagesPerPage}
                type={'upload inventory'}
            />
            {
                uploadedImages.length>0?
                <div className='flex w-full justify-end gap-[20px] mt-[30px] mb-[20px] mr-[10px]'>
                    <button className='px-[20px] py-[10px] bg-black text-white'
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                    <button className='px-[20px] py-[10px] bg-black text-white'
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>:''
            }
        </div>
    )
}

export default ShowUploadedImages