import React from 'react'
const LinearLoader = () => {
  return (
    <div className="w-full h-full flex flex-col items-center">
        Please wait... 
        <div className="w-[250px] overflow-hidden h-[4px] bg-[#b0b0b0]">
            <div className="progress-bar-value w-full h-full bg-[#f2f2f2]"></div>
        </div>
    </div>
  )
}

export default LinearLoader