import React from 'react'
import { useContext } from 'react';
import { FaAngleDown } from "react-icons/fa";
import { MdOutlinePhonelinkOff,MdOutlinePhonelink,MdOutlineDeleteOutline } from "react-icons/md";
import { AppContext } from '../../../context/AppContextProvider';
import { ApiService } from '../../../services/apiUtility';
import { useNavigate } from 'react-router-dom';
export const Actions = ({selectedProducts,handleUnSelectProducts=()=>{},
    enableOrDisableProduct=()=>{},fetchCategories=()=>{},type,selectedLooksCategory,selectedCategory,handleCategoryOnClick=()=>{}})=>{
    const {setShowNotification,setOpenDeleteConfirmation} = useContext(AppContext)
    const navigate = useNavigate()

    const handleDelete = async ()=>{
        const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
        if(selectedProducts.length==0){
            setShowNotification({open:true,message:'No product selected.Please first select product to perform actions',type:'error',hideAutomatically:true})
        }else{
            setOpenDeleteConfirmation(true)
        }

    }

    const handleEnableOrDisable = async (val)=>{
        const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
        if(selectedProducts.length==0){
            setShowNotification({open:true,message:'No product selected.Please first select product to perform actions',type:'error',hideAutomatically:true})
        }else{
            if(type=='products'){
                handleEnableOrDisableProducts(val)
            }
            else if(type=='looks'){
                handleEnableOrDisableLooks(val)
            }
        }
    }

    const handleEnableOrDisableProducts = async(val)=>{
        const subBrandId = selectedProducts[0].subBrandId
            let products=[]
            for(let product of selectedProducts){
                products.push({
                    productId:product.productId,
                    productStatus:val
                })
            }
            const params = {
                "product":products
            }
            const res = await enableOrDisableProduct(params,subBrandId)
            if(res.status==200 && res.data.product && res.data.product.success && res.data.product.success.length>0){
                handleCategoryOnClick(selectedCategory.category,true)
                handleUnSelectProducts([])
                setShowNotification({open:true,message:`Product ${val?'enabled':'disabled'}`,type:'success',hideAutomatically:true})
            } else{
                if(res.status==403){
                    localStorage.clear()
                    navigate('/authentication/sign-in')
                    setShowNotification({open:true,message:'Unauthorized! You have been logged out',type:'error',hideAutomatically:true})
                }
                else{
                    setShowNotification({open:true,message:'There is some error',type:'error',hideAutomatically:true})
                }
            }
    }

    const handleEnableOrDisableLooks= async (val)=>{
        let looks=[]
        for(let look of selectedProducts){
            looks.push({
                key:look.key,
                label:look.label,
                image:[look.image[0]],
                // video:[look.video[0]],
                status:val
            })
        }
        const param={
            brandId:ApiService.brandId,
            looks:looks
        }
        const res =await ApiService.createLook(param)
        if(res){
            await handleCategoryOnClick(selectedLooksCategory)
            handleUnSelectProducts()
            setShowNotification({open:true,message:`Look ${val?'enabled' :'disabled'}.`,type:'success',hideAutomatically:true})
        }else{
            setShowNotification({open:true,message:'There is some error',type:'error',hideAutomatically:true})
        }
    }

    return(
        <div className="dropdown">
            <label tabIndex="0" className="flex rounded gap-[8px] items-center justify-center text-[#fff] min-w-[120px] bg-black min-h-[36px] max-h-[36px]">
                Actions<FaAngleDown size='16'/>
            </label>
            <ul tabIndex="0" className="dropdown-content menu shadow shadow-gray-400 min-w-[120px] cursor-pointer z-10">
                {
                    type!='looks'?
                    <li onClick={handleDelete}>
                        <div className='w-full justify-center hover:bg-[#f2f2f2] bg-white flex flex-row items-center p-[10px]'>
                            <MdOutlineDeleteOutline/>
                            Delete
                        </div>
                    </li>:''
                }
                <li onClick={()=>handleEnableOrDisable(false)}>
                    <div className='w-full justify-center hover:bg-[#f2f2f2] bg-white flex flex-row items-center p-[10px]'>
                        <MdOutlinePhonelinkOff/>
                        Disable
                    </div>
                </li>
                <li onClick={()=>handleEnableOrDisable(true)}>
                    <div className='w-full justify-center hover:bg-[#f2f2f2] bg-white flex flex-row items-center p-[10px]'>
                        <MdOutlinePhonelink/>
                        Enable
                    </div>
                </li>
            </ul>
        </div>
    )
}