import React, { useContext, useEffect, useState } from 'react'
import { ApiService } from '../../../../services/apiUtility'
import CircularLoader from '../../../Loader/CircularLoader'
import { useNavigate } from 'react-router-dom'
import ManageLooksLookOverview from './ManageLooksLookOverview'
import ManageLooksChooseProductCategory from './ManageLooksChooseProductCategory'
import { AppContext } from '../../../../context/AppContextProvider'
import ManageTryOnWindow from './ManageTryOnWindow'
import ProductCatalogIcon from '../../../../assets/images/product-catalog-button.svg'
import ManageLooksProductCatalog from './ManageLooksProductCatalog'
import ToolTipContainer from './ToolTipContainer';
import { ManageLooksContext } from '../../../../context/LooksContext'
import { BiArrowBack } from "react-icons/bi";
import ManageLooksSubmitConfirmation from './ManageLooksSubmitConfirmation'

const EditLookContainer = ({
    look_key, handleBackButton, collection_key
}) => {
    const [openTooltip, setOpenTooltip] = useState(false)

    const { looksTempStorage, setLooksTempStorage, setShowNotification } = useContext(AppContext)

    const { previewProduct, setPreviewProduct, changedLookValues, preSelectedProduct, setPreSelectedProduct } = useContext(ManageLooksContext)

    const [lookData, setLookData] = useState(null)
    const [productCategories, setProductCategories] = useState({
        originalArr: [],
        changedArr: []
    })

    const [previewAndSelectNotSame, setPreviewAndSelectNotSame] = useState(false)

    const [lookName, setLookName] = useState('')
    const [openProductCatalog, setOpenProductCatalog] = useState(false)
    const [initialLookProducts, setInitialLookProducts] = useState()
    const [preSelectedCategory, setPreSelectedCategory] = useState({
        label: '',
        category: ''
    })

    const navigate = useNavigate()

    const steps = [
        {
            target: '#look_image,#look_name,#look_collection_name',
            events: async () => {
                await document.querySelector('#category_dropdown').click()
                await document.querySelector('#category_0').click()
            },
            content: "Look's Information",
            positionRight: true
        },
        {
            target: '#category_dropdown',
            content: "Choose a product category here",
            positionRight: false
        },
        {
            target: '#color-picker',
            events: async () => {
                await document.querySelector('#color-picker').click()
            },
            content: "Search among the products by color",
            positionRight: false
        },
        {
            target: '#eyedropper-icon,#look_image',
            events: async () => {
                await document.querySelector('#shader').click()
            },
            content: "Select the dropper and pick a color from the reference image",
            positionRight: false
        },
        {
            target: '#color-palette',
            content: "Adjust the gradient color or color shade here",
            positionRight: false
        },
        {
            target: '#color-search-input-box',
            content: "You can search for a hex or rgb value eg '#ff0000' or 'rgb(255,0,0)'",
            positionRight: false
        },
        {
            target: '#text-picker',
            events: async () => {
                await document.querySelector('#text-picker').click()
            },
            content: "Search among the products by name",
            positionRight: false
        },
        {
            target: '#product-list-0',
            events: async () => {
                document.querySelector('#product-scroll-container')?.scrollTo(0, 0)
            },
            content: "Select the product to apply on AR screen",
            positionRight: false
        },
        {
            target: '#product-list-add-button-0',
            content: "Click on ‘Add’ for adding the product to the look",
            positionRight: false
        },
        {
            target: "#product-catalog-button",
            events: async () => {
                document.querySelector('#dashboard_layout_container')?.scrollTo(0, 0)
            },
            content: "See the added products here",
            positionRight: false
        }
    ]

    const previewClickProduct = (productObj) => {

        const {
            category,
            in_wishlist,
            product,
            ...restData
        } = productObj
        const productFormat = {
            category,
            ...product,
            variant: restData
        }

        if (previewProduct[productFormat.category.category]) {
            if (previewProduct[productFormat.category.category].variant.variant_id === productFormat.variant.variant_id) {
                const previousProduct = previewProduct[productFormat.category.category]
                if (window?.mirrarBeautyAR?.applyMakeupEffect) {
                    window.mirrarBeautyAR.removeMakeupEffect(previousProduct.category.category, previousProduct.variant.variant_sku)
                }
                setPreviewProduct({
                    ...previewProduct,
                    [productFormat.category.category]: null
                })
            } else {
                const previousProduct = previewProduct[productFormat.category.category]
                if (window?.mirrarBeautyAR?.applyMakeupEffect) {
                    window.mirrarBeautyAR.removeMakeupEffect(previousProduct.category.category, previousProduct.variant.variant_sku)
                }
                setPreviewProduct({
                    ...previewProduct,
                    [productFormat.category.category]: productFormat
                })
            }
        } else {
            setPreviewProduct({
                ...previewProduct,
                [productFormat.category.category]: productFormat
            })
        }
    }

    const getLookInformation = async (key) => {
        const res = await ApiService.getLookProducts(key)
        if (res.look) {
            setLookName(res.look.looks_label)
            const collection = await ApiService.fetchLooksCategories()

            productAndCategoryOperations(res.data)
            if (res.data) setInitialLookProducts(res.data)
            // if (res.data) setPreSelectedProduct(res.data)
            const lookCollection = collection.lookCollection.find((collectionItem) => {
                if (collectionItem.collectionKey === collection_key) {
                    const element = collectionItem?.looksInCollection?.length
                        && collectionItem.looksInCollection.find((look) => look.key.toLowerCase() === res.look.looks_key.toLowerCase())
                    if (element)
                        return element
                    else
                        return false
                }
                return false
            })
            // fetchCategories(res.data)
            setLookData({ ...res.look, collectionInfo: lookCollection })
        } else {
            navigate('/manage-looks')
        }
    }

    const productAndCategoryOperations = (data) => {
        if (data)
            if (looksTempStorage[look_key]) {
                setPreSelectedProduct(looksTempStorage[look_key])
                fetchCategories(looksTempStorage[look_key])
            } else {
                setPreSelectedProduct(data)
                setLooksTempStorage({
                    ...looksTempStorage,
                    [look_key]: data
                })
                fetchCategories(data)
            }
    }

    const addToProductArray = (removedArr, product) => {
        if (!product) return

        const addedArr = [...removedArr, product]

        // if (typeof window !== "undefined") {
        //     window.mirrarBeautyAR.applyMakeupEffect(product.category.category, product.variant.variant_sku, product.product_code, product.variant)
        // }

        setPreSelectedProduct(addedArr)
        setLooksTempStorage({
            ...looksTempStorage,
            [look_key]: addedArr
        })

        let categoriesOriginalData = productCategories.originalArr
        let categoriesChangedData = categoriesOriginalData.map((item, index) => {
            const productFind = [...preSelectedProduct, product].find((product) => product.category.category === item.category)
            if (productFind)
                return {
                    ...item,
                    selected: true
                }
            else return item
        })

        setProductCategories({
            ...productCategories,
            changedArr: categoriesChangedData
        })
    }

    const removeFromProductArray = async (product) => {
        if (!product) return
        const removedArr = preSelectedProduct.filter((item) => item.variant.variant_id !== product.variant.variant_id)
        if (removedArr?.length != preSelectedProduct?.length) {
            if (window && window.mirrarBeautyAR && window.mirrarBeautyAR.removeMakeupEffect) {
                await window.mirrarBeautyAR.removeMakeupEffect(product.category.category, product.variant.variant_sku)
            }
        }
        setPreSelectedProduct(removedArr)
        setLooksTempStorage({
            ...looksTempStorage,
            [look_key]: removedArr
        })

        let categoriesOriginalData = productCategories.changedArr
        let categoriesChangedData = categoriesOriginalData.map(({ selected, ...item }, index) => {
            if (product.category.category === item.category)
                return item
            else {
                if (selected) return {
                    ...item,
                    selected
                }
                else return item
            }
        })

        setProductCategories({
            ...productCategories,
            changedArr: categoriesChangedData
        })
    }

    const checkCategory = (product) => {
        // Remove all products in that category
        const filteredCategory = preSelectedProduct.filter((item) => product.category.category !== item.category.category)
        const removedArr = preSelectedProduct.filter((item) => product.category.category === item.category.category && product.variant.variant_id !== item.variant.variant_id)
        // removedArr?.map((item) => {
        //     if (item && window && window.mirrarBeautyAR && window.mirrarBeautyAR.removeMakeupEffect) {
        //         window.mirrarBeautyAR.removeMakeupEffect(item.category.category, item.variant.variant_sku)
        //     }
        // })
        setLooksTempStorage({
            ...looksTempStorage,
            [look_key]: filteredCategory
        })
        setPreSelectedProduct(filteredCategory)
        return filteredCategory
    }

    const addOrRemoveProduct = (productObj, addBool) => {
        const {
            category,
            in_wishlist,
            product,
            ...restData
        } = productObj
        const productFormat = {
            category,
            ...product,
            variant: restData
        }
        // To add Product
        if (addBool) {
            // To check if product exists in that criteria if yes then remove all the products
            const removedArr = checkCategory(productFormat)
            // To simply add a product
            addToProductArray(removedArr, productFormat)
        }
        // To remove Product
        else {
            removeFromProductArray(productFormat)
        }

    }

    const fetchCategories = async (preData) => {
        let categoriesOriginalData = await ApiService.getCategories()
        let categoriesChangedData
        if (preData) {
            categoriesChangedData = categoriesOriginalData.map((item, index) => {
                const productFind = preData.find((product) => product.category.category === item.category)
                if (productFind)
                    return {
                        ...item,
                        selected: true
                    }
                else return item
            })
        }
        setProductCategories({
            originalArr: categoriesOriginalData,
            changedArr: categoriesChangedData
        })
    }

    const convertDataURLToBlob = (url) => {
        const arr = url.split(',');
        const bstr = atob(arr[1]);
        let n = bstr.length;

        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return u8arr
    }


    const editLookName = async (lookStatus = true) => {
        const defaultLookImage = Array.isArray(lookData.looks_image) ? lookData.looks_image[0] : lookData.looks_image
        const defaultLookVideo = Array.isArray(lookData.looks_video) ? lookData.looks_video[0] : lookData.looks_video
        if (!lookStatus) {
            const param = {
                brandId: lookData.looks_brand_id,
                looks: [{
                    key: lookData.looks_key,
                    label: lookData.looks_label,
                    image: [defaultLookImage],
                    video: [defaultLookVideo],
                    status: false
                }],
            }
            const lookResponse = await ApiService.createLook(param)
            if (lookResponse) {
                return
            }
        }
        let imageUrl
        if (changedLookValues?.referenceImage?.imageUrl) {
            const imageBlob = convertDataURLToBlob(changedLookValues?.referenceImage?.imageUrl)

            const file = new File([imageBlob], changedLookValues?.referenceImage?.name, { type: changedLookValues?.referenceImage?.type })

            const formData = new FormData();
            formData.append("file", file);
            imageUrl = await ApiService.getUploadedImageUrl(formData)
        }
        let videoUrl
        if (changedLookValues?.referenceVideo?.videoUrl) {
            const videoBlob = convertDataURLToBlob(changedLookValues?.referenceVideo?.videoUrl)

            const file = new File([videoBlob], changedLookValues?.referenceVideo?.name, { type: changedLookValues?.referenceVideo?.type })

            const formData = new FormData();
            formData.append("file", file);
            videoUrl = await ApiService.getUploadedImageUrl(formData)
        }

        const param = {
            brandId: lookData.looks_brand_id,
            looks: [{
                key: lookData.looks_key,
                label: changedLookValues?.name || lookData.looks_label,
                image: [imageUrl || defaultLookImage],
                video: [videoUrl || defaultLookVideo],
                status: lookStatus
            }],
        }

        const lookResponse = await ApiService.createLook(param)

        if (changedLookValues?.collectionName && changedLookValues?.collectionName?.value !== lookData?.collectionInfo?.collectionKey) {
            // Map the look to the Collection
            const paramsForCollectionLookMapping = {
                lookCollectionLookMapping: [
                    {
                        mappingStatus: false,
                        lookCollection: {
                            collectionKey: lookData?.collectionInfo?.collectionKey
                        },
                        look: {
                            key: lookData.looks_key
                        }
                    },
                    {
                        mappingStatus: true,
                        lookCollection: {
                            collectionKey: changedLookValues?.collectionName?.value
                        },
                        look: {
                            key: lookData.looks_key
                        }
                    }
                ]
            }

            const responseForCollectionLookMapping = await ApiService.createCollectionLookMapping(paramsForCollectionLookMapping)

            if (responseForCollectionLookMapping) {
                setShowNotification({
                    type: "success",
                    message: `Look's Collection has been successfully changed from ${lookData?.collectionInfo?.collectionLabel} to ${changedLookValues?.collectionName?.label}`,
                    open: true,
                    hideAutomatically:true
                })
            }
        }

        if ((changedLookValues?.name || changedLookValues.collectionName || changedLookValues.referenceImage) && lookResponse) {
            setShowNotification({
                type: "success",
                message: "Look's details has been changed successfully",
                open: true,
                hideAutomatically:true
            })
        }
    }

    useEffect(() => {
        getLookInformation(look_key)
        fetchCategories()

        //Show the tooltip on first load of the page
        setTimeout(() => {
            if (!localStorage.getItem('look-tip-closed')) {
                setOpenTooltip(true)
            }
        }, 500)
    }, [])

    // console.log("previewProduct", previewProduct);

    //Finish Look
    const submitHandler = async e => {
        e.preventDefault()
        // await editLookName()
        if (!preSelectedProduct?.length) {
            setShowNotification({
                open: true,
                type: 'Error',
                message: 'Please select at least one product',
                hideAutomatically:true
            })
            return
        }

        if (e.nativeEvent.submitter.name === "Discard") {
            const tempData = looksTempStorage
            delete tempData[look_key]
            localStorage.setItem('looks_info', JSON.stringify(tempData))
            setLooksTempStorage(tempData)
            await editLookName(e.nativeEvent.submitter.name === "Finish")
            navigate('/manage-looks')
            return
        }

        if (Object.values(previewProduct)?.length) {
            const value = Object.values(previewProduct).some((item) => {
                if (!item) return false
                const elementFind = preSelectedProduct.find((product) => product?.variant?.variant_id === item?.variant?.variant_id)
                return !elementFind
            })
            setPreviewAndSelectNotSame(value)
            if (!value) {
                await submitProductsFunction()
            }
        } else {
            await submitProductsFunction()
        }
    }

    const submitProductsFunction = async () => {

        const productsInfoInFormat = preSelectedProduct.map((product) => {
            return {
                "lookProductMappingProductId": product.variant.variant_parent_id,
                "lookProductMappingLookId": lookData.looks_id,
                "lookProductMappingVariantId": product.variant.variant_id,
                "lookProductMappingStatus": true
            }
        })

        const initialProductsInFormat = initialLookProducts
            .map((initialProduct) => {
                const findProduct = productsInfoInFormat.find((product) => product.lookProductMappingVariantId === initialProduct.variant.variant_id)
                if (findProduct) {
                    return {
                        "lookProductMappingProductId": initialProduct.variant.variant_parent_id,
                        "lookProductMappingLookId": lookData.looks_id,
                        "lookProductMappingVariantId": initialProduct.variant.variant_id,
                        "lookProductMappingStatus": true
                    }
                } else
                    return {
                        "lookProductMappingProductId": initialProduct.variant.variant_parent_id,
                        "lookProductMappingLookId": lookData.looks_id,
                        "lookProductMappingVariantId": initialProduct.variant.variant_id,
                        "lookProductMappingStatus": false
                    }
            })
            .filter((product) => !product.lookProductMappingStatus)

        if (initialProductsInFormat.length) {
            await ApiService.mapProductToLooks({
                mapping: initialProductsInFormat
            })
        }

        const response = await ApiService.mapProductToLooks({
            mapping: productsInfoInFormat
        })

        if (response) {
            const tempData = looksTempStorage
            delete tempData[look_key]
            localStorage.setItem('looks_info', JSON.stringify(tempData))
            setLooksTempStorage(tempData)
            await editLookName(true)
            navigate('/look-success?edit_success=true')
        } else {
            setShowNotification({ open: true, message: 'There is some error while creating looks.', type: 'error',hideAutomatically:true })
        }

    }

    return lookData && looksTempStorage[look_key] ? (
        <form className='min-h-[95%]' onSubmit={submitHandler}>
            <ToolTipContainer
                setOpenTooltip={setOpenTooltip}
                openTooltip={openTooltip}
                steps={steps}
            />
            {
                previewAndSelectNotSame
                    ? <ManageLooksSubmitConfirmation
                        submitProductsFunction={submitProductsFunction}
                        setPreviewAndSelectNotSame={setPreviewAndSelectNotSame}
                    />
                    : null
            }
            {
                openProductCatalog
                    ? <ManageLooksProductCatalog
                        setOpenProductCatalog={setOpenProductCatalog}
                        lookData={lookData}
                        productsData={preSelectedProduct}
                        setPreSelectedCategory={setPreSelectedCategory}
                        removeFromProductArray={removeFromProductArray}
                    />
                    : null
            }
            <div className='flex justify-between py-[19px] items-center'>
                <span id="edit_button" className='font-semibold text-[16px]'>
                    EDIT LOOKS
                </span>
                <button id="product-catalog-button" type="button" className='flex gap-2 items-center px-3 py-3 rounded-[4px] relative bg-[#4525DD]' onClick={() => setOpenProductCatalog(true)}>
                    <span className='text-[12px] text-[#fff]'>Product Catalog</span>
                    <img src={ProductCatalogIcon} className='h-4 w-4' alt='Product Catalog' />
                    {
                        preSelectedProduct.length
                            ? <span className='text-[#fff] bg-[#F43333] h-[22px] w-[22px] absolute right-0 bottom-0 translate-y-1/2 translate-x-1/2 rounded-full'>{preSelectedProduct.length}</span>
                            : null
                    }
                </button>
            </div>
            <div className='flex items-center gap-[10px] mb-[10px]' >
                <BiArrowBack className='cursor-pointer' onClick={() => handleBackButton(collection_key)} />Back
            </div>
            <div className='flex gap-[20px] rounded w-full relative mb-5 h-[95%] items-stretch'>
                <div className=' bg-[#FFFFFF] w-3/12 z-[1] rounded-md h-[75vh] sticky top-0'>
                    <ManageLooksLookOverview lookData={lookData} setLookName={setLookName} setOpenTooltip={setOpenTooltip} />
                </div>
                <div className='w-5/12 flex relative'>
                    <ManageTryOnWindow defaultMakeup={Object.values(previewProduct)} />
                </div>
                <div className=' w-4/12  h-[75vh]'>
                    <ManageLooksChooseProductCategory
                        productCategories={productCategories.changedArr}
                        preSelectedProducts={preSelectedProduct}
                        lookKey={look_key}
                        addOrRemoveProduct={addOrRemoveProduct}
                        preSelectedCategory={preSelectedCategory}
                        previewClickProduct={previewClickProduct}
                        previewProduct={Object.values(previewProduct)}
                    />
                </div>
            </div>


        </form>
    ) : <CircularLoader />
}

export default EditLookContainer
