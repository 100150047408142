import React from 'react'
import SignUpComponent from '../../../Components/Authentication/SignUp/SignUpComponent'

const SignupPage = () => {
  return (
    <div className='flex h-full w-full justify-center items-center' style={{ backgroundImage: `url(${require('../../../assets/images/backgroundImage.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <SignUpComponent/>
    </div>
    
  )
}

export default SignupPage