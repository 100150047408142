import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

const Pagination = ({ selectedCategory, updateDataOnPageChange=()=>{},
      categories,searchText,searchResults,currentPage,
      setCurrentPage,recordsPerPage,type,tableData,images,selectedCategoryLooks,statusType  }) => {
  const containerRef = useRef(null);
  const getNumberOfProducts = ()=>{
    let length=0
    if(type=='products'){
      if(searchText===''){
        if(statusType=='all'){
          const selectedCategoryProducts = categories.filter((category,index)=>{
            return selectedCategory.category.label == category.category.label
          })
          length=selectedCategoryProducts[0].productData.length
        }else{
          length = searchResults.productData.length
        }
      }else if(searchText!==''){
        length=searchResults.productData?.length
      }
    }else if(type=='table data'){
      if(searchText===''){
        if(statusType=='all'){
          length=tableData.length
        }else{
          length = searchResults.length
        }
      }else{
        length = searchResults.length
      }
    }else if(type=='upload inventory'){
      if(searchText===''){
          length=images.length
      }else{
          length = searchResults.length
      }
    }else if(type=='looks'){
      if(searchText===''){
        if(statusType=='all'){
          length=selectedCategoryLooks.length
        }else{
          length=searchResults.length
        }
      }else{
          length = searchResults.length
      }
    }
    else{
      if(searchText===''){
        const selectedCategoryVariants = categories.filter((category,index)=>{
          return selectedCategory.categoryLabel == category.categoryLabel
        })
        length=selectedCategoryVariants[0].variants.length
      }else if(searchText!==''){
        length=searchResults?.variants?.length
      }
    }
    return length
  }
  
  const lastIndex = currentPage * recordsPerPage
  const firstIndex = lastIndex - recordsPerPage
  const numberOfRecords = getNumberOfProducts()
  const totalPages = Math.ceil(numberOfRecords/recordsPerPage)
  
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState(false);
  let pageNumbers = [];
  for (let i = 0; i < totalPages; i++) {
    pageNumbers.push(i + 1);
  }

  useEffect(()=>{
    initialiseCurrentPage()
  },[totalPages])

  const initialiseCurrentPage = ()=>{
    setDisableNextButton(currentPage >= totalPages)
    setDisablePreviousButton(currentPage <= 1)
  }

  const handlePageNumberClick = (number) => {
    setCurrentPage(number);
    setDisablePreviousButton(number <= 1);
    setDisableNextButton(number >= totalPages);
    scrollToContainer(number)
  };

  const handlePreviousButtonClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setDisableNextButton(false);
      setDisablePreviousButton(currentPage - 1 <= 1);
      scrollToContainer(currentPage - 1)
    }
  };

  const handleNextButtonClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setDisablePreviousButton(false);
      setDisableNextButton(currentPage + 1 >= totalPages);
      scrollToContainer(currentPage + 1)
    }
  };

  const scrollToContainer = (currentPage) => {
    if (containerRef.current) {
      const container = containerRef.current;
      const pageNumberElements = container.getElementsByClassName('page-number');
      const activePageNumberElement = document.getElementById(`${currentPage}`);
  
      if (pageNumberElements.length > 0 && activePageNumberElement) {
        const pageNumberWidth = pageNumberElements[0].offsetWidth;
        const activePageNumberIndex = Array.from(pageNumberElements).indexOf(activePageNumberElement);
        const scrollLeft = pageNumberWidth * activePageNumberIndex;
        container.scrollTo({ left: scrollLeft, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
      fetchCategoryProductsAndVariants()
  }, [currentPage,searchResults,images]);

  const fetchCategoryProductsAndVariants = async ()=>{
    setDisableNextButton(currentPage >= totalPages)
    setDisablePreviousButton(currentPage <= 1)
    if(searchText===''){
      if(type=='table data'){
        if(statusType=='all'){
          updateDataOnPageChange(tableData,firstIndex,lastIndex)
        }else{
          updateDataOnPageChange(searchResults,firstIndex,lastIndex)
        }
      }else if(type=='calibrate'){
        const selectedCategoryVariants = categories.filter((category,index)=>{
          return selectedCategory.categoryLabel == category.categoryLabel
        })
        updateDataOnPageChange(selectedCategoryVariants[0],firstIndex,lastIndex)
      }else if(type=='upload inventory'){
        updateDataOnPageChange(images,firstIndex,lastIndex)
      }else if(type=='looks'){
        if(statusType=='all'){
          updateDataOnPageChange(selectedCategoryLooks,firstIndex,lastIndex)
        }else{
          updateDataOnPageChange(searchResults,firstIndex,lastIndex)
        }
      }
      else{
        if(statusType=='all'){
          const selectedCategoryVariants = categories.filter((category,index)=>{
            return selectedCategory.category.label == category.category.label
          })
          updateDataOnPageChange(selectedCategoryVariants[0],firstIndex,lastIndex)
        }else{
          updateDataOnPageChange(searchResults,firstIndex,lastIndex)
        }
      }
    }else if(searchText!==''){
      updateDataOnPageChange(searchResults,firstIndex,lastIndex)
    }
  }

  return (
    <div className='mb-[10px] w-full'>
      {
        numberOfRecords>0?
        (
          <div className={`flex-col flex w-full items-center`}>
            <div className={` w-full flex justify-center whitepace-nowrap  text-[14px] my-[10px]`}>
              Showing {(currentPage-1)*recordsPerPage+1} to {currentPage ==Math.ceil(numberOfRecords/recordsPerPage)?numberOfRecords:currentPage*recordsPerPage} of {numberOfRecords} entries
            </div>
            <div className="flex items-start justify-center w-[75%]">
                <div
                  onClick={handlePreviousButtonClick}
                  className={`cursor-pointer px-[10px] py-[3px] flex items-center`}
                >
                  <AiOutlineLeft style={{ color: disablePreviousButton ? 'lightGray' : 'black' }} />
                </div>
                <div className="flex overflow-x-auto container2" ref={containerRef}>
                  {pageNumbers.map((number, index) => (
                    <div
                      key={index}
                      id={number}
                      className={`${currentPage === number ? 'text-white bg-black active' : 'text-black bg-white'}
                                    text-[14px] cursor-pointer px-[10px] py-[2px] mb-[10px] page-number`}
                      onClick={() => handlePageNumberClick(number)}
                    >
                      {number}
                    </div>
                  ))}
                </div>
                <div
                  onClick={handleNextButtonClick}
                  className={`cursor-pointer px-[10px] py-[3px] `}
                >
                  <AiOutlineRight style={{ color: disableNextButton ? 'lightGray' : 'black' }} />
                </div>
            </div>
          </div> 
        ):''
      }
    </div>
    
  );
};

export default Pagination;

