import React, { useContext } from 'react'
import SignInComponent from '../../../Components/Authentication/SignIn/SignInComponent'
import { AppContext } from '../../../context/AppContextProvider'
import NotificationBar from '../../../Components/Dialog/NotificationBar'

const SignInPage = () => {
  const {showNotification} = useContext(AppContext)
  return (
    <div className='flex h-full w-full justify-center items-center' style={{ backgroundImage: `url(${require('../../../assets/images/backgroundImage.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {showNotification.open?
                <NotificationBar 
                    message={showNotification.message}
                    type={showNotification.type}
                />:''
            }
        <SignInComponent/>
    </div>
  )
}

export default SignInPage