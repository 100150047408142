import React, { useContext,useState,useEffect } from 'react'
import AnalyticsOptionsDropDown from '../Analytics/AnalyticsOptionsDropDown'
import { AppContext } from '../../../context/AppContextProvider'
import dayjs from 'dayjs';
import PieChart from '../Analytics/PieChart'
import { ApiService } from '../../../services/apiUtility';
import LinearLoader2 from '../../Loader/LinearLoader2';
const TriedOnMakeup = () => {
    const {setShowNotification} = useContext(AppContext)
    const [analyticsOptions,setAnalyticsOptions] = useState('totals')
    const [timePeriodType,setTimePeriodType] = useState('1')
    const [dateStart,setDateStart] = useState(dayjs(dayjs().subtract(1, 'month').format('YYYY-MM-DD')).format('YYYY-MM-DD'))
    const [dateEnd,setDateEnd] = useState(dayjs().format('YYYY-MM-DD'))
    const eventType = 'makeup-selected'
    const [chartData,setChartData] = useState(null)
    const [loader,setShowLoader] = useState(false)
    const [firstTimeLoad,setFirstTimeLoad] = useState(true)

    const handleAnalyticsOption = (type) =>{
        setAnalyticsOptions(type)
    }

    const handleTimePeriodType = (type) =>{
        setTimePeriodType(type)
    }

    const handleDateStart = (date)=>{
        const startDate =dayjs(date).format('YYYYMMDD')
        const endDate = dayjs(dateEnd).format('YYYYMMDD') 
        if((startDate>endDate)){
            setShowNotification({open:true,message:'Invalid date range',type:'error',hideAutomatically:true})
        }else{
            setDateStart(date)
        }
    }

    const handleDateEnd = (date)=>{
        const endDate = dayjs(date).format('YYYYMMDD') 
        const currentDate=dayjs().format('YYYYMMDD') 
        if((endDate>currentDate)){
            setShowNotification({open:true,message:'Invalid date range',type:'error',hideAutomatically:true})
        }else{
            setDateEnd(date)
        }
    }

    useEffect(() => {
        setShowLoader(true)
        fetchData()
    }, [analyticsOptions,timePeriodType,dateStart,dateEnd])

    const fetchData = async ()=>{
        const graphData = JSON.parse(localStorage.getItem('triedOnMakeup'))
        if(firstTimeLoad && graphData){ 
            setChartData(graphData)
            setShowLoader(false)
            setFirstTimeLoad(false)
        }else{
            const startDate =dayjs(dateStart).format('YYYYMMDD')
            const endDate = dayjs(dateEnd).format('YYYYMMDD')     
            const analyticsData =await ApiService.amplitudeAnalytics(
                eventType,timePeriodType,
                analyticsOptions,startDate,endDate,true,['city'],'user')
            if(analyticsData){
                if(!graphData){
                    localStorage.setItem('triedOnMakeup',JSON.stringify(analyticsData))
                }
                setChartData(analyticsData)
            }
            setShowLoader(false)
        }
    }
    return(
    <div className='bg-white w-full h-[718px] shadow-[0_5px_4px_0px_rgba(0,0,0,0.05)] rounded'>
    <div className='px-[10px] flex flex-col justify-evenly whitespace-nowrap h-[10%] border-b-[1px]'>
      <div className='font-bold text-[16px]'>
      Tried on makeup
      </div>
      <AnalyticsOptionsDropDown 
        handleAnalyticsOption={handleAnalyticsOption}
        handleTimePeriodType={handleTimePeriodType}
        handleDateStart={handleDateStart}
        handleDateEnd={handleDateEnd}
        analyticsOptions={analyticsOptions}
        timePeriodType={timePeriodType}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
    </div>
    <div className='h-[80%]'>
      {loader?<LinearLoader2/>:
      chartData && chartData.seriesLabels.length>0?
      <PieChart chartData={chartData} />
      :<div className='text-center p-[20px]'>No data available</div>
    }
      
    </div>
  </div>
    )
}

export default TriedOnMakeup