import React from 'react'
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement
);

export const options = {
  responsive:true,
  maintainAspectRatio:true,
  aspectRatio:1/1,
  plugins:{
    legend:{
      labels: {
        color: "black",
        padding:20
      },
      position:'bottom',
    }
  }
}
const Chart = ({chartData}) => {
    let data={
      labels:'',
      datasets:[]
    }
  
    const color =['black','blue','green','red','yellow','gray','orange','brown','cyan','purple']
  
    let labels=[] 
    let dataValues =[]
    chartData && chartData.seriesLabels && chartData.seriesLabels.map((value,index)=>{
        if(index<10){
            labels.push(value[1])
            
        }
    })
    chartData && chartData.seriesLabels && chartData.seriesCollapsed.map((value,index)=>{
        if(index<10){
            dataValues.push(value[0].value)
        }
    })
    const obj = {
        label:'tried on makeup',
        data: dataValues,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        pointRadius: 5,
        tension: 0.4
    }
    data.labels=labels
    data.datasets=[obj]
    return (
      <div className='w-full h-full flex justify-center items-center my-3.5'>
          <Pie data={data} options={options}/>
      </div>
    )
}

export default Chart