import React, { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContextProvider'
import { ApiService } from '../../services/apiUtility'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const AddToCart = () => {
  const navigate = useNavigate()
  const { setShowNotification, brandSettings, getBrandSettings, setBrandSettings } = useContext(AppContext)
  const [bagNavigationLink, setBagNavigationLink] = useState('')
  const [addToCartWehbook, setAddToCartWehbook] = useState('')
  const [bagNavigationLinkError,setBagNavigationLinkError] = useState(null)
  const [addToCartWehbookError,setAddToCartWehbookError] = useState(null)

  useEffect(() => {
    setBagNavigationLink(brandSettings?.setting_meta_json?.cart_info?.bag_navigation_link || '')
    setAddToCartWehbook(brandSettings?.setting_meta_json?.cart_info?.add_to_cart_webhook || '')
  }, [brandSettings])

  const isValidUrl = ()=> {
      const urlPattern = new RegExp('^(https?:\\/\\/)' + // validate protocol
                          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                          '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
                          '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
                          '(\\#[-a-z\\d_]*)?$', 'i');
      if(!urlPattern.test(bagNavigationLink)){
        setBagNavigationLinkError('Invalid url')
        return false
      }
      if(!urlPattern.test(addToCartWehbook)){
          setAddToCartWehbookError('Invalid url')
          return false
      }
      return true
  }

  const handleCartInfoChange = async () => {
    if(!isValidUrl()){
      return
    }
    setBrandSettings((brandSettings) => {
      const settings = { ...brandSettings.setting_meta_json }
      settings['cart_info'] = {
        "bag_navigation_link": bagNavigationLink,
        "add_to_cart_webhook": addToCartWehbook
      }
      const params = {
        brandId: ApiService.brandId,
        setting: {
          json: settings
        }
      }
      ApiService.updateSettings(params).then((res) => {
        if (res && res.status == 200) {
          setShowNotification({ open: true, message: 'Settings saved successfully', type: 'success',hideAutomatically:true })
        } else if (res && res.status == 403) {
          localStorage.clear()
          navigate('/authentication/sign-in')
          setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
        } else {
          setShowNotification({ open: true, message: 'There is some error while saving settings', type: 'error',hideAutomatically:true })
        }
      })
      brandSettings.setting_meta_json = settings
      return {...brandSettings}
    })
  }
  return (
    <div className='flex flex-col gap-[30px] items-center w-full h-[calc(100%_-_40px)] my-[20px] bg-[#fff]'>
      <div className='py-[20px] flex flex-col gap-[20px] w-[30%]'>
        <div className='text-center font-medium text-[16px]'>
          Cart Info
        </div>
        <div className='border-[1px] rounded flex flex-col p-[20px]'>
          <div className='mb-[15px]'>
            <span className='ml-[5px] text-[14px] font-medium'>Bag Navigation Link</span>
            <div>
              <input
                type="text"
                placeholder="Bag Navigation Link"
                className="h-[35px] mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                required
                value={bagNavigationLink}
                onChange={(event) => {
                  setBagNavigationLinkError(null)
                  setBagNavigationLink(event.target.value)
                }}
              />
              {
                  bagNavigationLinkError?
                  <label className="label">
                      <span className="label-text-alt text-red-500">{bagNavigationLinkError}</span>
                  </label>:''
              }

            </div>
          </div>
          <div className='mb-[15px]'>
            <span className='ml-[5px] text-[14px] font-medium'>Add To Cart Webhook</span>
            <div>
              <input
                type="text"
                placeholder="Add to cart webhook"
                className="h-[35px] mt-[10px] bg-white border-[1px] border-black text-[14px] rounded w-full p-4"
                required
                value={addToCartWehbook}
                onChange={(event) => {
                  setAddToCartWehbookError(null)
                  setAddToCartWehbook(event.target.value)
                }}
              />
              {
                  addToCartWehbookError?
                  <label className="label">
                      <span className="label-text-alt text-red-500">{addToCartWehbookError}</span>
                  </label>:''
              }

            </div>
          </div>
          <div className='w-full flex justify-center'>
            <button className='bg-black text-[14px] text-white px-[15px] py-[3px]'
              onClick={handleCartInfoChange}
            >Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddToCart