import React, { useState } from 'react'

const SkinAnalysisLanguageCode = ({
    setCurrencyCode,
    currencyCode
}) => {

    const [currency, setCurrency] = useState(currencies2[0])

    const changeValue = (index) => {
        const itemObj = currencies2[index]
        setCurrency(itemObj)
        setCurrencyCode(itemObj.symbol)
    }

    return (
        <div className='flex-grow flex ml-[30px] gap-3 items-center'>
            <select className=' capitalize truncate w-4/5 py-2 px-3 border rounded-md' onChange={e => changeValue(e.target.value)} defaultValue={currencies2.findIndex((item) => item.symbol === currencyCode)}>
                <option className='capitalize truncate' value={null}>None Selected</option>
                {
                    currencies2.map((item, index) => {
                        return <option key={index} className='capitalize truncate' value={index}>{item?.name} - {item?.symbol}</option>
                    })
                }
            </select>
        </div>
    )
}

export default SkinAnalysisLanguageCode


const currencies2 = [
    {
        "currency": "INR",
        "locale": "hi-IN",
        "symbol": "₹",
        "name": "Indian Rupee",
        "symbol_native": "টকা",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "INR",
        "name_plural": "Indian rupees"
    },
    {
        "currency": "USD",
        "locale": "en-US",
        "symbol": "$",
        "name": "US Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "USD",
        "name_plural": "US dollars"
    },
    {
        "currency": "CAD",
        "locale": "en-CA",
        "symbol": "CA$",
        "name": "Canadian Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CAD",
        "name_plural": "Canadian dollars"
    },
    {
        "currency": "EUR",
        "locale": "en-EU",
        "symbol": "€",
        "name": "Euro",
        "symbol_native": "€",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EUR",
        "name_plural": "euros"
    },
    {
        "currency": "AED",
        "locale": "ar-AE",
        "symbol": "AED",
        "name": "United Arab Emirates Dirham",
        "symbol_native": "د.إ.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AED",
        "name_plural": "UAE dirhams"
    },
    {
        "currency": "AFN",
        "locale": "fa-AF",
        "symbol": "Af",
        "name": "Afghan Afghani",
        "symbol_native": "؋",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "AFN",
        "name_plural": "Afghan Afghanis"
    },
    {
        "currency": "AUD",
        "locale": "en-AU",
        "symbol": "AU$",
        "name": "Australian Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AUD",
        "name_plural": "Australian dollars"
    },
    {
        "currency": "BRL",
        "locale": "pt-BR",
        "symbol": "R$",
        "name": "Brazilian Real",
        "symbol_native": "R$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BRL",
        "name_plural": "Brazilian reals"
    },
    {
        "currency": "CNY",
        "locale": "zh-CN",
        "symbol": "CN¥",
        "name": "Chinese Yuan",
        "symbol_native": "CN¥",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CNY",
        "name_plural": "Chinese yuan"
    },
    {
        "currency": "GBP",
        "locale": "en-GB",
        "symbol": "£",
        "name": "British Pound Sterling",
        "symbol_native": "£",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GBP",
        "name_plural": "British pounds sterling"
    },
    {
        "currency": "JPY",
        "locale": "ja-JP",
        "symbol": "¥",
        "name": "Japanese Yen",
        "symbol_native": "￥",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "JPY",
        "name_plural": "Japanese yen"
    },
    {
        "currency": "KRW",
        "locale": "ko-KR",
        "symbol": "₩",
        "name": "South Korean Won",
        "symbol_native": "₩",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "KRW",
        "name_plural": "South Korean won"
    },
    {
        "currency": "MXN",
        "locale": "es-MX",
        "symbol": "MX$",
        "name": "Mexican Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MXN",
        "name_plural": "Mexican pesos"
    },
    {
        "currency": "RUB",
        "locale": "ru-RU",
        "symbol": "RUB",
        "name": "Russian Ruble",
        "symbol_native": "руб.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "RUB",
        "name_plural": "Russian rubles"
    },
    {
        "currency": "ZAR",
        "locale": "en-ZA",
        "symbol": "R",
        "name": "South African Rand",
        "symbol_native": "R",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ZAR",
        "name_plural": "South African rand"
    }
]