import React from 'react'
import CreateLooks from '../../../Components/InventoryManagement/Looks/CreateLooks/CreateLooks'
import CreateLooksRevampContainer from '../../../Components/InventoryManagement/Looks/CreateLooksRevamp/CreateLooksRevampContainer'
import { CreateLooksProvider } from '../../../context/LooksContext'

const CreateLooksPage = () => {

    return (
        <CreateLooksProvider>
            <CreateLooksRevampContainer />
        </CreateLooksProvider>
        // <CreateLooks/>
    )
}

export default CreateLooksPage