import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContextProvider';
import { ApiService } from '../../services/apiUtility';
import { useNavigate } from 'react-router-dom';

const ThemeColor = () => {
  const { setShowNotification, getBrandSettings, brandSettings, setBrandSettings } = useContext(AppContext)
  const [selectedPrimaryColor, setSelectedPrimaryColor] = useState('');
  const [selectedPrimaryColorFromPallete, setSelectedPrimaryColorFromPallete] = useState('')
  const [selectedSecondaryColor, setSelectedSecondaryColor] = useState('');
  const [selectedSecondaryColorFromPallete, setSelectedSecondaryColorFromPallete] = useState('')
  const [selectedPrimaryColorRgbValue, setSelectedPrimaryColorRgbValue] = useState('')
  const [selectedSecondaryColorRgbValue, setSelectedSecondaryColorRgbValue] = useState('')
  const navigate = useNavigate()
  const colors = [
    { id: 1, color: '#FF0000' },
    { id: 2, color: '#0000FF' },
    { id: 3, color: '#007f00' },
    { id: 4, color: '#0096FF' },
    { id: 5, color: '#7393B3' },
    { id: 6, color: '#B2BEB5' },
  ];

  useEffect(() => {
    const primaryColor = brandSettings?.setting_meta_json?.theme_color?.primaryColor || '#000000'
    if (primaryColor) {
      const rgbObj = hexToRgb(primaryColor)
      const rgbValue = `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
      setSelectedPrimaryColorRgbValue(rgbValue)
      setSelectedPrimaryColor(primaryColor)
      setSelectedPrimaryColorFromPallete(primaryColor)
    }

    const secondaryColor = brandSettings?.setting_meta_json?.theme_color?.secondaryColor || '#FFFFFF'
    if (secondaryColor) {
      const rgbObj = hexToRgb(secondaryColor)
      const rgbValue = `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
      setSelectedSecondaryColorRgbValue(rgbValue)
      setSelectedSecondaryColor(secondaryColor)
      setSelectedSecondaryColorFromPallete(secondaryColor)
    }
  }, [brandSettings])

  const handleColorChange = (color, type) => {
    const rgbObj = hexToRgb(color)
    const rgbValue = `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
    if (type == 'primary') {
      setSelectedPrimaryColor(color);
      setSelectedPrimaryColorRgbValue(rgbValue)
    } else {
      setSelectedSecondaryColor(color);
      setSelectedSecondaryColorRgbValue(rgbValue)
    }

  };

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  const handleThemeColorChange = async () => {
    setBrandSettings((brandSettings) => {
      const settings = { ...brandSettings.setting_meta_json }
      settings['theme_color'] = {
        primaryColor: selectedPrimaryColor,
        secondaryColor: selectedSecondaryColor
      }
      const params = {
        brandId: ApiService.brandId,
        setting: {
          json: settings
        }
      }
      ApiService.updateSettings(params).then(res => {
        if (res && res.status == 200) {
          setShowNotification({ open: true, message: 'Theme color changed successfully', type: 'success',hideAutomatically:true })
        } else if (res && res.status == 403) {
          localStorage.clear()
          navigate('/authentication/sign-in')
          setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
        } else {
          setShowNotification({ open: true, message: 'There is some error while saving settings', type: 'error',hideAutomatically:true })
        }
      })
      brandSettings.setting_meta_json = settings
      return {...brandSettings}
    })
  }

  return (
    <div className="flex flex-col text-[16px] gap-[15px] pt-[20px]">
      <div className='flex flex-col gap-[15px]'>
        <div className='font-medium'>
          Primary Color Selection
        </div>
        <div className='flex gap-[40px]'>
          <div className='flex items-center gap-[10px] '>
            {colors.map((color) => (
              <div
                key={color.id}
                style={{ backgroundColor: color.color }}
                className={`flex items-center justify-center h-[25px] w-[25px] rounded-full cursor-pointer
                          ${selectedPrimaryColor === color.color ? 'border-2 border-gray-600' : ''}`}
                onClick={() => handleColorChange(color.color, 'primary')}
              >
                {selectedPrimaryColor === color.color && (
                  <span className="text-white font-bold">✓</span>
                )}
              </div>
            ))}
          </div>
          <div className='flex gap-[20px] whitespace-nowrap items-center'>
            <div>More Colors</div>
            <div className='flex border-[1px] items-center px-[10px] gap-[10px]'>
              <div>
                <input type="color" name="" id="" className='w-10 h-10' value={selectedPrimaryColorFromPallete ? selectedPrimaryColorFromPallete : ''}
                  onChange={(e) => {
                    setSelectedPrimaryColorFromPallete(e.target.value)
                    setSelectedPrimaryColor(e.target.value)
                    const rgbObj = hexToRgb(e.target.value)
                    const rgbValue = `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
                    setSelectedPrimaryColorRgbValue(rgbValue)
                  }}
                />
              </div>
            </div>

          </div>
        </div>
        <div className=''>
          <div className='w-full flex items-center justify-between gap-[10px]'>
            <div className='font-medium'>
              Selected color
            </div>
            <div className='w-10 h-10 border' style={{ backgroundColor: selectedPrimaryColor }}>

            </div>
            <div className='font-medium'>
              Hex Code: {selectedPrimaryColor}
            </div >
            <div className='font-medium'>
              RGB: {selectedPrimaryColorRgbValue}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col text-[16px] gap-[15px] mt-[20px]">
          <div className='font-medium'>
            Secondary Color Selection
          </div>
          <div className='flex gap-[40px]'>
            <div className='flex items-center gap-[10px] '>
              {colors.map((color) => (
                <div
                  key={color.id}
                  style={{ backgroundColor: color.color }}
                  className={`flex items-center justify-center h-[25px] w-[25px] rounded-full cursor-pointer
                              ${selectedSecondaryColor === color.color ? 'border-2 border-gray-600' : ''}`}
                  onClick={() => handleColorChange(color.color, 'secondary')}
                >
                  {selectedSecondaryColor === color.color && (
                    <span className="text-white font-bold">✓</span>
                  )}
                </div>
              ))}
            </div>
            <div className='flex gap-[20px] whitespace-nowrap items-center'>
              <div>More Colors</div>
              <div className='flex border-[1px] items-center px-[10px] gap-[10px]'>
                <div>
                  <input type="color" name="" id="" className='w-10 h-10' value={selectedSecondaryColorFromPallete ? selectedSecondaryColorFromPallete : ''}
                    onChange={(e) => {
                      setSelectedSecondaryColorFromPallete(e.target.value)
                      setSelectedSecondaryColor(e.target.value)
                      const rgbObj = hexToRgb(e.target.value)
                      const rgbValue = `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
                      setSelectedSecondaryColorRgbValue(rgbValue)
                    }}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className=''>
            <div className='w-full flex items-center justify-between gap-[10px]'>
              <div className='font-medium'>
                Selected color
              </div>
              <div className='w-10 h-10 border' style={{ backgroundColor: selectedSecondaryColor }}>

              </div>
              <div className='font-medium'>
                Hex Code: {selectedSecondaryColor}
              </div >
              <div className='font-medium'>
                RGB: {selectedSecondaryColorRgbValue}
              </div>
            </div>


          </div>
        </div>
      </div>
      <div className='w-full flex justify-center'>
        <button className='px-[20px] py-[5px] bg-black text-white' onClick={handleThemeColorChange}>
          Change
        </button>
      </div>
    </div>
  );
};

export default ThemeColor;
