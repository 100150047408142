import React,{useRef,useContext,useState} from 'react'
import { AppContext } from '../../../../context/AppContextProvider'

const UploadImageFolder = ({selectedCategory,handleUploadedImageFolder=()=>{}}) => {
    const {setShowNotification} = useContext(AppContext)
    const inputRef = useRef(null)
    const [inventorySize,setInventorySize] = useState(0)

    const handleDragOver = (e)=>{
        e.preventDefault()
    }

    const handleDrop = async (event)=>{
        event.preventDefault()
        const entry = event.dataTransfer.items[0].webkitGetAsEntry();
        const fileListArray = await handleEntry(entry);
        if(fileListArray.length==0){
            setShowNotification({open:true,message:'This folder is empty.Please upload folder with images',type:'error',hideAutomatically:true})
        }
        handleUploadedImageFolder(fileListArray,selectedCategory)
    }

    const handleEntry = async(entry)=> {
        if (entry.isDirectory) {
          const reader = entry.createReader();
          const entries = await readEntriesAsync(reader);
          const files = [];
      
          for (const dir of entries) {
            const subFiles = await handleDir(dir);
            if(subFiles[0].type.includes('image'))
            {
                subFiles[0]['parent']=entry.name
                files.push(...subFiles);
            }
            else{
                setShowNotification({open:true,message:`Please drag and drop folder containing images only`,type:'error',hideAutomatically:true})
                return
            }
          }
          return files;
        }else{
            setShowNotification({open:true,message:`Please drag and drop ${selectedCategory.label} image folder`,type:'error',hideAutomatically:true})
        }
      }

    const handleDir = async (entry)=>{
        return [await handleFile(entry)];
    }
      
    const handleFile = (file)=> {
        return new Promise((resolve, reject) => {
            file.file(resolve, reject);
        });
    }
      
    const readEntriesAsync =(reader)=> {
        return new Promise((resolve, reject) => {
            reader.readEntries(resolve, reject);
        });
    }

    const handleBrowseFiles = (event)=>{
        const fileList=event.target.files
        const delimiter ='@_@'
        let size=0
        const fileListArray = Array.from(fileList).map((file)=>{
            size=size+file.size
            let blob = file.slice(0, file.size, file.type); 
            
             file['parent'] = file.webkitRelativePath.split('/')[0]
             let newFile = new File([blob], file['parent']+delimiter+file['name'], {type: file.type});
             return newFile
        })
        setInventorySize(Math.floor(size / 1024))
        handleUploadedImageFolder(fileListArray,selectedCategory,delimiter)
        event.target.value=null
    }

    return (
        <div className='my-[20px] h-[400px] max-h-[400px] bg-[#fff] rounded border-[1px]'>
            <div className='border-[1px] py-[20px] px-[20px] text-[16px] font-medium'>
                Attached Files
            </div>
            <div>
                <div className='py-[20px] px-[20px] text-[14px] text-[#717474] '>
                    Add {selectedCategory && selectedCategory.label ?selectedCategory.label:''} attached files here
                </div>
                <div className='w-full px-[20px]'>
                    <div className='border-dashed rounded border-[2px] border-[#DCDBDB]
                        w-full h-[240px] m-auto flex 
                        flex-col items-center justify-center font-medium gap-[10px]'
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <img src={require("../../../../assets/images/uploadFolder.jpg")} alt="" />
                        <div className='text-center px-[45px] text-[16px] font-normal'>Drag & Drop {selectedCategory && selectedCategory.label ?selectedCategory.label:''} image folder here</div>
                        <div className='text-center text-[16px] font-normal'>Or</div>
                        <input type="file" multiple hidden
                            onChange={handleBrowseFiles}
                            directory="" webkitdirectory=""
                            ref={inputRef}
                        />
                        <button className='text-[16px] font-medium cursor-pointer px-[40px] py-[8px] border-[1px] border-[#130F24]'
                                onClick={()=>{
                                    inputRef.current.click()
                                }}
                        >
                            Browse Files
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default UploadImageFolder