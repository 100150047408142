import React from 'react'

const PercentUploadLoader = ({ percent }) => {
    return (
        <div className="relative mt-4 bg-slate-300 h-4 w-[60%] rounded-full">
            <div className="w-0 bg-[green] h-full rounded-full transition-all duration-500 py-[5px]" style={{ width: `${percent}%` }}></div>
            <div className="absolute text-white text-[12px] inset-0 flex items-center justify-center py-[5px]">Uploaded {percent}%</div>
        </div>
    )
}

export default PercentUploadLoader