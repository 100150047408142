import React, { useContext, useEffect, useMemo, useState } from 'react'
import DropDownIcon from '../../../../assets/images/dropdown.svg'
import SearchIcon from "../../../../assets/images/search-icon.svg"
import { ApiService } from '../../../../services/apiUtility'
import ManageLooksColorPicker from './ManageLooksColorPicker'
import ManageLooksDisablePopup from './ManageLooksDisablePopup'
import { ManageLooksContext } from '../../../../context/LooksContext'
import CloseCatalogIcon from '../../../../assets/images/close-catalog.svg'

const ManageLooksChooseProductCategory = ({
    productCategories,
    preSelectedProducts,
    lookKey,
    addOrRemoveProduct,
    preSelectedCategory,
    previewClickProduct,
}) => {

    const [displayProductCategories, setDisplayProductCategories] = useState(productCategories)
    const {
        color, setColor,
        searchType, setSearchType,
        productsArr, setProductsArr,
        previewProduct
    } = useContext(ManageLooksContext)
    const [selectedCategoryDropdown, setSelectedCategoryDropdown] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState({
        label: productCategories && productCategories?.[0]?.label,
        category: productCategories && productCategories?.[0]?.category
    })
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false)
    const [confirmationPopupValues, setConfirmationPopupValues] = useState({
        checkbox: localStorage.getItem('product_category_confirmation') == true,
        productValue: null,
        productBoolean: false
    })

    const [disableLookPopup, setDisableLookPopup] = useState(false)

    useEffect(() => {
        if(productCategories && productCategories.length>0){
            setDisplayProductCategories(productCategories)
            if (!selectedCategory.label) {
                setSelectedCategory({
                    label: productCategories?.[0]?.label,
                    category: productCategories?.[0]?.category
                })
            }
        }
    }, [productCategories])

    useEffect(() => {
        const firstProductWithInventory = productCategories?.findIndex(item => item.active_product_count > 0)
        if (firstProductWithInventory)
            setConfirmationPopupValues({
                label: productCategories?.[firstProductWithInventory]?.label,
                category: productCategories?.[firstProductWithInventory]?.category
            })
    }, [productCategories])

    useEffect(() => {
        setSelectedCategory(preSelectedCategory)
    }, [preSelectedCategory])

    const selectCategory = (item) => {
        setSelectedCategoryDropdown(false)
        setColor("")
        setSearchType('text_picker')
        setSelectedCategory({
            label: item.label,
            category: item.category
        })
        getRecommendedProducts(item.category)
    }

    const getRecommendedProducts = async (category = null) => {
        // const products = await ApiService.getRecommendedProducts(category || selectedCategory.category, 'rgb(0,0,0)', 0)
        const productsAll = await ApiService.getCategoryProductsAndVariants(category || selectedCategory.category, undefined, 0)
        if (productsAll?.data) {
            const allProductAndVariants = []
            productsAll.data.map(({
                brand_id, collection, product_handle, product_code, product_category_id, sub_brand_id, product_images, product_meta_json, product_status,
                variants,
                ...restData
            }) => {
                if (variants?.length) {
                    variants.map((variant) => {
                        // Push the values of the variant in the allProductAndVariants array
                        // Parent values will remain same for all the Variants 
                        allProductAndVariants.push({
                            product: {
                                brand_id, collection, product_handle, product_code, product_category_id, sub_brand_id, product_images, product_meta_json, product_status,
                            },
                            ...restData,
                            ...variant
                        })
                    })
                }
            })

            let productsFilteredArr = null
            if (color) {
                setSearchType('color_picker')
                productsFilteredArr = await ApiService.getRecommendedProducts(category || selectedCategory.category, color, 85)
            }
            // Object.values(products)?.map((item, index) => {
            //     const variant = preSelectedProducts.find((product, index) => {
            //         return product.variant.variant_id === item.variant_id
            //     })
            // })

            setProductsArr({
                mainArray: allProductAndVariants,
                searchedArray: productsFilteredArr ? Object.values(productsFilteredArr)[0] : null
            })
        }
    }

    const lowerCase = (text) => text ? text.toLowerCase() : ''

    const searchProductByText = async e => {
        const searchedText = e.target.value.toLowerCase()
        if (!searchedText) setProductsArr({
            ...productsArr,
            searchedArray: null
        })
        const searchedArray = productsArr.mainArray.filter((product) => lowerCase(product?.product?.code).includes(searchedText)
            || lowerCase(product?.variant_color_name).includes(searchedText)
            || lowerCase(product?.variant_sku).includes(searchedText)
            || lowerCase(product?.variant_finish).includes(searchedText)
            || lowerCase(JSON.stringify(product?.variant_filter || {})).includes(searchedText)
        )
        setProductsArr({
            ...productsArr,
            searchedArray: searchedArray
        })
    }

    const searchProductByColor = async (color) => {
        if (searchType !== "color_picker" || !color) return
        try {
            const products = await ApiService.getRecommendedProducts(selectedCategory.category, color, 85)
            setProductsArr({
                ...productsArr,
                searchedArray: Object.values(products)[0]
            })
        } catch (error) {
            setProductsArr({
                ...productsArr,
                searchedArray: []
            })
        }
    }

    useEffect(() => {
        searchProductByColor(color)
    }, [color])

    useEffect(() => {
        if (selectedCategory.category) {
            getRecommendedProducts()
        }
    }, [selectedCategory])
    // useEffect(() => {
    //     // setColor("rgb(253,2,2)")
    //     setProductsArr({
    //         ...productsArr,
    //         searchedArray: null
    //     })
    // }, [searchType])
    const ColorPickerMemo = useMemo(() => {
        return (
            <ManageLooksColorPicker />
        )
    }, [color])

    const checkIfSelected = (product) => {
        return !!preSelectedProducts.find((item) => item.variant.variant_id === product.variant_id)
    }

    const searchCategories = e => {
        const searchedText = lowerCase(e.target.value)
        if (!searchedText) {
            setDisplayProductCategories(productCategories)
            return
        }
        const filteredArr = productCategories.filter((item) => lowerCase(item.label).includes(searchedText)
            || lowerCase(item.category).includes(searchedText)
        )
        setDisplayProductCategories(filteredArr)
    }

    const checkIfClicked = (product) => {
        return !!Object.values(previewProduct).find((item) => item && item.variant.variant_id === product.variant_id)
    }

    const changeColorType = (val) => {
        setColor("")
        setSearchType(val)
        setProductsArr({
            ...productsArr,
            searchedArray: null
        })
    }

    const confirmationLinkProcess = (product, addProduct = true) => {
        if (addProduct) {
            const productFind = displayProductCategories.find((category) => category.category === product.category.category && category.selected)
            if (confirmationPopupValues.checkbox || !productFind) {
                addOrRemoveProduct(product, addProduct)
            } else {
                setConfirmationPopupValues({
                    ...confirmationPopupValues,
                    productValue: product,
                    productBoolean: addProduct
                })
                setOpenConfirmationPopup(true)
            }
        } else {
            addOrRemoveProduct(product, addProduct)
        }
    }

    const updateProductConfirmationClick = () => {
        if (confirmationPopupValues.checkbox) {
            localStorage.setItem('product_category_confirmation', 'true')
        }

        addOrRemoveProduct(confirmationPopupValues.productValue, confirmationPopupValues.productBoolean)
        setConfirmationPopupValues({
            checkbox: JSON.parse(localStorage.getItem('product_category_confirmation') || 'false'),
            productValue: null,
            productBoolean: false
        })
        setOpenConfirmationPopup(false)
    }

    const closeConfirmationPopup = () => {
        setConfirmationPopupValues({
            checkbox: JSON.parse(localStorage.getItem('product_category_confirmation') || 'false'),
            productValue: null,
            productBoolean: false
        })
        setOpenConfirmationPopup(false)
    }

    return (
        <div className="bg-[#FFFFFF] w-full rounded-md h-full flex flex-col">
            {
                disableLookPopup
                    ? <ManageLooksDisablePopup setDisableLookPopup={setDisableLookPopup} />
                    : null
            }
            <div className={`drop-shadow-[0px_0px_4px_0px_rgba(0,0,0,0.15)] sticky top-0 z-10 bg-white `}>
                <span id='category_dropdown' onClick={() => setSelectedCategoryDropdown(!selectedCategoryDropdown)} className={`cursor-pointer block px-3 py-2 rounded-[4px] ${selectedCategoryDropdown ? 'rounded-b-none border-b-0' : ''}  border border-[#8E8E8E]`}>{selectedCategory.label || 'Select Looks Category'}</span>
                <span><img src={DropDownIcon} alt='dropdown-icon' className={`absolute right-[20px] top-[21px] -translate-y-1/2 transition-all ${selectedCategoryDropdown ? 'rotate-180' : 'rotate-0'}`} /></span>
                {
                    selectedCategoryDropdown
                        ? <div className=' z-10 mb-2 px-3 pb-2 absolute left-0 rounded-t-none top-full w-full bg-[#fff] rounded-[4px] border border-t-0 border-[#8E8E8E] drop-shadow-[0px_0px_4px_0px_rgba(0,0,0,0.15)'>
                            <div className='flex px-4 mb-3 bg-[#F5F5F5] w-full py-3 text-[12px] rounded-[4px] '>
                                <img src={SearchIcon} alt='search-icon' />
                                <input type='text' className=' w-full outline-none pl-2 bg-transparent placeholder:text-[#9E9898]' placeholder={`Search ${displayProductCategories?.length} Categories`} onChange={searchCategories} />
                            </div>
                            <div className='max-h-[250px] overflow-y-auto scrollbar-container3 '>
                                {
                                    displayProductCategories?.map((item, index) => {
                                        return (
                                            <span
                                                id={`category_${index}`}
                                                className={`flex justify-between items-center hover:bg-[#EFEFEF] gap-3 cursor-pointer rounded-[4px] py-2 mr-2 px-4 ${item.selected ? 'font-bold' : ''}`}
                                                key={index}
                                                onClick={() => selectCategory(item)}
                                                style={{
                                                    pointerEvents: !item.active_product_count ? 'none' : 'auto'
                                                }}
                                            >
                                                <span className={`line-clamp-1 ${!item.active_product_count ? 'w-3/4' : ''}`}>{item.label}</span>
                                                {!item.active_product_count && <span className='text-[10px] w-1/4 text-[#797979] font-bold'>No Active Products</span>}
                                                {item.selected ? <span className='text-[#797979] text-[10px] rounded-[4px] px-2 py-1 border-[0.5px] border-[#797979]'>Added</span> : null}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        : null
                }
            </div>
            {
                productsArr.mainArray.length
                    ? <div className='flex flex-col h-[calc(100%-42px)]'>
                        <div>
                            <div className='flex mt-4'>
                                <span id='color-picker' className={`w-1/2 cursor-pointer text-center pb-2 transition-all border-b-2 border-[${searchType === 'color_picker' ? '#000' : '#D9D9D9'}]`} onClick={() => changeColorType('color_picker')}>Color Picker</span>
                                <span id='text-picker' className={`w-1/2 cursor-pointer text-center pb-2 transition-all border-b-2 border-[${searchType === 'text_picker' ? '#000' : '#D9D9D9'}]`} onClick={() => changeColorType('text_picker')}>Text Picker</span>
                            </div>
                            {
                                searchType === 'text_picker'
                                    ? <div>
                                        <input
                                            onChange={searchProductByText}
                                            placeholder='Search Product Name'
                                            className='border border-[#D9D9D9] bg-[#F5F5F5] my-2 w-full rounded-[4px] text-[12px] py-2 outline-none px-4' type='text' />
                                    </div>
                                    : searchType === 'color_picker'
                                        ? <div className='mt-2'>
                                            {ColorPickerMemo}
                                        </div>
                                        : null
                            }
                        </div>
                        <p className='flex justify-between px-3 py-3 border-b border-[#D9D9D9] '> <span className='text-[14px] '>Products</span> <span className='text-[#929292] text-[10px]'>{(productsArr.searchedArray || productsArr.mainArray).length} {productsArr.length} product{(productsArr.searchedArray || productsArr.mainArray).length === 1 ? '' : 's'}</span> </p>
                        <div id='product-scroll-container' className='flex-grow overflow-y-auto scrollbar-container3 px-2 '>
                            {(productsArr.searchedArray || productsArr.mainArray).length
                                ? (productsArr.searchedArray || productsArr.mainArray).map((product, index) => {
                                    const productColorStyle = {
                                        backgroundColor: `${product?.variant_color?.split(')')?.[0]})`,
                                    }
                                    const selected = checkIfSelected(product)
                                    const productClicked = checkIfClicked(product)
                                    return (
                                        <div id={`product-list-${index}`} key={index} className={`py-2 px-1 cursor-pointer ${productClicked ? 'bg-[#DCDBDB] hover:bg-[#F3F3F3]' : 'bg-[#fff] hover:bg-[#EEEDED]'}  w-full gap-3 flex border-b items-stretch border-[#D9D9D9] last-of-type:border-b-0`}>
                                            <span className={` w-[15%] flex items-stretch`} onClick={() => previewClickProduct(product)}
                                            >
                                                {
                                                    searchType === 'color_picker' && color && productsArr.searchedArray
                                                        ? <span style={{ backgroundColor: color }} className='rounded-l-[4px] w-1/6 h-full'>&nbsp;</span>
                                                        : null
                                                }
                                                <span style={productColorStyle} className={`flex-grow ${searchType === 'color_picker' && productsArr.searchedArray ? 'rounded-r-[4px]' : 'rounded-[4px]'}  h-full w-auto`}>&nbsp;</span>
                                            </span>
                                            <span
                                                className='text-[12px] w-min text-[#333333] flex-grow h-full my-auto'
                                                onClick={() => previewClickProduct(product)}
                                                title={product?.product_title || product?.variant_sku}
                                            >
                                                <span className='w-full font-light line-clamp-2 '>{product.variant_sku}</span>
                                                <span
                                                    className='text-[12px] w-full text-[#333333] flex-grow h-full my-auto'
                                                >{product?.variant_finish ? <span className='font-semibold capitalize'>{product?.variant_finish}</span> : null} {product?.variant_feature ? <span className='font-semibold capitalize'> - {product?.variant_feature}</span> : null}</span>
                                            </span>
                                            <button type='button' id={`product-list-add-button-${index}`} className={`${selected ? 'bg-[#4525DD] text-[#fff]' : ''} w-[20%] text-center border py-2 rounded-[4px] border-[#000]`} onClick={() => confirmationLinkProcess(product, !selected)}>{selected ? 'Added' : 'Add'}</button>
                                        </div>
                                    );
                                })
                                : <div className={`h-full flex flex-col text-[#A0A0A0] text-[12px] gap-3 justify-center items-center text-center`}>
                                    <span>No product found</span>
                                    <span>Use search bar to get product recommendation.</span>
                                </div>
                            }
                        </div>
                        <div className='flex gap-2 mt-3'>
                            <button type="submit" name='Finish' className='w-3/5 text-center py-3 bg-[#2C2C2C] rounded-[4px] text-[#fff]'>Finish Look</button>
                            <button type="button" name='Discard' className='w-2/5 text-center py-3 border border-[#2C2C2C] rounded-[4px]' onClick={() => setDisableLookPopup(true)}>Discard Look</button>
                        </div>
                    </div>
                    : <div className={`h-full`}>

                    </div>
            }
            {
                openConfirmationPopup
                    ? <div className='fixed h-full w-full top-0 left-0 bg-[rgba(255,255,255,0.4)] z-20 backdrop-blur-sm'>
                        <div className='absolute flex flex-col py-4 rounded-[6px] px-6 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[455px] h-[275px] bg-[#fff] border border-[#D6D6D6]'>
                            <img src={CloseCatalogIcon} alt='close button' className='cursor-pointer absolute top-7 right-4 w-3 h-3' onClick={closeConfirmationPopup} />
                            <p className='text-[24px] mb-4'>Update new product</p>
                            <p className='text-[14px] font-light mb-3'>This will update the previous product in this category with the current selected product.</p>
                            <p className='text-[12px] text-[#E23D3D]'> Note: Only one product can be added per category.</p>
                            <div className='flex-grow'>&nbsp;</div>
                            <div className='flex gap-2 w-full mb-4'>
                                <input type='checkbox' id='update-product-checkbox' className='ml-auto w-[20px] h-[20px] accent-white checkbox' onChange={e => setConfirmationPopupValues({ ...confirmationPopupValues, checkbox: e.target.checked })} />
                                <label htmlFor='update-product-checkbox' className=' text-[12px] text-[#666]'>Remember my choice</label>
                            </div>
                            <div className='flex items-center gap-4'>
                                <button type='button' className='w-2/5 ml-auto py-3 border-[2px] border-[#000] rounded-[4px]' onClick={closeConfirmationPopup}  >Cancel</button>
                                <button type='button' className='w-2/5 py-3 border-[2px] border-transparent bg-[#4552C5] text-[#fff] rounded-[4px]' onClick={updateProductConfirmationClick}>Update</button>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default ManageLooksChooseProductCategory
