import React,{  useContext, useRef, useState } from 'react'
import { BiEdit } from "react-icons/bi";
import { MdOutlinePhonelinkOff,MdOutlinePhonelink,MdOutlineDeleteOutline } from "react-icons/md";
import { AppContext } from '../../../context/AppContextProvider';
import { useNavigate } from 'react-router-dom';

export const ProductCard = ({product,handleShowOrHideVariantsTable=()=>{},
    enableOrDisableProduct=()=>{},fetchCategories=()=>{},
    deleteProduct=()=>{},handleCheckboxClick=()=>{},selectedProducts,handleCategoryOnClick,selectedCategory})=>{
    const {setShowNotification} = useContext(AppContext)
    const viewTooltipRef= useRef(null)
    const deleteTooltipRef= useRef(null)
    const enableTooltipRef= useRef(null)
    const disableTooltipRef= useRef(null)
    const navigate = useNavigate()
    const handleIsChecked=()=>{
        var data = selectedProducts.find(function(ele) {
            return ele.productId == product.productId
        });
        if(data){
            return true
        }else{
            return false
        }  
    }

    const handleEditProduct = ()=>{
        handleShowOrHideVariantsTable(true,product)
    }

    const handleDeleteProduct = ()=>{
        deleteProduct(product)
    }
    
    const handleEnableOrDisableProduct = async (val)=>{
            const params = {
                product:[
                    {
                        "productId": product.productId,
                        "productStatus":val
                    }
                 ]
            }
            const res= await enableOrDisableProduct(params,product.productSubBrandId) 
            if(res.status==200 && res.data.product && res.data.product.success && res.data.product.success.length>0){
                handleCategoryOnClick(selectedCategory.category,true)
                setShowNotification({open:true,message:`Product ${val?'enabled':'disabled'}`,type:'success',hideAutomatically:true})
            } else{
                if(res.status==403){
                    localStorage.clear()
                    navigate('/authentication/sign-in')
                    setShowNotification({open:true,message:'Unauthorized! You have been logged out',type:'error',hideAutomatically:true})
                }
                else{
                    setShowNotification({open:true,message:'There is some error',type:'error',hideAutomatically:true})
                }
            }
    }

    return (
        <div className='flex flex-col w-[280px] rounded border-[1px] h-[325px] border-[#0001] hover:shadow-lg'>
            <div className='flex flex-col gap-[10px] p-[20px]'>
                    <div> 
                    <input
                        type='checkbox'
                        id={product.productId}
                        data-subbrandid={product.productSubBrandId}
                        onChange={handleCheckboxClick}
                        checked={handleIsChecked()}
                        className='w-[20px] h-[20px] accent-white checkbox'
                    />
                    </div>
                    <div className='flex justify-start items-center relative'>
                        {
                            !product.productStatus?
                            (
                                <div className='absolute w-[200px] top-0 right-0 bottom-0 left-0 z-1 flex items-center justify-center rounded bg-[rgba(0,0,0,.5)]'>
                                    <p className="p-[10px] text-red bg-[#ffe4e1] rounded ">DISABLED</p>
                                </div>
                            ):''
                        }
                        <div>
                            {
                                product.productImages && product.productImages[0]?
                                <img src={product.productImages[0]} alt="product" className='w-[200px] h-[200px] object-contain'/>
                                :<div className='w-[200px] h-[200px] flex justify-center items-center text-white bg-gray-400'>No image available</div>
                            }
                            
                        </div>
                        <div className='flex flex-col border-[2px] absolute top-0 right-[-10px] '>
                            <div className='relative p-[8px] flex justify-center items-center border-b-[2px] cursor-pointer'
                                onClick={handleEditProduct}
                                onMouseEnter={(e) => {
                                    viewTooltipRef.current.classList.add('visible')
                                    viewTooltipRef.current.classList.remove('invisible')
                                }}
                                onMouseLeave={(e) => {
                                    viewTooltipRef.current.classList.add('invisible')
                                    viewTooltipRef.current.classList.remove('visible')
                                }}
                            >
                                <BiEdit size={20}/>
                                <div ref={viewTooltipRef} className="tooltip text-[12px] rounded-[4px]  text-[white] py-[5px] px-[10px] whitespace-nowrap font-normal bg-black block invisible  absolute z-30  right-[40px] ">
                                    View Variants
                                </div>
                            </div>
                            <div className='p-[8px] relative flex justify-center items-center border-b-[2px] cursor-pointer'
                                onClick={handleDeleteProduct}
                                onMouseEnter={(e) => {
                                    deleteTooltipRef.current.classList.add('visible')
                                    deleteTooltipRef.current.classList.remove('invisible')
                                }}
                                onMouseLeave={(e) => {
                                    deleteTooltipRef.current.classList.add('invisible')
                                    deleteTooltipRef.current.classList.remove('visible')
                                }}
                            >
                                <MdOutlineDeleteOutline size={20}/>
                                <div ref={deleteTooltipRef} className="tooltip text-[12px] rounded-[4px]  text-[white] py-[5px]   font-normal bg-black block invisible  absolute z-30 w-[90px] right-[40px] ">
                                    Delete
                                </div>
                            </div>
                            <div className='p-[8px] relative flex justify-center items-center border-b-[2px] cursor-pointer'
                                onClick={()=>{handleEnableOrDisableProduct(true)}}
                                onMouseEnter={(e) => {
                                    enableTooltipRef.current.classList.add('visible')
                                    enableTooltipRef.current.classList.remove('invisible')
                                }}
                                onMouseLeave={(e) => {
                                    enableTooltipRef.current.classList.add('invisible')
                                    enableTooltipRef.current.classList.remove('visible')
                                }}
                            >
                                <MdOutlinePhonelink size={20}/>
                                <div ref={enableTooltipRef} className="tooltip text-[12px] rounded-[4px]  text-[white] py-[5px]   font-normal bg-black block invisible  absolute z-30 w-[90px] right-[40px] ">
                                    Enable
                                </div>
                            </div>
                            <div className='p-[8px] relative flex justify-center items-center cursor-pointer'
                                onClick={()=>{handleEnableOrDisableProduct(false)}}
                                onMouseEnter={(e) => {
                                    disableTooltipRef.current.classList.add('visible')
                                    disableTooltipRef.current.classList.remove('invisible')
                                }}
                                onMouseLeave={(e) => {
                                    disableTooltipRef.current.classList.add('invisible')
                                    disableTooltipRef.current.classList.remove('visible')
                                }}
                            >
                                <MdOutlinePhonelinkOff size={20}/>
                                <div ref={disableTooltipRef} className="tooltip text-[12px] rounded-[4px]  text-[white] py-[5px]   font-normal bg-black block invisible  absolute z-30 w-[90px] right-[40px] ">
                                    Disable
                                </div>
                            </div>
                        </div>
                    </div>    
                    <div className='text-[12px] flex gap-[20px] items-center'>
                        <span className='w-full line-clamp text-center'>{product.productTitle}</span>
                    </div>
            </div>
        </div> 
    )
}