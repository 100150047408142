import React, { useContext, useRef, useState, useEffect } from 'react'
import "cropperjs/dist/cropper.css";

import DeleteIcon from '../../../../assets/images/delete_image.svg'
import { AppContext } from '../../../../context/AppContextProvider';
import { CreateLooksContext } from '../../../../context/LooksContext';

const ReferenceVideo = () => {
    const { setReferenceVideo, referenceVideo } = useContext(CreateLooksContext)
    const { setShowNotification } = useContext(AppContext)
    const [error, setError] = useState(false)

    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (!file.type.startsWith('video/')) {
            setShowNotification({ open: true, type: 'failure', message: "Please upload a valid video file", hideAutomatically: true })
            return;
        }

        if (file.size / 1000000 >= 50) {
            setError(true)
            setShowNotification({ open: true, type: 'failure', message: "Video size should be less than 50MB", hideAutomatically: true })
            return;
        }

        setReferenceVideo({
            type: file.type,
            videoUrl: URL.createObjectURL(file),
            name: file.name,
            file: file
        })
        setError(false)
    }

    const deleteVideo = () => {
        setReferenceVideo({
            type: '',
            videoUrl: null,
            name: '',
            file: null
        })
    }
    

    return (
        <div className='w-full h-full relative'>
            {referenceVideo.videoUrl ? (
                <>
                    <img onClick={deleteVideo} src={DeleteIcon} className="cursor-pointer absolute right-[15px] top-[15px] z-10" alt='delete icon' />
                    <video src={referenceVideo.videoUrl} controls className='w-full h-full object-cover rounded-[12px]' />
                </>
            ) : (
                <div className='w-full h-full flex flex-col items-center justify-center border border-dashed border-gray-300 rounded-[12px]'>
                    <p className='text-[18px] mb-2'>Upload Look Video</p>
                    <input 
                        type="file" 
                        accept="video/*" 
                        onChange={handleVideoChange}

                        className='hidden'
                        id="videoUpload"
                    />
                    <label 
                        htmlFor="videoUpload" 
                        className='bg-blue-500 text-white px-4 py-2 rounded cursor-pointer hover:bg-blue-600'
                    >
                        Select Video
                    </label>
                    {error && <p className='text-red-500 mt-2'>Video size should be less than 50MB</p>}
                </div>
            )}
        </div>
    )
}



export default ReferenceVideo