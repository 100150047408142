import React from 'react'

const AnalyticsOptionsDropDown = ({handleAnalyticsOption,handleTimePeriodType,
    handleDateStart,handleDateEnd,analyticsOptions,
    timePeriodType,dateStart,
    dateEnd}) =>{

    const handleAnalyticsOptionChange = (event)=>{
        handleAnalyticsOption(event.target.value)
    }

    const handleTimePeriodTypeChange = (event)=>{
        handleTimePeriodType(event.target.value)
    }

    const handleStartDateChange = (e)=>{
        handleDateStart(e.target.value)
    }

    const handleEndDateChange = (e)=>{
        handleDateEnd(e.target.value)
    }

    return (
        <div className="w-full flex justify-around gap-[10px] event-selection-container2 flex-wrap">
            <div>
                <select
                    value={analyticsOptions}
                    onChange={handleAnalyticsOptionChange} 
                    className='h-[20px] text-[12px] text-center rounded border-[2px] border-black bg-white'
                >
                    <option value='uniques'>
                        Uniques
                    </option>
                    <option value='totals'>
                        Event Totals
                    </option>
                </select>
            </div>  
            <div>
                <select
                    value={timePeriodType}
                    onChange={handleTimePeriodTypeChange} 
                    className='h-[20px] text-[12px] text-center rounded border-[2px] border-black bg-white'
                >
                    <option value='1'>
                        Daily
                    </option>
                    <option value='7'>
                        Weekly
                    </option>
                    <option value='30'>
                        Monthly
                    </option>
                </select>
            </div> 
            <div className='flex items-center gap-[10px]'>
                    <span className='text-[14px]'>Start</span>
                    <input type="date" className='text-center text-[12px] rounded bg-white cursor-pointer border-[2px] border-black'
                        value={dateStart}
                        onChange={handleStartDateChange}
                    />
            </div>
            <div className='flex items-center gap-[10px]'>
                    <span className='text-[14px]'>End</span>
                    <input type="date" className='text-center text-[12px] rounded bg-white cursor-pointer border-[2px] border-black'
                        value={dateEnd}
                        onChange={handleEndDateChange}
                    />
            </div>
        </div>
        
      );
}

export default AnalyticsOptionsDropDown
