import React, { useContext, useState } from 'react'
import { AppContext } from '../../../context/AppContextProvider'
import { ApiService } from '../../../services/apiUtility'
import { useLocation, useNavigate } from 'react-router-dom'

const ResetPassword = () => {
    const { setShowNotification } = useContext(AppContext)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(null);
    const [newPasswordError,setNewPasswordError] = useState(null)
    const [confirmPasswordError,setConfirmPasswordError] = useState(null)
    const search = useLocation().search;
    const uuid=new URLSearchParams(search).get("uuid")
    const navigate=useNavigate()
    
    const validateEmail = (email) => {
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        const [emailPrefix, emailDomain] = email.split('@');
        const isValidEmail = pattern.test(email);
        const hasValidDomain = emailDomain && emailDomain.includes('.');

        return isValidEmail && hasValidDomain;
    };

    const validatePasswordLength = ()=>{
        if(newPassword.length<8 || newPassword.length>12){
          setNewPasswordError('Password length should be between 8 and 12 characters')
          return false
        }
        return true
    }

    const validatePassword = () => {
        return confirmPassword == newPassword
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(!validatePasswordLength()){
            return
        }
        if (!validateEmail(email)) {
            setEmailError("Invalid email format");
            return;
        }
        setEmailError(null);
        if (!validatePassword()) {
            setShowNotification({ open: true, message: 'New Password and confirm Password do not match', type: 'error',hideAutomatically:true })
            return;
        }
        const params = {
            "uuid":uuid,
            "email": email,
            "newPassword": newPassword,
            "confirmPassword": confirmPassword,
            "nextPath":`${window.location.origin}/authentication/sign-in?email=${email}`
        }
        const response = await ApiService.resetPassword(params)
        if (response && response.status == '200') {
            setShowNotification({ open: true, message: response.message, type: 'success',hideAutomatically:true })
            navigate(`/authentication/sign-in?email=${email}`)
        } else {
            setShowNotification({ open: true, message: response.message, type: 'error',hideAutomatically:true })
        }
    };
    return (
        <div className='relative w-[40%] py-[50px] rounded bg-[#fff]'>
            <div className="relative text-center">
                <h1>Reset Password</h1>
            </div>
            <div className="mt-[60px]">
                <form onSubmit={handleSubmit} className='h-[45%] w-[75%] flex flex-col max-w-[450px] m-auto gap-[40px]'>
                    <div className=''>
                        <span className='ml-[5px] text-[#00000093] font-medium'>Email</span>
                        <div>
                            <input
                                type="text"
                                placeholder="Enter email"
                                className="h-[50px] mt-[5px] bg-white border border-gray-300 text-gray-900 text-sm rounded w-full p-4"
                                required
                                value={email}
                                onChange={(event) => {
                                    setEmailError('')
                                    setEmail(event.target.value)
                                }}
                                id="email"
                                name="email"
                            />
                            {
                                emailError?
                                <label className="label">
                                    <span className="label-text-alt text-red-500">{emailError}</span>
                                </label>:''
                            }
                        </div>
                    </div>
                    <div className="">
                        <span className='ml-[5px] text-[16px]'>New Password*</span>
                        <div>
                            <input
                                name={Math.random()*100}
                                type="password"
                                placeholder="Enter new password"
                                className="w-[100%] h-[54px] mt-[10px] border bg-white border-gray-300 text-[#D9D9D9] text-[14px] rounded pl-[20px] focus:text-black"
                                required
                                value={newPassword}
                                onChange={(event) =>{ 
                                    setNewPassword(event.target.value)
                                    setNewPasswordError(null)
                                }}
                                autoComplete='new-password'
                            />
                            {
                                newPasswordError?
                                <label className="label">
                                    <span className="label-text-alt text-red-500">{newPasswordError}</span>
                                </label>:''
                            }
                        </div>
                    </div>
                    <div className="">
                        <span className='ml-[5px] text-[16px]'>Confirm Password*</span>
                        <div>
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                className="w-[100%] h-[54px] mt-[10px] border bg-white border-gray-300 text-[#D9D9D9] text-[14px] rounded pl-[20px] focus:text-black"
                                required
                                value={confirmPassword}
                                onChange={(event) => {
                                    setConfirmPassword(event.target.value)
                                    setConfirmPasswordError(null)
                                }}
                            />
                            {
                                confirmPasswordError?
                                <label className="label">
                                    <span className="label-text-alt text-red-500">{confirmPasswordError}</span>
                                </label>:''
                            }
                        </div>
                    </div>
                    <div className="flex w-full justify-center mt-[20px]">
                        <button
                            className="bg-black rounded text-white w-full h-[50px] font-medium"
                        >
                            <span>Submit</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword