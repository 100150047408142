import React, { useContext, useEffect, useState } from 'react'
import * as _ from 'lodash'
import { MdDelete,MdModeEditOutline } from "react-icons/md";
import { AppContext } from '../../../../context/AppContextProvider';
import DeleteConfirmation from '../../../Dialog/DeleteConfirmation';
import { FiEdit } from "react-icons/fi";

const TableDataCell = ({data, dataKey, onChange = () => {},id,editableRowIndex,updateImageIndex,setUpdateImageIndex}) => {
  const [val, setVal] = useState(data?data:'')
  const [isImage, setIsImage] = useState()
  const [imagePreview,setImagePreview] = useState({src:'',show:false})
  const [showFullContent,setShowFullContent] = useState(false)
  const finish=['matte', 'metallic', 'glossy', 'satin', 'lipgloss', 'shimmer', 'lipglossShimmer', 'glitter','none']
  const isImageAvailable = () => {
    return dataKey.toLowerCase().includes('image') && data
  }

  const previewImage = (event,src) =>{
    if(event.type=='mousedown'){
      setImagePreview({src:src,show:true})
    }else{
      setImagePreview({src:'',show:false})
    }
  }

  const hexToRgb = (hex)=>{
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }

  const rgbToHex = (rgbValue)=> {
    var rgb = rgbValue.replace(/^rgba?\(|\s+|\)$/g,'').split(',');
    const r = rgb[0]
    const g= rgb[1]
    const b= rgb[2]
    return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
  }

  useEffect(() => {
    setVal(data)
    setIsImage(isImageAvailable())
  }, [data])

  return (
    <>
      {
        isImage && updateImageIndex!=id  ? 
        <div className='relative' onMouseUp={(event)=>previewImage(event,val)}>
          {
            val[0]==undefined || val[0]==''?
            <div className='w-[75px] h-[75px] flex justify-center text-center items-center text-white bg-gray-400 rounded text-[10px]'>No image available</div>
            :<img
              src={val[0]}
              className="w-[75px] h-[75px] rounded"
              alt="data"
              onMouseDown={(event)=>previewImage(event,val)}
            />
          }
          
          <div className='absolute bottom-0 left-[80px] rounded bg-white/70'>
            <div onClick={() => {
              if(id==editableRowIndex){
                setIsImage(false)
                setUpdateImageIndex(id)
              }
              }}>
              <MdModeEditOutline size='20' className='cursor-pointer'/>
            </div>
          </div>
          {
            imagePreview.show?(
              <div className='absolute top-[-24px] left-[0px] z-10 border border-slate-200'>
                {
                  imagePreview.src?
                  <img src={imagePreview.src} alt="" className='w-[120px] h-[120px]'/>
                  :<div className='w-[120px] h-[120px] flex justify-center items-center text-white bg-gray-400'>No image available</div>
                }
                  
              </div>
            ):''
          }
        </div>
        :dataKey.toLowerCase().includes('status')?
        <div className="border-[1px] mt-[5px] w-[50%]">
                <select className="bg-white text-center cursor-pointer w-full min-h-[36px] max-h-[36px]"
                    disabled={id!=editableRowIndex}
                    value={val}
                    onChange={(event)=> {
                      if(id==editableRowIndex){
                        onChange(event.target.value)
                      }
                    }}
                >
                    <option value={true}>Enabled</option>
                    <option value={false}>Disabled</option>
                </select>
            </div>
        :dataKey.toLowerCase().includes('finish')?
        (<div>
            <div className="border-[1px] mt-[5px]">
                <select className="bg-white text-center cursor-pointer w-full min-h-[36px] max-h-[36px]"
                    disabled={id!=editableRowIndex}
                    value={val!=''?val:'none'}
                    onChange={(event)=> {
                      if(id==editableRowIndex){
                        onChange(event.target.value)
                      }
                    }}
                >
                    {
                        finish.map((type,index)=>{
                            return <option className='bg-white text-black' key={index}
                                        value={type} 
                                    > {type.charAt(0).toUpperCase() + type.slice(1)}</option>
                        })
                    }
                </select>
            </div>
        </div>)
        :dataKey.toLowerCase()=='variant_color'?
        (<div>
        
        <div className="relative flex mt-[5px] ">
          <input
            type="text"
            className="!outline-none bg-white h-[50px] w-[70%] bg-white text-gray-900 text-sm rounded p-4"
            value={data}
            readOnly={true} 
          />
          <input 
            type="color" 
            className="h-[50px] !outline-none bgt-white cursor-pointer"
            value={rgbToHex(data)} 
            disabled={id!=editableRowIndex}
            onChange={(e)=>{
              if(id==editableRowIndex){
                const rgbObj = hexToRgb(e.target.value)
                const rgbValue = `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
                onChange(rgbValue)
              }
            }}/>
        </div>
      </div>)
        :dataKey.toLowerCase()=='variant_sku' 
        ?
        (id!=editableRowIndex)?
        <textarea 
          type="text" 
          value={val} 
          className='!outline-none w-full bg-white text-[14px]' 
          onChange={(event)=> {
            onChange(event.target.value)
          }}
          readOnly
        />
        :<textarea 
        type="text" 
        value={val} 
        className='!outline-none w-full bg-white text-[14px]' 
        onChange={(event)=> {
          onChange(event.target.value)
        }}
      />
      :<input 
      type="text" 
      value={val} 
      className='!outline-none w-full bg-white text-[14px]' 
      onChange={(event)=> {
        onChange(event.target.value)
      }}
      readOnly={dataKey.toLowerCase().includes('id') || (id!=editableRowIndex)}
    />
      }
    </>
  )
}

const ActionsMenu = ({onDelete, data,handleEditVariantProperties,rowIndex})=>{
  return <div className="dropdown py-[5px] px-[16px]">
              <label tabIndex="0" className="flex cursor-pointer  text-[16px] font-medium min-w-[70px] min-h-[36px] max-h-[36px]">
                  <div className='flex items-center justify-center px-[10px] py-[2px] border-[1px]'>...</div>
              </label>
              <ul tabIndex="0" className="dropdown-content translate-x-[-70px] translate-y-[-75px]
                          menu min-w-[70px] cursor-pointer shadow shadow-gray-500"
              >
                  <li>
                      <div className='w-full hover:bg-[#f2f2f2] bg-white flex px-[16px] text-[14px]' onClick={()=>handleEditVariantProperties(rowIndex)}>
                            <FiEdit size='14'/> Edit 
                      </div>
                  </li>
                  <li>
                      <div className='w-full hover:bg-[#f2f2f2] bg-white flex px-[16px] text-[14px]' onClick={()=>onDelete(data)}>
                            <MdDelete size='14'/> Delete 
                      </div>
                  </li>
              </ul>
      </div>
}

const TableData = ({tableData = [], handleUpdateData = () => {},
handleDeleteVariant=()=>{},handleShowSaveButton=()=>{},showSaveButton,saveChanges=()=>{}}) => {
  const {openDeleteConfirmation,setOpenDeleteConfirmation} = useContext(AppContext)
  const [editableRowIndex,setEditableRowIndex] = useState()
  const onDataChange = (val, data, tableRowIndex, key) => {
    handleUpdateData({val, index: tableRowIndex, key})
  }
  const [rowData,setRowData]=useState({})
  const [updateImageIndex,setUpdateImageIndex] = useState(null)

  const onDelete = (data) => {
    const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
    setRowData(data)
    setOpenDeleteConfirmation(true)
  }

  const onDeleteConfirm=async ()=>{
    handleDeleteVariant(rowData)
  }

  const handleEditVariantProperties = (rowIndex)=>{
    const elem = document.activeElement;
    if(elem){
        elem?.blur();
    }
    handleShowSaveButton(true)
    const tableElement = document.getElementById('variantTable');
    if (tableElement) {
      tableElement.scrollTo({ left: tableElement.clientWidth+900, behavior: 'smooth' });
    }
    
    setEditableRowIndex(rowIndex)
  }

  const capitalizeFirstLetter = (heading)=>{
    const labelArray = heading.split(" ")

    const label = labelArray.map((word) => { 
      return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");

    return label
  }

  const table=tableData.length>0?
    <div className="w-[100%]">
          <div id='variantTable' className='container2 overflow-x-auto'>
            <table className='border-[1px] border-[rgba(224,224,224,1)]'>
              <thead>
                {<tr>
                  {
                    _.keys(tableData[0]).map((key, index) => {
                      return (<th key={key} className='px-[16px] py-[20px] w-[200px] min-w-[200px] text-start border-b-[1px] border-[rgba(224,224,224,1)]'>
                        <div className={`${key.toLowerCase().includes('id')?'w-[100px] min-w-[100px]':'w-[200px] min-w-[200px]'} font-medium`}>{capitalizeFirstLetter(key.replaceAll('_',' '))}</div>
                      </th>)
                    }
                    )
                  }
                  <th className='px-[16px] py-[20px] w-[80px] min-w-[80px] text-start border-b-[1px] border-[rgba(224,224,224,1)]'>
                        <div className='w-[80px] min-w-[80px] font-medium'>Actions</div>
                  </th>
                  <th className='px-[16px] py-[20px] w-[100px] min-w-[100px] text-start border-b-[1px] border-[rgba(224,224,224,1)]'>
                      <div className='w-[100px] min-w-[100px] font-medium'></div>
                  </th>
                  
                </tr>
                }
                </thead>
                <tbody>
                {
                  tableData.map((data,tableRowIndex) => {
                    
                    return(<tr key={tableRowIndex} className="border-b-[1px]">
                    {
                      Object.keys(data).map((key,index)=>{
                        return <td key={index} className='py-[5px] px-[16px]'>
                          <TableDataCell
                            data={data!=null && data[key]!=null?data[key]:''}
                            dataKey={key}
                            id={tableRowIndex}
                            editableRowIndex={editableRowIndex}
                            onChange={(val) => {
                              onDataChange(val, data, tableRowIndex, key)
                            }}
                            className='border-b-[1px]'
                            updateImageIndex={updateImageIndex}
                            setUpdateImageIndex={setUpdateImageIndex}
                          ></TableDataCell>
                        </td>
                      })
                    }
                    <td key={'action'}>
                      <ActionsMenu onDelete={onDelete} data={data} handleEditVariantProperties={handleEditVariantProperties} rowIndex={tableRowIndex}/>
                    </td>
                    {
                      showSaveButton && editableRowIndex==tableRowIndex ?
                      <td key={'save changes'}>
                        <button className='whitespace-nowrap bg-black text-white py-[5px] px-[10px]'
                          onClick={()=>{
                              saveChanges()
                              setUpdateImageIndex(null)
                          }}
                        >Save Changes</button>
                      </td>:''
                    }
                    
                  </tr>)
                  }

                  )}
                </tbody>
            </table>
          </div>
    </div>:
    <div className='w-full flex justify-center'>
        <div className='w-[30%] h-[70%] flex justify-center items-start'>
            No variant found
        </div>
    </div>
  
  return (
    <div>
      {
        openDeleteConfirmation?
        <DeleteConfirmation onDeleteConfirm={onDeleteConfirm}/>:''
      }
      {table}
    </div>
  )
}

export default TableData