import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../../services/apiUtility";
import { ManageLooksContext } from "../../../../context/LooksContext";

const ManageTryOnWindow = ({
  additionalClasses = "items-center",
  skinAnalyze = false,
  defaultMakeup = [],
}) => {
  const [loaded, setLoaded] = useState(false);

  const { previewProduct, preSelectedProduct } = useContext(ManageLooksContext)

  useEffect(() => {
    let onARLoad = () => {
      // do something when beautyAR successfully loads
      // showing
      setLoaded(true);
      const showParam = {
        querySelectorToAttach: "#tryonappenddiv",
        callback: () => {
          // on success full show
        },
        customStyleObj: {
          class: ["w-full", "h-full"],
          css: {
            // top : '1px'
          },
        },
      };
      // below function also works with await as well, incase callback hell is to be avoided
      if (window?.mirrarBeautyAR?.showBeautyAR) {
        window.mirrarBeautyAR.showBeautyAR(showParam);
      }

      if (loaded && window?.mirrarBeautyAR?.applyMakeupEffect) {
        Object.values(previewProduct).map((product) => {
          if (
            typeof window !== "undefined" &&
            product?.category?.category &&
            product?.variant?.variant_sku &&
            product?.product_code &&
            product?.variant
          ) {
            window.mirrarBeautyAR.applyMakeupEffect(
              product.category.category,
              product.variant.variant_sku,
              product.product_code,
              product.variant
            );
          }
        });
      }
    };

    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    let brandId = ApiService.brandId;
    let config = {
      callback: onARLoad,
      brandId: brandId,
      urlParams: {
        sdkVersion: "mirrar",
      },
    };

    script.onload = () => {
      window.mirrarBeautyAR.loadBeautyAR(config);
    };
    script.src = process.env.REACT_APP_MIRRAR_SRC;

    document.body.append(script);
  }, []);

  console.log("previewProduct",previewProduct);


  useEffect(() => {
    (async () => {

      // await window?.mirrarBeautyAR?.removeAllMakeupEffect?.()          // To remove all makeup before applying new ones

      if (loaded && window?.mirrarBeautyAR?.applyMakeupEffect) {
        // Always apply Product Catalog Products first
        preSelectedProduct.map(async (product) => {
          if (
            typeof window !== "undefined" &&
            product?.category?.category &&
            product?.variant?.variant_sku &&
            product?.product_code &&
            product?.variant
          ) {
            await window.mirrarBeautyAR.applyMakeupEffect(
              product.category.category,
              product.variant.variant_sku,
              product.product_code,
              product.variant
            );
          }
        })

        // After Product Catalog Products are applied then apply Click Preview Product
        Object.values(previewProduct).map(async (product) => {
          if (
            typeof window !== "undefined" &&
            product?.category?.category &&
            product?.variant?.variant_sku &&
            product?.product_code &&
            product?.variant
          ) {
            await window.mirrarBeautyAR.applyMakeupEffect(
              product.category.category,
              product.variant.variant_sku,
              product.product_code,
              product.variant
            );
          }
        });
      }
    })()
  }, [previewProduct, preSelectedProduct, loaded]);

  return (
    <>
      <div
        id="tryOn"
        className={`rounded-[4px] h-fit sticky top-0 w-full ${additionalClasses} relative`}
      >
        {/* <div className='absolute -z-[1] full top-0 right-0 bottom-0 left-0 z-1 flex items-center justify-center rounded bg-[rgba(0,0,0,.2)]'>&nbsp;</div> */}
        <div
          id="tryonappenddiv"
          className="text-white flex justify-center items-center"
          style={{
            width: "100%",
            height: "75vh",
          }}
        ></div>
      </div>
    </>
  );
};

export default ManageTryOnWindow;
