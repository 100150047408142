import React from 'react'

import CloseCatalogIcon from '../../../../assets/images/close-catalog.svg'

const ManageLooksSubmitConfirmation = ({
    submitProductsFunction = () => { },
    setPreviewAndSelectNotSame = () => { }
}) => {

    const closePopup = () => {
        setPreviewAndSelectNotSame(false)
    }

    return (
        <div className='fixed h-full w-full top-0 left-0 bg-[rgba(255,255,255,0.4)] z-20 backdrop-blur-sm'>
            <div className='absolute flex flex-col py-4 rounded-[6px] px-6 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[455px] h-[250px] bg-[#fff] border border-[#D6D6D6]'>
                <img src={CloseCatalogIcon} alt='close button' className='cursor-pointer absolute top-7 right-4 w-3 h-3' onClick={closePopup} />
                <p className='text-[24px] mb-4'>Finish Look</p>
                <p className='text-[0.875rem] font-light mb-3'>You have applied a different product but not added in catalog.</p>
                <p className='text-[0.75rem] font-light mb-3 text-[#E23D3D]'>This action will not save the applied product, only added products will be saved for this look.</p>
                <div className='flex-grow'>&nbsp;</div>
                <div className='flex items-center gap-4'>
                    <button type='button' className='w-2/6 cursor-pointer ml-auto py-3 border-[2px] border-[#000] rounded-[4px]' onClick={closePopup}>No</button>
                    <button type='button' className='w-2/6 cursor-pointer py-3 border-[2px] border-transparent bg-[#4552C5] text-[#fff] rounded-[4px]' onClick={submitProductsFunction}>Yes</button>
                </div>
            </div>
        </div>
    )
}

export default ManageLooksSubmitConfirmation
